import { NgIf } from '@angular/common';
import { Component, DoCheck, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Indexable } from '@core/utils/general.utils';
import { isEmpty } from 'lodash-es';
import { ERRORS } from './form-error-messages';

@Component({
  selector: 'smv-form-field-errors',
  standalone: true,
  imports: [NgIf],
  template: '<span *ngIf="showError">{{ mainError }}</span>',
})
export class FormFieldErrorsComponent implements DoCheck {
  @Input() field?: AbstractControl; // Used for field validators
  @Input() form?: FormGroup; // Used for form validators
  @Input() messages: Indexable<string> = {};
  @Input() handledErrors: string[] = [];

  public showError = false;
  public mainError?: string;

  ngDoCheck(): void {
    const control: AbstractControl | undefined = this.field ?? this.form;
    this.mainError = undefined;
    if (this.field) {
      this.showError = this.field.touched || !isEmpty(this.field.value);
    }
    if (this.form) {
      // Form value always contains a property for each form field
      // The form is considered pre-filled when at least one form field isn't empty
      this.showError = this.form.touched || Object.values(this.form.value).some((value) => !isEmpty(value));
    }
    if (control?.errors) {
      let errorKeys = Object.keys(control.errors as object);
      if (this.handledErrors.length) {
        errorKeys = errorKeys.filter((key) => this.handledErrors.includes(key));
      }
      this.mainError = this.messages[errorKeys[0]] ?? ERRORS[errorKeys[0]];

      this.mainError = this.mainError.replace('$value', control.errors[errorKeys[0]]);
    }
  }
}
