import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContextItem } from '@core/model/context-item.model';
import { Customer } from '@core/model/customer.model';
import { PaginatedResult } from '@core/model/paginated-result.model';
import { PagingInfo } from '@core/model/paging-info.model';
import { BooleanWrapper, CommonService, ListWrapper } from '@core/services/common.service';
import { TrapFilter } from '@widgets/components/statistic/statistic.model';
import { GeoserverVariablesService } from '@widgets/widgets-filter-cql.service';
import { Observable } from 'rxjs';
import { FilterConfig } from '../model/context-item-config.model';
import { Trap, TrapStatement } from '../model/trap.model';
import { VigieViroseDisplayMode } from '../vigie-virose-display-mode/vigie-virose-display-mode.component';

@Injectable({ providedIn: 'root' })
export class VigieViroseService extends CommonService {
  private readonly samplesBaseUrl: string = this.baseUrl + '/samples';
  private readonly datumBaseUrl: string = this.baseUrl + '/vigieVirose/datum';
  protected override readonly baseUrl: string = this.baseUrl + '/traps';

  public readonly filterState = new ContextItem<FilterConfig>({
    displayMode: VigieViroseDisplayMode.MODE_POINTS,
    displayFilter: 'FILTER_OBSERVATION',
    dataFilter: {},
  });

  constructor(protected override http: HttpClient, private geoserverFilter: GeoserverVariablesService) {
    super(http);
  }

  getTraps(trapCode: string, query?: TrapFilter): Observable<TrapStatement[]> {
    const params = query ?? {};
    return this.pipeExtractList(
      this.http.get<ListWrapper<TrapStatement>>(this.baseUrl + '/' + trapCode + '/samples', { params })
    );
  }

  getMultiTrapByZone(filter: TrapFilter): Observable<TrapStatement[]> {
    const customerFilterValue = this.geoserverFilter.customerFilterState.getValue();
    filter.customers = customerFilterValue != '*' ? customerFilterValue.replaceAll("'", '') : '';
    return this.pipeExtractList(
      this.http.get<ListWrapper<TrapStatement>>(this.baseUrl + '/multiTrapsInZone' + filter.getGetMappingValue())
    );
  }

  getMondayDatesWithData(useSendDate = false) {
    const url = this.samplesBaseUrl + '/mondayDatesWithData';
    const params = new HttpParams().set('useSendDate', useSendDate);
    return this.pipeExtractList(this.http.get<ListWrapper<string>>(url, { params }));
  }

  notifyCustomersOfWeekSamples(customers: Customer[], appId: number, pastWeekCount: number, useSendDate: boolean) {
    const codes = customers.map((customer) => customer.code);
    const params = new HttpParams().set('useSendDate', useSendDate);
    return this.http.post(this.samplesBaseUrl + `/notify/${appId}/${pastWeekCount}`, codes, { params });
  }

  checkCanSpecifyTrap(trapCode: string, email: string, restrict = true): Observable<boolean> {
    const params = {
      trapCode,
      email,
      restrict,
    };
    return this.pipeExtractBoolean(this.http.get<BooleanWrapper>(this.baseUrl + '/canSpecifyTrap', { params }));
  }

  retrieveCreatedTraps(appId: number, pagingInfo: PagingInfo): Observable<PaginatedResult<Trap>> {
    return this.sendListQuery<Trap>(pagingInfo, this.baseUrl + `/${appId}/userCreatedTraps`);
  }

  retrieveAllCreatedTraps(appId: number, pagingInfo: PagingInfo): Observable<Trap[]> {
    let url = this.baseUrl + `/${appId}/userCreatedTraps/list`;
    let params = new HttpParams();
    if (pagingInfo.filters.length) {
      url += '/search';
      params = pagingInfo.setPageFilters(params);
    }
    return this.pipeExtractList(this.http.get<ListWrapper<Trap>>(url, { params }));
  }

  retrieveTrapStatements(appId: number, pagingInfo: PagingInfo): Observable<PaginatedResult<TrapStatement>> {
    return this.sendListQuery<TrapStatement>(pagingInfo, this.datumBaseUrl + `/${appId}/vigieViroseUserDatums`);
  }

  retrieveAllTrapStatements(appId: number, pagingInfo: PagingInfo): Observable<TrapStatement[]> {
    let url = this.datumBaseUrl + `/${appId}/vigieViroseUserAllDatums`;
    let params = new HttpParams();
    if (pagingInfo.filters.length) {
      url += '/search';
      params = pagingInfo.setPageFilters(params);
    }
    return this.pipeExtractList(this.http.get<ListWrapper<TrapStatement>>(url, { params }));
  }
}
