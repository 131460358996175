import WKT from 'ol/format/WKT';
import { Geometry } from 'ol/geom';
import { comparaisonTag, inBetweenTag, logicalTag, notTag, nullTag, rootTag } from '../sld.utils';
import { XmlTag } from '../xml.utils';

interface Token {
  type: Pattern;
  text: string;
  remainder: string;
}

type Pattern =
  | 'EXPRESSION'
  | 'PROPERTY'
  | 'COMPARISON'
  | 'IS_NULL'
  | 'COMMA'
  | 'ARITHMETIC'
  | 'LOGICAL'
  | 'VALUE'
  | 'VARIABLE'
  | 'LPAREN'
  | 'RPAREN'
  | 'SPATIAL'
  | 'NOT'
  | 'BETWEEN'
  | 'GEOMETRY'
  | 'END';

export class CQL {
  private patterns = {
    VARIABLE: /{[_a-zA-Z.]*}[_a-zA-Z()[\]@:\-.,0-9]*/,
    EXPRESSION: /\[|\]|\*|\/|\+|-/,
    PROPERTY: /^[_a-zA-Z()[\]@:\-.,0-9]*/,
    COMPARISON: /^(=|<>|<=|<|>=|>|LIKE)/i,
    IS_NULL: /^IS NULL/i,
    COMMA: /^,/,
    ARITHMETIC: /^(\*|\/|\+|-)/,
    LOGICAL: /^(AND|OR)/i,
    VALUE: /^('([^']|'')*'|\d+(\.\d*)?|\.\d+|true|false)/,
    LPAREN: /^\(/,
    RPAREN: /^\)/,
    SPATIAL: /^(BBOX|INTERSECTS|DWITHIN|WITHIN|CONTAINS)/i,
    NOT: /^NOT/i,
    BETWEEN: /^BETWEEN/i,
    GEOMETRY: (text: string) => {
      const type = /^(POINT|LINESTRING|POLYGON|MULTIPOINT|MULTILINESTRING|MULTIPOLYGON|GEOMETRYCOLLECTION)/.exec(text);
      if (type) {
        const len = text.length;
        let idx = text.indexOf('(', type[0].length);
        if (idx > -1) {
          let depth = 1;
          while (idx < len && depth > 0) {
            idx++;
            switch (text.charAt(idx)) {
              case '(':
                depth++;
                break;
              case ')':
                depth--;
                break;
              default:
              // in default case, do nothing
            }
          }
        }
        return [text.substring(0, idx + 1)];
      }
      return;
    },
    END: /^$/,
  };

  private follows = {
    LPAREN: ['GEOMETRY', 'SPATIAL', 'PROPERTY', 'VALUE', 'VARIABLE', 'LPAREN'],
    RPAREN: ['NOT', 'LOGICAL', 'END', 'RPAREN', 'COMPARISON', 'ARITHMETIC'],
    PROPERTY: ['COMPARISON', 'BETWEEN', 'COMMA', 'IS_NULL', 'ARITHMETIC', 'RPAREN'],
    BETWEEN: ['VALUE', 'VARIABLE'],
    IS_NULL: ['END', 'RPAREN'],
    COMPARISON: ['VALUE', 'VARIABLE'],
    ARITHMETIC: ['VALUE', 'VARIABLE', 'PROPERTY'],
    COMMA: ['GEOMETRY', 'VALUE', 'VARIABLE', 'PROPERTY'],
    VALUE: ['LOGICAL', 'COMMA', 'RPAREN', 'END', 'ARITHMETIC'],
    VARIABLE: ['LOGICAL', 'COMMA', 'RPAREN', 'END', 'ARITHMETIC'],
    SPATIAL: ['LPAREN'],
    LOGICAL: ['NOT', 'VALUE', 'VARIABLE', 'SPATIAL', 'PROPERTY', 'LPAREN'],
    NOT: ['PROPERTY', 'LPAREN'],
    GEOMETRY: ['COMMA', 'RPAREN'],
    EXPRESSION: [],
    END: [],
  };

  private precedence = {
    RPAREN: 3,
    LOGICAL: 2,
    COMPARISON: 1,
    LPAREN: 0,
    PROPERTY: 0,
    BETWEEN: 0,
    IS_NULL: 0,
    ARITHMETIC: 0,
    COMMA: 0,
    VALUE: 0,
    VARIABLE: 0,
    SPATIAL: 0,
    NOT: 0,
    GEOMETRY: 0,
    EXPRESSION: 0,
    END: 0,
  };

  private tryToken(text: string, pattern: RegExp | ((text: string) => string[] | undefined)) {
    if (pattern instanceof RegExp) {
      return pattern.exec(text);
    } else {
      return pattern(text);
    }
  }

  private nextToken(text: string, tokens: Pattern[]): Token | undefined {
    const len = tokens.length;

    for (let i = 0; i < len; i++) {
      const token = tokens[i];
      const pat = this.patterns[token];
      const matches = this.tryToken(text, pat);
      if (matches) {
        const match = matches[0];
        const remainder = text.substring(match.length).replace(/^\s*/, '');
        return {
          type: token,
          text: match,
          remainder: remainder,
        };
      }
    }
    return;
  }

  private tokenize(text: string): Token[] {
    const results: Token[] = [];
    let token;
    let expect: Pattern[] = ['NOT', 'GEOMETRY', 'SPATIAL', 'PROPERTY', 'LPAREN'];

    do {
      token = this.nextToken(text, expect);
      if (token) {
        text = token.remainder ?? '';
        expect = this.follows[token.type] as Pattern[];
        if (token?.type != 'END' && !expect) {
          throw new Error('No follows list for ' + token.type);
        }
        results.push(token);
      } else {
        break;
      }
    } while (token?.type != 'END');

    return results;
  }

  private buildTree(tokens: Token[]): XmlTag | string | number | number[] | Geometry {
    const tok = tokens.pop();
    let rhs, lhs;
    let value, property;
    let min, max;
    let match;
    switch (tok?.type) {
      case 'LOGICAL':
        rhs = this.buildTree(tokens) as XmlTag;
        lhs = this.buildTree(tokens) as XmlTag;
        if (tok.text.toUpperCase() == 'AND') {
          return logicalTag('And', lhs, rhs);
        } else {
          return logicalTag('Or', lhs, rhs);
        }
      case 'ARITHMETIC':
        rhs = this.buildTree(tokens) as string | number;
        lhs = this.buildTree(tokens) as string | number;
        return '(' + lhs + tok.text + rhs + ')';
      case 'NOT':
        return notTag(this.buildTree(tokens) as XmlTag);
      case 'BETWEEN':
        max = this.buildTree(tokens) as string | number;
        min = this.buildTree(tokens) as string | number;
        property = this.buildTree(tokens) as string;
        return inBetweenTag(property, Number(min), Number(max));
      case 'COMPARISON':
        value = this.buildTree(tokens) as string | number;
        property = this.buildTree(tokens) as string;
        if (tok.text.toUpperCase() == 'LIKE') {
          return comparaisonTag('PropertyIsLike', property, String(value));
        }
        if (tok.text.toUpperCase() == '=') {
          return comparaisonTag('PropertyIsEqualTo', property, value);
        }
        if (tok.text.toUpperCase() == '>') {
          return comparaisonTag('PropertyIsGreaterThan', property, Number(value));
        }
        if (tok.text.toUpperCase() == '<') {
          return comparaisonTag('PropertyIsLessThan', property, Number(value));
        }
        if (tok.text.toUpperCase() == '>=') {
          return comparaisonTag('PropertyIsGreaterThanOrEqualTo', property, Number(value));
        }
        if (tok.text.toUpperCase() == '<=') {
          return comparaisonTag('PropertyIsLessThanOrEqualTo', property, Number(value));
        }
        return '';
      case 'IS_NULL':
        return nullTag(this.buildTree(tokens) as string);

      case 'VALUE':
        match = tok.text.match(/^'(.*)'$/);
        if (match) {
          return match[1].replace(/''/g, "'");
        } else if (tok.text === 'true' || tok.text === 'false') {
          return tok.text;
        } else {
          return Number(tok.text);
        }
      case 'SPATIAL':
        throw new Error('Spatial Filter not implemented');
      case 'GEOMETRY':
        return new WKT().readGeometry(tok.text);
      case 'PROPERTY':
        match = '' + tok.text + '';
        //Dans le cas d'un calcul,on rajoute des ()
        if (this.tryToken(match, this.patterns.EXPRESSION)) {
          match = '(' + match + ')';
        }
        return match;
      case 'VARIABLE':
        return tok.text;
      default:
        return tok?.text ?? '';
    }
  }

  private buildAst(tokens: Token[]): XmlTag {
    const operatorStack: Token[] = [];
    const postfix: Token[] = [];

    while (tokens.length) {
      const tok = tokens.shift();
      let p = 0;
      switch (tok?.type) {
        case 'PROPERTY':
        case 'GEOMETRY':
        case 'VALUE':
        case 'VARIABLE':
          postfix.push(tok);
          break;
        case 'ARITHMETIC':
        case 'COMPARISON':
        case 'BETWEEN':
        case 'IS_NULL':
        case 'LOGICAL':
          if (
            !(tok.type == 'LOGICAL' && tok.text == 'AND' && operatorStack[operatorStack.length - 1].type == 'BETWEEN')
          ) {
            p = this.precedence[tok?.type];

            while (operatorStack.length > 0 && this.precedence[operatorStack[operatorStack.length - 1].type] <= p) {
              const operator = operatorStack.pop();
              if (operator) {
                postfix.push(operator);
              }
            }

            operatorStack.push(tok);
          }
          break;
        case 'SPATIAL':
        case 'NOT':
        case 'LPAREN':
          operatorStack.push(tok);
          break;
        case 'COMMA':
        case 'END':
        case 'RPAREN':
          while (operatorStack.length > 0 && operatorStack[operatorStack.length - 1].type != 'LPAREN') {
            const operator = operatorStack.pop();
            if (operator) {
              postfix.push(operator);
            }
          }
          operatorStack.pop(); // toss out the LPAREN

          if (operatorStack.length > 0 && operatorStack[operatorStack.length - 1].type == 'SPATIAL') {
            const operator = operatorStack.pop();
            if (operator) {
              postfix.push(operator);
            }
          }
          break;
        default:
          throw new Error('Unknown token type ' + tok?.type);
      }
    }

    while (operatorStack.length > 0) {
      const operator = operatorStack.pop();
      if (operator) {
        postfix.push(operator);
      }
    }

    const result = rootTag(this.buildTree(postfix) as XmlTag);
    if (postfix.length > 0) {
      let msg = 'Remaining tokens after building AST: \n';
      for (let i = postfix.length - 1; i >= 0; i--) {
        msg += postfix[i].type + ': ' + postfix[i].text + '\n';
      }
      throw new Error(msg);
    }

    return result;
  }

  filterToSld(filter?: string) {
    if (filter) {
      return this.buildAst(this.tokenize(filter));
    }
    return rootTag();
  }
}
