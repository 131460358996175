/* eslint-disable @typescript-eslint/no-unused-vars */
import { ControlValueAccessor, FormControl, FormControlDirective, FormControlName, NgControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { CoercionComponent } from '@core/model/coercion-component.model';
import { isEmpty } from 'lodash-es';

type ControlValueAccessorFn<T> = (_: T) => void;

export class ControlValueAccessorImpl<T> extends CoercionComponent implements ControlValueAccessor {
  public value?: T;
  public control?: FormControl;
  public readonly errorStateMatcher: ErrorStateMatcher = {
    isErrorState: () => {
      if (!this.control) {
        return false;
      }
      return this.control.invalid && (this.control.touched || !isEmpty(this.control.value));
    },
  };

  private propagateChange: ControlValueAccessorFn<T> = (_: T) => {
    /* Do nothing */
  };

  updateValue(updatedValue: T): void {
    this.value = updatedValue;
    this.control?.markAsTouched();
    this.propagateChange(this.value);
  }

  writeValue(value: T): void {
    if (value !== this.value) {
      this.value = value;
    }
  }

  registerOnChange(fn: ControlValueAccessorFn<T>): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: ControlValueAccessorFn<T>): void {
    // Do nothing
  }

  setDisabledState?(isDisabled: boolean): void {
    // Do nothing
  }

  registerFormControl(control: NgControl): void {
    if (control instanceof FormControlName) {
      this.control = control.control;
    } else if (control instanceof FormControlDirective) {
      this.control = control.form;
    }
  }
}
