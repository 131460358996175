import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ParameterId, WidgetParameter, WidgetType } from '@core/model/application-api/widget.model';
import { DateUtils } from '@core/utils/date.utils';
import { WidgetService } from '@widgets/widgets.service';
import { Subscription, interval, map, startWith, takeWhile } from 'rxjs';
import { TimelineDatePipe } from './timeline-date.pipe';
import { Periodicity, PlayState, Timeline } from './timeline.model';
import { TimelineService } from './timeline.service';

@Component({
  selector: 'smv-timeline',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatSlideToggleModule, MatTooltipModule, TimelineDatePipe],
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit, OnDestroy {
  @Input() appId!: number;
  private GRADUATION_KEY = 'smv-{appId}-timeline-graduation-enabled';

  private startDate: Date;
  private endDate: Date;
  public dates: Date[] = [];
  public currentDate?: Date;

  public PlayState = PlayState;
  public playState = PlayState.INITIAL;
  public speeds = [1, 2, 4, 10, 20];
  public speedIndex = 0;
  public settingsOpen = false;
  public graduationVisible = false;
  public isLastDate = false;
  public isFirstDate = false;
  public periodicity = Periodicity.WEEKS;
  public controlsVisible = false;

  private playDelay = 1000;
  private animation?: Subscription;
  private subscriptions = new Subscription();

  constructor(private timelineService: TimelineService, private widgetService: WidgetService) {
    this.startDate = DateUtils.stringToDate('now -7', this.periodicity);
    this.endDate = DateUtils.stringToDate('now +7', this.periodicity);
    this.subscriptions.add(
      this.widgetService
        .getWidgetContextItem(WidgetType.TIMELINE)
        .getStream()
        .subscribe((widget) => {
          if (widget) {
            this.updateWidgetParameters(widget.parameters);
          }
        })
    );
  }

  ngOnInit() {
    this.dates = DateUtils.getDates(this.startDate, this.endDate, this.periodicity);
    this.clickOnDate(this.getDefaultDate());
    this.GRADUATION_KEY = this.GRADUATION_KEY.replace('{appId}', `${this.appId}`);
    if (localStorage.getItem(this.GRADUATION_KEY)) {
      this.graduationVisible = localStorage.getItem(this.GRADUATION_KEY) === 'true';
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.animation?.unsubscribe();
  }

  updateWidgetParameters(timelineParameters: WidgetParameter[]) {
    let date;
    timelineParameters.forEach((parameter) => {
      switch (parameter.id) {
        case ParameterId.PERIODICITY:
          this.periodicity = parameter.value as Periodicity;
          break;
        case ParameterId.STEP_DURATION:
          this.playDelay = parameter.value as number;
          break;
        case ParameterId.START_DATE:
          date = DateUtils.stringToDate(parameter.value as string, this.periodicity);
          this.startDate = date;
          break;
        case ParameterId.END_DATE:
          date = DateUtils.stringToDate(parameter.value as string, this.periodicity);
          this.endDate = date;
          break;
        case ParameterId.GRADUATION:
          this.graduationVisible = Boolean(parameter.value);
          break;
        default:
          break;
      }
    });
    this.ngOnInit();
  }

  private getDefaultDate(): Date {
    const now = Date.now();
    const datesMs = this.dates.map((date) => date.getTime());

    const infIndex = datesMs.findIndex((dateMs) => dateMs > now);
    if (infIndex > 0) {
      return this.dates[infIndex - 1];
    }
    return this.dates[this.dates.length - 2];
  }

  selectDate(date: Date) {
    this.currentDate = date;
    const dateIndex = this.dates.findIndex((d) => d === date);
    this.isFirstDate = dateIndex === 0;
    this.isLastDate = dateIndex === this.dates.length - 1;
    this.timelineService.timelineState.setValue(new Timeline(this.currentDate, this.periodicity));
  }

  clickOnDate(date: Date): void {
    this.selectDate(date);
    if (
      this.playState !== PlayState.INITIAL &&
      this.currentDate !== this.dates[this.dates.length - 1] &&
      this.currentDate !== this.dates[0]
    ) {
      this.playState = PlayState.PAUSING;
    } else {
      this.playState = PlayState.INITIAL;
    }
  }

  changeSpeed() {
    this.speedIndex = (this.speedIndex + 1) % this.speeds.length;
    this.pause();
    this.play();
  }

  play() {
    let currentDateIndex = 0;
    if (this.currentDate && this.playState != PlayState.INITIAL) {
      currentDateIndex = this.dates.indexOf(this.currentDate);
    }

    this.playState = PlayState.PLAYING;

    const play = interval(this.playDelay / this.speeds[this.speedIndex]).pipe(
      startWith(0),
      map(() => this.dates[currentDateIndex++ % this.dates.length]),
      takeWhile(() => this.playState === PlayState.PLAYING)
    );

    this.animation = play.subscribe((date) => {
      this.selectDate(date);

      if (date === this.dates[this.dates.length - 1]) {
        this.animation?.unsubscribe();
        this.playState = PlayState.INITIAL;
      }
    });
  }

  pause() {
    this.playState = PlayState.PAUSING;
    this.animation?.unsubscribe();
  }

  stop() {
    this.playState = PlayState.INITIAL;
    this.animation?.unsubscribe();
    this.currentDate = this.dates[0];
  }

  previous(): void {
    const dateIndex = this.dates.findIndex((date) => date === this.currentDate);
    this.selectDate(this.dates[dateIndex - 1]);
  }

  next(): void {
    const dateIndex = this.dates.findIndex((date) => date === this.currentDate);
    this.selectDate(this.dates[dateIndex + 1]);
  }

  toggleGraduation(active: boolean): void {
    this.graduationVisible = active;
    localStorage.setItem(this.GRADUATION_KEY, `${active}`);
  }

  toggleControls(): void {
    this.controlsVisible = !this.controlsVisible;
    this.settingsOpen = false;
  }
}
