import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { ApplicationApiService } from '@core/services/api/application-api.service';
import { NotificationService } from '@core/services/notification.service';
import { TrapFilter, WidgetTrap } from '@widgets/components/statistic/statistic.model';
import { StatisticService } from '@widgets/components/statistic/statistic.service';
import { IcareService } from '../icare.service';
import { TrapStatistic } from '../model/trap.model';

import moment from 'moment';
import { finalize } from 'rxjs';

@Component({
  selector: 'smv-icare-sheet',
  templateUrl: './icare-sheet.component.html',
  styleUrls: ['./icare-sheet.component.scss'],
})
export class IcareSheetComponent implements OnDestroy {
  public loading = true;
  public isDataLoaded = false;

  public title = $localize`Service Eudémis`;
  public titleMulti = $localize`Pression ravageur sur la zone géographique`;

  public traps: WidgetTrap[] = [];
  public trapMulti: TrapStatistic[] = [];
  public trapStatements: { [index: string]: TrapStatistic[] } = {};

  private date?: string;

  constructor(
    private icareService: IcareService,
    private statisticService: StatisticService,
    private applicationService: ApplicationApiService,
    private notificationService: NotificationService
  ) {
    const app = this.applicationService.currentApplication.getValue();
    if (app?.functionnalityConfig.campaignStart) {
      this.date = moment(app.functionnalityConfig.campaignStart, 'DD/MM').format('YYYY-MM-DD');
    }

    const statisticConfig = this.statisticService.statisticState.getValue();
    if (statisticConfig.filter) {
      this.getAreaData(statisticConfig.filter);
    } else if (statisticConfig.traps) {
      this.getTrapsData(statisticConfig.traps);
    } else {
      this.loading = false;
    }
  }

  ngOnDestroy() {
    this.statisticService.statisticState.reset();
  }

  getTrapsData(traps: WidgetTrap[]) {
    const query = {} as TrapFilter;
    if (this.date) {
      query.startDate = this.date;
    }

    for (const trap of traps) {
      this.icareService
        .getTrapsForStatistics(trap.trap_code, query)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe({
          next: (trapStatements: TrapStatistic[]) => {
            if (trapStatements.length != 0) {
              this.trapStatements[trap.trap_code] = trapStatements;
              this.traps.push(trap);
              this.isDataLoaded = true;
            }
          },
          error: (error: HttpErrorResponse) => {
            if (error.status === HttpStatusCode.Unauthorized) {
              this.notificationService.error(
                $localize`:Icare Statistic:Vous n'avez pas les droits pour visualiser le piège sélectionné`
              );
            } else if (error.status === HttpStatusCode.BadRequest) {
              this.notificationService.error(
                $localize`:Icare Statistic:Une erreur s'est produite dû à un manque de cohérence des paramètres `
              );
            } else {
              this.notificationService.unknownServerError();
            }
          },
        });
    }
  }

  getAreaData(filter: TrapFilter) {
    filter.startDate = this.date;

    this.icareService
      .getMultiTrapByZoneForStatistics(filter)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (traps: TrapStatistic[]) => {
          if (traps.length) {
            this.trapMulti = traps;
            this.isDataLoaded = true;
          }
        },
        error: (error: HttpErrorResponse) => {
          if (error.status === HttpStatusCode.InternalServerError) {
            this.notificationService.unknownServerError();
          }
        },
      });
  }
}
