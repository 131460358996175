import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { WfsLayerConfig } from '@core/model/layer-config.model';
import { MapUtils } from '@core/utils/map.utils';
import { GeoserverVariablesService } from '@widgets/widgets-filter-cql.service';

import { Feature } from 'ol';
import { getCenter } from 'ol/extent';
import LineString from 'ol/geom/LineString';
import { getLength } from 'ol/sphere';

interface EditionWarningsOptions {
  label: string;
  value: EditionWarnings;
}

type EditionWarnings = 'ALL' | 'ONLY_WARNINGS' | 'NOT_WARNINGS';

@Component({
  selector: 'smv-validation-filter-warning',
  templateUrl: './validation-filter-warning.component.html',
  styleUrls: ['./validation-filter-warning.component.scss'],
})
export class ValidationFilterWarningComponent implements OnChanges, OnDestroy {
  @Input() layerConfig!: WfsLayerConfig;
  @Input() editedFeatures: Feature[] = [];
  @Input() originalFeatures: Map<number, Feature> = new Map();

  @Output() warningIds = new EventEmitter<number[]>();

  public title = $localize`Filtre alertes`;

  public displayWarnings = new FormControl<EditionWarnings>('ALL');
  public options: EditionWarningsOptions[] = [
    {
      label: $localize`:Edition warning:Toutes les entités`,
      value: 'ALL',
    },
    {
      label: $localize`:Edition warning:Sans alertes`,
      value: 'NOT_WARNINGS',
    },
    {
      label: $localize`:Edition warning:Avec alertes`,
      value: 'ONLY_WARNINGS',
    },
  ];

  private warnings: number[] = [];

  constructor(private geoserverVariables: GeoserverVariablesService) {
    this.displayWarnings.valueChanges.subscribe(() => this.computeCqlFilter());
    this.computeCqlFilter();
  }

  ngOnChanges(): void {
    this.checkWarnings();
  }

  ngOnDestroy(): void {
    this.geoserverVariables.editionValidationState.setParticularValue('editionWarnings', '');
  }

  private checkWarnings() {
    this.warnings = [];
    if (this.layerConfig.validationConfiguration?.distanceWarning) {
      this.checkGeomDistance(this.layerConfig.validationConfiguration?.distanceWarning);
    }
    this.warnings = [...new Set(this.warnings)];
    this.warningIds.emit(this.warnings);
  }

  private checkGeomDistance(threshold: number) {
    for (const feature of this.editedFeatures) {
      const originalFeature = this.originalFeatures.get(feature.get('linked_entity'));
      if (originalFeature) {
        const editedGeom = feature.getGeometry()?.getExtent();
        const originalGeom = originalFeature.getGeometry()?.getExtent();

        if (editedGeom && originalGeom) {
          const editedCenter = getCenter(editedGeom);
          const originalCenter = getCenter(originalGeom);

          const line = new LineString([editedCenter, originalCenter]);
          const length = getLength(line, {
            projection: this.layerConfig.projection ?? MapUtils.PROJECTION_MAP?.getCode(),
          });

          if (length >= threshold) {
            this.warnings.push(MapUtils.getFeatureId(feature));
          }
        }
      }
    }
  }

  private computeCqlFilter() {
    let geoserverVariable = '';
    if (this.warnings.length) {
      geoserverVariable = ' id in (' + this.warnings.join(', ') + ') ';
      switch (this.displayWarnings.value) {
        case 'ONLY_WARNINGS':
          break;
        case 'NOT_WARNINGS':
          geoserverVariable = ' not' + geoserverVariable;
          break;
        default:
          geoserverVariable = '';
      }
    }
    this.geoserverVariables.editionValidationState.setParticularValue('editionWarnings', geoserverVariable);
  }
}
