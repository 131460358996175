import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { DragDropItem } from './drag-drop-item.model';

@Component({
  selector: 'smv-drag-drop-item',
  standalone: true,
  imports: [CommonModule, DragDropModule, MatIconModule],
  templateUrl: './drag-drop-item.component.html',
  styleUrls: ['./drag-drop-item.component.scss'],
})
export class DragDropItemComponent<T> implements OnInit, OnChanges {
  @Input() parent?: DragDropItem<T>;
  @Input() item!: DragDropItem<T>;
  @Input() connectedDropLists: string[] = [];
  @Input() firstChild = false;
  @Input() lastChild = false;
  @Input() dragging = false;
  @Output() draggingChange = new EventEmitter<boolean>();
  @Output() dropped = new EventEmitter<void>();

  public isDraggingItem = false;

  ngOnInit(): void {
    if (!this.parent) {
      this.connectedDropLists = this.extractDropListIds(this.item).reverse();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dragging']) {
      this.isDraggingItem = this.dragging;
    }
  }

  toggleDragging(isDragging: boolean): void {
    this.isDraggingItem = isDragging;
    this.draggingChange.emit(isDragging);
  }

  // Nested items
  droppedInside(
    event: CdkDragDrop<{
      parent: DragDropItem<T> | undefined;
      item: DragDropItem<T>;
    }>
  ): void {
    if (event.previousContainer.data.parent && event.container.data.parent) {
      this.insertIntoContainer(event.item.data, event.previousContainer.data.parent, event.container.data.item);
    }
  }

  // Items can be dropped before/after their siblings or before/after another node in the tree
  droppedAround(
    event: CdkDragDrop<{ parent: DragDropItem<T>; item: DragDropItem<T> }>,
    position: 'before' | 'after'
  ): void {
    const parentItem = event.container.data.parent;
    const movedItem = event.item.data;
    const refItem = event.container.data.item;
    if (parentItem.hasChild(movedItem)) {
      this.moveInSameContainer(movedItem, refItem, parentItem, position);
    } else {
      this.insertIntoContainer(movedItem, event.previousContainer.data.parent, parentItem, refItem, position);
    }
  }

  // CDK must know all the drop list IDs to determine where the items can be moved.
  // IDs are recursively extracted from the root then passed as a parameter to the
  // nested DragDropItemComponents
  private extractDropListIds(item: DragDropItem<T>): string[] {
    const ids: string[] = item.getContainers();
    item.children.forEach((child) => {
      ids.push(...this.extractDropListIds(child));
    });
    return ids;
  }

  // Reorder siblings in the same parent item
  private moveInSameContainer(
    item: DragDropItem<T>,
    refItem: DragDropItem<T>,
    refItemParent: DragDropItem<T>,
    position: 'after' | 'before'
  ): void {
    const refItemIndex = refItemParent.childIndex(refItem);
    const updatedItemIndex = position === 'before' ? refItemIndex : refItemIndex + 1;
    moveItemInArray(refItemParent.children, refItemParent.childIndex(item), updatedItemIndex);
    this.dropped.emit();
  }

  // Move an item to a different node of the tree.
  // The target node cannot be a descendant of the item being moved.
  private insertIntoContainer(
    movedItem: DragDropItem<T>,
    movedItemParent: DragDropItem<T>,
    refItemParent: DragDropItem<T>,
    refItem?: DragDropItem<T>,
    position?: 'after' | 'before'
  ): void {
    // Prevent drop in a nested child
    if (movedItem.allowDrop(refItemParent.uniqueId)) {
      movedItemParent.removeChild(movedItem);
      refItemParent.children.push(movedItem);
      if (refItem && position) {
        this.moveInSameContainer(movedItem, refItem, refItemParent, position);
      }
    }
  }
}
