import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AAC_GEOSERVER_LAYER, RPG_GEOSERVER_LAYER } from '@assets/widgets/qualih2o/qualih2o_base_config';
import { DrawInteractionHelper } from '@core/helpers/draw-interaction.helper';
import { WidgetType } from '@core/model/application-api/widget.model';
import { WmsLayerConfig } from '@core/model/layer-config.model';
import { RpgStyle } from '@core/model/widgets/qualih2o.model';
import { ApplicationApiService } from '@core/services/api/application-api.service';
import { MapService } from '@core/services/map.service';
import { XmlBuilder, XmlTag } from '@core/utils/xml.utils';
import { DataLayerConfig, DataLayerGroupConfig } from '@feature/client-app/data-layer-config.model';
import { ParcelAreaStatsComponent } from '@feature/client-app/widgets/quali-h2o/parcel-area-stats/parcel-area-stats.component';
import { TownListComponent } from '@feature/client-app/widgets/quali-h2o/town-list/town-list.component';
import { CqlLayerHelper, RpgLayerHelper } from './helpers/cql-layer.helper';

import { OlGmapDefaultConfiguration } from '@syngenta/ol-gmap';
import { FeatureCollection } from 'geojson';
import { Map as OlMap } from 'ol';
import WFS from 'ol/format/WFS';
import Intersects from 'ol/format/filter/Intersects';
import { Geometry } from 'ol/geom';
import TileWMS from 'ol/source/TileWMS';
import { Observable, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class Qualih2oService {
  public get mapInstance(): OlMap {
    return this.mapService.mapInstance;
  }

  public rpgLayer = new RpgLayerHelper();
  public aacLayer = new CqlLayerHelper();

  constructor(
    private dialog: MatDialog,
    private http: HttpClient,
    private mapService: MapService,
    private applicationService: ApplicationApiService
  ) {
    mapService.layers$.getStream().subscribe((layerGroups) => this.registerLayers(layerGroups));
  }

  registerLayers(layerGroups: DataLayerGroupConfig[]): void {
    const allLayers = layerGroups
      .flatMap((group) => group.layers)
      .filter((layer) => layer.config.fromWidget === WidgetType.QUALIH2O);

    this.rpgLayer.setLayer(this.getWmsLayer(allLayers, RPG_GEOSERVER_LAYER));
    this.aacLayer.setLayer(this.getWmsLayer(allLayers, AAC_GEOSERVER_LAYER));
  }

  observeWmsLegend(layer: DataLayerConfig): Observable<string | string[]> {
    if (!layer.olLayer || !(layer.config instanceof WmsLayerConfig)) {
      return of([]);
    }

    // Update the legend based on the RPG's current style
    if (layer.config.geoserverLayerName === RPG_GEOSERVER_LAYER) {
      return this.rpgLayer.layerStyle$.pipe(
        map((style) => this.getRpgLegendUrl(layer.config as WmsLayerConfig, style))
      );
    }

    return of(layer.config.getLegendUrl(layer.olLayer.getSource() as TileWMS, this.mapService.getScale()));
  }

  drawParcelArea(): DrawInteractionHelper | undefined {
    if (!this.mapInstance) {
      return;
    }

    const drawInteraction = new DrawInteractionHelper(this.mapInstance, {
      freehand: false,
      onDrawEnd: (feature) => {
        const geometry = feature.getGeometry();
        if (geometry) {
          this.getParcelStatsInGeometry(geometry);
        }
      },
    });
    drawInteraction.addToMap();
    return drawInteraction;
  }

  getParcelStatsInGeometry(geometry: Geometry): void {
    const featureRequest = new WFS().writeGetFeature({
      srsName: OlGmapDefaultConfiguration.mapEpsg,
      featureNS: 'http://syngenta',
      featurePrefix: 'syngenta',
      featureTypes: ['rpg'],
      outputFormat: 'application/json',
      filter: new Intersects('geom', geometry, OlGmapDefaultConfiguration.mapEpsg),
    }) as HTMLElement;
    this.dialog.open(ParcelAreaStatsComponent, { data: featureRequest.outerHTML });
  }

  getTownsInAAC(featureId: string): void {
    const [layerName, objectId] = featureId.split('.');

    // Write a manual GetFeature request due to OL's writeGetFeature
    // not supporting the ogc:Function filter
    const customFilterTag: XmlTag = {
      name: 'Filter',
      attributes: { xmlns: 'http://www.opengis.net/ogc' },
      content: [
        {
          name: 'Intersects',
          content: [
            { name: 'PropertyName', content: 'geom' },
            {
              name: 'ogc:Function',
              attributes: { name: 'querySingle' },
              content: [
                { name: 'ogc:Literal', content: layerName },
                { name: 'ogc:Literal', content: 'geom' },
                { name: 'ogc:Literal', content: `IN ('${objectId}')` },
              ],
            },
          ],
        },
      ],
    };

    const getFeatureTagWrapper: XmlTag = {
      name: 'GetFeature',
      attributes: {
        xmlns: 'http://www.opengis.net/wfs',
        service: 'WFS',
        version: '1.1.0',
        outputFormat: 'application/json',
        'xsi:schemaLocation': 'http://www.opengis.net/wfs http://schemas.opengis.net/wfs/1.1.0/wfs.xsd',
        'xmlns:ogc': 'http://www.opengis.net/ogc',
        'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
      },
      content: [
        {
          name: 'Query',
          attributes: { typeName: 'syngenta:communes', srsName: 'EPSG:3857', 'xmlns:syngenta': 'http://syngenta' },
          content: [customFilterTag],
        },
      ],
    };

    this.dialog.open(TownListComponent, {
      data: this.http.post<FeatureCollection>(
        '/services-webapp-syngentamap/proxy-carte/' + this.applicationService.currentApplication.getValue()?.id,
        XmlBuilder.buildTag(getFeatureTagWrapper)
      ),
    });
  }

  getRpgLegendUrl(config: WmsLayerConfig, rpgStyle?: RpgStyle): string | string[] {
    if (!this.rpgLayer.tileLayer) {
      return [];
    }
    const configDuplicate = WmsLayerConfig.fromLayer(config);
    configDuplicate.activeStyle = this.rpgLayer.getStyleName(rpgStyle);
    return configDuplicate.getLegendUrl(this.rpgLayer.tileLayer.getSource() as TileWMS, this.mapService.getScale());
  }

  private getWmsLayer(layers: DataLayerConfig[], layerName: string): DataLayerConfig | undefined {
    return layers.find(
      (layer) => layer.config instanceof WmsLayerConfig && layer.config.geoserverLayerName === layerName
    );
  }
}
