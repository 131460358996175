import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { WfsLayerConfig } from '@core/model/layer-config.model';
import { GeoserverService } from '@core/services/geoserver.service';
import { GeoserverLayersUtils } from '@core/utils/geoserver-layers.utils';
import { finalize } from 'rxjs';
import { AttributeForm } from '../../layer-attribute-tab/layer-attribute-tab.component';

@Component({
  selector: 'smv-attribute-default-values',
  templateUrl: './attribute-default-values.component.html',
  styleUrls: ['./attribute-default-values.component.scss'],
})
export class AttributeDefaultValuesComponent implements OnInit {
  @Input() config!: WfsLayerConfig;
  @Input() attribute!: FormGroup<AttributeForm>;

  public UUID = '{UUID}';
  public ID = '{X_NUMBER_ID}';
  public LATITUDE = '{LATITUDE}';
  public LONGITUDE = '{LONGITUDE}';
  public DEPT_CODE = '{DEPT_CODE}';
  public NOM_COMMUNE = '{NOM_COMMUNE}';
  public ZIP_CODE = '{ZIP_CODE}';

  public newAttr = new FormControl<string | null>(null);
  public isLoading = false;
  public keys: string[] = ['default'];

  private name?: string;
  private url?: string;

  constructor(private geoserverService: GeoserverService) {}

  ngOnInit(): void {
    this.name = this.attribute.controls.name.value;
    this.keys = Object.keys(this.attribute.controls.defaultValues.value);
    if (this.name) {
      this.url = GeoserverLayersUtils.wfsGetPropertyValuesUrl(
        this.config,
        this.name,
        this.name + ' is not null',
        this.name + ' A'
      );
    }

    this.attribute.controls.defaultValues.valueChanges.subscribe((value) => (this.keys = Object.keys(value)));
  }

  get nbOfValues() {
    return Object.values(this.attribute.controls.defaultValues.value).flat().length;
  }

  loadAttrValues() {
    if (this.url && this.name) {
      this.isLoading = true;
      this.geoserverService
        .getPropertyValuesFromGeoserver(this.url, this.name)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe((values) => {
          this.attribute.controls.defaultValues.setValue({ default: values });
          this.keys = ['default'];
        });
    }
  }

  onAddAttribute(newAttr: string | null) {
    if (newAttr) {
      const attrs = this.attribute.controls.defaultValues.value;
      if (attrs['default']) {
        attrs['default'].unshift(newAttr);
      } else {
        attrs['default'] = [newAttr];
      }
      this.attribute.controls.defaultValues.patchValue(attrs);
      this.newAttr.reset();
    }
  }

  removeAttribute(indexKey: string, indexValue: number) {
    const attrs = this.attribute.controls.defaultValues.value;
    if (indexKey !== 'default' && attrs[indexKey].length === 1) {
      delete attrs[indexKey];
    } else {
      attrs[indexKey].splice(indexValue, 1);
    }
    this.attribute.controls.defaultValues.patchValue(attrs);
  }

  resetAttrValues() {
    this.attribute.controls.dependsOn.reset();
    this.attribute.controls.defaultValues.reset();
  }

  drop(event: CdkDragDrop<string[]>, key: string) {
    const attrs = this.attribute.controls.defaultValues.value;
    moveItemInArray(attrs[key], event.previousIndex, event.currentIndex);
    this.attribute.controls.defaultValues.patchValue(attrs);
  }
}
