<form [formGroup]="formGroup">
  <ng-container *ngIf="formGroup.controls.point">
    <div class="section-title" i18n="Layer base style">Points</div>
    <div formGroupName="point" class="field-grid">
      <smv-form-field-wrapper formControlName="symbol" controlType="select" [selectOptions]="symbols" required>
        <span mat-label i18n="Layer base style">Type de symbole</span>
      </smv-form-field-wrapper>
      <smv-form-field-wrapper formControlName="symbolSize" type="number">
        <span mat-label i18n="Layer base style">Taille des symboles</span>
      </smv-form-field-wrapper>
      <smv-color-picker-field formControlName="symbolFillColor">
        <span mat-label i18n="Layer base style">Remplissage</span>
      </smv-color-picker-field>
      <smv-color-picker-field formControlName="symbolStrokeColor">
        <span mat-label i18n="Layer base style">Couleur de la bordure</span>
      </smv-color-picker-field>
      <smv-form-field-wrapper formControlName="symbolStrokeWidth" type="number">
        <span mat-label i18n="Layer base style">Épaisseur</span>
      </smv-form-field-wrapper>
    </div>
  </ng-container>

  <ng-container class="section-title" [ngSwitch]="layerGeometry">
    <div class="section-title" *ngSwitchCase="GeometryType.LINE" i18n="Layer base style">Lignes</div>
    <div class="section-title" *ngSwitchCase="GeometryType.POLYGON" i18n="Layer base style">Polygones</div>
    <div class="section-title" *ngSwitchCase="undefined" i18n="Layer base style">Lignes et polygones</div>
  </ng-container>
  <div *ngIf="formGroup.controls.line" formGroupName="line">
    <div class="field-grid">
      <smv-form-field-wrapper
        formControlName="strokePattern"
        controlType="select"
        [selectOptions]="strokePatterns"
        required
      >
        <span mat-label i18n="Layer base style">Type</span>
      </smv-form-field-wrapper>
      <smv-color-picker-field formControlName="strokeColor">
        <span mat-label i18n="Layer base style">Couleur</span>
      </smv-color-picker-field>
      <smv-form-field-wrapper formControlName="strokeWidth" type="number">
        <span mat-label i18n="Layer base style">Épaisseur</span>
      </smv-form-field-wrapper>
    </div>
  </div>

  <div *ngIf="formGroup.controls.polygon" formGroupName="polygon">
    <div class="subsection-title" i18n="Layer base style">Remplissage</div>
    <div class="field-grid">
      <smv-form-field-wrapper
        formControlName="fillPattern"
        controlType="select"
        [selectOptions]="fillPatterns"
        required
      >
        <span mat-label i18n="Layer base style">Motif</span>
      </smv-form-field-wrapper>
      <smv-color-picker-field formControlName="fillColor">
        <span mat-label i18n="Layer base style">Couleur</span>
      </smv-color-picker-field>
    </div>

    <div class="subsection-title" i18n="Layer base style">Contour</div>
    <div class="field-grid">
      <smv-form-field-wrapper
        formControlName="strokePattern"
        controlType="select"
        [selectOptions]="strokePatterns"
        required
      >
        <span mat-label i18n="Layer base style">Type</span>
      </smv-form-field-wrapper>
      <smv-color-picker-field formControlName="strokeColor">
        <span mat-label i18n="Layer base style">Couleur</span>
      </smv-color-picker-field>
      <smv-form-field-wrapper formControlName="strokeWidth" type="number">
        <span mat-label i18n="Layer base style">Épaisseur</span>
      </smv-form-field-wrapper>
    </div>
  </div>

  <div class="section-title" i18n="Layer base style">Étiquettes</div>
  <div formGroupName="text">
    <smv-form-field-wrapper formControlName="text" hasHelp>
      <span mat-label i18n="Layer base style">Texte</span>
      <span help>{{ styleTextHelp }}</span>
    </smv-form-field-wrapper>
    <div class="field-grid">
      <smv-form-field-wrapper formControlName="fontSize" type="number">
        <span mat-label i18n="Layer base style">Taille de la police</span>
      </smv-form-field-wrapper>
      <smv-form-field-wrapper
        formControlName="alignment"
        controlType="select"
        [selectOptions]="textAlignments"
        required
      >
        <span mat-label i18n="Layer base style">Alignement</span>
      </smv-form-field-wrapper>
      <smv-form-field-wrapper formControlName="offsetX" type="number">
        <span mat-label i18n="Layer base style">Décalage en X</span>
      </smv-form-field-wrapper>
      <smv-form-field-wrapper formControlName="offsetY" type="number">
        <span mat-label i18n="Layer base style">Décalage en Y</span>
      </smv-form-field-wrapper>
      <smv-color-picker-field formControlName="textFillColor">
        <span mat-label i18n="Layer base style">Couleur du texte</span>
      </smv-color-picker-field>
      <smv-color-picker-field formControlName="textStrokeColor">
        <span mat-label i18n="Layer base style">Couleur de contour</span>
      </smv-color-picker-field>
      <smv-color-picker-field formControlName="textBackGroundColor">
        <span mat-label i18n="Layer base style">Couleur de fond</span>
      </smv-color-picker-field>
      <smv-form-field-wrapper formControlName="textMargin" type="number">
        <span mat-label i18n="Layer base style">Espace autour du texte</span>
      </smv-form-field-wrapper>
    </div>
  </div>
</form>
