<div class="smv-dialog">
  <h1 mat-dialog-title>{{ title }}</h1>
  <mat-divider></mat-divider>
  <div mat-dialog-content class="content">
    <div class="numbers">
      <label><b i18n>Nombre d'agriculteurs</b> {{ synthesis?.nbFarmers }}</label>
      <label><b i18n>Nombre de parcelles</b> {{ synthesis?.nbParcels }}</label>
      <label><b i18n>Surface totale</b> {{ synthesis?.totalSurface | number : '1.1-2' }} ha</label>
    </div>
    <smv-chart *ngIf="config" [elementChart]="config" [pie]="true" />
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button mat-dialog-close i18n="Button">Fermer</button>
  </div>
</div>
