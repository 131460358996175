import { Indexable } from '@core/utils/general.utils';

export type ErrorMessages = Indexable<string>;

export const ERRORS: ErrorMessages = Object.freeze({
  required: $localize`Champ requis`,
  email: $localize`Email invalide`,
  min: $localize`La valeur est inférieure au minimum autorisé`,
  max: $localize`La valeur est supérieure au maximum autorisé`,
  minlength: $localize`La chaîne de caractère est trop courte`,
  maxlength: $localize`La chaîne de caractère est trop longue`,
  dateAfter: $localize`La date est inférieure à $value`,
  dateBefore: $localize`La date est supérieure à $value`,
  pattern: $localize`Le formatage est invalide`,
  excludePattern: $localize`La valeur ne doit pas correspondre à $value`,
  excludeValue: $localize`La valeur $value n'est pas acceptée`,
});
