import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GeoserverVariablesService } from '@widgets/widgets-filter-cql.service';
import { SoilType } from '../../model/soilt-type.model';
import { RevoltService } from '../../revolt.service';

@Component({
  selector: 'smv-revolt-soil-type-filter',
  templateUrl: './revolt-soil-type-filter.component.html',
  styleUrls: ['./revolt-soil-type-filter.component.scss'],
})
export class RevoltSoilTypeFilterComponent {
  public soilTypes: SoilType[] = [];
  public selectedSoilType = new FormControl<number>(0, { nonNullable: true });

  private readonly filterAttr = 'water_availability';

  constructor(
    private readonly geoserverVariables: GeoserverVariablesService,
    private readonly revoltService: RevoltService
  ) {
    this.revoltService.getSoilTypes().subscribe((soilTypes) => {
      soilTypes.sort((a, b) => a.waterAvailability - b.waterAvailability);
      this.soilTypes = soilTypes;
      soilTypes.length > 0 && this.selectedSoilType.setValue(soilTypes[0].waterAvailability);
    });
    this.computeFilter = this.computeFilter.bind(this);
    this.selectedSoilType.valueChanges.subscribe(this.computeFilter);
  }

  private computeFilter(selected: number) {
    const filter = `${this.filterAttr} = ${selected}`;
    this.geoserverVariables.revoltFilterState.setParticularValue('soilType', filter);
  }
}
