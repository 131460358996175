import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ApplicationModel } from '@core/model/application-api/application.model';
import { User } from '@core/model/user.model';
import { ApplicationApiService } from '@core/services/api/application-api.service';
import { AuthService } from '@core/services/auth.service';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'smv-home',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatTooltipModule, MatIconModule, MatDividerModule, RouterModule],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public applications$?: Observable<ApplicationModel[]>;
  public currentUser?: User;

  public baseUrl: string;

  constructor(private applicationApi: ApplicationApiService, private authService: AuthService) {
    this.authService.whoAmI().subscribe((user: User) => {
      if (user) {
        this.currentUser = user;
      }
    });

    this.baseUrl = this.applicationApi.getBaseUrl();
  }

  ngOnInit(): void {
    this.applications$ = this.applicationApi.getAccessibleApplications().pipe(map((page) => page.content));
  }
}
