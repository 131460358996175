<smv-loading [loaders]="[loader]" />
<div class="smv-dialog">
  <h1 mat-dialog-title i18n="Notify Customers|Title">Sélection des clients</h1>
  <mat-divider />
  <div mat-dialog-content>
    <p i18n="Notify customers|Description">
      Vous allez envoyer une notification aux utilisateurs associés aux clients sélectionnés
    </p>
    <mat-slide-toggle (change)="changeUseSendDate($event.checked)">
      <span i18n="Notify Customers|Selected by">Sélection par</span>
      <span
        [ngClass]="useSendDateVar ? 'inactive-toggle-value' : 'active-toggle-value'"
        i18n="Notify Customers|Delivery date"
      >
        date de réception
      </span>
      /
      <span
        [ngClass]="useSendDateVar ? 'active-toggle-value' : 'inactive-toggle-value'"
        i18n="Notify Customers|Send date"
      >
        date d'envoi
      </span>
    </mat-slide-toggle>
    <mat-form-field appearance="fill" class="date-selection">
      <mat-label i18n="Notify Customers">Choix de la semaine</mat-label>
      <mat-select [(value)]="pastWeekCount" (selectionChange)="changeDataWeek($event.value)">
        <mat-option *ngFor="let date of dateOptions" [value]="date.value">{{ date.label }}</mat-option>
      </mat-select>
    </mat-form-field>
    <smv-table
      [generalActions]="actionsConfig"
      identifier="code"
      [paginatorDisabled]="true"
      [columns]="columns"
      [data]="customers"
      (selectedRows)="selectedCustomers = $event"
    />
  </div>

  <div mat-dialog-actions>
    <button mat-button mat-dialog-close i18n="Button">Annuler</button>
    <button mat-raised-button color="primary" (click)="sendData()" [disabled]="!selectedCustomers.length" i18n="Button">
      Valider
    </button>
  </div>
</div>
