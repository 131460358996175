import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OlGmapDefaultConfiguration } from '@syngenta/ol-gmap';
import { ImageTile, Tile } from 'ol';
import { getWidth } from 'ol/extent';
import { default as GeoJSON, default as GeoJSONFormat } from 'ol/format/GeoJSON';
import WFS from 'ol/format/WFS';
import { Geometry, Point } from 'ol/geom';
import BaseLayer from 'ol/layer/Base';
import Heatmap from 'ol/layer/Heatmap';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import { bbox } from 'ol/loadingstrategy';
import { get as getProjection, Projection } from 'ol/proj';
import TileSource from 'ol/source/Tile';
import WMSTileSource from 'ol/source/TileWMS';
import VectorSource from 'ol/source/Vector';
import VectorTile from 'ol/source/VectorTile';
import WMTS from 'ol/source/WMTS';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import TileState from 'ol/TileState';

import { AttributeType, LayerProperty, LayerSubtype, LayerType } from '@core/model/application-api/layer.model';
import {
  LayerConfig,
  VectorLayerConfig,
  WfsLayerConfig,
  WmsLayerConfig,
  WmtsLayerConfig,
} from '@core/model/layer-config.model';
import { GeoserverLayersUtils } from '@core/utils/geoserver-layers.utils';
import { MapUtils } from '@core/utils/map.utils';
import { DataLayerConfig } from '@feature/client-app/data-layer-config.model';
import { GeoserverVariablesService } from '@feature/client-app/widgets/widgets-filter-cql.service';
import VectorTileLayer from 'ol/layer/VectorTile';
import { catchError, map, Observable, of } from 'rxjs';
import { CapabilitiesParserService } from './capabilities-parser.service';
import { NotificationService } from './notification.service';

interface FeaturesType {
  featureTypes: FeatureType[];
  targetNamespace: string;
  targetPrefix: string;
}

interface FeatureType {
  properties: LayerProperty[];
  typeName?: string;
}

@Injectable({ providedIn: 'root' })
export class LayerService {
  readonly olGmapConfiguration = OlGmapDefaultConfiguration;

  constructor(
    private readonly http: HttpClient,
    private readonly capabilitiesParser: CapabilitiesParserService,
    private readonly geoserverVariables: GeoserverVariablesService,
    private readonly notification: NotificationService
  ) {}

  createIgnLayer(layer: WmtsLayerConfig): TileLayer<TileSource> {
    if (!layer.matrixSet) {
      throw new Error('Missing matrixSet property for IGN layer ' + layer.shortName);
    }

    const resolutions: number[] = [];
    const matrixIds: string[] = [];
    const mapProjection = getProjection(this.olGmapConfiguration.mapEpsg);

    if (!mapProjection) {
      throw new Error('Unknown projection: ' + this.olGmapConfiguration.mapEpsg);
    }

    const maxResolution = getWidth(mapProjection.getExtent()) / 256;

    for (let i = 0; i < 20; i++) {
      matrixIds.push(i.toString());
      resolutions.push(maxResolution / Math.pow(2, i));
    }

    const tileGrid = new WMTSTileGrid({
      origin: [-20037508, 20037508],
      resolutions,
      matrixIds,
    });

    const ignSource = new WMTS({
      url: layer.url,
      layer: layer.geoserverLayerName ?? '',
      matrixSet: layer.matrixSet,
      format: layer.format,
      projection: this.olGmapConfiguration.mapEpsg,
      tileGrid,
      style: 'normal',
      attributions: layer.getAttributionTemplate(),
    });

    return new TileLayer({ source: ignSource });
  }

  createWmtsLayer(layer: WmtsLayerConfig, geoserverUrl: string): Observable<TileLayer<WMTS> | VectorTileLayer> {
    const wmtsCapabilitiesUrl = layer.url ?? geoserverUrl + '/gwc/service/wmts?REQUEST=GetCapabilities';
    return this.capabilitiesParser.getCapabilities(wmtsCapabilitiesUrl, LayerType.WMTS).pipe(
      map((capabilities) => {
        const layerSource = new WMTS(GeoserverLayersUtils.configureWmtsOptions(layer, capabilities));
        const olLayer = new TileLayer({ source: layerSource });

        this.addLoadingFunction(layerSource, layer.auth);

        return olLayer;
      }),
      catchError(() => {
        return of(
          new VectorTileLayer({
            source: new VectorTile({
              attributions: '© No data',
            }),
          })
        );
      })
    );
  }

  createVectorTileLayer(layer: WmtsLayerConfig, geoserverUrl: string): Observable<VectorTileLayer> {
    const wmtsCapabilitiesUrl = layer.url ?? geoserverUrl + '/gwc/service/wmts?REQUEST=GetCapabilities';
    const loadingErrorCallback = () => this.displayLayerLoadingError(layer.shortName ?? '');
    return this.capabilitiesParser.getCapabilities(wmtsCapabilitiesUrl, LayerType.WMTS).pipe(
      map((capabilities) => {
        const layerSource = new VectorTile(GeoserverLayersUtils.configureVectorTileOptions(layer, capabilities));
        // TODO : gestion des styles
        const olLayer = new VectorTileLayer({ renderMode: 'vector', source: layerSource });

        if (layer.ignoreUrlInCapabilities) {
          layerSource.setTileUrlFunction(GeoserverLayersUtils.getVectorTileUrlFunction(layer, layerSource));
        }
        layerSource.setTileLoadFunction(
          GeoserverLayersUtils.getVectorTileLoader(layer, layerSource, loadingErrorCallback)
        );

        return olLayer;
      })
    );
  }

  createWfsLayer(
    layer: WfsLayerConfig,
    geoserverUrl: string,
    specifierConfig: boolean
  ): VectorLayer<VectorSource> | Heatmap {
    const serviceUrl = layer.url ?? geoserverUrl + '/wfs?';
    const formatter = layer.outputFormat.toUpperCase().includes('JSON') ? new GeoJSON() : new WFS();
    const loadingErrorCallback = () => this.displayLayerLoadingError(layer.shortName ?? '');

    if (specifierConfig) {
      layer.maxFeatures = 1;
    }

    let olLayer: VectorLayer<VectorSource> | Heatmap;
    if (layer.subtype === LayerSubtype.HEATMAP) {
      const source = new VectorSource<Point>({
        attributions: layer.getAttributionTemplate(),
        format: formatter,
        strategy: bbox,
      });
      source.setLoader(
        GeoserverLayersUtils.getWfsLoader(
          layer,
          source,
          serviceUrl,
          formatter,
          GeoserverLayersUtils.computeCqlFilterValue(
            layer.cqlFilter ?? '',
            this.geoserverVariables.geoserverVariablesState.getValue()
          ),
          loadingErrorCallback
        )
      );
      olLayer = new Heatmap({
        source,
        blur: Number(layer.heatmap.heatBlur),
        radius: Number(layer.heatmap.heatRadius),
        properties: {
          title: layer.shortName,
        },
        weight: layer.heatmap.heatWeightProperty,
      });
    } else {
      const source = new VectorSource<Geometry>({
        attributions: layer.getAttributionTemplate(),
        format: formatter,
        strategy: bbox,
      });
      source.setLoader(
        GeoserverLayersUtils.getWfsLoader(
          layer,
          source,
          serviceUrl,
          formatter,
          GeoserverLayersUtils.computeCqlFilterValue(
            layer.cqlFilter ?? '',
            this.geoserverVariables.geoserverVariablesState.getValue()
          ),
          loadingErrorCallback
        )
      );
      olLayer = new VectorLayer({
        source,
        style: layer.getStyleFunction(),
      });
    }
    // TODO: this.addLoadingListener(vectorSource, layerconfig);
    return olLayer;
  }

  createWmsLayer(layer: WmsLayerConfig, geoserverUrl: string, defaultProjection?: string): TileLayer<WMSTileSource> {
    const serviceUrl = layer.url ?? geoserverUrl + '/wms?';

    // The layer's URL might contain parameters for the GetCapabilities request
    // Remove these parameters so that OL can build a clean URL
    const baseUrl = serviceUrl.substring(0, serviceUrl.indexOf('?'));

    const source = new WMSTileSource({
      attributions: layer.getAttributionTemplate(),
      url: baseUrl,
      crossOrigin: 'anonymous',
      params: { LAYERS: layer.geoserverLayerName, EXCEPTIONS: 'INIMAGE' },
      projection: layer.projection ?? defaultProjection,
    });

    if (layer.cqlFilter) {
      source.updateParams({
        CQL_FILTER: GeoserverLayersUtils.computeCqlFilterValue(
          layer.cqlFilter,
          this.geoserverVariables.geoserverVariablesState.getValue()
        ),
      });
    }

    if (layer.useCustomSld) {
      const computedSld = GeoserverLayersUtils.computeCqlFilterValue(
        layer.styleToSld(),
        this.geoserverVariables.geoserverVariablesState.getValue()
      );
      source.updateParams({ SLD_BODY: computedSld });
    }

    if (layer.activeStyle) {
      source.updateParams({ STYLES: layer.getActiveStyle()?.style });
    }

    this.addLoadingFunction(source);

    const olLayer = new TileLayer({ source: source });

    return olLayer;
  }

  createVectorLayer(layer: VectorLayerConfig): VectorLayer<VectorSource> {
    let drawSource = new VectorSource();
    if (layer.geojsonFeatures) {
      const format = new GeoJSONFormat();
      drawSource.addFeatures(format.readFeatures(layer.geojsonFeatures));
    } else if (layer.url) {
      drawSource = new VectorSource({ url: layer.url, format: new GeoJSON() });
    }

    return new VectorLayer({ source: drawSource, style: layer.getStyleFunction() });
  }

  updateCqlFilter(layer: DataLayerConfig, filter: string, geoserverUrl: string) {
    const variables = this.geoserverVariables.geoserverVariablesState.getValue();
    const cqlFilter = variables ? GeoserverLayersUtils.computeCqlFilterValue(filter, variables) : filter;
    if ((layer.olLayer?.getSource() as WMSTileSource)?.updateParams) {
      (layer.olLayer?.getSource() as WMSTileSource)?.updateParams({
        CQL_FILTER: cqlFilter ? cqlFilter : undefined,
      });
    } else if ((layer.olLayer?.getSource() as VectorSource).setLoader) {
      this.updateWfsLoader(layer, cqlFilter, geoserverUrl);
      layer.olLayer?.getSource()?.refresh();
    }
  }

  private updateWfsLoader(layer: DataLayerConfig, filter: string, geoserverUrl: string) {
    const config = layer.config as WfsLayerConfig;
    const source = layer.olLayer?.getSource() as VectorSource;
    const serviceUrl = config.url ?? geoserverUrl + '/wfs?';
    const formatter = config.outputFormat.toUpperCase().includes('JSON') ? new GeoJSON() : new WFS();
    const loadingErrorCallback = () => this.displayLayerLoadingError(config.shortName ?? '');

    source.setLoader(
      GeoserverLayersUtils.getWfsLoader(config, source, serviceUrl, formatter, filter, loadingErrorCallback)
    );
  }

  setLayerResolutions(layer: BaseLayer, config: LayerConfig, projection: Projection): void {
    if (config.maxScaleDenominator) {
      layer.setMaxResolution(MapUtils.getResolutionFromScale(config.maxScaleDenominator, projection));
    }
    if (config.minScaleDenominator) {
      layer.setMinResolution(MapUtils.getResolutionFromScale(config.minScaleDenominator, projection));
    }
  }

  private addLoadingFunction(source: WMTS | WMSTileSource, auth?: { login: string; password: string }): void {
    const login = auth?.login;
    const password = auth?.password;

    const loader = (tile: Tile, src: string): void => {
      let isPostRequest = false;

      if (!(tile instanceof ImageTile)) {
        throw new Error('Loader can only process ImageTile tiles');
      }

      const tileImage = tile.getImage();
      if (tileImage instanceof HTMLCanvasElement) {
        throw new Error('Cannot process HTMLCanvasElement in tile loader');
      }

      if (!login && !password) {
        // Auth headers are automaticaly passed by the interceptor
        this.http.get(src, { responseType: 'blob' }).subscribe({
          next: (blob) => {
            const urlCreator = window.URL || window.webkitURL;
            const imageUrl = urlCreator.createObjectURL(blob);
            tileImage.src = imageUrl;
          },
          error: (error) => {
            this.notification.error(error.status + ': ' + error.statusText);
          },
        });
        return;
      }

      const client = new XMLHttpRequest();
      client.responseType = 'arraybuffer';

      let params = undefined;
      isPostRequest = true;
      const urlArray = src.split('?');
      const url = urlArray[0];
      params = urlArray[1];
      client.open('POST', url, true);
      client.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      client.setRequestHeader('Authorization', localStorage.getItem('Authorization') || '');
      if (login && password) {
        client.setRequestHeader('login', 'Basic ' + window.btoa(login + ':' + password));
      }

      client.onload = () => {
        if (client.status === 200) {
          if (!isPostRequest && client.responseText) {
            const erreur = JSON.parse(client.responseText);
            if (erreur.errorCode) {
              // TODO: récupérer le bon message d'erreur
              this.notification.error(erreur.errorCode);
            }
          }
        }
        const arrayBufferView = new Uint8Array(client.response);
        const blob = new Blob([arrayBufferView], { type: 'image/png' });
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(blob);
        tileImage.src = imageUrl;
      };
      client.addEventListener('error', () => tile.setState(TileState.ERROR));
      client.send(params);
    };

    source.setTileLoadFunction(loader);
  }

  private displayLayerLoadingError(name: string): void {
    this.notification.error($localize`Une erreur est survenue lors du chargement de la couche ${name}`);
  }

  public getFeaturesTypes(layer: DataLayerConfig): Observable<FeatureType> {
    if (layer.config.type == LayerType.WMS || layer.config.type == LayerType.WFS) {
      const config = layer.config as WmsLayerConfig | WfsLayerConfig;
      return this.getGeoserverConfig(config).pipe(
        map((features) => {
          return features.featureTypes[0];
        })
      );
    } else if (layer.config.type == LayerType.VECTOR) {
      const features = (layer.olLayer?.getSource() as VectorSource).getFeatures();
      if (features.length > 0) {
        const properties = features[0].getKeys().map((key) => {
          return { name: key, type: AttributeType.STRING, display: true, editable: true, nillable: true };
        });
        return of({ properties: properties });
      }
    }
    return of({ properties: [] });
  }

  public getGeoserverConfig(layer: WfsLayerConfig | WmsLayerConfig): Observable<FeaturesType> {
    const url = layer.url;
    if (url) {
      const baseUrl = url.substring(0, url.indexOf('?'));
      const params = new HttpParams()
        .append('service', 'wfs')
        .append('version', '2.0.0')
        .append('request', 'DescribeFeatureType')
        .append('TypeName', layer.geoserverLayerName)
        .append('outputFormat', 'application/json');

      return this.http.get<FeaturesType>(baseUrl, { params });
    }
    return of({
      featureTypes: [],
      targetNamespace: '',
      targetPrefix: '',
    });
  }
}
