<form [formGroup]="form">
  <div class="toggle" *ngIf="!readonly">
    <mat-slide-toggle formControlName="active">Activer l'infobulle</mat-slide-toggle>
    <smv-help-overlay i18n="Layer tooltip">
      L'infobulle apparaîtra lors du passage de la souris sur les objets
    </smv-help-overlay>
    <smv-help-overlay *ngIf="form.value.active" i18n="Layer tooltip">
      {{ helpText }}
    </smv-help-overlay>
  </div>

  <quill-editor *ngIf="form.value.active" formControlName="content" />
</form>
