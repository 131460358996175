import { Indexable } from './general.utils';

type TagAttributes = Indexable<string>;

export interface XmlTag {
  name: string;
  selfClosing?: boolean;
  attributes?: TagAttributes;
  content?: string | number | XmlTag[];
}

export class XmlBuilder {
  static build(root: XmlTag): string {
    const header = '<?xml version="1.0" encoding="ISO-8859-1"?>';
    return header + this.buildTag(root);
  }

  static buildTag(xmlTag: XmlTag): string {
    const attr = xmlTag.attributes ? this.buildAttributes(xmlTag.attributes) : '';
    let builtTag: string;
    if (xmlTag.selfClosing) {
      builtTag = `<${xmlTag.name}${attr}/>`;
    } else {
      const openingTag = `<${xmlTag.name}${attr}>`;
      const closingTag = `</${xmlTag.name}>`;
      let tagContent = '';
      if (xmlTag.content) {
        tagContent = Array.isArray(xmlTag.content)
          ? xmlTag.content.map((tag) => this.buildTag(tag)).join('')
          : xmlTag.content.toString();
      }
      builtTag = openingTag + tagContent + closingTag;
    }

    return builtTag;
  }

  private static buildAttributes(attributes: TagAttributes): string {
    const builtAttr = Object.keys(attributes)
      .map((key) => `${key}="${attributes[key]}"`)
      .join(' ');
    return ' ' + builtAttr;
  }
}
