import { Component, Input } from '@angular/core';
import { Qualih2oService } from '@widgets/quali-h2o/qualih2o.service';
import { Feature } from 'ol';

@Component({
  selector: 'smv-aac-feature-actions',
  templateUrl: './aac-feature-actions.component.html',
  styleUrls: ['./aac-feature-actions.component.scss'],
})
export class AacFeatureActionsComponent {
  @Input() feature?: Feature;

  constructor(private qualih2oService: Qualih2oService) {}

  getParcelStats(): void {
    const geometry = this.feature?.getGeometry();
    if (geometry) {
      this.qualih2oService.getParcelStatsInGeometry(geometry);
    }
  }

  getTowns(): void {
    if (this.feature) {
      this.qualih2oService.getTownsInAAC(this.feature.getId() as string);
    }
  }

  filterParcels(): void {
    const aacName = this.feature?.get('nom');
    if (aacName) {
      this.qualih2oService.rpgLayer.filterOnAac(aacName);
    }
  }
}
