import { Indexable } from '@core/utils/general.utils';

export interface AreaStatisticsWrapper {
  ParcelAreaStatistics: ParcelAreaStatistics;
}

export interface ParcelAreaStatistics {
  cropStatistics: Indexable<CropStatistics>;
  vulnerabilityStatistics: Indexable<VulnerabilityStatistics>;
}

export class CropStatistics {
  cropType = '';
  totalArea = 0;
  surfaceBuffer5m = 0;
  surfaceBuffer20m = 0;
  surfaceBuffer50m = 0;
  surfaceBuffer100m = 0;
  highVulnerability = 0;
  mediumVulnerability = 0;
  mildVulnerability = 0;
  lowVulnerability = 0;

  addAll(statistics: CropStatistics[]): void {
    statistics.forEach((stat) => {
      this.totalArea += stat.totalArea;
      this.surfaceBuffer5m += stat.surfaceBuffer5m;
      this.surfaceBuffer20m += stat.surfaceBuffer20m;
      this.surfaceBuffer50m += stat.surfaceBuffer50m;
      this.surfaceBuffer100m += stat.surfaceBuffer100m;
      this.highVulnerability += stat.highVulnerability;
      this.mediumVulnerability += stat.mediumVulnerability;
      this.mildVulnerability += stat.mildVulnerability;
      this.lowVulnerability += stat.lowVulnerability;
    });
  }
}

export class VulnerabilityStatistics {
  vulnerabilityLevel = '';
  totalArea = 0;
  surfaceBuffer5m = 0;
  surfaceBuffer20m = 0;
  surfaceBuffer50m = 0;
  surfaceBuffer100m = 0;

  addAll(statistics: VulnerabilityStatistics[]): void {
    statistics.forEach((stat) => {
      this.totalArea += stat.totalArea;
      this.surfaceBuffer5m += stat.surfaceBuffer5m;
      this.surfaceBuffer20m += stat.surfaceBuffer20m;
      this.surfaceBuffer50m += stat.surfaceBuffer50m;
      this.surfaceBuffer100m += stat.surfaceBuffer100m;
    });
  }
}

export const cropStatisticsLabels = {
  cropType: $localize`:Parcel area statistics:Type de culture`,
  totalArea: $localize`:Parcel area statistics:Surface totale (ha)`,
  surfaceBuffer5m: $localize`:Parcel area statistics:Surface tampon à 5m (ha)`,
  surfaceBuffer20m: $localize`:Parcel area statistics:Surface tampon à 20m (ha)`,
  surfaceBuffer50m: $localize`:Parcel area statistics:Surface tampon à 50m (ha)`,
  surfaceBuffer100m: $localize`:Parcel area statistics:Surface tampon à 100m (ha)`,
  highVulnerability: $localize`:Parcel area statistics:Fortement vulnérable (ha)`,
  mediumVulnerability: $localize`:Parcel area statistics:Moyennement vulnérable (ha)`,
  mildVulnerability: $localize`:Parcel area statistics:Faiblement vulnérable (ha)`,
  lowVulnerability: $localize`:Parcel area statistics:Très faiblement vulnérable (ha)`,
} as const;
export const cropStatisticsColumnOrder = [
  'cropType',
  'totalArea',
  'surfaceBuffer5m',
  'surfaceBuffer20m',
  'surfaceBuffer50m',
  'surfaceBuffer100m',
  'highVulnerability',
  'mediumVulnerability',
  'mildVulnerability',
  'lowVulnerability',
] as const;

export const vulnerabilityStatisticsLabels = {
  vulnerabilityLevel: $localize`:Parcel area statistics:Vulnérabilité`,
  totalArea: $localize`:Parcel area statistics:Surface par vulnérabilité (ha)`,
  surfaceBuffer5m: $localize`:Parcel area statistics:Surface tampon à 5m (ha)`,
  surfaceBuffer20m: $localize`:Parcel area statistics:Surface tampon à 20m (ha)`,
  surfaceBuffer50m: $localize`:Parcel area statistics:Surface tampon à 50m (ha)`,
  surfaceBuffer100m: $localize`:Parcel area statistics:Surface tampon à 100m (ha)`,
} as const;
export const vulnerabilityStatisticsColumnOrder = [
  'vulnerabilityLevel',
  'totalArea',
  'surfaceBuffer5m',
  'surfaceBuffer20m',
  'surfaceBuffer50m',
  'surfaceBuffer100m',
] as const;
