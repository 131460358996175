<button
  mat-icon-button
  [color]="iconColor"
  (click)="toggleOverlay()"
  cdkOverlayOrigin
  #messageButton="cdkOverlayOrigin"
  type="button"
>
  <mat-icon *ngIf="isHelp">help</mat-icon>
  <mat-icon *ngIf="isWarning">warning</mat-icon>
</button>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="messageButton"
  [cdkConnectedOverlayOpen]="isOverlayOpen"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
  (overlayOutsideClick)="outsideEvent()"
  (detach)="outsideEvent()"
>
  <div [class]="{ 'overlay-content': true, help: isHelp, warning: isWarning }">
    <ng-content></ng-content>
  </div>
</ng-template>
