import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeometryType } from '@core/model/application-api/layer.model';
import { BaseStyleForm } from '../../layer-base-style-tab/style-form.model';

interface DialogData {
  ruleStyle: FormGroup<BaseStyleForm>;
  geometry: GeometryType;
}

@Component({
  selector: 'smv-style-rule-edit',
  templateUrl: './style-rule-edit.component.html',
  styleUrls: ['./style-rule-edit.component.scss'],
})
export class StyleRuleEditComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
