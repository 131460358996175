import { HttpParams } from '@angular/common/http';
import { rgbaToOpacity, rgbToHex } from '@core/utils/layer-style.utils';
import { isNil } from 'lodash-es';
import { Fill, Icon, RegularShape, Stroke } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { SymbolPattern } from './style-config.model';

export interface SymbolStyleModel {
  symbol?: SymbolPattern | null;
  symbolStrokeColor?: string | null;
  symbolFillColor?: string | null;
  symbolStrokeWidth?: number | null;
  imgcache?: HTMLImageElement | null;
  svgData?: string | null;
  svgUrl?: string | null;
  symbolSize?: number | null;
}

interface BaseSymbolStyle {
  symbol: SymbolPattern;
  symbolStrokeColor: string;
  symbolFillColor: string;
  symbolStrokeWidth: number;
  imgcache?: HTMLImageElement;
  svgData?: string;
  svgUrl?: string;
  symbolSize: number;
}

export class SymbolStyle {
  private baseStyle: BaseSymbolStyle;

  public symbol: SymbolPattern = 'circle';

  public symbolStrokeColor = '#000000';

  public symbolFillColor = 'rgba(51, 153, 204, 0.5)';

  public symbolStrokeWidth = 1;

  public imgcache?: HTMLImageElement;

  public svgData = '';

  public svgUrl = '';

  public symbolSize = 16;

  constructor(data?: SymbolStyleModel | null) {
    if (data) {
      Object.assign(this, data);
    }
    this.baseStyle = this;
  }

  public setBaseStyle(baseStyle: SymbolStyle) {
    this.baseStyle = baseStyle;
  }

  getSvgContent() {
    return this.replaceSvgColor(
      this.svgData,
      this.getSymbolFillColor(),
      this.getSymbolStrokeColor(),
      this.getSymbolStrokeWidth()
    );
  }

  setDefaultValues() {
    this.symbolFillColor = 'rgba(51, 153, 204, 0.5)';
    this.symbolStrokeColor = '#000000';
    this.symbolStrokeWidth = 1;
    this.symbol = 'circle';
    this.symbolSize = 16;
  }

  toModel(): SymbolStyleModel {
    return {
      symbol: this.symbol,
      symbolSize: this.symbolSize,
      symbolStrokeColor: this.symbolStrokeColor,
      symbolFillColor: this.symbolFillColor,
      symbolStrokeWidth: this.symbolStrokeWidth,
    };
  }

  getImage() {
    if (isNil(this.imgcache) || this.imgcache.src != this.getSvgSymbolUrl()) {
      const svgurl = this.getSvgIconUrl();
      if (svgurl?.length) {
        this.fetchIcon();
      }
    }
    return this.imgcache;
  }

  replaceSvgColor(svgData: string, fillcolor: string, strokecolor: string, strokeWidth: number): string {
    if (fillcolor.length > 0) {
      svgData = svgData.replace('param(fill)', rgbToHex(fillcolor));
      svgData = svgData.replace('param(fill-opacity)', rgbaToOpacity(fillcolor));
    }
    if (strokecolor.length > 0) {
      svgData = svgData.replace(/param\(stroke\)\s*\w*"/, strokecolor + '"');
      svgData = svgData.replace(/param\(stroke-width\)\s*\d*"/, strokeWidth + '"');
    }
    return svgData;
  }

  fetchIcon() {
    const svgurl = this.getSvgIconUrl();
    const img = new Image();

    if (svgurl) {
      fetch(svgurl).then((response) => {
        if (response.status !== 200) {
          return;
        }
        // Examine the text in the response
        response.text().then((data) => {
          let svgData = data;

          svgData = svgData.replace('<?xml version="1.0" encoding="UTF-8" standalone="no"?>', '');

          this.svgData = svgData;
          svgData = this.getSvgContent();

          img.src = 'data:image/svg+xml; charset=utf8, ' + encodeURIComponent(svgData);
          this.imgcache = img;
          this.imgcache.src = this.getSvgSymbolUrl();
        });
      });
    }
    return img;
  }

  getSvgIconUrl(): string | undefined {
    return !isNil(this.svgUrl) ? this.svgUrl : this.baseStyle.svgUrl;
  }

  getSvgSymbolUrl(): string {
    const fillcolor = rgbToHex(this.getSymbolFillColor());
    const strokecolor = rgbToHex(this.getSymbolStrokeColor());
    const url = 'https://map.magellium.com/' + this.getSvgIconUrl();
    let params = new HttpParams();
    if (fillcolor.length > 0) {
      params = params.append('fill', fillcolor).append('fill-opacity', rgbaToOpacity(this.getSymbolFillColor()));
    }
    if (strokecolor.length > 0) {
      params = params.append('stroke', strokecolor).append('stroke-width', this.getSymbolStrokeWidth());
    }
    return url + params.toString();
  }

  getSymbolFillColor(): string {
    return !isNil(this.symbolFillColor) && this.symbolFillColor.length > 0
      ? this.symbolFillColor
      : this.baseStyle.symbolFillColor;
  }

  getSymbolStrokeColor(): string {
    return !isNil(this.symbolStrokeColor) && this.symbolStrokeColor.length > 0
      ? this.symbolStrokeColor
      : this.baseStyle.symbolStrokeColor;
  }

  getSymbolStrokeWidth(): number {
    return !isNil(this.symbolStrokeWidth) ? this.symbolStrokeWidth : this.baseStyle.symbolStrokeWidth;
  }

  getSize(): number {
    return !isNil(this.symbolSize) ? this.symbolSize : this.baseStyle.symbolSize;
  }

  getOlStyle(): CircleStyle | Icon | RegularShape | undefined {
    let symbolStyle;
    const fill = new Fill({
      color: this.getSymbolFillColor(),
    });
    const stroke = new Stroke({
      color: this.getSymbolStrokeColor(),
      width: this.getSymbolStrokeWidth(),
    });

    if (this.symbol == 'circle') {
      symbolStyle = new CircleStyle({
        radius: this.getSize() / 2,
        stroke: stroke,
        fill: fill,
      });
    }
    if (this.symbol == 'svg') {
      if (this.getSvgContent() != undefined) {
        const svgContent = this.getSvgContent();
        if (svgContent && svgContent.length > 0) {
          const imgContent = 'data:image/svg+xml; charset=utf8, ' + encodeURIComponent(svgContent);

          symbolStyle = new Icon({
            crossOrigin: 'anonymous',
            scale: Number(this.getSize()) / 14,
            src: imgContent,
          });
        }
      }
    }
    if (['square', 'cross', 'triangle', 'star'].includes(this.symbol)) {
      let points = 4;
      const radius = this.getSize() / 2;
      let radius2 = undefined;
      let angle = Math.PI / 4;
      let rotation = 0;
      if (this.symbol == 'cross') {
        points = 4;
        radius2 = 0;
        rotation = 0;
        angle = 0;
      } else if (this.symbol == 'triangle') {
        points = 3;
        angle = 0;
      } else if (this.symbol == 'star') {
        points = 5;
        radius2 = this.getSize() / 4;
        rotation = 0;
        angle = 0;
      }

      symbolStyle = new RegularShape({
        stroke: stroke,
        fill: fill,
        points: points,
        radius: radius,
        radius2: radius2,
        rotation: rotation,
        angle: angle,
      });
    }
    return symbolStyle;
  }
}
