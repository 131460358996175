import { Component, Input } from '@angular/core';
import { ApplicationModel } from '@core/model/application-api/application.model';
import { WidgetModel } from '@core/model/application-api/widget.model';
import { ApplicationConfig, SpecificationConfig } from '@core/model/application.model';
import { ApplicationApiService } from '@core/services/api/application-api.service';
import { MapService } from '@core/services/map.service';
import { NotificationService } from '@core/services/notification.service';
import { fromLonLat } from 'ol/proj';
import { finalize } from 'rxjs';
import { DataLayerGroupConfig } from '../data-layer-config.model';

@Component({
  selector: 'smv-application-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss', '../client-app-panel.scss'],
})
export class ApplicationSettingsComponent {
  @Input() application!: ApplicationModel;
  @Input() configuration!: ApplicationConfig;
  @Input() layerGroups!: DataLayerGroupConfig[];

  public widgetConfiguration?: WidgetModel;
  public isSavingWidget = false;
  public isSavingSpec = false;
  public isSavingGrip = false;

  constructor(
    private applicationService: ApplicationApiService,
    private mapService: MapService,
    private notificationService: NotificationService
  ) {}

  closeSettingsPanel() {
    this.applicationService.settingsPanelState.setValue(false);
  }

  updateWidgets(widgets: WidgetModel[]) {
    this.isSavingWidget = true;
    this.configuration.widgets = widgets;
    this.updateConfiguration(this.configuration, false);
  }

  updateSpec(specConfig: SpecificationConfig) {
    this.isSavingSpec = true;
    this.configuration.specification = specConfig;
    this.updateConfiguration(this.configuration, false);
  }

  updateConfiguration(configuration: ApplicationConfig, zoom = true) {
    this.isSavingGrip = !this.isSavingWidget;
    if (this.application) {
      const updatedConfiguration = new ApplicationConfig(this.application.config);
      updatedConfiguration.initZoom = configuration.initZoom;
      updatedConfiguration.initLocation = configuration.initLocation;
      updatedConfiguration.widgets = configuration.widgets;
      updatedConfiguration.specification = configuration.specification;
      this.applicationService
        .updateApplicationConfig(this.application, updatedConfiguration)
        .pipe(
          finalize(() => {
            this.isSavingWidget = false;
            this.isSavingSpec = false;
            this.isSavingGrip = false;
          })
        )
        .subscribe({
          next: (app) => {
            if (zoom) {
              this.mapService.zoomTo(fromLonLat(updatedConfiguration.initLocation), updatedConfiguration.initZoom);
            }
            this.configuration = app.parsedConfig;
            if (this.isSavingWidget) {
              this.notificationService.success(
                $localize`Le paramètrage des fonctionnalités a été enregistré avec succès.`
              );
            }
            if (this.isSavingGrip) {
              this.notificationService.success($localize`L'emprise initiale a été modifiée avec succès.`);
            }
            if (this.isSavingSpec) {
              this.notificationService.success(
                $localize`Le paramètrage de la spécification a été enregistré avec succès.`
              );
            }
          },
          error: () => {
            this.notificationService.unknownServerError();
          },
        });
    }
  }
}
