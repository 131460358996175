import { getSldGraphic, getStrokeGraphicSld } from '@core/utils/layer-style.utils';
import { XmlTag } from '@core/utils/xml.utils';
import { Stroke } from 'ol/style';
import { StrokePattern } from './style-config.model';
import { SymbolStyle, SymbolStyleModel } from './symbol-style.model';

export interface StrokeStyleModel {
  symbol?: SymbolStyleModel | null;
  strokeColor?: string | null;
  strokePattern?: StrokePattern | null;
  strokeWidth?: number | null;
  patternMargin?: number | null;
}

interface BaseStrokeStyle {
  symbol: SymbolStyleModel;
  strokeColor: string;
  strokePattern: StrokePattern;
  strokeWidth: number;
  patternMargin: number;
}

export class StrokeStyle {
  private baseStyle: BaseStrokeStyle;

  public symbol: SymbolStyle;
  public strokeColor = '#3399CC';
  public strokePattern: StrokePattern = 'simple';
  public strokeWidth = 1;
  public patternMargin = 0;

  constructor(data?: StrokeStyleModel | null) {
    if (data) {
      Object.assign(this, data);
      this.symbol = new SymbolStyle(data.symbol);
    } else {
      this.symbol = new SymbolStyle();
    }
    this.baseStyle = this;
  }

  public setBaseStyle(baseStyle: StrokeStyle) {
    this.baseStyle = baseStyle;
    if (baseStyle != undefined) {
      this.symbol.setBaseStyle(baseStyle.symbol);
    }
  }

  hasPattern() {
    const fillPattern = this.getPattern();
    return fillPattern && fillPattern.length > 0 && fillPattern != 'simple';
  }

  setDefaultValues() {
    this.symbol = new SymbolStyle();
    this.symbol.setDefaultValues();

    this.strokeColor = '#3399CC';
    this.strokeWidth = 1;
    this.strokePattern = 'simple';
    this.patternMargin = 0;
  }

  toModel(): StrokeStyleModel {
    return {
      symbol: this.symbol.toModel(),
      strokeColor: this.strokeColor,
      strokeWidth: this.strokeWidth,
      strokePattern: this.strokePattern,
      patternMargin: this.patternMargin,
    };
  }

  getPatternMargin(): number {
    return this.patternMargin != undefined ? this.patternMargin : this.baseStyle.patternMargin;
  }

  getColor(): string {
    return this.strokeColor ?? this.baseStyle.strokeColor;
  }

  getStrokeWidth(): number {
    return this.strokeWidth != undefined ? this.strokeWidth : this.baseStyle.strokeWidth;
  }

  toSld(): XmlTag[] {
    let strokeContent;
    if (this.hasPattern()) {
      strokeContent = getSldGraphic(this.symbol, this.getPattern(), 'stroke');
    } else {
      strokeContent = getStrokeGraphicSld(this.symbol);
    }

    const retour: XmlTag[] = [
      {
        name: 'Stroke',
        content: [
          strokeContent,
          {
            name: 'CssParameter',
            attributes: { name: 'stroke-dasharray' },
            content: this.symbol.getSize() + ` ` + this.getPatternMargin(),
          },
          {
            name: 'CssParameter',
            attributes: { name: 'stroke-dashoffset' },
            content: 0.0,
          },
        ],
      },
      {
        name: 'PerpendicularOffset',
        content: 0,
      },
    ];
    return retour;
  }

  getPattern(): StrokePattern {
    return this.strokePattern ?? this.baseStyle.strokePattern;
  }

  getRenderFunction() {
    const pattern = this.getPattern();
    const img = this.symbol.getImage();

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      const colorfunction = (() => {
        if (['slash', 'backslash'].indexOf(pattern) >= 0) {
          canvas.width = 32;
          canvas.height = 16;
        } else if (['horline', 'vertline'].indexOf(pattern) >= 0) {
          canvas.width = 10;
          canvas.height = 10;
        } else if (['svg'].indexOf(pattern) >= 0) {
          canvas.width = this.symbol.getSize() + this.getPatternMargin() * 2;
          canvas.height = this.symbol.getSize() + this.getPatternMargin() * 2;
        } else {
          canvas.width = this.symbol.getSize() + this.getPatternMargin() * 2;
          canvas.height = this.symbol.getSize() + this.getPatternMargin() * 2;
        }

        const strokeColor = this.symbol.getSymbolStrokeColor();
        context.strokeStyle = strokeColor;

        const fillColor = this.symbol.getSymbolFillColor();
        context.fillStyle = fillColor;
        context.lineWidth = this.symbol.getSymbolStrokeWidth();
        if (pattern == 'slash') {
          const x0 = 36;
          const x1 = -4;
          const y0 = -2;
          const y1 = 18;
          const offset = 32;
          context.beginPath();
          context.moveTo(x0, y0);
          context.lineTo(x1, y1);
          context.moveTo(x0 - offset, y0);
          context.lineTo(x1 - offset, y1);
          context.moveTo(x0 + offset, y0);
          context.lineTo(x1 + offset, y1);
          context.stroke();
        } else if (pattern == 'backslash') {
          const x0 = 36;
          const x1 = -4;
          const y0 = -2;
          const y1 = 18;
          const offset = 32;
          context.scale(-1, 1);
          context.beginPath();
          context.moveTo(x0, y0);
          context.lineTo(x1, y1);
          context.moveTo(x0 - offset, y0);
          context.lineTo(x1 - offset, y1);
          context.moveTo(x0 + offset, y0);
          context.lineTo(x1 + offset, y1);
          context.stroke();
        } else if (pattern == 'cross') {
          context.scale(this.symbol.getSize() / 10, this.symbol.getSize() / 10);
          context.beginPath();
          context.moveTo(5, 0);
          context.lineTo(5, 10);
          context.moveTo(0, 5);
          context.lineTo(10, 5);
          context.stroke();
        } else if (pattern == 'plus') {
          context.translate(this.getPatternMargin(), this.getPatternMargin());
          context.scale(this.symbol.getSize() / 10, this.symbol.getSize() / 10);
          context.beginPath();
          context.moveTo(5, 0);
          context.lineTo(5, 10);
          context.moveTo(0, 5);
          context.lineTo(10, 5);
          context.stroke();
        } else if (pattern == 'square') {
          context.scale(this.symbol.getSize() / 10, this.symbol.getSize() / 10);
          context.beginPath();
          context.moveTo(1, 1);
          context.lineTo(9, 1);
          context.lineTo(9, 9);
          context.lineTo(1, 9);
          context.lineTo(1, 1);
          if (strokeColor.length > 0) {
            context.stroke();
          }
          if (fillColor.length > 0) {
            context.fill();
          }
          context.setTransform(1, 0, 0, 1, 0, 0);
        } else if (pattern == 'dot') {
          context.scale(this.symbol.getSize() / 10, this.symbol.getSize() / 10);
          context.beginPath();
          context.arc(5, 5, 1, 0, 2 * Math.PI);
          if (strokeColor.length > 0) {
            context.stroke();
          }
          if (fillColor.length > 0) {
            context.fill();
          }
          context.setTransform(1, 0, 0, 1, 0, 0);
        } else if (pattern == 'circle') {
          context.scale(this.symbol.getSize() / 10, this.symbol.getSize() / 10);
          context.beginPath();
          context.arc(5, 5, 4, 0, 2 * Math.PI);
          if (strokeColor.length > 0) {
            context.stroke();
          }
          if (fillColor.length > 0) {
            context.fill();
          }
          context.setTransform(1, 0, 0, 1, 0, 0);
        } else if (pattern == 'horline') {
          context.beginPath();
          context.moveTo(0, 5);
          context.lineTo(10, 5);
          context.stroke();
        } else if (pattern == 'vertline') {
          context.beginPath();
          context.moveTo(5, 0);
          context.lineTo(5, 10);
          context.stroke();
        } else if (pattern == 'svg') {
          if (img instanceof HTMLImageElement) {
            context.drawImage(
              img,
              this.getPatternMargin(),
              this.getPatternMargin(),
              this.symbol.getSize(),
              this.symbol.getSize()
            );
          }
        }

        return context.createPattern(canvas, 'repeat');
      })();
      return colorfunction ?? undefined;
    } else {
      return;
    }
  }

  getOlStyle(): Stroke {
    const strokeColor = this.hasPattern() ? this.getRenderFunction() : this.getColor();
    return new Stroke({
      width: this.getStrokeWidth(),
      color: strokeColor,
    });
  }
}
