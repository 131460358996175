import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';

import { CustomerFilterComponent } from '@widgets/components/customer-filter/customer-filter.component';
import { PanelComponent } from '@widgets/components/panel/panel.component';
import { UploadFileComponent } from '../components/upload-file/upload-file.component';
import { OptiSemisConstraintFilterComponent } from './opti-semis-filter/opti-semis-constraint-filter/opti-semis-constraint-filter.component';
import { OptiSemisCropFilterComponent } from './opti-semis-filter/opti-semis-crop-filter/opti-semis-crop-filter.component';
import { OptiSemisFilterComponent } from './opti-semis-filter/opti-semis-filter.component';
import { OptiSemisRecommendationFilterComponent } from './opti-semis-filter/opti-semis-recommendation-filter/opti-semis-recommendation-filter.component';
import { OptiSemisComponent } from './opti-semis.component';

@NgModule({
  declarations: [
    OptiSemisComponent,
    OptiSemisFilterComponent,
    OptiSemisCropFilterComponent,
    OptiSemisConstraintFilterComponent,
    OptiSemisRecommendationFilterComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatCheckboxModule,
    FormsModule,
    PanelComponent,
    CustomerFilterComponent,
    UploadFileComponent,
  ],
  exports: [OptiSemisComponent],
})
export class OptiSemisModule {}
