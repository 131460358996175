import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GeometryType, LayerEditType, LayerType } from '@core/model/application-api/layer.model';
import {
  LayerConfig,
  VectorLayerConfig,
  WfsLayerConfig,
  WmsLayerConfig,
  WmtsLayerConfig,
} from '@core/model/layer-config.model';
import { MapService } from '@core/services/map.service';
import { DataLayerConfig } from '@feature/client-app/data-layer-config.model';
import { isNil, pick } from 'lodash-es';
import { debounce, interval } from 'rxjs';
import { extentValidator, scaleValidator } from '../property-validators';

interface LayerPropertyForm {
  shortName: FormControl<string | null>;
  code: FormControl<string | null>;
  description: FormControl<string | null>;
  geoserverLayerName?: FormControl<string | null>;
  url?: FormControl<string | null>;
  geojsonUrl?: FormControl<string | null>;
  geometryType: FormControl<string | null>;
  editable: FormControl<boolean | null>;
  editType?: FormControl<LayerEditType | null>;
  cqlFilter?: FormControl<string | null>;
  maxFeatures?: FormControl<number | null>;
  copyright: FormControl<string | null>;
  copyrightLink: FormControl<string | null>;
  extentMinX: FormControl<number | null>;
  extentMinY: FormControl<number | null>;
  extentMaxX: FormControl<number | null>;
  extentMaxY: FormControl<number | null>;
  minScaleDenominator: FormControl<number | null>;
  maxScaleDenominator: FormControl<number | null>;
}

const extentXLimit = 180;
const extentYLimit = 90;

@Component({
  selector: 'smv-layer-info-tab',
  templateUrl: './layer-info-tab.component.html',
  styleUrls: ['./layer-info-tab.component.scss'],
})
export class LayerInfoTabComponent implements OnInit {
  @Input() layer!: DataLayerConfig;
  @Input() readonly = false;
  @Output() configUpdate = new EventEmitter<LayerConfig | undefined>();

  public formFields = new FormGroup<LayerPropertyForm>(
    {
      shortName: new FormControl<string | null>(null, Validators.required),
      code: new FormControl<string | null>(null),
      description: new FormControl<string | null>(null),
      geometryType: new FormControl<string | null>(null),
      editable: new FormControl<boolean>(false),
      editType: new FormControl<LayerEditType | null>(null),
      copyright: new FormControl<string | null>(null),
      copyrightLink: new FormControl<string | null>(null),
      extentMinX: new FormControl<number | null>(null, [Validators.min(-extentXLimit), Validators.max(extentXLimit)]),
      extentMinY: new FormControl<number | null>(null, [Validators.min(-extentYLimit), Validators.max(extentYLimit)]),
      extentMaxX: new FormControl<number | null>(null, [Validators.min(-extentXLimit), Validators.max(extentXLimit)]),
      extentMaxY: new FormControl<number | null>(null, [Validators.min(-extentYLimit), Validators.max(extentYLimit)]),
      minScaleDenominator: new FormControl<number | null>(null),
      maxScaleDenominator: new FormControl<number | null>(null),
    },
    [extentValidator, scaleValidator]
  );

  public mapScale = this.mapService.getScale(true);
  public layerType?: LayerType;
  public specificConfigSectionName?: string;
  public showExtentSection = true;
  public showScaleSection = true;
  public geometryTypes = [
    { label: $localize`:Geometry type:Non défini`, value: null },
    { label: $localize`:Geometry type:Ligne`, value: GeometryType.LINE },
    { label: $localize`:Geometry type:Point`, value: GeometryType.POINT },
    { label: $localize`:Geometry type:Polygone`, value: GeometryType.POLYGON },
  ];
  public editableProperties = [
    { label: $localize`:Editable property:Attributs et géométries`, value: LayerEditType.ALL },
    { label: $localize`:Editable property:Modifications uniquement`, value: LayerEditType.MODIFY },
    { label: $localize`:Editable property:Attributs uniquement`, value: LayerEditType.ATTRIBUTES },
    { label: $localize`:Editable property:Géométries uniquement`, value: LayerEditType.GEOMETRIES },
  ];
  public extentXErrors = {
    min: $localize`:Layer editor|Error message: Min : -${extentXLimit}`,
    max: $localize`:Layer editor|Error message: Max : ${extentXLimit}`,
  };
  public extentYErrors = {
    min: $localize`:Layer editor|Error message: Min : -${extentYLimit}`,
    max: $localize`:Layer editor|Error message: Max : ${extentYLimit}`,
  };
  public formErrors = {
    incompleteExtent: $localize`:Layer editor|Error message: Emprise incomplète`,
    invalidCoordinates: $localize`:Layer editor|Error message: Les coordonnées (max X, max Y) doivent être supérieures aux coordonnées (min X, min Y)`,
    invalidScales: $localize`:Layer editor|Error message: L'échelle max doit être supérieure à l'échelle min`,
  };

  private cqlFilter = new FormControl<string | null>(null);
  private maxFeatures = new FormControl<number | null>(1000, [
    Validators.required,
    Validators.min(1000),
    Validators.max(10000),
    Validators.pattern(/^\d+$/),
  ]);
  private geoserverLayerName = new FormControl<string | null>(null, Validators.required);
  private capabilitiesUrl = new FormControl<string | null>(null, Validators.required);
  private geojsonUrl = new FormControl<string | null>(null, Validators.required);

  constructor(private mapService: MapService) {}

  ngOnInit(): void {
    this.layerType = this.layer.config.type;
    const config = this.layer.config;

    const [minX, minY, maxX, maxY] = config.bboxEpsg4326 ?? [null, null, null, null];

    this.formFields.patchValue({
      shortName: config.shortName,
      code: config.code,
      copyright: config.attributions?.title,
      copyrightLink: config.attributions?.link,
      description: config.description,
      editable: config.editable,
      editType: config.editType ?? LayerEditType.ALL,
      extentMinX: minX,
      extentMinY: minY,
      extentMaxX: maxX,
      extentMaxY: maxY,
      geometryType: config.geometryType ?? null,
      maxScaleDenominator: config.maxScaleDenominator,
      minScaleDenominator: config.minScaleDenominator,
    });

    if (config instanceof VectorLayerConfig) {
      this.addVectorFields(config);
    } else if (config instanceof WmsLayerConfig) {
      this.addWmsFields(config);
    } else if (config instanceof WfsLayerConfig) {
      this.addWfsFields(config);
    } else if (config instanceof WmtsLayerConfig) {
      this.addWmtsFields(config);
    }

    const { minScaleDenominator, maxScaleDenominator } = this.formFields.value;
    this.showScaleSection = !this.readonly || !isNil(minScaleDenominator) || !isNil(maxScaleDenominator);
    this.showExtentSection = !this.readonly || !isNil(minX);

    this.geoserverLayerName.disable();

    this.emitConfig();

    if (this.readonly) {
      this.formFields.disable();
    }

    this.formFields.valueChanges.pipe(debounce(() => interval(500))).subscribe(() => this.emitConfig());
  }

  fillMinResolution(): void {
    this.formFields.controls.minScaleDenominator.setValue(this.mapScale);
  }

  fillMaxResolution(): void {
    this.formFields.controls.maxScaleDenominator.setValue(this.mapScale);
  }

  private addVectorFields(layer: VectorLayerConfig): void {
    this.specificConfigSectionName = $localize`:Layer editor:Configuration de couche vectorielle`;
    this.formFields.addControl('geojsonUrl', this.geojsonUrl);
    this.formFields.patchValue({ geojsonUrl: layer.url });
  }

  private addWmsFields(layer: WmsLayerConfig): void {
    this.specificConfigSectionName = $localize`:Layer editor:Configuration WMS`;
    this.formFields.addControl('cqlFilter', this.cqlFilter);
    this.formFields.addControl('geoserverLayerName', this.geoserverLayerName);
    this.formFields.addControl('url', this.capabilitiesUrl);
    this.formFields.patchValue({
      cqlFilter: layer.cqlFilter,
      geoserverLayerName: layer.geoserverLayerName,
      url: layer.url,
    });
  }

  private addWfsFields(layer: WfsLayerConfig): void {
    this.specificConfigSectionName = $localize`:Layer editor:Configuration WFS`;
    this.formFields.addControl('cqlFilter', this.cqlFilter);
    this.formFields.addControl('maxFeatures', this.maxFeatures);
    this.formFields.addControl('geoserverLayerName', this.geoserverLayerName);
    this.formFields.addControl('url', this.capabilitiesUrl);
    this.formFields.patchValue({
      cqlFilter: layer.cqlFilter,
      maxFeatures: layer.maxFeatures,
      geoserverLayerName: layer.geoserverLayerName,
      url: layer.url,
    });
  }

  private addWmtsFields(layer: WmtsLayerConfig): void {
    this.specificConfigSectionName = $localize`:Layer editor:Configuration WMTS`;
    this.formFields.addControl('geoserverLayerName', this.geoserverLayerName);
    this.formFields.addControl('url', this.capabilitiesUrl);
    this.formFields.patchValue({
      geoserverLayerName: layer.geoserverLayerName,
      url: layer.url,
    });
  }

  private toLayerConfig(): LayerConfig {
    // Use the raw value to preserve the "readonly" field values
    const rawValues = this.formFields.getRawValue();

    const assignedProperties = ['shortName', 'code', 'description', 'editable', 'editType', 'geometryType'];

    let config;
    if (this.layer.config instanceof VectorLayerConfig) {
      config = VectorLayerConfig.fromLayer(this.layer.config);
      config.url = rawValues.geojsonUrl ?? undefined;
    } else if (this.layer.config instanceof WmsLayerConfig) {
      config = WmsLayerConfig.fromLayer(this.layer.config);
      assignedProperties.push('cqlFilter', 'geoserverLayerName', 'url');
    } else if (this.layer.config instanceof WfsLayerConfig) {
      config = WfsLayerConfig.fromLayer(this.layer.config);
      assignedProperties.push('cqlFilter', 'maxFeatures', 'geoserverLayerName', 'url');
    } else if (this.layer.config instanceof WmtsLayerConfig) {
      config = WmtsLayerConfig.fromLayer(this.layer.config);
      assignedProperties.push('geoserverLayerName', 'url');
    } else {
      config = LayerConfig.fromLayer(this.layer.config);
    }

    Object.assign(config, pick(rawValues, assignedProperties));

    config.bboxEpsg4326 = undefined;
    const { extentMinX, extentMinY, extentMaxX, extentMaxY } = rawValues;
    if (extentMinX && extentMinY && extentMaxX && extentMaxY) {
      config.bboxEpsg4326 = [+extentMinX, +extentMinY, +extentMaxX, +extentMaxY];
    }

    config.minScaleDenominator = rawValues.minScaleDenominator ? +rawValues.minScaleDenominator : undefined;
    config.maxScaleDenominator = rawValues.maxScaleDenominator ? +rawValues.maxScaleDenominator : undefined;

    config.attributions = undefined;
    const { copyright, copyrightLink } = rawValues;
    if (copyright) {
      // Force undefined if empty because 'link' does not accept null values
      config.attributions = { title: copyright, link: copyrightLink ?? undefined };
    }

    return config;
  }

  private emitConfig(): void {
    this.configUpdate.emit(this.formFields.valid ? this.toLayerConfig() : undefined);
  }
}
