import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ConfirmDialogConfig } from '@components/dialog/confirm-dialog/confirm-dialog.component';
import { DialogService } from '@components/dialog/dialog.service';
import { MapService } from '@core/services/map.service';
import { DataLayerConfig, DataLayerGroupConfig } from '../../data-layer-config.model';

@Component({
  selector: 'smv-data-layers',
  templateUrl: './data-layers.component.html',
  styleUrls: ['./data-layers.component.scss', '../../client-app-panel.scss'],
})
export class DataLayersComponent implements OnChanges {
  @Input() layerGroups: DataLayerGroupConfig[] = [];
  @Input() parent?: DataLayerGroupConfig;
  @Input() subgroupLevel = 0;
  @Input() editable = false;
  @Input() canUserToggleEditionMode = false;

  @Output() toggleSatelliteView = new EventEmitter<boolean>();
  @Output() renameLayerGroup = new EventEmitter<DataLayerGroupConfig>();
  @Output() layerOrGroupRemoved = new EventEmitter<void>();
  @Output() editLayerProperties = new EventEmitter<DataLayerConfig>();
  @Output() viewLayerProperties = new EventEmitter<DataLayerConfig>();
  @Output() toggleEditionMode = new EventEmitter<DataLayerConfig>();

  public subgroupSpace = '0px';
  public filteredGroups: DataLayerGroupConfig[] = [];

  constructor(private dialog: DialogService, private mapService: MapService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['layerGroups']) {
      this.subgroupSpace = this.subgroupLevel * 20 + 'px';
      this.filteredGroups = this.layerGroups.filter((group) => this.hasLayers(group));
    }
  }

  toggleLayerVisibility(layer: DataLayerConfig): void {
    layer.toggleLayerVisibility();
    if (layer.isSatelliteLayer) {
      this.toggleSatelliteView.emit(layer.displayParameters.isVisible);
    }
  }

  opacitySliderLabel(value: number): string {
    return Math.round(value * 100) + '%';
  }

  deleteLayer(layer: DataLayerConfig, group: DataLayerGroupConfig): void {
    const layerName = layer.config.shortName;
    const config: ConfirmDialogConfig = {
      isDanger: true,
      title: $localize`Supprimer la couche`,
      content: [
        $localize`:Confirm dialog:Vous avez demandé la suppression de la couche <strong>${layerName}</strong>.`,
        $localize`:Confirm dialog:Souhaitez-vous continuer ?`,
      ],
    };
    this.dialog.openConfirm(config).subscribe((confirmed) => {
      if (confirmed) {
        group.removeLayer(layer);
        this.layerOrGroupRemoved.emit();
      }
    });
  }

  deleteLayerGroup(group: DataLayerGroupConfig): void {
    const groupName = group.config.category;
    const config: ConfirmDialogConfig = {
      isDanger: true,
      title: $localize`Supprimer le groupe de couches`,
      content: [
        $localize`:Confirm dialog:Vous avez demandé la suppression du groupe <strong>${groupName}</strong>. Cette action supprimera le groupe ainsi que toutes les couches qu'il contient.`,
        $localize`:Confirm dialog:Souhaitez-vous continuer ?`,
      ],
    };
    this.dialog.openConfirm(config).subscribe((confirmed) => {
      if (confirmed) {
        if (this.parent) {
          this.removeGroup(this.parent.subgroups, group);
        } else {
          this.removeGroup(this.layerGroups, group);
        }
        this.layerOrGroupRemoved.emit();
      }
    });
  }

  updateEditionMode(layer: DataLayerConfig) {
    layer.isEditing = !layer.isEditing;
    this.toggleEditionMode.emit(layer);
  }

  zoomOnLayer(layer: DataLayerConfig): void {
    this.mapService.fitToLayer(layer.config);
  }

  private hasLayers(group: DataLayerGroupConfig): boolean {
    return !!group.layers.length || group.subgroups.some((subgroup) => this.hasLayers(subgroup));
  }

  private removeGroup(groups: DataLayerGroupConfig[], group: DataLayerGroupConfig): void {
    const subgroupIndex = groups.findIndex((layerGroup) => group === layerGroup);
    if (subgroupIndex !== -1) {
      groups.splice(subgroupIndex, 1);
    }
  }
}
