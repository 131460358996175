import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'smv-opti-semis',
  templateUrl: './opti-semis.component.html',
  styleUrls: ['./opti-semis.component.scss', '../../client-app-panel.scss'],
})
export class OptiSemisComponent {
  @Input() applicationId!: number;
  @Input() canImport = false;
  @Input() panelHide = true;
  @Output() displayed = new EventEmitter();

  public importLabel = $localize`:OptiSemis|Import label:Importer des parcelles`;
}
