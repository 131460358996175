import { CommonModule } from '@angular/common';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, Inject, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { NotificationService } from '@core/services/notification.service';
import { isNil } from 'lodash-es';
import { InterraScanService } from '../interra-scan.service';
import { Parcel, ParcelRpg } from '../model/parcel.model';
import { ParcelDetailFormComponent } from './parcel-detail-form/parcel-detail-form.component';

export interface DataParcel {
  appId: number;
  parcel: ParcelRpg;
}

type ParcelState = 'FETCHING' | 'ACCESS' | 'FORBIDDEN';

@Component({
  standalone: true,
  selector: 'smv-interra-scan-parcel-detail',
  templateUrl: './parcel-detail.component.html',
  styleUrls: ['./parcel-detail.component.scss'],
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatDividerModule, ParcelDetailFormComponent],
})
export class ParcelDetailComponent {
  @Input() title = $localize`:InterraScan|Parcel details:Informations sur la parcelle`;
  @Input() appId!: number;
  public state: ParcelState = 'FETCHING';
  public formInvalid = true;

  public parcel?: Partial<Parcel>;

  private newParcel = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DataParcel,
    private dialogRef: MatDialogRef<ParcelDetailComponent>,
    private interraScanService: InterraScanService,
    private notificationService: NotificationService
  ) {
    this.appId = data.appId;
    this.interraScanService.retrieveParcelDetails(this.appId, data.parcel.parcelId).subscribe({
      next: (parcel) => {
        this.state = 'ACCESS';
        this.parcel = isNil(parcel) ? data.parcel : parcel;
        this.newParcel = isNil(parcel);
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.Forbidden) {
          this.state = 'FORBIDDEN';
        } else {
          this.notificationService.unknownServerError();
        }
      },
    });
  }

  saveParcel() {
    let createOrModifyObs = undefined;
    if (this.parcel && this.newParcel) {
      createOrModifyObs = this.interraScanService.createParcel(this.parcel);
    } else if (this.parcel) {
      createOrModifyObs = this.interraScanService.modifyParcel(this.parcel);
    }
    if (createOrModifyObs) {
      createOrModifyObs.subscribe({
        next: () => {
          this.notificationService.success(
            $localize`:InterraScan|Parcel creation successful:Les informations de la parcelle ont bien été sauvegardées.`
          );
          this.dialogRef.close();
        },
        error: this.handleError.bind(this),
      });
    }
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status == HttpStatusCode.BadRequest) {
      this.notificationService.error(
        $localize`:InterraScan|Parcel not complete:Des données obligatoires de la parcelle sont manquantes. Veuillez réessayer ultérieurement ou prendre contact avec l'administrateur.`
      );
    } else if (error.status == HttpStatusCode.Conflict) {
      this.notificationService.error(
        $localize`:InterraScan|Parcel already exists:La parcelle est déjà spécifié pour le client renseigné.`
      );
    } else if (error.status == HttpStatusCode.Forbidden) {
      this.notificationService.error(
        $localize`:InterraScan|Parcel access denied:Vous n'avez les autorisations pour modifier la parcelle séléctionée.`
      );
    } else {
      this.notificationService.unknownServerError();
    }
  }
}
