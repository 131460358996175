import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { DateUtils } from '@core/utils/date.utils';

/**
 * Check that startDate and endDate are well ordered.
 *
 * @param form The FormGroup object
 * @returns Validation errors with "dateRangeInvalid" key
 */
export const dateRangeValidator = (form: AbstractControl): ValidationErrors | null => {
  if (!form.value) {
    return null;
  }
  const { periodicity, startDate, endDate } = form.value;

  if (!startDate || !endDate) {
    return null;
  }
  const startDateFormatError = (form as FormGroup).controls['startDate'].getError('pattern');
  if (startDateFormatError) {
    return null;
  }
  const endDateFormatError = (form as FormGroup).controls['endDate'].getError('pattern');
  if (endDateFormatError) {
    return endDateFormatError;
  }
  const computeStartDate: Date = DateUtils.stringToDate(startDate, periodicity);
  const computeEndDate: Date = DateUtils.stringToDate(endDate, periodicity);

  const error = computeStartDate.valueOf() <= computeEndDate.valueOf() ? null : { dateRangeInvalid: true };

  // Manually set errors on the form field as this is a FormGroup level validator
  (form as FormGroup).controls['endDate']?.setErrors(error);

  return error;
};
