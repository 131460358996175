import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { LoadingComponent } from '@components/loading/loading.component';
import { CustomerFilterComponent } from '@widgets/components/customer-filter/customer-filter.component';
import { PanelComponent } from '@widgets/components/panel/panel.component';
import { UploadFileComponent } from '@widgets/components/upload-file/upload-file.component';
import { ZoomPositionComponent } from '@widgets/components/zoom-position/zoom-position.component';
import { IcareAdminComponent } from './icare-admin.component';

@NgModule({
  declarations: [IcareAdminComponent],
  imports: [
    CommonModule,
    LoadingComponent,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    PanelComponent,
    CustomerFilterComponent,
    UploadFileComponent,
    ZoomPositionComponent,
  ],
  exports: [IcareAdminComponent],
})
export class IcareAdminModule {}
