import { BooleanInput } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  Input,
  OnChanges,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { FormControl, FormsModule, NG_VALUE_ACCESSOR, NgControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FloatLabelType, MatFormFieldModule, SubscriptSizing } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ErrorMessages } from '@components/form-field-errors/form-error-messages';
import { FormFieldErrorsComponent } from '@components/form-field-errors/form-field-errors.component';
import { HelpOverlayComponent } from '@components/help-overlay/help-overlay.component';
import { ControlValueAccessorImpl } from '@core/form/control-value-accessor.impl';
import { GeneralUtils, KeysOfType } from '@core/utils/general.utils';
import { isNil } from 'lodash-es';
import { ReplaySubject } from 'rxjs';

export type ControlType = 'input' | 'select' | 'textarea' | 'autocomplete';
export interface DropdownOption<T> {
  label: string;
  value: T;
}

const modules = [
  CommonModule,
  FormsModule,
  FormFieldErrorsComponent,
  HelpOverlayComponent,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  ReactiveFormsModule,
  MatAutocompleteModule,
];

@Component({
  selector: 'smv-form-field-wrapper',
  standalone: true,
  imports: modules,
  templateUrl: './form-field-wrapper.component.html',
  styleUrls: ['./form-field-wrapper.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormFieldWrapperComponent),
      multi: true,
    },
  ],
})
export class FormFieldWrapperComponent<T> extends ControlValueAccessorImpl<T> implements AfterViewInit, OnChanges {
  @Input() type = 'text';
  @Input() step = 1;
  @Input() placeholder = '';
  @Input() readonly: BooleanInput = false;
  @Input() required: BooleanInput = false;
  @Input() requireSelection: BooleanInput = false;
  @Input() showEmptyWhenDisabled: BooleanInput = false;
  @Input() hideLabel: BooleanInput = false;
  @Input() hasHint: BooleanInput = false;
  @Input() hasHelp: BooleanInput = false;
  @Input() hasAction: BooleanInput = false;
  @Input() optionsInit: BooleanInput = false;
  @Input() errorMessages: ErrorMessages = {};
  @Input() controlType: ControlType = 'input';
  @Input() selectOptions: DropdownOption<T>[] | T[] = [];
  @Input() selectIdentifier?: KeysOfType<T, string | number>;
  @Input() serverFilterCondition: (search: string) => boolean = (search) => search.length > 1;
  @Input() serverFilterOptions?: (search: string, filtered: ReplaySubject<DropdownOption<T>[] | T[]>) => void;
  @Input() subscriptSizing: SubscriptSizing = 'fixed';
  @Input() helpCloseAfter = 3000;
  @Input() override control?: FormControl<T>;

  public formattedSelectOptions: DropdownOption<T>[] = [];
  public formattedSelectOptionsFiltered: DropdownOption<T>[] = [];
  public isEmpty = true;
  // force the label to float on value
  public floatLabel: FloatLabelType = this.control?.value ? 'always' : 'auto';

  private searchValue?: T | string;
  private filteredServerOptions: ReplaySubject<DropdownOption<T>[] | T[]> = new ReplaySubject(1);

  constructor(@Inject(Injector) private injector: Injector, private changeDetector: ChangeDetectorRef) {
    super();
    this.filteredServerOptions.subscribe(
      (options) => (this.formattedSelectOptionsFiltered = this.formatOptions(options))
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.coerceAll([
      'hideLabel',
      'readonly',
      'hasHint',
      'hasHelp',
      'required',
      'requireSelection',
      'showEmptyWhenDisabled',
      'hasAction',
    ]);
    if ((this.controlType === 'select' || this.controlType === 'autocomplete') && this.selectOptions) {
      this.formattedSelectOptions = this.formatOptions(this.selectOptions);
      if (this.controlType === 'autocomplete' && this.control?.value) {
        this._filter(this.control.value);
      } else {
        this.formattedSelectOptionsFiltered = this.formattedSelectOptions;
      }
    } else {
      this.formattedSelectOptions = [];
    }
    if (changes['selectOptions'] && !changes['selectOptions'].isFirstChange()) {
      if (this.formattedSelectOptions?.length) {
        if (this.optionsInit && (this.controlType === 'autocomplete' || this.controlType == 'select')) {
          this.control?.setValue(this.control.value ?? this.formattedSelectOptions[0].value);
        } else {
          const value = this.formattedSelectOptions.find((v) => this.checkIsValue(v, this.control?.value))?.value;
          if (this.coerced['required']) {
            this.control?.setValue(value ?? this.formattedSelectOptions[0].value);
          } else {
            this.control?.setValue(value ?? <T>null);
          }
        }
      } else if (this.formattedSelectOptions.length == 0) {
        this.control?.setValue(<T>null);
      }
      this.floatLabel = this.control?.value ? 'always' : 'auto';
    }
    if (changes['required']) {
      this.changeDetector.detectChanges();
    }
  }

  ngAfterViewInit(): void {
    if (!this.control) {
      this.registerFormControl(this.injector.get(NgControl));
    }
    this.isEmpty = isNil(this.control?.value);
    if (this.controlType === 'autocomplete' || this.controlType == 'select') {
      this.formattedSelectOptionsFiltered = this.formattedSelectOptions;
      this.control?.valueChanges.subscribe((value) => this._filter(value));
    }
    this.changeDetector.detectChanges();
  }

  autocompleteFilter(value: string) {
    if (this.coerced['requireSelection']) {
      this._filter(value);
    }
  }

  _filter(value: T | string) {
    if (!value) {
      this.formattedSelectOptionsFiltered = this.formattedSelectOptions;
      return;
    }
    if (value != this.searchValue) {
      this.searchValue = value;
      if (typeof value === 'string') {
        if (!this.serverFilterOptions) {
          const loweredValue = value.toString().toLowerCase();
          this.formattedSelectOptionsFiltered = this.formattedSelectOptions.filter(
            (filter) =>
              filter.label.toLowerCase().includes(loweredValue) ||
              (typeof filter.value === 'string' && filter.value.toLowerCase().includes(loweredValue))
          );
        } else if (this.serverFilterCondition(value)) {
          this.serverFilterOptions(value, this.filteredServerOptions);
        }
      } else {
        this._filter(this.getLabel(value));
      }
    }
  }

  displayFn(newVal: T) {
    return this.formattedSelectOptionsFiltered.find((elem) => this.checkIsValue(elem, newVal))?.label ?? '';
  }

  private getLabel(newVal: T): string {
    return this.formattedSelectOptions.find((elem) => this.checkIsValue(elem, newVal))?.label ?? '';
  }

  private checkIsValue(option: DropdownOption<T>, value?: T) {
    if (!isNil(this.selectIdentifier) && !isNil(value)) {
      return option.value[this.selectIdentifier] == value[this.selectIdentifier];
    }
    return option.value == value;
  }

  override writeValue(value: T): void {
    super.writeValue(value);
    this.isEmpty = isNil(value);
  }

  private formatOptions(options: DropdownOption<T>[] | T[]): DropdownOption<T>[] {
    return GeneralUtils.isArrayOfPrimitive<T>(options)
      ? options.map((value) => {
          return {
            label: String(value),
            value: value,
          };
        })
      : options;
  }
}
