import { Component } from '@angular/core';
import { GeoserverVariablesService } from '@widgets/widgets-filter-cql.service';
import { Crop } from '../../model/crop.model';
import { OptiSemisService } from '../../opti-semis.service';

@Component({
  selector: 'smv-opti-semis-crop-filter',
  templateUrl: './opti-semis-crop-filter.component.html',
  styleUrls: ['./opti-semis-crop-filter.component.scss'],
})
export class OptiSemisCropFilterComponent {
  public crops: Crop[] = [];

  private filterAttr = 'crop_code';

  constructor(private geoserverVariables: GeoserverVariablesService, private optiSemisService: OptiSemisService) {
    this.optiSemisService.getCrops().subscribe((crops) => {
      crops.forEach((crop) => (crop.selected = true));
      this.crops = crops;
      this.computeFilter();
    });
  }

  private getSelectedJoinValues(): string {
    const values = this.crops.filter((filter) => filter.selected).map((filter) => filter.code);
    return values.join(`', '`);
  }

  public computeFilter() {
    const filter = `${this.filterAttr} in ('${this.getSelectedJoinValues()}')`;
    this.geoserverVariables.optiSemisFilterState.setParticularValue('crop', filter);
  }
}
