export interface WidgetTrap {
  trap_code: string;
  trap_name: string;
}

export interface StatisticConfig {
  display: boolean;
  traps?: WidgetTrap[];
  filter?: TrapFilter;
}

interface TrapFilterModel {
  emprise?: string;
  startDate?: string;
  endDate?: string;
  customers?: string;
}

export class TrapFilter {
  emprise?: string;
  startDate?: string;
  endDate?: string;
  customers?: string;

  constructor(input: TrapFilterModel) {
    if (input) {
      Object.assign(this, input);
    }
  }

  getPostMappingValue() {
    return { emprise: this.emprise, startDate: this.startDate, endDate: this.endDate };
  }

  getGetMappingValue() {
    return (
      '?emprise=' +
      this.emprise +
      (this.startDate ? '&startDate=' + this.startDate : '') +
      (this.endDate ? '&endDate=' + this.endDate : '') +
      (this.customers ? '&customers=' + this.customers : '')
    );
  }

  toString() {
    return (
      "{'emprise' : " + this.emprise + ", 'startDate' : " + this.startDate + ", 'endDate' : '" + this.endDate + '}'
    );
  }
}
