import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ParameterId, WidgetModel, WidgetType } from '@core/model/application-api/widget.model';
import { WidgetService } from '@widgets/widgets.service';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'smv-widgets-management',
  templateUrl: './widgets-management.component.html',
  styleUrls: ['./widgets-management.component.scss'],
})
export class WidgetsManagementComponent implements OnInit {
  @Input() widgets!: WidgetModel[];
  @Input() saving = false;

  @Output() editWidgetParameters = new EventEmitter<WidgetModel>();
  @Output() saveWidgets = new EventEmitter<WidgetModel[]>();

  public widgetsState: WidgetModel[] = [];

  public WidgetType = WidgetType;
  public ParameterId = ParameterId;

  constructor(private widgetService: WidgetService) {}

  ngOnInit() {
    this.widgetsState = this.widgetService.getWidgetsState();
  }

  updateWidgetVisibility(widget: WidgetModel) {
    this.updateWidget(widget);
    this.widgetService.widgetVisibilityState.setParticularValue(widget.widgetId, widget.visible);
    this.widgetService.widgetLocalVisibilityState.setParticularValue(widget.widgetId, widget.visible);
  }

  updateWidget(widget: WidgetModel | WidgetModel[]) {
    if (Array.isArray(widget)) {
      this.widgetService.updateWidgetState(widget);
    } else {
      this.widgetService.getWidgetContextItem(widget.widgetId).setValue(widget);
    }
  }

  resetWidgetParameters() {
    this.widgetsState = cloneDeep(this.widgets);
    this.updateWidget(this.widgetsState);
  }

  saveWidgetParameters() {
    this.saveWidgets.emit(this.widgetsState);
  }
}
