<div class="smv-dialog">
  <h1 mat-dialog-title>{{ title }}</h1>
  <mat-divider></mat-divider>
  <div mat-dialog-content class="content">
    <ng-container [ngSwitch]="state">
      <span *ngSwitchCase="'FETCHING'" i18n>Récupération des données de la parcelle...</span>
      <span *ngSwitchCase="'FORBIDDEN'" i18n>Parcelle déjà attribuée par un autre organisme</span>
      <smv-interra-scan-parcel-detail-form
        *ngSwitchCase="'ACCESS'"
        [appId]="appId"
        [(parcel)]="parcel"
        (formInvalid)="formInvalid = $event"
      />
    </ng-container>
    <div mat-dialog-actions>
      <ng-container [ngSwitch]="state">
        <ng-container *ngSwitchCase="'ACCESS'">
          <button mat-button [mat-dialog-close]="false" i18n="Button">Annuler</button>
          <button mat-raised-button color="primary" [disabled]="formInvalid" (click)="saveParcel()" i18n="Button">
            Confirmer
          </button>
        </ng-container>
        <button *ngSwitchDefault mat-flat-button mat-dialog-close i18n="Button">Fermer</button>
      </ng-container>
    </div>
  </div>
</div>
