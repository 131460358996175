<mat-form-field>
  <mat-label i18n>Nom du groupe</mat-label>
  <input matInput [formControl]="groupName" />
  <mat-error>
    <smv-form-field-errors [field]="groupName"></smv-form-field-errors>
  </mat-error>
</mat-form-field>

<div class="smv-sticky-bottom-actions">
  <button mat-stroked-button i18n="Button|Layer manager action" (click)="closePanel.emit()">Annuler</button>
  <button mat-flat-button color="primary" i18n="Button|Layer manager action" (click)="applyChanges()">Terminer</button>
</div>
