import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SelectionService } from '@core/services/selection.service';
import { GeneralUtils } from '@core/utils/general.utils';
import { isNil } from 'lodash-es';
import { Feature } from 'ol';
import { Subscription, filter } from 'rxjs';
import { FarmerStatsComponent } from './farmer-stats/farmer-stats.component';
import { InterraScanService } from './interra-scan.service';
import { DataParcel, ParcelDetailComponent } from './parcel-detail/parcel-detail.component';
import { ParcelStatsComponent } from './parcel-stats/parcel-stats.component';
import { ParcelSynthesisComponent } from './parcel-synthesis/parcel-synthesis.component';

@Component({
  selector: 'smv-interra-scan',
  templateUrl: './interra-scan.component.html',
  styleUrls: ['./interra-scan.component.scss', '../../client-app-panel.scss'],
})
export class InterraScanComponent implements OnDestroy {
  @Input() applicationId!: number;
  @Input() canEdit = false;
  @Input() panelHide = true;
  @Output() displayed = new EventEmitter();
  public labelZoom = $localize`:InterraScan:Code de la parcelle`;

  private readonly rpgLayerCode = 'rpgLayer';
  private subscriptions = new Subscription();

  constructor(
    private interraScanService: InterraScanService,
    private selectionService: SelectionService,
    private dialogModule: MatDialog
  ) {
    this.subscriptions.add(
      this.selectionService.selectedFeatures
        .getStream()
        .pipe(filter(() => this.interraScanService.activeDetailPopup.getValue()))
        .pipe(filter(GeneralUtils.isNotNull))
        .subscribe((selections) => {
          const filtered = selections.filter((selection) => selection.layer.config.code === this.rpgLayerCode);
          if (filtered.length && filtered[0].features.length) {
            this.openParcelDetail(filtered[0].features[0]);
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getPosition = (parcelId: string) => this.interraScanService.retrieveParcelPosition(parcelId);

  openFarmerStats() {
    this.dialogModule.open(FarmerStatsComponent, {
      data: {
        appId: this.applicationId,
      },
    });
  }

  openParcelStats() {
    this.dialogModule.open(ParcelStatsComponent, {
      data: {
        appId: this.applicationId,
      },
    });
  }

  openParcelSynthesis() {
    this.dialogModule.open(ParcelSynthesisComponent, {
      data: {
        appId: this.applicationId,
      },
    });
  }

  private openParcelDetail(parcel: Feature) {
    const parcelId = parcel.get('id_parcel');
    if (!isNil(parcel.getGeometry()) && !isNil(parcelId)) {
      const data: DataParcel = {
        appId: this.applicationId,
        parcel: {
          parcelId,
          surface: parcel.get('surf_parc'),
        },
      };
      this.dialogModule.open(ParcelDetailComponent, { data });
    }
  }
}
