<div id="sidemenu-title">MAPVIEW™</div>

<mat-list>
  <div>
    <ng-container *ngFor="let menu of menuOptions">
      <mat-list-item
        *ngIf="!menu.adminOnly || (isAdmin$ | async)"
        [routerLink]="menu.path"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        (click)="sendCloseSideNav()"
      >
        {{ menu.label }}
      </mat-list-item>
    </ng-container>
  </div>

  <!-- Bottom section -->
  <div>
    <mat-divider></mat-divider>
    <mat-nav-list>
      <a mat-list-item href="mailto:map.view@syngenta.com" i18n="Links|Link to contact page">Contact</a>
    </mat-nav-list>
    <mat-list-item
      *ngFor="let menu of bottomMenuOptions"
      [routerLink]="menu.path"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="sendCloseSideNav()"
    >
      {{ menu.label }}
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item
      routerLink="/compte"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="sendCloseSideNav()"
    >
      <mat-icon matListItemIcon>account_circle</mat-icon>
      <ng-container i18n="Sidenav">Mon compte</ng-container>
    </mat-list-item>
    <mat-list-item (click)="authService.logOut()">
      <mat-icon matListItemIcon>logout</mat-icon>
      <ng-container i18n="Sidenav">Déconnexion</ng-container>
    </mat-list-item>
  </div>
</mat-list>
<div id="sidemenu-footer">
  <span>©{{ copyrightYear }} Syngenta</span>
  <span>v{{ smvVersion }}</span>
</div>
