import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ConfirmDialogConfig } from '@components/dialog/confirm-dialog/confirm-dialog.component';
import { DialogService } from '@components/dialog/dialog.service';
import { AttributeType, LayerProperty, LayerType } from '@core/model/application-api/layer.model';
import { ApplicationConfig } from '@core/model/application.model';
import { Communes } from '@core/model/communes.model';
import { ContextItem } from '@core/model/context-item.model';
import { VectorLayerConfig } from '@core/model/layer-config.model';
import { DateUtils } from '@core/utils/date.utils';
import { GeneralUtils, Indexable } from '@core/utils/general.utils';
import { MapUtils } from '@core/utils/map.utils';
import { DataLayerConfig } from '@feature/client-app/data-layer-config.model';
import { ApplicationApiService } from './api/application-api.service';
import { AuthService } from './auth.service';
import { CommunesService } from './communes.service';
import { FeatureCategories, GeoserverService, actionType } from './geoserver.service';
import { NotificationService } from './notification.service';
import { SelectionService } from './selection.service';

import { isBoolean } from 'lodash-es';
import { Collection, Feature, Map } from 'ol';
import { Coordinate } from 'ol/coordinate';
import BaseEvent from 'ol/events/Event';
import GeoJSONFormat from 'ol/format/GeoJSON';
import { Circle, Geometry, MultiPolygon, Polygon } from 'ol/geom';
import { Type } from 'ol/geom/Geometry';
import { fromCircle } from 'ol/geom/Polygon';
import { Draw, Modify, Select, Snap, Translate } from 'ol/interaction';
import { DrawEvent } from 'ol/interaction/Draw';
import { ModifyEvent } from 'ol/interaction/Modify';
import { SelectEvent } from 'ol/interaction/Select';
import { TranslateEvent } from 'ol/interaction/Translate';
import Layer from 'ol/layer/Layer';
import VectorSource from 'ol/source/Vector';
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from 'ol/style';
import { Observable, Subscription, asyncScheduler, forkJoin, map, observeOn, of, switchMap, tap } from 'rxjs';

export enum EditionToolType {
  ADD_POLYGON = 'ADD_POLYGON',
  ADD_POINT = 'ADD_POINT',
  ADD_LINE = 'ADD_LINE',
  ADD_CIRCLE = 'ADD_CIRCLE',
  UPDATE_SHAPE = 'UPDATE_SHAPE',
  MOVE_SHAPE = 'MOVE_SHAPE',
  DELETE = 'DELETE',
}

type ResponseType = Indexable<string | ResponseType[]>;

type ResponseTypeWrapper = Indexable<ResponseType> | ResponseType[] | ResponseType;

@Injectable({ providedIn: 'root' })
export class EditionService extends GeoserverService {
  private map?: Map;

  public activeTool = new ContextItem<EditionToolType | undefined>(undefined);

  protected override vectorSource?: VectorSource<Geometry>;
  private layerName?: string;
  private draw?: Draw;
  private select?: Select;
  private selectToDelete?: Select;
  private translate?: Translate;
  private snap?: Snap;
  private modify?: Modify;

  private editingLayer?: DataLayerConfig;
  private editingFeature?: Feature;

  private get interactions() {
    return [this.draw, this.select, this.selectToDelete, this.translate, this.modify];
  }

  private drawStyle = new Style({
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.2)',
    }),
    stroke: new Stroke({
      color: 'rgba(0, 0, 0, 0.5)',
      lineDash: [10, 10],
      width: 2,
    }),
    image: new CircleStyle({
      radius: 7,
      stroke: new Stroke({
        color: 'rgba(0, 0, 0, 0.5)',
        lineDash: [3, 3],
        width: 2,
      }),
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.5)',
      }),
    }),
    text: new Text({
      font: '12px Calibri,sans-serif',
      fill: new Fill({ color: '#000' }),
      stroke: new Stroke({
        color: '#fff',
        width: 1,
      }),
      text: '',
    }),
    zIndex: 50,
  });
  private deleteStyle = new Style({
    fill: new Fill({
      color: 'rgba(255, 0, 0, 0.2)',
    }),
    stroke: new Stroke({
      color: 'rgba(255, 0, 0, 0.5)',
      width: 2,
    }),
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({
        color: 'rgba(255, 0, 0, 0.5)',
      }),
    }),
    text: new Text({
      font: '12px Calibri,sans-serif',
      fill: new Fill({ color: 'rgba(255, 0, 0, 0.5)' }),
    }),
    zIndex: 50,
  });

  private get configConfirmDelete(): ConfirmDialogConfig {
    return {
      isDanger: true,
      title: $localize`Supprimer l'entité`,
      content: [
        $localize`:Confirm dialog:Vous avez demandé la suppression d'une entité de la couche <strong>${this.layerName}</strong>.`,
        $localize`:Confirm dialog:Souhaitez-vous continuer ?`,
      ],
    };
  }

  constructor(
    private applicationService: ApplicationApiService,
    private communesService: CommunesService,
    protected override selectionService: SelectionService,
    protected override authService: AuthService,
    private dialogService: DialogService,
    protected override notificationService: NotificationService,
    protected override http: HttpClient
  ) {
    super(selectionService, authService, notificationService, http);
  }

  setupEdition(mapInstance: Map) {
    this.map = mapInstance;
  }

  configureEdition(layer: DataLayerConfig) {
    this.vectorSource = layer.olLayer?.getSource() as VectorSource;
    this.editingLayer = layer;
    this.layerName = layer.config.shortName;

    of(true)
      .pipe(observeOn(asyncScheduler))
      .subscribe(() => this.setupInteractions(layer));
  }

  private setupInteractions(layer: DataLayerConfig) {
    if (layer.olLayer) {
      this.resetInteractions();
      this.setupSelectInteraction(layer.olLayer);
      this.setupSnapInteraction();
      this.setupModifyInteraction();
      this.setupTranslateInteraction();
      this.setupDeleteEdition();
    }
  }

  resetEdition(keepConfig = false) {
    if (!keepConfig) {
      this.vectorSource = undefined;
      this.layerName = undefined;
    }
    this.stopEdition();
  }

  /**
   * this function aims to get the data from a source and put the requested data into an object
   * @param response response from the query
   * @param path array of strings to search for, this can be a variable name or "[]" to itterate
   * @param ctrlValName name of the variable to set as the key
   * @param dispValName name of the variable to set as the value
   */
  private recursiveGetData(
    response: ResponseTypeWrapper,
    path: string[],
    ctrlValName: string,
    dispValName: string
  ): Indexable<string> {
    if (path.length == 0) {
      if (Object.keys(response).indexOf(dispValName) != -1 && Object.keys(response).indexOf(ctrlValName) != -1) {
        const retVal: Indexable<string> = {};
        if (!Array.isArray(response)) {
          const newKey = response[ctrlValName];
          if (typeof newKey === 'string' || typeof newKey === 'number') {
            retVal[newKey] = String(response[dispValName]);
            return retVal;
          }
        }
      }
      return {};
    }
    if (Object.keys(response).indexOf(path[0]) != -1) {
      if (!Array.isArray(response)) {
        const newResponse = response[path[0]];
        if (typeof newResponse !== 'string') {
          return this.recursiveGetData(newResponse, path.slice(1), ctrlValName, dispValName);
        }
      }
    }
    if (path[0] == '[]') {
      const newPath = path.slice(1);
      const retVal: Indexable<string> = {};
      if (Array.isArray(response)) {
        for (const element of response) {
          const elemsToAdd = this.recursiveGetData(element, newPath, ctrlValName, dispValName);
          for (const key in elemsToAdd) {
            retVal[key] = elemsToAdd[key];
          }
        }
      }
      return retVal;
    }
    return {};
  }

  getDataFromDataSource(dataSource: string, dependsOnValue?: string): Observable<Indexable<string>> {
    const [url, pathVal, ctrlValName, dispValName] = dataSource.split('::');
    const path = pathVal.split('.');

    if (!url.length || pathVal == undefined || !ctrlValName.length || !dispValName.length) {
      return new Observable((observer) => observer.next({}));
    }

    let urlCpy = url;
    if (dependsOnValue != undefined) {
      if (urlCpy.includes('{int}') && Number(dependsOnValue)) {
        urlCpy = url.replaceAll('{int}', String(dependsOnValue));
      } else if (urlCpy.includes('{string}')) {
        urlCpy = url.replaceAll('{string}', dependsOnValue);
      } else if (urlCpy.includes('{}')) {
        urlCpy = url.replaceAll('{}', dependsOnValue);
      } else {
        return new Observable((observer) => observer.next({}));
      }
    }

    const request = this.http
      .get<ResponseTypeWrapper>(urlCpy)
      .pipe(map((response: ResponseTypeWrapper) => this.recursiveGetData(response, path, ctrlValName, dispValName)));
    return request;
  }

  private resetInteractions() {
    if (this.select) {
      this.map?.removeInteraction(this.select);
    }
    if (this.selectToDelete) {
      this.map?.removeInteraction(this.selectToDelete);
    }
    if (this.snap) {
      this.map?.removeInteraction(this.snap);
    }
    if (this.translate) {
      this.map?.removeInteraction(this.translate);
    }
    if (this.modify) {
      this.map?.removeInteraction(this.modify);
    }
  }

  updateFeature(
    baseFeature: Feature,
    updatedFeature: Feature,
    layer: DataLayerConfig,
    action: actionType,
    stopEdition = true
  ): Subscription {
    if (stopEdition) {
      this.stopEdition();
    }
    if (action == 'insert' || action == 'updateAttributes' || action == 'updateGeometry') {
      return this.applyDefaultValues(updatedFeature, layer, action)
        .pipe(switchMap(() => this.launchUpdateOfFeature(baseFeature, updatedFeature, layer, action)))
        .subscribe((message: string | boolean) => {
          if (message && typeof message === 'string') {
            this.notificationService.success(message);
          }
        });
    } else {
      return this.launchUpdateOfFeature(baseFeature, updatedFeature, layer, action).subscribe(
        (message: string | boolean) => {
          if (message && typeof message === 'string') {
            this.notificationService.success(message);
          }
        }
      );
    }
  }

  private launchUpdateOfFeature(
    baseFeature: Feature,
    updatedFeature: Feature,
    layer: DataLayerConfig,
    action: actionType
  ): Observable<string | boolean> {
    const features: FeatureCategories = this.prepareEditionCategories(baseFeature, updatedFeature, action);
    if (layer.config.type === LayerType.WFS) {
      return this.updateWfsFeature(features, layer, false, action);
    } else if (layer.config.type === LayerType.VECTOR) {
      return this.updateVectorFeature(layer);
    }
    return of(false);
  }

  private updateVectorFeature(layer: DataLayerConfig) {
    const application = this.applicationService.currentApplication.getValue();
    if (application) {
      const updatedConfiguration = new ApplicationConfig(application.config);
      const config = layer.config as VectorLayerConfig;
      const source = layer.olLayer?.getSource() as VectorSource;
      if (config.type === 'Vector' && source) {
        const feats = source.getFeatures();
        const featstring = new GeoJSONFormat().writeFeatures(feats);
        const sizeInfo = Number(featstring.length / 1024);
        if (sizeInfo > 500) {
          this.notificationService.error(
            $localize`:Edition|Too much data:Il y a trop de données dans cette couche pour la sauvegarde (${sizeInfo} ko), vous devez supprimer des objets.`
          );
        } else {
          const layerInConfig = updatedConfiguration.layers.find((layerConfig) => {
            if ('nom_court' in layerConfig && layerConfig.type === 'Vector') {
              return layerConfig.nom_court === config.shortName;
            }
            return false;
          });
          if (layerInConfig && 'nom_court' in layerInConfig) {
            layerInConfig.jsonData = featstring;
            return this.applicationService
              .updateApplicationConfig(application, updatedConfiguration)
              .pipe(
                map(
                  () => $localize`:Edition|Success message:La modification de la couche a été enregistrée avec succès.`
                )
              );
          }
        }
      }
    }
    return of(false);
  }

  private setupAddInteraction(type: Type) {
    if (this.draw) {
      this.map?.removeInteraction(this.draw);
    }
    this.draw = new Draw({
      source: this.vectorSource,
      type: type,
      style: this.drawStyle,
      geometryName: 'geom',
    });
    this.map?.addInteraction(this.draw);

    this.draw.on('drawend', (event: DrawEvent) => {
      const feature: Feature = event.feature;

      // WFS ne peut enregistrer de cercle. On utilise OpenLayer pour le convertir en Polygone
      if (type == 'Circle') {
        const circle = (feature as Feature<Circle>).getGeometry();
        if (circle) {
          const polygonFromCircle: Polygon = fromCircle(circle);
          const guessFeatureType = this.getFeatureType('Polygon');
          if (guessFeatureType == 'MultiPolygon') {
            const multiPolygonFromCircle = new MultiPolygon(
              [polygonFromCircle.getCoordinates()],
              polygonFromCircle.getLayout()
            );
            feature.setGeometry(multiPolygonFromCircle);
          } else {
            feature.setGeometry(polygonFromCircle);
          }
        }
      }

      if (this.editingLayer) {
        this.updateFeature(feature, feature, this.editingLayer, 'insert');
      }
    });
  }

  private setupSelectInteraction(layer: Layer) {
    this.select = new Select({
      layers: [layer],
      style: this.drawStyle,
      multi: false,
    });
    this.selectToDelete = new Select({
      layers: [layer],
      style: this.deleteStyle,
      multi: false,
    });

    this.map?.addInteraction(this.select);
    this.map?.addInteraction(this.selectToDelete);
    this.select.setActive(false);
    this.selectToDelete.setActive(false);

    this.select.on('select', (event: SelectEvent) => {
      if (event.selected.length) {
        this.editingFeature = this.prepareClonedFeature(event.selected[0]);
      }
    });
  }

  private setupTranslateInteraction() {
    this.translate = new Translate({
      features: this.select?.getFeatures(),
    });

    this.map?.addInteraction(this.translate);

    this.translate.setActive(false);

    this.translate.on('translateend', (event: TranslateEvent) => {
      const feature = event.features.getArray()[0];
      if (this.editingFeature && this.editingLayer) {
        this.updateFeature(this.editingFeature, feature, this.editingLayer, 'updateGeometry');
      }
    });
  }

  private setupSnapInteraction() {
    this.snap = new Snap({
      source: this.vectorSource,
    });

    this.map?.addInteraction(this.snap);
  }

  private setupModifyInteraction() {
    this.modify = new Modify({
      style: this.drawStyle,
      features: this.select?.getFeatures(),
    });

    this.map?.addInteraction(this.modify);

    this.modify.setActive(false);

    this.modify.on('modifyend', (event: ModifyEvent) => {
      const feature = event.features.getArray()[0];
      if (this.editingFeature && this.editingLayer) {
        this.updateFeature(this.editingFeature, feature as Feature, this.editingLayer, 'updateGeometry', false);
      }
    });
  }

  private setupDeleteEdition() {
    this.selectToDelete?.on('select', (event: BaseEvent) => {
      const features: Collection<Feature> = event.target.getFeatures();
      if (features.getLength()) {
        const feature = features.getArray()[0];
        if (
          this.editingLayer?.config.type === 'Vector' ||
          feature.get('to_create') ||
          feature.get('to_modify_geometry') ||
          feature.get('to_modify_attributes') ||
          feature.get('to_delete')
        ) {
          this.dialogService.openConfirm(this.configConfirmDelete).subscribe((confirmed) => {
            if (confirmed) {
              if (this.editingLayer?.config.type === 'Vector') {
                this.vectorSource?.removeFeature(feature);
              }
              this.deleteEdition(feature);
            }
          });
        } else {
          this.deleteEdition(feature);
        }
      }
    });
  }

  private deleteEdition(feature: Feature) {
    if (this.editingLayer) {
      this.updateFeature(feature, feature, this.editingLayer, 'delete');
    }
    this.select?.getFeatures().clear();
    this.selectionService.selectedFeatures.reset();
  }

  activeEdition(type: EditionToolType) {
    this.selectionService.selection.setActive(false);
    this.activeTool.setValue(type);
    let geomType;

    switch (type) {
      case EditionToolType.ADD_POLYGON:
        geomType = this.getFeatureType('Polygon');
        if (geomType) {
          this.setupAddInteraction(geomType);
        }
        break;
      case EditionToolType.ADD_LINE:
        geomType = this.getFeatureType('LineString');
        if (geomType) {
          this.setupAddInteraction(geomType);
        }
        break;
      case EditionToolType.ADD_POINT:
        geomType = this.getFeatureType('Point');
        if (geomType) {
          this.setupAddInteraction(geomType);
        }
        break;
      case EditionToolType.ADD_CIRCLE:
        this.setupAddInteraction('Circle');
        break;
      case EditionToolType.UPDATE_SHAPE:
        this.select?.setActive(true);
        this.modify?.setActive(true);
        break;
      case EditionToolType.MOVE_SHAPE:
        this.select?.setActive(true);
        this.translate?.setActive(true);
        break;
      case EditionToolType.DELETE:
        this.selectToDelete?.setActive(true);
        break;
      default:
        break;
    }
  }

  stopEdition() {
    this.select?.getFeatures().clear();
    this.selectToDelete?.getFeatures().clear();
    this.interactions.forEach((interaction) => {
      interaction?.setActive(false);
    });
    this.selectionService.selection.setActive(true);
    this.activeTool.reset();
  }

  getFeatureType(_default?: Type): Type | undefined {
    const firstFeature = this.vectorSource?.getFeatures()[0];
    const geom = firstFeature?.getGeometry();
    if (firstFeature && geom) {
      return geom.getType();
    } else {
      return _default;
    }
  }

  private prepareClonedFeature(feature: Feature) {
    const clonedFeature: Feature = feature.clone();
    clonedFeature.setId(feature.getId());
    clonedFeature.setGeometryName('geom');

    return clonedFeature;
  }

  private applyDefaultValues(updatedFeature: Feature, layer: DataLayerConfig, action: actionType) {
    const properties = layer.config.properties;
    const calls: Observable<{ Communes: Communes }>[] = [];
    if (properties) {
      const coordsStr = ['{DEPT_CODE}', '{NOM_COMMUNE}', '{ZIP_CODE}'];
      const lonlat: Coordinate | null = MapUtils.convertFeatureToLonLat(updatedFeature);
      const needCom = coordsStr.some((elem) =>
        properties
          .filter((property) => property.configuration?.defaultValues['default']?.length)
          .some((property) =>
            property.configuration?.defaultValues['default'][0]
              ? property.configuration.defaultValues['default'][0].indexOf(elem) > -1
              : false
          )
      );
      if (lonlat && needCom) {
        calls.push(
          this.communesService.getCommuneFromLonLat(lonlat).pipe(
            tap((city) => {
              this.buildCasesToDefaultValues(
                action,
                updatedFeature,
                properties,
                lonlat,
                city ? city.Communes : undefined
              );
            })
          )
        );
      } else {
        this.buildCasesToDefaultValues(action, updatedFeature, properties, lonlat);
      }
    }
    return calls.length ? forkJoin(calls) : of([]);
  }

  private buildCasesToDefaultValues(
    action: actionType,
    updatedFeature: Feature,
    properties: LayerProperty[],
    lonlat: Coordinate | null,
    city?: Communes
  ) {
    properties.forEach((property) => {
      if (property.type === AttributeType.BOOLEAN && !isBoolean(updatedFeature.get(property.name))) {
        updatedFeature.set(property.name, property.configuration?.defaultBoolean ?? false);
      } else if (
        property.configuration?.defaultValues['default']?.length === 1 ||
        (!updatedFeature.get(property.name) &&
          !property.nillable &&
          property.configuration?.defaultValues['default'] &&
          property.configuration.defaultValues['default'].length > 1)
      ) {
        const defaultValue = property.configuration.defaultValues['default'][0];
        this.replaceDefaultValues(action, updatedFeature, property, lonlat, city, defaultValue);
      }
    });
    properties.forEach((property) => {
      if (property.configuration?.dependsOn && !property.nillable) {
        const value = updatedFeature.get(property.configuration.dependsOn);
        if (value && property.configuration.defaultValues[value]) {
          const defaultValue = property.configuration.defaultValues[value][0];
          this.replaceDefaultValues(action, updatedFeature, property, lonlat, city, defaultValue);
        }
      }
    });
  }

  private replaceDefaultValues(
    action: actionType,
    updatedFeature: Feature,
    property: LayerProperty,
    lonlat: Coordinate | null,
    city?: Communes,
    defaultValue?: string
  ) {
    const geomsStr = ['{LATITUDE}', '{LONGITUDE}', '{DEPT_CODE}', '{NOM_COMMUNE}', '{ZIP_CODE}'];
    if (property.configuration) {
      if (
        action != 'updateGeometry' ||
        !geomsStr.some((elem) => (defaultValue ? defaultValue.indexOf(elem) > -1 : false))
      ) {
        defaultValue = updatedFeature.get(property.name) ?? defaultValue;
      }
      defaultValue = defaultValue ?? property.configuration.defaultValues['default'][0];

      if (typeof defaultValue === 'string') {
        defaultValue = defaultValue.replaceAll('{UUID}', GeneralUtils.getUniqueId(4));
        defaultValue = defaultValue.replaceAll('{LONGITUDE}', lonlat ? lonlat[0].toString() : '');
        defaultValue = defaultValue.replaceAll('{LATITUDE}', lonlat ? lonlat[1].toString() : '');
        defaultValue = defaultValue.replaceAll('{DEPT_CODE}', String(city?.codeDept ?? ''));
        defaultValue = defaultValue.replaceAll('{ZIP_CODE}', city?.zipCode ?? '');
        defaultValue = defaultValue.replaceAll('{NOM_COMMUNE}', city?.nomCom ?? '');
        for (let index = 5; index <= 12; index++) {
          defaultValue = defaultValue?.replaceAll(
            `{${index.toString()}_NUMBER_ID}`,
            GeneralUtils.getUniqueNumberId(index)
          );
        }
      }

      updatedFeature.set(property.name, defaultValue, true);
    }
  }

  private prepareEditionCategories(
    baseFeature: Feature,
    updatedFeature: Feature,
    action: actionType
  ): FeatureCategories {
    const categories: FeatureCategories = {
      toAdd: [],
      toUpdate: [],
      toDelete: [],
    };

    const baseFeatureToBeSaved = this.prepareClonedFeature(baseFeature);
    const featureToBeSaved = this.prepareClonedFeature(updatedFeature);

    const appType = this.applicationService.currentApplication.getValue()?.functionnalityConfig.code;

    switch (action) {
      case 'insert':
        this.handleInsertEdition(featureToBeSaved, appType);
        featureToBeSaved.setGeometry(updatedFeature.getGeometry());
        categories.toAdd.push(featureToBeSaved);
        break;
      case 'updateGeometry':
      case 'updateAttributes':
        if (baseFeature.get('to_create')) {
          this.handleInsertUpdateEdition(featureToBeSaved, 'insert', appType);
          categories.toUpdate.push(featureToBeSaved);
        } else if (baseFeature.get('to_modify_geometry') || baseFeature.get('to_modify_attributes')) {
          this.handleInsertUpdateEdition(featureToBeSaved, action, appType);
          categories.toUpdate.push(featureToBeSaved);
        } else if (baseFeature.get('to_delete')) {
          this.notificationService.error(
            $localize`:Edition|Error message:Vous ne pouvez pas modifier une entité à supprimer. Supprimer la proposition de modification si vous voulez modifier l'entité.`
          );
        } else {
          this.handleUpdateEdition(baseFeatureToBeSaved, featureToBeSaved, action, appType);
          featureToBeSaved.setGeometry(updatedFeature.getGeometry());
          categories.toAdd.push(featureToBeSaved);
        }
        break;
      case 'delete':
        if (
          baseFeature.get('to_create') ||
          baseFeature.get('to_modify_geometry') ||
          baseFeature.get('to_modify_attributes') ||
          baseFeature.get('to_delete')
        ) {
          categories.toDelete.push(featureToBeSaved);
        } else {
          this.handleDeleteEdition(baseFeatureToBeSaved, featureToBeSaved, appType);
          featureToBeSaved.setGeometry(updatedFeature.getGeometry());
          categories.toAdd.push(featureToBeSaved);
        }
        break;
    }
    return categories;
  }

  private handleInsertEdition(insertedFeature: Feature, appType?: string) {
    const dateModif = DateUtils.getDateForEdition();
    const insertProperties = {
      date_modif: dateModif,
      user_modif: this.authService.getUserEmailInSync(),
      date_creat: dateModif,
      user_creat: this.authService.getUserEmailInSync(),
      to_create: true,
      is_modified: true,
      modified_in: appType,
    };
    insertedFeature.setProperties(insertProperties);
  }

  private handleInsertUpdateEdition(insertedFeature: Feature, action: actionType, appType?: string) {
    const dateModif = DateUtils.getDateForEdition();
    const insertProperties = {
      date_modif: dateModif,
      user_modif: this.authService.getUserEmailInSync(),
      modified_in: appType,
    };
    if (action === 'updateGeometry') {
      insertedFeature.set('to_modify_geometry', true);
    } else if (action === 'updateAttributes') {
      insertedFeature.set('to_modify_attributes', true);
    }
    insertedFeature.setProperties(insertProperties);
  }

  private handleUpdateEdition(toUpdateFeature: Feature, updatedFeature: Feature, action: actionType, appType?: string) {
    const dateModif = DateUtils.getDateForEdition();

    const updatedProperties = {
      date_creat: toUpdateFeature.getProperties()['date_creat'],
      user_creat: toUpdateFeature.getProperties()['user_creat'],
      date_modif: dateModif,
      user_modif: this.authService.getUserEmailInSync(),
      is_modified: true,
      linked_entity: MapUtils.getFeatureId(toUpdateFeature),
      modified_in: appType,
    };

    switch (action) {
      case 'updateGeometry':
        updatedFeature.set('to_modify_geometry', true);
        break;
      case 'updateAttributes':
        updatedFeature.set('to_modify_attributes', true);
        break;
    }

    updatedFeature.setProperties(updatedProperties);
  }

  private handleDeleteEdition(toDeleteFeature: Feature, deletedFeature: Feature, appType?: string) {
    const dateModif = DateUtils.getDateForEdition();
    const deleteProperties = {
      date_modif: dateModif,
      user_modif: this.authService.getUserEmailInSync(),
      is_modified: true,
      to_delete: true,
      linked_entity: MapUtils.getFeatureId(toDeleteFeature),
      modified_in: appType,
    };

    deletedFeature.setProperties(deleteProperties);
  }
}
