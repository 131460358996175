<div [hidden]="panelHide">
  <mat-card class="side-panel icare-panel">
    <mat-card-title>
      <div>
        <span i18n>Service Eudémis</span>
        <button mat-icon-button (click)="displayed.emit()">
          <mat-icon>keyboard_double_arrow_right</mat-icon>
        </button>
      </div>
    </mat-card-title>

    <mat-card-content>
      <smv-icare-filter />

      <smv-customer-filter [appId]="applicationId" />

      <smv-statistic [allTrapLayerCode]="allTrapLayerCode" />

      <smv-panel title="Import relevés" i18n-title *ngIf="canImport">
        <smv-upload-file [url]="'/icare/datum/' + this.applicationId" accept=".csv" />
      </smv-panel>
      <smv-panel *ngIf="canImport" title="Rapport" i18n-title>
        <smv-date-select [appId]="applicationId" />
      </smv-panel>
    </mat-card-content>
  </mat-card>
</div>

<smv-icare-sheet *ngIf="isSheetDisplayed" />
