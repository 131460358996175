import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { FeatureCategories, GeoserverService } from '@core/services/geoserver.service';
import { NotificationService } from '@core/services/notification.service';
import { SelectionService } from '@core/services/selection.service';
import { Feature } from 'ol';
import { Geometry } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import { Observable } from 'rxjs';
import { DataLayerConfig } from '../data-layer-config.model';

@Injectable({ providedIn: 'root' })
export class EditionValidationService extends GeoserverService {
  protected override messageFail = $localize`:Edition|Error message: La modification a échoué. Veuillez consulter un administrateur.`;
  protected override messageSuccess = $localize`:Edition|Success message:La modification a été enregistrée avec succès.`;
  protected override vectorSource?: VectorSource<Geometry>;

  constructor(
    protected override selectionService: SelectionService,
    protected override authService: AuthService,
    protected override notificationService: NotificationService,
    protected override http: HttpClient
  ) {
    super(selectionService, authService, notificationService, http);
  }

  public validEdition(
    editedFeatures: Feature[],
    layer: DataLayerConfig,
    originalFeatures: Feature[],
    valid = true,
    useConfigProj = true
  ): Observable<string | boolean> {
    this.vectorSource = layer.olLayer?.getSource() as VectorSource;
    let categories: FeatureCategories;
    if (valid) {
      categories = this.prepareEditionValidationCategories(editedFeatures, originalFeatures);
    } else {
      categories = this.prepareEditionRejectCategories(...editedFeatures);
    }
    return this.updateWfsFeature(categories, layer, useConfigProj);
  }

  public deleteLinkedEntities(layer: DataLayerConfig, ...entities: Feature[]) {
    const categories = this.prepareEditionRejectCategories(...entities);
    return this.updateWfsFeature(categories, layer).subscribe((message: string | boolean) => {
      if (message && typeof message === 'string') {
        this.notificationService.success(message);
      }
    });
  }

  private prepareClonedFeature(feature: Feature) {
    const clonedFeature: Feature = feature.clone();
    clonedFeature.setId(feature.getId());
    clonedFeature.setGeometryName('geom');

    return clonedFeature;
  }

  private prepareEditionValidationCategories(
    editedFeatures: Feature[],
    originalFeatures: Feature[]
  ): FeatureCategories {
    const categories: FeatureCategories = {
      toAdd: [],
      toUpdate: [],
      toDelete: [],
    };

    for (const index in editedFeatures) {
      const editedFeature = editedFeatures[index];
      const originalFeature = originalFeatures[index];
      const editedFeatureToBeSaved = this.prepareClonedFeature(editedFeature);
      const originalFeatureToBeSaved = this.prepareClonedFeature(originalFeature);

      if (editedFeature.get('to_create')) {
        this.handleInsertValidation(editedFeatureToBeSaved);
        categories.toUpdate.push(editedFeatureToBeSaved);
      } else if (editedFeature.get('to_modify_geometry') || editedFeature.get('to_modify_attributes')) {
        editedFeatureToBeSaved.setGeometry(editedFeature.getGeometry());
        this.handleModifyValidation(originalFeatureToBeSaved, editedFeatureToBeSaved);
        categories.toUpdate.push(originalFeatureToBeSaved);
        categories.toDelete.push(editedFeatureToBeSaved);
      } else if (editedFeature.get('to_delete')) {
        categories.toDelete.push(editedFeatureToBeSaved, originalFeatureToBeSaved);
      }
    }
    return categories;
  }

  private handleInsertValidation(insertedFeature: Feature) {
    const insertProperties = {
      to_create: false,
      is_modified: false,
    };
    insertedFeature.setProperties(insertProperties);
  }

  private handleModifyValidation(originalFeature: Feature, insertedFeature: Feature) {
    const insertProperties = {
      linked_entity: null,
      to_create: false,
      to_modify_geometry: false,
      to_modify_attributes: false,
      is_modified: false,
    };
    insertedFeature.setProperties(insertProperties);
    originalFeature.setProperties(insertedFeature.getProperties());
    originalFeature.unset('geometry');
  }

  private prepareEditionRejectCategories(...editedFeature: Feature[]): FeatureCategories {
    const categories: FeatureCategories = {
      toAdd: [],
      toUpdate: [],
      toDelete: [],
    };
    categories.toDelete.push(...editedFeature.map(this.prepareClonedFeature));

    return categories;
  }

  public retrieveSpecificDisplay(url: string) {
    return this.http.get(url, { responseType: 'text' });
  }
}
