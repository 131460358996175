import { DropdownOption } from '@components/form/form-field-wrapper/form-field-wrapper.component';
import {
  FillPattern,
  Palette,
  StrokePattern,
  SymbolPattern,
  TextAlignment,
} from '@core/model/styles/style-config.model';

const FILL_PATTERNS: readonly FillPattern[] = Object.freeze([
  'backslash',
  'circle',
  'cross',
  'dot',
  'horline',
  'plus',
  'simple',
  'slash',
  'square',
  'vertline',
]);

const STROKE_PATTERNS: readonly StrokePattern[] = Object.freeze([
  'backslash',
  'circle',
  'cross',
  'plus',
  'simple',
  'slash',
  'square',
  'vertline',
]);

const SYMBOLS: readonly SymbolPattern[] = Object.freeze(['circle', 'cross', 'square', 'star', 'triangle']);

const ALIGNMENTS: readonly TextAlignment[] = Object.freeze(['center', 'end', 'left', 'right', 'start']);

const PALETTES: readonly Palette[] = Object.freeze([
  'blue',
  'classic',
  'no-colors',
  'spectral',
  'trove',
  'yellowToRed',
]);

export const toOptions = (patterns: readonly string[]) =>
  patterns.map((pattern) => ({
    label: pattern,
    value: pattern,
  }));

export type DynamicStyleType = 'aucun' | 'categories' | 'gradient';

export const FILL_PATTERN_OPTIONS: DropdownOption<string>[] = toOptions(FILL_PATTERNS);
export const STROKE_PATTERN_OPTIONS: DropdownOption<string>[] = toOptions(STROKE_PATTERNS);
export const SYMBOL_OPTIONS: DropdownOption<string>[] = toOptions(SYMBOLS);
export const ALIGNMENT_OPTIONS: DropdownOption<string>[] = toOptions(ALIGNMENTS);
export const DYNAMIC_STYLE_TYPE_OPTIONS: DropdownOption<DynamicStyleType>[] = [
  { label: $localize`:Layer dynamic style:Aucun`, value: 'aucun' },
  { label: $localize`:Layer dynamic style:Par catégorie`, value: 'categories' },
  { label: $localize`:Layer dynamic style:Dégradé`, value: 'gradient' },
];
export const PALETTE_OPTIONS: DropdownOption<string>[] = toOptions(PALETTES);

const palettesDefinition: { [index: string]: string[] } = {
  blue: ['#f7fbff', '#deebf7', '#c6dbef', '#9ecae1', '#6baed6', '#4292c6', '#2171b5', '#08519c', '#08306b'],
  classic: [
    'rgb(249,248,113)',
    'rgb(255,199,95)',
    'rgb(255,150,113)',
    'rgb(255,111,145)',
    'rgb(214,93,177)',
    'rgb(132,94,194)',
    'rgb(44,115,210)',
    'rgb(0,137,186)',
    'rgb(0,142,155)',
    'rgb(0,143,122)',
  ],
  'no-colors': [],
  spectral: [
    '#9e0142',
    '#d53e4f',
    '#f46d43',
    '#fdae61',
    '#fee08b',
    '#e6f598',
    '#abdda4',
    '#66c2a5',
    '#3288bd',
    '#5e4fa2',
  ],
  trove: [
    'rgba(81,87,74,1)',
    'rgba(68,124,105,1)',
    'rgba(116,196,147,1)',
    'rgba(142,140,109,1)',
    'rgba(228,191,128,1)',
    'rgba(233,215,142,1)',
    'rgba(226,151,93,1)',
    'rgba(241,150,112,1)',
    'rgba(225,101,82,1)',
    'rgba(201,74,83,1)',
    'rgba(190,81,104,1)',
    'rgba(163,73,116,1)',
    'rgba(153,55,103,1)',
    'rgba(101,56,125,1)',
    'rgba(78,36,114,1)',
    'rgba(145,99,182,1)',
    'rgba(226,121,163,1)',
    'rgba(224,89,139,1)',
    'rgba(124,159,176,1)',
    'rgba(86,152,196,1)',
    'rgba(154,191,136,1)',
  ],
  yellowToRed: ['#ffffcc', '#ffeda0', '#fed976', '#feb24c', '#fd8d3c', '#fc4e2a', '#e31a1c', '#bd0026', '#800026'],
};

export const PALETTES_DEFINITION = Object.freeze(palettesDefinition);
