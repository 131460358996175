import { ContextItem } from '@core/model/context-item.model';

export class TemplateUtils {
  public static readonly noHeaderFooter = new ContextItem<boolean>(false);

  static setNoHeaderFooter(state: boolean) {
    this.noHeaderFooter.setValue(state);
  }

  static resetHeaderFooter() {
    this.noHeaderFooter.reset();
  }
}
