<smv-loading [loaders]="[pdfLoader]" />
<div class="smv-dialog">
  <h1 mat-dialog-title i18n="Parcel area statistics">Statistiques sur les parcelles</h1>
  <mat-divider />

  <div mat-dialog-content>
    <smv-loading [loaders]="[loader]" [overlay]="false" [spinnerDiameter]="50" />

    <ng-container *ngIf="!loader.condition">
      <!-- Crop statistics -->
      <!-- The table id is required for QH2O PDF export -->
      <mat-table [dataSource]="cropStatsData" id="areaCropStats">
        <ng-container matColumnDef="cropType">
          <mat-header-cell *matHeaderCellDef>{{ cropLabels.cropType }}</mat-header-cell>
          <mat-cell *matCellDef="let crop">{{ crop.cropType }}</mat-cell>
          <mat-footer-cell *matFooterCellDef i18n="Parcel area statistics">Total</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="totalArea">
          <mat-header-cell *matHeaderCellDef>{{ cropLabels.totalArea }}</mat-header-cell>
          <mat-cell *matCellDef="let crop">
            {{ crop.totalArea | statistic : cropFooter.totalArea }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ cropFooter.totalArea | statistic : cropFooter.totalArea }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="surfaceBuffer5m">
          <mat-header-cell *matHeaderCellDef>{{ cropLabels.surfaceBuffer5m }}</mat-header-cell>
          <mat-cell *matCellDef="let crop">
            {{ crop.surfaceBuffer5m | statistic : cropFooter.totalArea }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ cropFooter.surfaceBuffer5m | statistic : cropFooter.totalArea }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="surfaceBuffer20m">
          <mat-header-cell *matHeaderCellDef>{{ cropLabels.surfaceBuffer20m }}</mat-header-cell>
          <mat-cell *matCellDef="let crop">
            {{ crop.surfaceBuffer20m | statistic : cropFooter.totalArea }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ cropFooter.surfaceBuffer20m | statistic : cropFooter.totalArea }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="surfaceBuffer50m">
          <mat-header-cell *matHeaderCellDef>{{ cropLabels.surfaceBuffer50m }}</mat-header-cell>
          <mat-cell *matCellDef="let crop">
            {{ crop.surfaceBuffer50m | statistic : cropFooter.totalArea }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ cropFooter.surfaceBuffer50m | statistic : cropFooter.totalArea }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="surfaceBuffer100m">
          <mat-header-cell *matHeaderCellDef>{{ cropLabels.surfaceBuffer100m }}</mat-header-cell>
          <mat-cell *matCellDef="let crop">
            {{ crop.surfaceBuffer100m | statistic : cropFooter.totalArea }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ cropFooter.surfaceBuffer100m | statistic : cropFooter.totalArea }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="highVulnerability">
          <mat-header-cell *matHeaderCellDef i18n="Parcel area statistics">Fortement vulnérable (ha)</mat-header-cell>
          <mat-cell *matCellDef="let crop">
            {{ crop.highVulnerability | statistic : cropFooter.totalArea }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ cropFooter.highVulnerability | statistic : cropFooter.totalArea }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="mediumVulnerability">
          <mat-header-cell *matHeaderCellDef i18n="Parcel area statistics">Moyennement vulnérable (ha)</mat-header-cell>
          <mat-cell *matCellDef="let crop">
            {{ crop.mediumVulnerability | statistic : cropFooter.totalArea }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ cropFooter.mediumVulnerability | statistic : cropFooter.totalArea }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="mildVulnerability">
          <mat-header-cell *matHeaderCellDef i18n="Parcel area statistics">Faiblement vulnérable (ha)</mat-header-cell>
          <mat-cell *matCellDef="let crop">
            {{ crop.mildVulnerability | statistic : cropFooter.totalArea }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ cropFooter.mildVulnerability | statistic : cropFooter.totalArea }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="lowVulnerability">
          <mat-header-cell *matHeaderCellDef i18n="Parcel area statistics"
            >Très faiblement vulnérable (ha)</mat-header-cell
          >
          <mat-cell *matCellDef="let crop">
            {{ crop.lowVulnerability | statistic : cropFooter.totalArea }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ cropFooter.lowVulnerability | statistic : cropFooter.totalArea }}
          </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="cropColumns" />
        <mat-row *matRowDef="let row; columns: cropColumns" />
        <mat-footer-row *matFooterRowDef="cropColumns" />
      </mat-table>

      <!-- Vulnerability statistics -->
      <!-- The table id is required for QH2O PDF export -->
      <mat-table [dataSource]="vulnerabilityStatsData" id="areaVulnerabilityStats">
        <ng-container matColumnDef="vulnerabilityLevel">
          <mat-header-cell *matHeaderCellDef i18n="Parcel area statistics">Vulnérabilité</mat-header-cell>
          <mat-cell *matCellDef="let vulnerability">
            <ng-container [ngSwitch]="vulnerability.vulnerabilityLevel">
              <ng-container *ngSwitchCase="'V'" i18n="Parcel area statistics">Fortement vulnérable</ng-container>
              <ng-container *ngSwitchCase="'d'" i18n="Parcel area statistics">Moyennement vulnérable</ng-container>
              <ng-container *ngSwitchCase="'m'" i18n="Parcel area statistics">Faiblement vulnérable</ng-container>
              <ng-container *ngSwitchCase="'o'" i18n="Parcel area statistics">Très faiblement vulnérable</ng-container>
              <ng-container *ngSwitchDefault>{{ vulnerability.vulnerabilityLevel }}</ng-container>
            </ng-container>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef i18n="Parcel area statistics">Total</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="totalArea">
          <mat-header-cell *matHeaderCellDef i18n="Parcel area statistics"
            >Surface par vulnérabilité (ha)</mat-header-cell
          >
          <mat-cell *matCellDef="let vulnerability">
            {{ vulnerability.totalArea | statistic : vulnerabilityFooter.totalArea }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ vulnerabilityFooter.totalArea | statistic : vulnerabilityFooter.totalArea }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="surfaceBuffer5m">
          <mat-header-cell *matHeaderCellDef i18n="Parcel area statistics">Surface tampon à 5m (ha)</mat-header-cell>
          <mat-cell *matCellDef="let vulnerability">
            {{ vulnerability.surfaceBuffer5m | statistic : vulnerabilityFooter.totalArea }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ vulnerabilityFooter.surfaceBuffer5m | statistic : vulnerabilityFooter.totalArea }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="surfaceBuffer20m">
          <mat-header-cell *matHeaderCellDef i18n="Parcel area statistics">Surface tampon à 20m (ha)</mat-header-cell>
          <mat-cell *matCellDef="let vulnerability">
            {{ vulnerability.surfaceBuffer20m | statistic : vulnerabilityFooter.totalArea }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ vulnerabilityFooter.surfaceBuffer20m | statistic : vulnerabilityFooter.totalArea }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="surfaceBuffer50m">
          <mat-header-cell *matHeaderCellDef i18n="Parcel area statistics">Surface tampon à 50m (ha)</mat-header-cell>
          <mat-cell *matCellDef="let vulnerability">
            {{ vulnerability.surfaceBuffer50m | statistic : vulnerabilityFooter.totalArea }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ vulnerabilityFooter.surfaceBuffer50m | statistic : vulnerabilityFooter.totalArea }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="surfaceBuffer100m">
          <mat-header-cell *matHeaderCellDef i18n="Parcel area statistics">Surface tampon à 100m (ha)</mat-header-cell>
          <mat-cell *matCellDef="let vulnerability">
            {{ vulnerability.surfaceBuffer100m | statistic : vulnerabilityFooter.totalArea }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ vulnerabilityFooter.surfaceBuffer100m | statistic : vulnerabilityFooter.totalArea }}
          </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="vulnerabilityColumns" />
        <mat-row *matRowDef="let row; columns: vulnerabilityColumns" />
        <mat-footer-row *matFooterRowDef="vulnerabilityColumns" />
      </mat-table>
    </ng-container>
  </div>

  <div mat-dialog-actions>
    <ng-container *ngIf="!loader.condition">
      <button mat-flat-button color="primary" (click)="exportToPdf()" i18n="Button">Export PDF</button>
      <button mat-flat-button color="primary" (click)="exportToCalc()" i18n="Button">Export XLSX</button>
    </ng-container>
    <button mat-flat-button mat-dialog-close i18n="Button">Fermer</button>
  </div>
</div>
