<div class="smv-search">
  <mat-form-field class="input-search" appearance="outline" subscriptSizing="dynamic">
    <input
      matInput
      type="text"
      class="search-field"
      [placeholder]="placeholder"
      [(ngModel)]="searchValue"
      (keyup.enter)="onSearch()"
    />
    <div matSuffix class="actions">
      <ng-container *ngIf="searchValue">
        <button mat-icon-button (click)="clear()">
          <mat-icon>close</mat-icon>
        </button>
        <div class="divider"></div>
      </ng-container>
      <button mat-icon-button color="primary" [disabled]="!canSearch()" (click)="onSearch()">
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </mat-form-field>
</div>
