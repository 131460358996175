<form [formGroup]="validConfig" class="smv-form">
  <mat-slide-toggle class="unselectable" formControlName="grouped" i18n="Layer Validation|Grouped validation">
    Activer la validation groupée
  </mat-slide-toggle>

  <p class="section-title unselectable" i18n="Layer Validation|Warnings">Alertes sur les entités éditées</p>

  <mat-slide-toggle class="unselectable" formControlName="warnings" i18n="Layer Validation|Warning validation">
    Activer les alertes
  </mat-slide-toggle>
  <smv-form-field-wrapper
    formControlName="distanceWarning"
    type="number"
    [readonly]="!validConfig.controls.warnings.value"
    hasHelp
    [helpCloseAfter]="5000"
  >
    <span mat-label i18n="Layer Validation">Distance du déplacement de la géométrie</span>
    <span help i18n="Layer Validation">
      Alerte quand la distance entre la géométrie initiale et la géométrie modifiée est supérieure à la valeur
      renseignée. Laisser vide pour désactiver.
    </span>
  </smv-form-field-wrapper>

  <p class="section-title unselectable" i18n="Layer Validation|Notifications">Notifier l'édition</p>

  <smv-form-field-wrapper
    formControlName="notify"
    [selectOptions]="notifyOptions"
    subscriptSizing="dynamic"
    controlType="select"
    required
    hideLabel
  />
  <mat-slide-toggle
    *ngIf="validConfig.controls.notify.value !== 'NONE'"
    class="unselectable"
    formControlName="notifyForced"
    i18n="Layer Validation|Force notification"
  >
    Forcer la notification
  </mat-slide-toggle>

  <ng-container *ngIf="validConfig.controls.notify.value === 'CNC_EDITION'">
    <smv-form-field-wrapper
      formControlName="defaultValidationFeedback"
      subscriptSizing="dynamic"
      controlType="textarea"
    >
      <span mat-label i18n="Layer Validation">Message de validation par défaut</span>
    </smv-form-field-wrapper>

    <smv-form-field-wrapper formControlName="defaultRejectionFeedback" subscriptSizing="dynamic" controlType="textarea">
      <span mat-label i18n="Layer Validation">Message de rejet par défaut</span>
    </smv-form-field-wrapper>
  </ng-container>

  <p class="section-title unselectable" i18n="Layer Validation|Comment">Commentaires</p>

  <mat-slide-toggle class="unselectable" formControlName="comments" i18n="Layer Validation">
    Activer les commentaires
  </mat-slide-toggle>
</form>
