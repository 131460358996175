import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiMessage } from '@core/model/api-message.model';
import { map, Observable } from 'rxjs';

function isApiMessageError(object: object | null): object is ApiMessage {
  return object ? 'errorCode' in object : false;
}

@Injectable()
export class ApiMessageInterceptor implements HttpInterceptor {
  intercept(httpRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(httpRequest).pipe(
      map((event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse && typeof event.body === 'object' && isApiMessageError(event.body)) {
          throw new Error(event.body.errorCode, { cause: event.body.errorMessage ?? event.body.trace });
        }
        return event;
      })
    );
  }
}
