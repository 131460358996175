import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Loader } from '@components/loading/loading.component';
import {
  CropStatistics,
  VulnerabilityStatistics,
  cropStatisticsColumnOrder,
  cropStatisticsLabels,
  vulnerabilityStatisticsColumnOrder,
  vulnerabilityStatisticsLabels,
} from '@core/model/parcel-api/parcel-stats.model';
import { ParcelApiService } from '@core/services/api/parcel-api.service';
import { NotificationService } from '@core/services/notification.service';
import { XlsxSheet, exportToXlsx, splitToCells } from '@core/utils/xlsx.utils';
import { finalize } from 'rxjs';
import { Qualih2oExporterService } from '../quali-h2o-exporter.service';

@Component({
  selector: 'smv-parcel-area-stats',
  templateUrl: './parcel-area-stats.component.html',
  styleUrls: ['./parcel-area-stats.component.scss'],
})
export class ParcelAreaStatsComponent implements OnInit {
  public cropLabels = cropStatisticsLabels;
  public vulnerabilityLabels = vulnerabilityStatisticsLabels;
  public cropColumns = cropStatisticsColumnOrder;
  public vulnerabilityColumns = vulnerabilityStatisticsColumnOrder;
  public cropStatsData: CropStatistics[] = [];
  public cropFooter = new CropStatistics();
  public vulnerabilityStatsData: VulnerabilityStatistics[] = [];
  public vulnerabilityFooter = new VulnerabilityStatistics();
  public loader: Loader = { condition: true, text: $localize`Calcul des statistiques, veuillez patienter` };
  public pdfLoader: Loader = {
    text: $localize`:PDF loader:Préparation du PDF, veuillez patienter`,
    condition: false,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) private statRequest: string,
    private parcelApi: ParcelApiService,
    private qualih2oExporterService: Qualih2oExporterService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.parcelApi
      .computeStatsInArea(this.statRequest)
      .pipe(finalize(() => (this.loader.condition = false)))
      .subscribe({
        next: (stats) => {
          this.cropStatsData = Object.values(stats.cropStatistics);
          this.cropFooter.addAll(this.cropStatsData);
          this.vulnerabilityStatsData = Object.values(stats.vulnerabilityStatistics);
          this.vulnerabilityFooter.addAll(this.vulnerabilityStatsData);
        },
        error: () => {
          this.notificationService.unknownServerError();
        },
      });
  }

  exportToCalc(): void {
    const cropSheet: XlsxSheet = {
      name: $localize`Surface par type de culture`,
      headerRow: [...this.cropColumns],
      dataRows: splitToCells(this.cropStatsData, this.cropColumns),
    };

    const vulnerabilitySheet: XlsxSheet = {
      name: $localize`Surface par vulnérabilité`,
      headerRow: [...this.vulnerabilityColumns],
      dataRows: splitToCells(this.vulnerabilityStatsData, this.vulnerabilityColumns),
    };

    exportToXlsx($localize`export_rapport_surfacique`, [cropSheet, vulnerabilitySheet]);
  }

  exportToPdf(): void {
    this.pdfLoader.condition = true;
    this.qualih2oExporterService.exportToPdf(true).finally(() => (this.pdfLoader.condition = false));
  }
}
