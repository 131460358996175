import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { IcareService } from '../icare/icare.service';
import { trapviewTrapStatsComponent } from './trapview-trap-stats/trapview-trap-stats.component';

@Component({
  selector: 'smv-icare-admin',
  templateUrl: './icare-admin.component.html',
  styleUrls: ['./icare-admin.component.scss', '../../client-app-panel.scss'],
})
export class IcareAdminComponent {
  @Input() applicationId!: number;
  @Input() panelHide = true;
  @Input() canImport = false;
  @Output() displayed = new EventEmitter();

  public loading = false;
  public labelImport = $localize`:Icare:Import du mapping piège/client`;
  public labelZoom = $localize`:Icare:Code du piège`;

  constructor(private icareService: IcareService, private dialogModule: MatDialog) {}

  getPosition = (code: string) => this.icareService.getTrapPosition(code);

  openTrapStats() {
    this.dialogModule.open(trapviewTrapStatsComponent, {
      data: {
        appId: this.applicationId,
      },
    });
  }
}
