import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { FormFieldWrapperComponent } from '@components/form/form-field-wrapper/form-field-wrapper.component';
import { ParameterId, WidgetType } from '@core/model/application-api/widget.model';
import { MapService } from '@core/services/map.service';
import { isNull } from 'lodash-es';
import { Overlay } from 'ol';
import { fromLonLat } from 'ol/proj';
import { WidgetService } from '../widgets.service';

type Format = 'DD' | 'DMS';

@Component({
  selector: 'smv-coordinates-zoom',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatRadioModule,
    FormFieldWrapperComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './coordinates-zoom.component.html',
  styleUrls: ['./coordinates-zoom.component.scss'],
})
export class CoordinatesZoomComponent {
  private maxLatAbs = 90;
  private maxLongAbs = 180;
  private zoomLevel = 15;

  private ANY_NUMBER = /\d+(\.?\d+)*/;
  private DMS_FORMAT_D_LAT = /-?\d{1,2}°/;
  private DMS_FORMAT_D_LONG = /-?\d{1,3}°/;
  private DMS_FORMAT_M = /\d{1,2}'/;
  private DMS_FORMAT_S = /\d{1,2}(\.\d+)?"/;
  private DMS_COORD_LAT = new RegExp(
    '^' + this.DMS_FORMAT_D_LAT.source + this.DMS_FORMAT_M.source + this.DMS_FORMAT_S.source + '[NS]$'
  );
  private DMS_COORD_LONG = new RegExp(
    '^' + this.DMS_FORMAT_D_LONG.source + this.DMS_FORMAT_M.source + this.DMS_FORMAT_S.source + '[EO]$'
  );

  private markerElement = document.createElement('div');
  private marker = new Overlay({
    positioning: 'center-center',
    element: this.markerElement,
    stopEvent: false,
  });

  public formDMS = this.formBuilder.group({
    latitude: ['' as string, [Validators.pattern(this.DMS_COORD_LAT), Validators.required]],
    longitude: ['' as string, [Validators.pattern(this.DMS_COORD_LONG), Validators.required]],
  });

  public formDD = this.formBuilder.group({
    latitude: [undefined as number | undefined, Validators.required],
    longitude: [undefined as number | undefined, Validators.required],
  });
  public dmsLatInvalid = false;
  public dmsLongInvalid = false;
  public ddLatInvalid = false;
  public ddLongInvalid = false;

  public latitudeDegree: number | undefined = undefined;
  public longitudeDegree: number | undefined = undefined;

  public latitudePlaceholder = `48°51'45.81"N`;
  public longitudePlaceholder = `2°17'15.331"E`;

  public format: Format = 'DD';
  public markerVisible = false;

  constructor(
    private formBuilder: NonNullableFormBuilder,
    private mapService: MapService,
    private widgetService: WidgetService
  ) {
    this.markerElement.className = 'ol-marker';
    this.formDMS.valueChanges.subscribe(() => {
      const coords = this.formDMS.getRawValue();
      if (!this.formDMS.controls.latitude.invalid && coords.latitude) {
        this.latitudeDegree = this.toDegree(coords.latitude);
        this.dmsLatInvalid = !!this.latitudeDegree && Math.abs(this.latitudeDegree) > this.maxLatAbs;
      }

      if (!this.formDMS.controls.longitude.invalid && coords.longitude) {
        this.longitudeDegree = this.toDegree(coords.longitude);
        this.dmsLongInvalid = !!this.longitudeDegree && Math.abs(this.longitudeDegree) > this.maxLongAbs;
      }
    });

    this.formDD.valueChanges.subscribe(() => {
      const coords = this.formDD.getRawValue();
      if (!this.formDD.controls.latitude.invalid && coords.latitude) {
        this.latitudeDegree = coords.latitude;
        this.ddLatInvalid = !!this.latitudeDegree && Math.abs(this.latitudeDegree) > this.maxLatAbs;
      }
      if (!this.formDD.controls.longitude.invalid && coords.longitude) {
        this.longitudeDegree = coords.longitude;
        this.ddLongInvalid = !!this.longitudeDegree && Math.abs(this.longitudeDegree) > this.maxLongAbs;
      }
    });

    this.widgetService
      .getWidgetContextItem(WidgetType.COORDINATES_ZOOM)
      .getStream()
      .subscribe((widget) => {
        this.zoomLevel = Number(widget?.parameters.find((parameter) => parameter.id == ParameterId.ZOOM)?.value ?? 15);
      });
  }

  zoomToLocation() {
    if (!this.longitudeDegree || !this.latitudeDegree) {
      return;
    }

    const coord = fromLonLat([this.longitudeDegree, this.latitudeDegree]);
    this.marker.setPosition(coord);
    this.mapService.zoomTo(coord, this.zoomLevel);
    this.mapService.handleOverlay(this.marker, true);
    this.markerVisible = true;
  }

  hideMarker() {
    this.mapService.handleOverlay(this.marker);
    this.markerVisible = false;
  }

  private toDegree(coordinate: string): number | undefined {
    const degrees = this.ANY_NUMBER.exec(this.DMS_FORMAT_D_LONG.exec(coordinate)?.shift() ?? '')?.shift();
    const minutes = this.ANY_NUMBER.exec(this.DMS_FORMAT_M.exec(coordinate)?.shift() ?? '')?.shift();
    const seconds = this.ANY_NUMBER.exec(this.DMS_FORMAT_S.exec(coordinate)?.shift() ?? '')?.shift();
    if (isNull(degrees) || isNull(minutes) || isNull(seconds)) {
      return undefined;
    }
    const degCoord = Number(degrees) + Number(minutes) / 60 + Number(seconds) / 3600;
    if (!isNull(/[SO]/.exec(coordinate)) || !isNull(/-/.exec(coordinate))) {
      return -degCoord;
    }
    return degCoord;
  }

  checkDisabled() {
    return (
      (this.isDD() && this.formDD.invalid) ||
      (this.isDMS() && this.formDMS.invalid) ||
      !this.latitudeDegree ||
      !this.longitudeDegree ||
      Math.abs(this.latitudeDegree) > this.maxLatAbs ||
      Math.abs(this.longitudeDegree) > this.maxLongAbs
    );
  }

  private isDD() {
    return this.format == 'DD';
  }

  private isDMS() {
    return this.format == 'DMS';
  }
}
