import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MultiSelectComponent } from '@components/multi-select/multi-select.component';
import { TableComponent } from '@components/table/table.component';
import { ChartComponent } from '@widgets/components/chart/chart.component';
import { CustomerFilterComponent } from '@widgets/components/customer-filter/customer-filter.component';
import { FilterTypeDataComponent } from '@widgets/components/filter-type-data/filter-type-data.component';
import { PanelComponent } from '@widgets/components/panel/panel.component';
import { SheetStatisticComponent } from '@widgets/components/sheet-statistic/sheet-statistic.component';
import { StatisticComponent } from '@widgets/components/statistic/statistic.component';
import { UploadFileComponent } from '@widgets/components/upload-file/upload-file.component';
import { VigieViroseDisplayModeComponent } from './vigie-virose-display-mode/vigie-virose-display-mode.component';
import { VigieViroseCultureComponent } from './vigie-virose-filter/vigie-virose-filter-observations/vigie-virose-culture/vigie-virose-culture.component';
import { VigieViroseFilterObservationsComponent } from './vigie-virose-filter/vigie-virose-filter-observations/vigie-virose-filter-observations.component';
import { VigieViroseIndicatorComponent } from './vigie-virose-filter/vigie-virose-filter-observations/vigie-virose-indicators/vigie-virose-indicators.component';
import { VigieViroseFilterSimulationComponent } from './vigie-virose-filter/vigie-virose-filter-simulation/vigie-virose-filter-simulation.component';
import { VigieViroseFilterComponent } from './vigie-virose-filter/vigie-virose-filter.component';
import { VigieViroseSheetComponent } from './vigie-virose-sheet/vigie-virose-sheet.component';
import { VigieViroseTrapDescriptionComponent } from './vigie-virose-sheet/vigie-virose-trap-description/vigie-virose-trap-description.component';
import { VigieViroseTrapHistoryComponent } from './vigie-virose-sheet/vigie-virose-trap-history/vigie-virose-trap-history.component';
import { VigieViroseTrapVeriliferousStateComponent } from './vigie-virose-sheet/vigie-virose-trap-veriliferous-state/vigie-virose-trap-veriliferous-state.component';
import { VigieViroseComponent } from './vigie-virose.component';

@NgModule({
  declarations: [
    VigieViroseComponent,
    VigieViroseDisplayModeComponent,
    VigieViroseFilterComponent,
    VigieViroseFilterObservationsComponent,
    VigieViroseCultureComponent,
    VigieViroseIndicatorComponent,
    VigieViroseFilterSimulationComponent,
    VigieViroseSheetComponent,
    VigieViroseTrapDescriptionComponent,
    VigieViroseTrapHistoryComponent,
    VigieViroseTrapVeriliferousStateComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,
    MatRadioModule,
    MatDividerModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    FormsModule,
    TableComponent,
    MultiSelectComponent,
    PanelComponent,
    ChartComponent,
    FilterTypeDataComponent,
    CustomerFilterComponent,
    StatisticComponent,
    SheetStatisticComponent,
    UploadFileComponent,
  ],
  exports: [VigieViroseComponent],
})
export class VigieViroseModule {}
