import { CommonModule, DecimalPipe, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { HelpOverlayComponent } from '@components/help-overlay/help-overlay.component';
import { LoadingComponent } from '@components/loading/loading.component';
import { AacFeatureActionsComponent } from './aac-feature-actions/aac-feature-actions.component';
import { ParcelAreaStatsComponent } from './parcel-area-stats/parcel-area-stats.component';
import { QualiH2oComponent } from './quali-h2o.component';
import { StatisticPipe } from './statistic.pipe';
import { TownListComponent } from './town-list/town-list.component';

@NgModule({
  declarations: [
    ParcelAreaStatsComponent,
    QualiH2oComponent,
    StatisticPipe,
    AacFeatureActionsComponent,
    TownListComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HelpOverlayComponent,
    LoadingComponent,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MatRadioModule,
    MatSortModule,
    MatTableModule,
  ],
  exports: [QualiH2oComponent, AacFeatureActionsComponent],
  providers: [DecimalPipe, PercentPipe],
})
export class QualiH2oModule {}
