<div class="report-file-field">
  <mat-form-field subscriptSizing="dynamic">
    <mat-label i18n>Choisir une date</mat-label>
    <input
      matInput
      [formControl]="dateControl"
      [min]="minDate"
      [max]="maxDate"
      [matDatepicker]="picker"
      (dateInput)="OnDateChange($event.value)"
      readonly
    />
    <mat-hint i18n>JJ/MM/AAAA</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker [startAt]="date" #picker></mat-datepicker>
  </mat-form-field>
  <button
    id="triggerPdfButton"
    mat-raised-button
    color="primary"
    [disabled]="!formattedDate"
    (click)="triggerPdf()"
    i18n
  >
    Générer le pdf
  </button>
</div>
