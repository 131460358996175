<div class="smv-dialog">
  <h1 mat-dialog-title i18n>Modifier le style</h1>
  <mat-divider></mat-divider>
  <div mat-dialog-content cdkScrollable>
    <smv-feature-style-form [formGroup]="data.ruleStyle" [layerGeometry]="data.geometry"></smv-feature-style-form>
  </div>

  <div mat-dialog-actions>
    <button mat-raised-button color="primary" [disabled]="data.ruleStyle.invalid" mat-dialog-close i18n="Button">
      Fermer
    </button>
  </div>
</div>
