import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '@core/services/notification.service';
import { NotifyCustomerConfig } from '../components/samples-admin/notify-customer/notify-customer.component';
import { VigieViroseService } from '../vigie-virose/services/vigie-virose.service';
import { UserDatumStatsComponent } from './user-datum-stats/user-datum-stats.component';
import { UserTrapStatsComponent } from './user-trap-stats/user-trap-stats.component';

import { finalize } from 'rxjs';

@Component({
  selector: 'smv-vigie-virose-admin',
  templateUrl: './vigie-virose-admin.component.html',
  styleUrls: ['./vigie-virose-admin.component.scss', '../../client-app-panel.scss'],
})
export class VigieViroseAdminComponent {
  @Input() applicationId!: number;
  @Input() panelHide = true;
  @Input() canImportDatum = false;
  @Input() canEditTrap = false;
  @Output() displayed = new EventEmitter();

  public availableDates: string[] = [];

  public loading = false;

  public useSendDate = false;

  constructor(
    private notificationService: NotificationService,
    private vigieViroseService: VigieViroseService,
    private dialogModule: MatDialog
  ) {
    this.vigieViroseService
      .getMondayDatesWithData(this.useSendDate)
      .subscribe((dates) => (this.availableDates = dates));
  }

  notifyCustomers(config: NotifyCustomerConfig) {
    this.loading = true;
    this.vigieViroseService
      .notifyCustomersOfWeekSamples(config.customers, this.applicationId, config.pastWeekCount, this.useSendDate)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: () =>
          this.notificationService.success($localize`La notification a été envoyée aux utilisateurs concernés.`),
        error: (e) => {
          if (e.status == 502) {
            this.notificationService.success(
              $localize`L'envoi des notifications est en cours. Vous recevrez un mail de rapport à l'issue de celui-ci.`
            );
          } else if (e.error.length) {
            this.notificationService.error(e.error, 30000, true);
          } else {
            this.notificationService.unknownServerError();
          }
        },
      });
  }

  updateUseSendDate(useSendDate: boolean) {
    this.useSendDate = useSendDate;
    this.vigieViroseService
      .getMondayDatesWithData(this.useSendDate)
      .subscribe((dates) => (this.availableDates = dates));
  }

  openTrapStats() {
    this.dialogModule.open(UserTrapStatsComponent, {
      data: {
        appId: this.applicationId,
      },
    });
  }

  openDatumStats() {
    this.dialogModule.open(UserDatumStatsComponent, {
      data: {
        appId: this.applicationId,
      },
    });
  }
}
