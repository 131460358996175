import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServerStatusService } from '@core/services/server-status.service';
import { environment } from '@env/environment';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable()
export class BackendResponseInterceptor implements HttpInterceptor {
  constructor(private serverStatusService: ServerStatusService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.startsWith(environment.apiUrl) || req.url.indexOf('services-webapp-syngentamap') >= 0) {
      return next.handle(req).pipe(
        catchError((error: HttpErrorResponse) => {
          if (Math.floor(error.status / 100) == 5) {
            this.serverStatusService.checkServerStatus();
          }
          return throwError(() => error);
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
