import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Indicators } from '../vigie-virose-filter-observations.component';

@Component({
  selector: 'smv-vigie-virose-indicators',
  templateUrl: './vigie-virose-indicators.component.html',
  styleUrls: ['./vigie-virose-indicators.component.scss'],
})
export class VigieViroseIndicatorComponent {
  @Input() public indicators?: Indicators;

  @Output() selectionChanged = new EventEmitter<Indicators>();

  onInsectSelectionChange(code: string) {
    if (this.indicators) {
      this.indicators.insects.filter((i) => i.code !== code).forEach((insect) => (insect.selected = false));
      this.indicators.viruses.forEach((virus) => (virus.selected = false));
    }
    this.onSelectionChanged();
  }

  virusState(code: string) {
    if (this.indicators) {
      const insectSelected = this.indicators.insects.find((insect) => insect.selected);
      if (insectSelected) {
        const virusSelectable = insectSelected.virusAnalyzes.find((virus) => virus.code == code);
        if (virusSelectable) {
          return false;
        }
      }
    }
    return true;
  }

  onSelectionChanged() {
    this.selectionChanged.emit(this.indicators);
  }
}
