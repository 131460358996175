import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService, ListWrapper } from '@core/services/common.service';
import { Observable } from 'rxjs';
import { Constraint } from './model/constraint.model';
import { Recommendation } from './model/recommendation.model';
import { RevoltSubscription } from './model/revolt-subscription.model';
import { SoilType } from './model/soilt-type.model';

@Injectable({ providedIn: 'root' })
export class RevoltService extends CommonService {
  protected override readonly baseUrl: string = this.baseUrl + '/revolt';

  constructor(protected override http: HttpClient) {
    super(http);
  }

  signUp(subscription: RevoltSubscription): Observable<void> {
    return this.http.post<void>(this.baseUrl + '/signup', subscription);
  }

  resub(subscription: RevoltSubscription): Observable<void> {
    return this.http.post<void>(this.baseUrl + '/resub', subscription);
  }

  getRecommendations() {
    return this.pipeExtractList(this.http.get<ListWrapper<Recommendation>>(`${this.baseUrl}/recommendations`));
  }

  getConstraintCodes() {
    return this.pipeExtractList(this.http.get<ListWrapper<Constraint>>(`${this.baseUrl}/constraintCodes`));
  }

  getSoilTypes() {
    return this.pipeExtractList(this.http.get<ListWrapper<SoilType>>(`${this.baseUrl}/soilTypes`));
  }
}
