<smv-loading [loaders]="[pdfLoader]" />
<div [hidden]="panelHidden">
  <mat-card class="side-panel">
    <mat-card-title>
      <div>
        <img src="assets/widgets/qualih2o/QualiH2O.png" />
        <button mat-icon-button (click)="closePanel.emit()">
          <mat-icon>keyboard_double_arrow_right</mat-icon>
        </button>
      </div>
    </mat-card-title>

    <mat-card-content>
      <div class="active-filter" *ngIf="activeAacFilter$ | async">
        <div class="header-row">
          <span i18n>Filtre sur l'AAC</span>
          <button color="accent" mat-button (click)="removeAacFilter()" i18n="Button">Retirer</button>
        </div>
        <span class="filter-name">{{ activeAacFilter$ | async }}</span>
      </div>

      <!-- RPG layer filters -->
      <ng-container *ngIf="(rpgLayer$ | async)?.displayParameters?.isVisible">
        <div class="rpg-style-selector">
          <label i18n="QualiH2O">Style du RPG</label>
          <mat-radio-group [value]="activeRpgStyle" (change)="changeRpgStyle($event.value)">
            <mat-radio-button value="NONE" i18n="QualiH2O">Vierge</mat-radio-button>
            <mat-radio-button value="CROP" i18n="QualiH2O">Cultures</mat-radio-button>
            <mat-radio-button value="VULNERABILITY" i18n="QualiH2O">Vulnérabilités</mat-radio-button>
          </mat-radio-group>
        </div>

        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header i18n="QualiH2O">Filtre du RPG par culture</mat-expansion-panel-header>

          <ng-container *ngTemplateOutlet="selectionListActions; context: { $implicit: cropList }" />

          <mat-selection-list #cropList [(ngModel)]="cropSelection" (ngModelChange)="rpgFilterChanged()">
            <mat-list-option *ngFor="let option of cropOptions" togglePosition="before" [value]="option.filterValue">
              {{ option.label }}
            </mat-list-option>
          </mat-selection-list>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header i18n="QualiH2O">Filtre du RPG par vulnérabilité</mat-expansion-panel-header>

          <ng-container *ngTemplateOutlet="selectionListActions; context: { $implicit: vulnerabilityList }" />

          <mat-selection-list
            #vulnerabilityList
            [(ngModel)]="vulnerabilitySelection"
            (ngModelChange)="rpgFilterChanged()"
          >
            <mat-list-option
              *ngFor="let option of vulnerabilityOptions"
              togglePosition="before"
              [value]="option.filterValue"
            >
              {{ option.label }}
            </mat-list-option>
          </mat-selection-list>
        </mat-expansion-panel>
      </ng-container>

      <!-- AAC layer filter -->
      <mat-expansion-panel class="mat-elevation-z0" *ngIf="(aacLayer$ | async)?.displayParameters?.isVisible">
        <mat-expansion-panel-header i18n="QualiH2O">Filtre des AAC</mat-expansion-panel-header>

        <ng-container *ngTemplateOutlet="selectionListActions; context: { $implicit: aacList }" />

        <mat-selection-list #aacList [(ngModel)]="aacFilterSelection" (ngModelChange)="aacFilterChanged()">
          <mat-list-option *ngFor="let option of aacFilterOptions" togglePosition="before" [value]="option.filterValue">
            {{ option.label }}
          </mat-list-option>
        </mat-selection-list>
      </mat-expansion-panel>

      <div class="compute-area-stats">
        <button
          *ngIf="areaDrawingInteraction?.isActive; else startAreaDrawing"
          mat-flat-button
          color="primary"
          (click)="cancelAreaDrawing()"
        >
          <mat-icon>close</mat-icon>
          <ng-container i18n="QualiH2O">Annuler le calcul</ng-container>
        </button>
        <ng-template #startAreaDrawing>
          <button mat-stroked-button color="primary" (click)="drawAreaForStats()">
            <mat-icon>insert_chart</mat-icon>
            <ng-container i18n="QualiH2O">Calcul de surfaces</ng-container>
          </button>
        </ng-template>
        <smv-help-overlay i18n="QualiH2O">
          Obtenez des statistiques par type de culture et vulnérabilité sur un ensemble de parcelles
        </smv-help-overlay>
      </div>

      <div class="download-buttons">
        <button mat-stroked-button (click)="downloadKML()">
          <mat-icon>download</mat-icon>
          KML
        </button>

        <button mat-stroked-button (click)="downloadPdf()">
          <mat-icon>picture_as_pdf</mat-icon>
          PDF
        </button>

        <a mat-stroked-button href="assets/widgets/qualih2o/Lexique.pdf" target="_blank">
          <mat-icon>live_help</mat-icon>
          <ng-container i18n>Lexique</ng-container>
        </a>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #selectionListActions let-list>
  <div class="selection-list-actions">
    <button mat-button (click)="list.selectAll()" i18n="Button">Tout sélectionner</button>
    <button mat-button (click)="list.deselectAll()" i18n="Button">Vider</button>
  </div>
</ng-template>
