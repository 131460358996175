<div class="attributes-actions">
  <button mat-stroked-button (click)="addAttribute()" *ngIf="!readonly">
    <mat-icon>add</mat-icon>
    <ng-container i18n="Layer attribute form">Ajouter un attribut</ng-container>
  </button>
  <button mat-stroked-button (click)="reloadAttributes()" *ngIf="!readonly">
    <mat-icon>refresh</mat-icon>
    <ng-container i18n="Layer attribute form">Recharger les attributs</ng-container>
  </button>
  <button mat-stroked-button (click)="toggleSortAttributes(true)" *ngIf="!readonly">
    <mat-icon>swap_vert</mat-icon>
    <ng-container i18n="Layer attribute form">Réorganiser les attributs</ng-container>
  </button>
  <button mat-stroked-button (click)="toggleSortAttributes(false)" *ngIf="isSortingAttributes">
    <ng-container i18n="Layer attribute form">Terminer la réorganisation</ng-container>
  </button>
  <smv-help-overlay *ngIf="isSortingAttributes" [closeAfter]="3000" i18n="Layer attribute form|Sorting help">
    Vous pouvez réorganiser les lignes du tableau en cliquant et faisant glisser. Appuyer sur le bouton terminer pour
    pouvoir modifier les attributs.
  </smv-help-overlay>
</div>

<form *ngIf="attributeFieldGroups.controls?.length; else noAttributeText" class="attr-list">
  <mat-table
    cdkDropList
    #list="cdkDropList"
    [cdkDropListData]="attributeFieldGroups.controls"
    [cdkDropListDisabled]="!isSortingAttributes"
    (cdkDropListDropped)="onListDrop($event)"
    [dataSource]="attributeFieldGroups.controls"
  >
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef i18n="Layer attribute form|Table header">Nom</mat-header-cell>
      <mat-cell *matCellDef="let attribute">
        <smv-form-field-wrapper
          [control]="attribute.controls.name"
          [readonly]="readonly"
          subscriptSizing="dynamic"
          required
          hideLabel
        />
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="label">
      <mat-header-cell *matHeaderCellDef>
        <span i18n="Layer attribute form|Table header">Label</span>
        <smv-help-overlay i18n="Layer attribute form|Table header help">
          Label affiché dans la fiche d'information. Si le label n'est pas rempli, le nom de l'attribut sera utilisé
        </smv-help-overlay>
      </mat-header-cell>
      <mat-cell *matCellDef="let attribute">
        <smv-form-field-wrapper
          [control]="attribute.controls.label"
          [readonly]="readonly"
          subscriptSizing="dynamic"
          hideLabel
          showEmptyWhenDisabled
        />
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef i18n="Layer attribute form|Table header">Type</mat-header-cell>
      <mat-cell *matCellDef="let attribute">
        <smv-form-field-wrapper
          [readonly]="readonly"
          [control]="attribute.controls.type"
          [selectOptions]="attributeTypeOptions"
          subscriptSizing="dynamic"
          controlType="select"
          required
          hideLabel
        />
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="visible">
      <mat-header-cell *matHeaderCellDef>
        <span i18n="Layer attribute form|Table header">Affiché</span>
        <smv-help-overlay i18n="Layer attribute form|Table header help">
          Détermine si l'attribut doit être affiché dans la fiche d'information des objets
        </smv-help-overlay>
      </mat-header-cell>
      <mat-cell *matCellDef="let attribute">
        <mat-checkbox [formControl]="attribute.controls.display" />
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="editable">
      <mat-header-cell *matHeaderCellDef i18n="Layer attribute form|Table header">Éditable</mat-header-cell>
      <mat-cell *matCellDef="let attribute">
        <mat-checkbox [formControl]="attribute.controls.editable" />
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef />
      <mat-cell *matCellDef="let attribute; let index = index">
        <button
          mat-icon-button
          (click)="editAttrDefault(attribute)"
          color="primary"
          type="button"
          matTooltip="Paramétrer l'édition"
          i18n-matTooltip="Layer attribute form"
        >
          <mat-icon>edit_note</mat-icon>
        </button>
        <button
          mat-icon-button
          color="warn"
          type="button"
          (click)="removeAttribute(index)"
          matTooltip="Supprimer l'attribut"
          i18n-matTooltip="Layer attribute form"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns" />
    <mat-row *matRowDef="let row; columns: columns" cdkDrag />
  </mat-table>
</form>

<ng-template #noAttributeText>
  <p i18n="Layer attribute form">Aucun attribut défini pour la couche</p>
</ng-template>
