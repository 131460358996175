import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { GeometryType } from '@core/model/application-api/layer.model';
import { LayerConfig } from '@core/model/layer-config.model';
import { StyleRule } from '@core/model/styles/style-rule.model';
import { LineString, Point, Polygon } from 'ol/geom';
import { toContext } from 'ol/render';

@Component({
  selector: 'smv-vector-legend',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './vector-legend.component.html',
  styleUrls: ['./vector-legend.component.scss'],
})
export class VectorLegendComponent implements AfterViewInit {
  @ViewChild('chartLegend') chartLegend?: ElementRef;

  @Input() layerConfig!: LayerConfig;
  @Input() categorie?: StyleRule;

  ngAfterViewInit(): void {
    this.drawLegend();
  }

  private drawLegend() {
    if (this.chartLegend) {
      const ctx: CanvasRenderingContext2D = this.chartLegend.nativeElement.getContext('2d');
      const vectorContext = toContext(ctx, { size: [100, 30] });

      if (this.categorie) {
        vectorContext.setStyle(this.categorie.style.getOlStyle());
      } else if (this.layerConfig.customStyle) {
        vectorContext.setStyle(this.layerConfig.customStyle.getOlStyle());
      }

      if (this.layerConfig.geometryType == GeometryType.POLYGON) {
        vectorContext.drawGeometry(
          new Polygon([
            [
              [2, 2],
              [98, 2],
              [98, 28],
              [2, 28],
              [2, 2],
            ],
          ])
        );
      } else if (this.layerConfig.geometryType == GeometryType.LINE) {
        vectorContext.drawGeometry(
          new LineString([
            [2, 15],
            [98, 15],
          ])
        );
      } else if (this.layerConfig.geometryType == GeometryType.POINT) {
        vectorContext.drawGeometry(new Point([50, 15]));
      } else {
        vectorContext.drawGeometry(
          new Polygon([
            [
              [2, 2],
              [98, 2],
              [98, 28],
              [2, 28],
              [2, 2],
            ],
          ])
        );
      }
    }
  }

  getLabel(rule: StyleRule) {
    return rule.label ?? rule.cqlValue;
  }
}
