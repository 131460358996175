<div>
  <div class="overlay"></div>
  <mat-card *ngIf="!loading" class="smv-sheet">
    <mat-card-title>
      <div class="header">
        <div>
          <span *ngIf="widgetLabel">{{ widgetLabel }} - </span>
          <span i18n="Sheet Statistic">Statistiques</span>
        </div>
        <button mat-icon-button (click)="ngOnDestroy()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content class="content">
      <ng-container *ngIf="!isDataLoaded">
        <div class="noStatisticsData" i18n="No data">Aucune donnée à afficher.</div>
      </ng-container>
      <ng-container *ngIf="isDataLoaded">
        <smv-panel *ngIf="isMulti" [title]="titleMulti">
          <ng-content select="[statisticsMulti]" />
        </smv-panel>
        <ng-container *ngIf="!isMulti">
          <mat-accordion>
            <ng-content select="[statisticsTrap]" />
          </mat-accordion>
        </ng-container>
      </ng-container>
    </mat-card-content>
  </mat-card>
</div>
