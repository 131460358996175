export const qualiH2OLegalNotices = {
  main: $localize`Quali’H2O Territoire est un outil pour les professionnels agricoles. Il constitue une aide à la prise de décision. Ce rapport est une extraction partielle issue de la plateforme digitale « Quali’H2O Territoire », outil d’aide à la décision mis à disposition des professionnels de l’agriculture, distributeurs et partenaires. Les données sources sont compilées dans un tableau disponible via la plateforme et sur demande auprès de votre interlocuteur Syngenta. Les contenus de Quali’H2O Territoire sont publiés à titre d’information, à l’exclusion de toute garantie sur leur exactitude, leur exhaustivité ou leur adéquation aux besoins spécifiques de l’ utilisateur. Il appartient à l’utilisateur de vérifier par lui-même les données qui pourraient avoir une incidence sur sa décision.  Les références à des produits, matières actives, spécialités commerciales phytosanitaires qui pourraient être cités du fait de l’utilisation de Quali’H2O Territoire par un professionnel ne constitue pas une recommandation de Syngenta France. Syngenta France décline toute responsabilité quant aux préconisations qui pourraient en découler. Syngenta France SAS, 12 Chemin de l’Hobit 31790 SAINT SAUVEUR- N° d’agrément MP02249 ©Marque déposée du groupe Syngenta.`,
  emphasis: $localize`Avant utilisation, assurez-vous que celle-ci est indispensable. Privilégiez chaque fois que possible les méthodes alternatives et les produits présentant le risque le plus faible pour la santé humaine et animale et pour l’environnement, conformément aux principes de protection intégrée, consultez http://agriculture.gouv.fr/ecophyto`,
};

export const icareLegalNotices = {
  emphasis: $localize`Syngenta France SAS - 1228, Chemin de l’Hobit 31790 Saint-Sauveur France. SAS au capital de 111 447 427 Euros. RCS – RSAC Toulouse 443 716 832. Numéro de TVA intra-communautaire : FR 11 443 716 832. N° d’agrément MP02249 : distribution et application de produits phytopharmaceutiques.
  
  Avant toute utilisation, assurez-vous que celle‑ci est indispensable. Privilégiez chaque fois que possible les méthodes alternatives et les produits présentant le risque le plus faible pour la santé humaine et animale et pour l'environnement, conformément aux principes de la protection intégrée, consultez agriculture.gouv.fr/ecophyto. Pour les conditions d'emploi et les usages, doses et conditions préconisées : se référer à l'étiquette du produit ou www.syngenta.fr
  `,
  intenseEmphasis: $localize`PRODUITS POUR LES PROFESSIONNELS: UTILISEZ LES PRODUITS PHYTOPHARMACEUTIQUES AVEC PRÉCAUTION.\n AVANT TOUTE UTILISATION, LISEZ L'ÉTIQUETTE ET LES INFORMATIONS CONCERNANT LE PRODUIT.`,
};
