import { ParsedCapabilities, ParsedLayer, ParsedStyle } from '@core/model/capabilities.model';
import { LayerAttributions } from '@core/model/layer-config.model';
import WMSCapabilities from 'ol/format/WMSCapabilities';

export interface WmsLayerSchema {
  Title: string;
  Name: string;
  Abstract?: string;
  EX_GeographicBoundingBox: [number, number, number, number];
  Attribution?: { OnlineResource: string; Title: string };
  CRS: string[];
  MaxScaleDenominator?: number;
  MinScaleDenominator?: number;
  Style?: { Name: string; Title: string; LegendURL: { Format: string; OnlineResource: string }[] }[];
}

export class WMSCapabilitiesParser {
  private parser = new WMSCapabilities();

  read(xml: string): ParsedCapabilities {
    return this.parser.read(xml);
  }

  parseLayer(layer: WmsLayerSchema): ParsedLayer {
    const styles: ParsedStyle[] | undefined = layer.Style?.map((style) => ({
      name: style.Name,
      title: style.Title,
      legendUrl: style.LegendURL[0].OnlineResource,
    }));
    const attributions: LayerAttributions | undefined = layer.Attribution
      ? { title: layer.Attribution.Title, link: layer.Attribution.OnlineResource }
      : undefined;
    return {
      attributions,
      bbox: layer.EX_GeographicBoundingBox,
      detail: layer.Abstract,
      maxScaleDenominator: layer.MaxScaleDenominator,
      minScaleDenominator: layer.MinScaleDenominator,
      serverIdentifier: layer.Name,
      styles,
      title: layer.Title,
    };
  }
}
