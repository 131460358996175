import html2image from 'html2canvas';
import { jsPDF } from 'jspdf';
import {
  BuilderConfiguration,
  CanvasElementConfig,
  DrawnElement,
  FontStyle,
  ImageElementConfig,
  LoadedImage,
  PdfElement,
  TextElementConfig,
} from './pdf-builder-types';
import { fitElementToSize, getExternalImage, getRatio, pxToMm } from './pdf-builder.utils';

const defaultConfiguration: BuilderConfiguration = {
  orientation: 'landscape',
  font: 'helvetica',
  fontSize: 12,
  pageWidth: 297,
  pageHeight: 210,
  widthMargin: 15,
  heightMargin: 15,
};

const PAGE_FORMAT = 'a4';

class JsPdfWrapper {
  public readonly pdf: jsPDF;
  public readonly config: Readonly<BuilderConfiguration>;

  constructor(configuration?: Partial<BuilderConfiguration>) {
    const config: BuilderConfiguration = Object.assign({}, defaultConfiguration, configuration);
    this.config = Object.freeze(config);

    this.pdf = new jsPDF({
      orientation: this.config.orientation,
      format: PAGE_FORMAT,
    });
    this.pdf.setFont(this.config.font);
    this.pdf.setFontSize(this.config.fontSize);
  }

  resetPdfState(): void {
    this.pdf.setFontSize(this.config.fontSize);
    this.pdf.setFont(this.config.font, 'normal');
    this.pdf.setTextColor('#000000');
  }

  /**
   * Adds a text with (optional) styling options.
   *
   * @param text Text to write
   * @param config Position and optional styling
   */
  addText(text: string | string[], config: TextElementConfig): void {
    const currentFontSize = this.pdf.getFontSize();
    const currentFont = this.pdf.getFont();
    const currentColor = this.pdf.getTextColor();

    if (config.fontSize) {
      this.pdf.setFontSize(config.fontSize);
    }

    if (config.fontStyle) {
      this.setFontStyle(config.fontStyle);
    }

    if (config.color) {
      this.pdf.setTextColor(config.color);
    }

    this.pdf.text(text, config.x, config.y, { align: config.align });

    // Reset pdf state
    this.pdf.setFontSize(currentFontSize);
    this.pdf.setFont(currentFont.fontName, currentFont.fontStyle);
    this.pdf.setTextColor(currentColor);
  }

  /**
   * Adds a long text that needs to be splitted into multiple lines.
   *
   * @param text Text to write
   * @param config Text options
   * @param maxWidth Max width of the line in mm
   */
  addWrappedText(text: string, config: TextElementConfig, maxWidth: number): void {
    const textLines = this.pdf.splitTextToSize(text, maxWidth);
    this.addText(textLines, config);
  }

  /**
   * Changes the font style without changing the font family.
   *
   * @param style Font style to apply
   */
  setFontStyle(style: FontStyle): void {
    const currentFont = this.pdf.getFont().fontName;
    this.pdf.setFont(currentFont, style);
  }

  /**
   * Adds a page with the same format and orientation.
   */
  addPage(): void {
    this.pdf.addPage(PAGE_FORMAT, this.config.orientation);
  }
}

export class PdfBuilder extends JsPdfWrapper {
  /**
   * Captures and adds an image from the HTML.
   *
   * @param cssSelector DOM class or id selector to retrieve the element
   * @param posX x position from the left edge
   * @param posY y position from the upper edge
   * @param ratio Ratio applied to compute the height and width
   */
  async addImageFromHtml(cssSelector: string, config: CanvasElementConfig): Promise<DrawnElement> {
    let scale = { width: 0, height: 0 };
    try {
      const canvas = await this.getCanvasFromHtml(cssSelector, config.renderScale);
      scale = this.addImageFromCanvas(canvas, config);
    } catch (error) {
      console.warn('Unable to find DOM element with class or id ' + cssSelector);
    }

    return scale;
  }

  // Fireable simultaneously by processes
  async getCanvasFromHtml(cssSelector: string, renderScale = 1): Promise<HTMLCanvasElement> {
    const selector = cssSelector.substring(1);
    let domElement: HTMLElement | null = null;

    if (cssSelector.startsWith('.')) {
      const matchedElements = document.getElementsByClassName(selector);
      if (matchedElements.length && matchedElements[0] instanceof HTMLElement) {
        domElement = matchedElements[0];
      }
    } else {
      domElement = document.getElementById(selector);
    }
    return new Promise((resolve, reject) => {
      if (domElement) {
        html2image(domElement, { allowTaint: true, useCORS: false, scale: renderScale ?? 1 }).then((resultCanvas) => {
          resolve(resultCanvas);
        });
      } else {
        reject('Unable to find DOM element with class or id ' + cssSelector);
      }
    });
  }

  addImageFromCanvas(canvas: HTMLCanvasElement, config: CanvasElementConfig): DrawnElement {
    let imageHeight = 0;
    let imageWidth = 0;
    const ratio = config.size
      ? getRatio(canvas, config.size.availableWidth, config.size.availableHeight)
      : config.ratio ?? 1;
    imageWidth = canvas.width / ratio;
    imageHeight = canvas.height / ratio;
    this.pdf.addImage(canvas.toDataURL('image/jpeg,1.0'), 'JPEG', config.x, config.y, imageWidth, imageHeight);
    return { width: imageWidth, height: imageHeight };
  }

  async addExternalImage(url: string, config: ImageElementConfig): Promise<void> {
    const image = await this.loadExternalImage(url, { width: config.maxWidth, height: config.maxHeight });
    this.pdf.addImage(image.data, config.type ?? 'JPEG', config.x, config.y, image.width, image.height);
  }

  async loadExternalImage(url: string, fitInto?: PdfElement): Promise<LoadedImage> {
    const image = await getExternalImage(url);
    let width = pxToMm(image.width);
    let height = pxToMm(image.height);
    if (fitInto) {
      const fittedImage = fitElementToSize({ width, height }, fitInto.width, fitInto.height);
      width = fittedImage.width;
      height = fittedImage.height;
    }
    return { data: image.data, width, height };
  }

  /**
   * Gets the content's max width, taking into account the margins.
   *
   * @returns Size in mm
   */
  getMaxContentWidth(): number {
    return this.config.pageWidth - 2 * this.config.widthMargin;
  }

  /**
   * Computes the y position of the element starting from the bottom of the page.
   *
   * @param height Height from the bottom
   * @param includeMargin Include or ignore the bottom margin
   * @returns y position against the upper edge
   */
  getYFromBottom(height: number, includeMargin = false): number {
    const margin = includeMargin ? this.config.heightMargin : 0;
    return this.config.pageHeight - height - margin;
  }

  /**
   * Computes the y position of the element starting from the top of the page.
   *
   * @param height Height from the top
   * @param includeMargin Include or ignore the top margin
   * @returns y position against the upper edge
   */
  getYFromTop(height: number, includeMargin = false): number {
    const margin = includeMargin ? this.config.heightMargin : 0;
    return height + margin;
  }

  /**
   * Computes the x position of the element starting from the right of the page.
   *
   * @param width Width from the right
   * @param includeMargin Include or ignore the right margin
   * @returns x position against the left edge
   */
  getXFromRight(width: number, includeMargin = false): number {
    const margin = includeMargin ? this.config.widthMargin : 0;
    return this.config.pageWidth - width - margin;
  }

  /**
   * Computes the x position of the element starting from the left of the page.
   *
   * @param width Width from the left
   * @param includeMargin Include or ignore the left margin
   * @returns x position against the left edge
   */
  getXFromLeft(width: number, includeMargin = false): number {
    const margin = includeMargin ? this.config.widthMargin : 0;
    return width + margin;
  }
}
