import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

export interface Loader {
  condition: boolean;
  text: string;
}

@Component({
  selector: 'smv-loading',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnChanges {
  @Input() loaders: Loader[] = [];
  @Input() overlay = true;
  @Input() spinnerDiameter = 100;

  public loading: Loader[] = [];

  ngOnChanges() {
    this.loading = this.loaders;
  }

  anyLoading() {
    return this.loading.find((loader) => loader.condition);
  }
}
