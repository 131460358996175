import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Indexable } from '@core/utils/general.utils';
import { isNil, omit } from 'lodash-es';

/**
 * Check that all extent coordinates are filled with consistent values.
 *
 * @param form The FormGroup object
 * @returns Validation errors with "incompleteExtent" or "invalidCoordinates" keys
 */
export const extentValidator = (form: AbstractControl): ValidationErrors | null => {
  if (!form.value) {
    return null;
  }
  const { extentMinX, extentMinY, extentMaxX, extentMaxY } = form.value;
  const extent = [extentMinX, extentMinY, extentMaxX, extentMaxY];

  let errors: Indexable<boolean> | null = {};
  const formGroup = form as FormGroup;
  removeErrors(
    formGroup,
    ['extentMinX', 'extentMinY', 'extentMaxX', 'extentMaxY'],
    ['incompleteExtent', 'invalidCoordinates']
  );

  if (extent.some((value) => !isNil(value))) {
    if (extent.some((value) => isNil(value))) {
      errors['incompleteExtent'] = true;
      addError(formGroup, ['extentMinX', 'extentMinY', 'extentMaxX', 'extentMaxY'], 'incompleteExtent');
    }

    if (extentMinX >= extentMaxX) {
      errors['invalidCoordinates'] = true;
      addError(formGroup, ['extentMinX', 'extentMaxX'], 'invalidCoordinates');
    }

    if (extentMinY >= extentMaxY) {
      errors['invalidCoordinates'] = true;
      addError(formGroup, ['extentMinY', 'extentMaxY'], 'invalidCoordinates');
    }
  }

  if (Object.keys(errors).length === 0) {
    errors = null;
  }

  return errors;
};

/**
 * Check that min and max scales are consistent with each other.
 *
 * @param form The FormGroup object
 * @returns Validation errors with "invalidScales" key
 */
export const scaleValidator = (form: AbstractControl): ValidationErrors | null => {
  if (!form.value) {
    return null;
  }
  const { minScaleDenominator, maxScaleDenominator } = form.value;

  let hasError = false;
  const formGroup = form as FormGroup;
  removeErrors(formGroup, ['minScaleDenominator', 'maxScaleDenominator'], ['invalidScales']);

  if (!isNil(minScaleDenominator) && !isNil(maxScaleDenominator) && maxScaleDenominator < minScaleDenominator) {
    hasError = true;
    addError(formGroup, ['minScaleDenominator', 'maxScaleDenominator'], 'invalidScales');
  }

  return hasError ? { invalidScales: true } : null;
};

function addError(formGroup: FormGroup, controls: string[], error: string): void {
  controls.forEach((name) => {
    const controlErrors = formGroup.controls[name].errors ?? {};
    controlErrors[error] = true;
    formGroup.controls[name].setErrors(controlErrors);
  });
}

function removeErrors(formGroup: FormGroup, controls: string[], errors: string[]): void {
  controls.forEach((name) => {
    let controlErrors = formGroup.controls[name].errors ?? {};
    controlErrors = omit(controlErrors, errors);
    formGroup.controls[name].setErrors(Object.keys(controlErrors).length ? controlErrors : null);
  });
}
