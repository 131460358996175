<mat-form-field [appearance]="appearance" class="vv-customer-filter" subscriptSizing="dynamic">
  <mat-label>{{ label }}</mat-label>
  <mat-chip-grid #chipGrid>
    <mat-chip-row *ngFor="let element of selected" (removed)="remove(element)">
      {{ displayChip(element) }}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
  </mat-chip-grid>
  <input
    #inputIdForReset
    matInput
    type="text"
    [placeholder]="placeholder"
    [formControl]="searchControl"
    [matChipInputFor]="chipGrid"
    [matAutocomplete]="auto"
  />
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event); inputIdForReset.value = ''">
    <mat-option *ngFor="let element of filtered | async" [value]="element">
      {{ displayRow(element) }} <em>{{ displayRowEm(element) }}</em>
    </mat-option>
  </mat-autocomplete>

  <div matSuffix>
    <ng-content slot="[suffix]" />
  </div>
</mat-form-field>
