import { CommonModule } from '@angular/common';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { DialogService } from '@components/dialog/dialog.service';
import { Application } from '@core/model/application.model';
import { ApplicationApiService, SpecificationState } from '@core/services/api/application-api.service';
import { HeaderImage, NavigationService } from '@core/services/navigation.service';
import { NotificationService } from '@core/services/notification.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'smv-header',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, RouterModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() isLoggedIn = false;
  @Output() toggleMenu = new EventEmitter<void>();

  public headerTitle$?: Observable<string | undefined>;
  public headerImage$?: Observable<HeaderImage | undefined>;

  public baseUrl?: string;
  public settingsPanelState?: boolean;
  public application?: Application;
  public specificationConfig?: SpecificationState;

  constructor(
    private navigation: NavigationService,
    private applicationService: ApplicationApiService,
    private dialogService: DialogService,
    private notificationService: NotificationService
  ) {
    this.baseUrl = this.applicationService.getBaseUrl();
    this.applicationService.settingsPanelState.getStream().subscribe((state) => (this.settingsPanelState = state));

    this.applicationService.currentApplication.getStream().subscribe((app) => (this.application = app));

    this.applicationService.isSpecification.getStream().subscribe((config) => (this.specificationConfig = config));
  }

  ngOnInit(): void {
    this.headerTitle$ = this.navigation.headerTitle.asObservable();
    this.headerImage$ = this.navigation.headerImage.asObservable();
  }

  toggleSettingsPanel() {
    this.settingsPanelState = !this.settingsPanelState;
    this.applicationService.settingsPanelState.setValue(this.settingsPanelState);
  }

  getHelpPdf() {
    if (this.application) {
      this.applicationService.getHelpPdf(this.application.id).subscribe({
        next: (blob) => {
          const fileURL = URL.createObjectURL(blob);
          window.open(fileURL, 'u_blank');
        },
        error: (error: HttpErrorResponse) => {
          if (error.status === HttpStatusCode.PreconditionFailed) {
            this.notificationService.error(
              $localize`:Notification|Error:Le pdf n'est pas accessible pour cette application. Si ceci vous semble être une erreur, veuillez contacter un administrateur.`
            );
          }
          this.notificationService.unknownServerError();
        },
      });
    }
  }

  openIframeDialog() {
    if (this.application?.tutorialUrl) {
      this.dialogService.openIframe({
        title: $localize`:Application Tuto|Title:Tutoriel de l'application`,
        contentUrl: this.application.tutorialUrl,
      });
    }
  }

  changeSpecificationEntity() {
    this.applicationService.isSpecification.setParticularValue('opened', true);
  }
}
