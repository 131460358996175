<form [formGroup]="gripForm" class="smv-form">
  <smv-form-field-wrapper type="number" [step]="0.00001" [control]="gripForm.controls.longitude">
    <span mat-label i18n="Map parameter|Longitude">Longitude</span>
  </smv-form-field-wrapper>
  <smv-form-field-wrapper type="number" [step]="0.00001" [control]="gripForm.controls.latitude">
    <span mat-label i18n="Map parameter|Latitude">Latitude</span>
  </smv-form-field-wrapper>
  <smv-form-field-wrapper type="number" [step]="0.1" [control]="gripForm.controls.zoom">
    <span mat-label i18n="Map parameter|Zoom">zoom</span>
  </smv-form-field-wrapper>
</form>

<div class="smv-sticky-bottom-actions">
  <button
    mat-button
    (click)="applyMapBounds()"
    [ngClass]="{ btnSpinner: saving, loaded: saving }"
    [disabled]="gripForm.invalid"
    matTooltip="Enregistrer l'emprise initiale et appliquer cette emprise à la carte"
    matTooltipPosition="below"
    i18n-matTooltip
  >
    <mat-icon>arrow_right_alt</mat-icon><span i18n="Grip panel|apply">Appliquer</span>
  </button>
  <button
    mat-button
    (click)="captureCurrentMapBounds()"
    matTooltip="Récupérer l'emprise à partir de la carte"
    matTooltipPosition="below"
    i18n-matTooltip
  >
    <mat-icon>adjust</mat-icon><span i18n="Grip panel|grip">Emprise</span>
  </button>
</div>
