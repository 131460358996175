import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface HeaderImage {
  id: number;
  name: string;
  baseUrl: string;
}

@Injectable({ providedIn: 'root' })
export class NavigationService {
  public readonly headerTitle = new BehaviorSubject<string | undefined>(undefined);
  public readonly headerImage = new BehaviorSubject<HeaderImage | undefined>(undefined);
}
