import { isNil } from 'lodash-es';

export class GeneralUtils {
  static isNotNull<T>(value: T): value is NonNullable<T> {
    return !isNil(value);
  }

  static isString<T>(value: string | T): value is string {
    return typeof value === 'string';
  }

  static isArrayOfPrimitive<T>(array: T[] | object[]): array is T[] {
    return typeof array?.[0] !== 'object';
  }

  static valueToRegex(value: string, wildCard?: string, singleChar?: string, escapeChar?: string): string {
    if (wildCard == '.') {
      throw new Error("'.' is an unsupported wildCard character for " + 'OpenLayers.Filter.Comparison');
    }

    wildCard = wildCard ?? '*';
    singleChar = singleChar ?? '.';
    escapeChar = escapeChar ?? '!';

    value = value.replace(new RegExp('\\' + escapeChar + '(.|$)', 'g'), '\\$1');
    value = value.replace(new RegExp('\\' + singleChar, 'g'), '.');
    value = value.replace(new RegExp('\\' + wildCard, 'g'), '.*');
    value = value.replace(new RegExp('\\\\.\\*', 'g'), '\\' + wildCard);
    value = value.replace(new RegExp('\\\\\\.', 'g'), '\\' + singleChar);

    return value;
  }

  static getDistinct<T, K extends keyof T>(data: T[], property: K): T[K][] {
    const allValues = data.reduce((values: T[K][], current) => {
      if (current[property]) {
        values.push(current[property]);
      }
      return values;
    }, []);

    return [...new Set(allValues)];
  }

  static getUniqueId(parts: number): string {
    const stringArr = [];
    for (let i = 0; i < parts; i++) {
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }

  static getUniqueNumberId(length: number): string {
    let id;
    do {
      id = (Math.random() * 10 ** length).toFixed(0);
    } while (id.length != length);
    return id;
  }
}

export type KeysOfType<T, V> = keyof {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [P in keyof T as T[P] extends V ? P : never]: any;
};

export type Indexable<T> = { [key: string | number]: T };
