<div class="header-container" *ngIf="isLoggedIn; else anonymous">
  <div class="side-menu-toggle">
    <button mat-icon-button (click)="toggleMenu.emit()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div class="app-title">
    <img
      *ngIf="headerImage$ | async as image"
      src="{{ image.baseUrl }}/{{ image.id }}/logo"
      [alt]="image.name"
      height="40px"
    />
    <span *ngIf="headerTitle$ | async as title">{{ title }}</span>
  </div>
  <div *ngIf="specificationConfig && specificationConfig.config">
    <button mat-stroked-button (click)="changeSpecificationEntity()" [disabled]="specificationConfig.opened">
      {{ specificationConfig.config.labelButtonHeader }}
    </button>
  </div>
  <div *ngIf="application?.displayedHelpPdf">
    <button mat-icon-button (click)="getHelpPdf()"><mat-icon>help_center</mat-icon></button>
  </div>
  <div *ngIf="application?.displayedTutorial">
    <button mat-icon-button (click)="openIframeDialog()">
      <mat-icon>play_arrow</mat-icon>
    </button>
  </div>
  <div *ngIf="settingsPanelState !== undefined">
    <button mat-icon-button (click)="toggleSettingsPanel()"><mat-icon>settings</mat-icon></button>
  </div>
</div>

<ng-template #anonymous>
  <div class="header-container mobile-stacked">
    <div class="app-title">MAPVIEW™</div>
    <div class="actions">
      <button mat-button color="primary" routerLink="/inscription" i18n="Header">S'inscrire</button>
      <button mat-button color="primary" routerLink="/connexion" i18n="Header">Se connecter</button>
    </div>
  </div>
</ng-template>
