import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MapService } from '@core/services/map.service';
import { StatisticConfig } from '@widgets/components/statistic/statistic.model';
import { StatisticService } from '@widgets/components/statistic/statistic.service';
import { Subscription } from 'rxjs';
import { IcareAllTrapsConfig, IcareLayerConfig } from './icare-config';
import { IcareService } from './icare.service';
import { IcareFilterConfig } from './model/icare.model';

@Component({
  selector: 'smv-icare',
  templateUrl: './icare.component.html',
  styleUrls: ['./icare.component.scss', '../../client-app-panel.scss'],
})
export class IcareComponent implements OnDestroy {
  public isSheetDisplayed = false;

  public allTrapLayerCode = new IcareAllTrapsConfig().getLayerCode();

  private layerConfig = new IcareLayerConfig();
  private subscriptions = new Subscription();

  @Input() applicationId!: number;
  @Input() canImport = false;
  @Input() panelHide = true;
  @Output() displayed = new EventEmitter();

  constructor(
    private icareService: IcareService,
    private statisticService: StatisticService,
    private mapService: MapService
  ) {
    this.subscriptions.add(
      this.statisticService.statisticState
        .getStream()
        .subscribe((config: StatisticConfig) => (this.isSheetDisplayed = config.display))
    );
    this.subscriptions.add(
      this.icareService.filterState.getStream().subscribe((filter) => this.updateLayerDisplayAndRender(filter))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updateLayerDisplayAndRender(filter: IcareFilterConfig) {
    const layersCodeConfig = this.layerConfig.retrieveLayerCodeConfig(filter);
    for (const layerCode of Object.keys(layersCodeConfig)) {
      const layer = this.mapService.getLayerByCode(layerCode);
      if (layer) {
        layer.setLayerVisibility(layersCodeConfig[layerCode]);
      }
    }
  }
}
