import { Component, OnDestroy } from '@angular/core';
import { ApplicationApiService } from '@core/services/api/application-api.service';
import { TrapFilter, WidgetTrap } from '@widgets/components/statistic/statistic.model';
import { StatisticService } from '@widgets/components/statistic/statistic.service';
import { InsectEnum } from '@widgets/vigie-virose/model/insect.model';
import { GeoServerTrap, TrapStatement, TrapStatementSimple } from '@widgets/vigie-virose/model/trap.model';
import { VigieViroseService } from '@widgets/vigie-virose/services/vigie-virose.service';

import moment from 'moment';

interface TrapStatements {
  [index: string]: TrapStatement[];
}

interface TrapStatementSimples {
  [index: string]: TrapStatementSimple[][];
}

@Component({
  selector: 'smv-vigie-virose-sheet',
  templateUrl: './vigie-virose-sheet.component.html',
  styleUrls: ['./vigie-virose-sheet.component.scss'],
})
export class VigieViroseSheetComponent implements OnDestroy {
  public InsectEnum = InsectEnum;
  public loading = true;
  public isDataLoaded = false;

  public MULTI = 'multi';
  public titleMulti = $localize`Etat virulifère de la zone géographique`;

  public traps: TrapStatements = {};
  public trapList: GeoServerTrap[] = [];

  public trapsCereals: TrapStatementSimples = {};
  public trapsColza: TrapStatementSimples = {};

  private date?: string;

  constructor(
    private vigieViroseService: VigieViroseService,
    private statisticService: StatisticService,
    private applicationService: ApplicationApiService
  ) {
    const app = this.applicationService.currentApplication.getValue();
    if (app?.functionnalityConfig.campaignStart) {
      this.date = moment(app.functionnalityConfig.campaignStart, 'DD/MM').format('YYYY-MM-DD');
    }

    const statisticConfig = this.statisticService.statisticState.getValue();
    if (statisticConfig.filter) {
      this.getAreaData(statisticConfig.filter);
    } else if (statisticConfig.traps && this.isVigieViroseTraps(statisticConfig.traps)) {
      this.getTrapsData(statisticConfig.traps);
    } else {
      this.loading = false;
    }
  }

  ngOnDestroy() {
    this.statisticService.statisticState.reset();
  }

  private isVigieViroseTraps(traps: WidgetTrap[]): traps is GeoServerTrap[] {
    return 'trap_cultivation_code' in traps[0];
  }

  getTrapsData(traps: GeoServerTrap[]) {
    this.trapList = traps;
    const query = {} as TrapFilter;
    if (this.date) {
      query.startDate = this.date;
    }

    for (const trap of this.trapList) {
      this.vigieViroseService.getTraps(trap.trap_code, query).subscribe({
        next: (trapStatements: TrapStatement[]) => {
          if (trapStatements.length != 0) {
            this.traps[trap.trap_code] = trapStatements;
            if (['BLE', 'ORG'].includes(trapStatements[0].trap.cultivationCode)) {
              this.populateCerealDisease(trap.trap_code, trapStatements);
            } else if (['COL'].includes(trapStatements[0].trap.cultivationCode)) {
              this.populateColzaDisease(trap.trap_code, trapStatements);
            }
            this.isDataLoaded = true;
          }
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
    }
  }

  getAreaData(filter: TrapFilter) {
    if (this.date) {
      filter.startDate = this.date;
    }

    this.vigieViroseService.getMultiTrapByZone(filter).subscribe({
      next: (traps: TrapStatement[]) => {
        if (traps.length == 0) {
          this.loading = false;
        } else {
          this.populateCerealDisease(this.MULTI, traps);
          this.loading = false;
          this.isDataLoaded = true;
        }
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  populateCerealDisease(trapCode: string, statements: TrapStatement[]) {
    const trapsAphid: TrapStatementSimple[] = [];
    const trapsLeafhopper: TrapStatementSimple[] = [];

    statements.forEach((statement) => {
      trapsAphid.push({
        mondayDate: statement.mondayDate,
        diseaseState: statement.diseaseStateForAphid,
      });
      trapsLeafhopper.push({
        mondayDate: statement.mondayDate,
        diseaseState: statement.diseaseStateForLeafhopper,
      });
    });
    this.trapsCereals[trapCode] = [trapsAphid, trapsLeafhopper];
  }

  populateColzaDisease(trapCode: string, statements: TrapStatement[]) {
    const trapsAphid: TrapStatementSimple[] = [];
    const trapsLeafhopper: TrapStatementSimple[] = [];
    const trapsFleabeetle: TrapStatementSimple[] = [];
    const trapsSawflyle: TrapStatementSimple[] = [];

    statements.forEach((statement) => {
      trapsAphid.push({
        mondayDate: statement.mondayDate,
        diseaseState: statement.diseaseStateForAphidRapeseed,
      });
      trapsLeafhopper.push({
        mondayDate: statement.mondayDate,
        diseaseState: statement.diseaseStateForLeafhopperRapeseed,
      });
      trapsFleabeetle.push({
        mondayDate: statement.mondayDate,
        diseaseState: statement.diseaseStateForFleabeetleRapeseed,
      });
      trapsSawflyle.push({
        mondayDate: statement.mondayDate,
        diseaseState: statement.diseaseStateForSawflyleRapeseed,
      });
    });
    this.trapsColza[trapCode] = [trapsAphid, trapsLeafhopper, trapsFleabeetle, trapsSawflyle];
  }
}
