import { XmlTag } from './xml.utils';

export type ComparaisonTag =
  | 'PropertyIsLike'
  | 'PropertyIsEqualTo'
  | 'PropertyIsLessThan'
  | 'PropertyIsGreaterThan'
  | 'PropertyIsLessThanOrEqualTo'
  | 'PropertyIsGreaterThanOrEqualTo';

export type LogicalTag = 'And' | 'Or';

export const inBetweenTag = (property: string, min: number, max: number): XmlTag => {
  return {
    name: 'PropertyIsBetween',
    content: [
      {
        name: 'PropertyName',
        content: property,
      },
      {
        name: 'LowerBoundary',
        content: [
          {
            name: 'Literal',
            content: min,
          },
        ],
      },
      {
        name: 'UpperBoundary',
        content: [
          {
            name: 'Literal',
            content: max,
          },
        ],
      },
    ],
  };
};

export const comparaisonTag = (type: ComparaisonTag, property: string, value: string | number): XmlTag => {
  return {
    name: type,
    attributes: type == 'PropertyIsLike' ? { wildCard: '*', singleChar: '.', escapeChar: '!' } : {},
    content: [
      {
        name: 'PropertyName',
        content: property,
      },
      {
        name: 'Literal',
        content: value,
      },
    ],
  };
};

export const nullTag = (property: string): XmlTag => {
  return { name: 'PropertyIsNull', content: [{ name: 'PropertyName', content: property }] };
};

export const notTag = (element: XmlTag): XmlTag => {
  return { name: 'Not', content: [element] };
};

export const logicalTag = (type: LogicalTag, first: XmlTag, second: XmlTag): XmlTag => {
  return { name: type, content: [first, second] };
};

export const rootTag = (tags?: XmlTag): XmlTag => {
  if (tags) {
    return { name: 'Filter', content: [tags] };
  }
  return { name: 'Filter' };
};
