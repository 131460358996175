<smv-panel [title]="title">
  <smv-multi-select
    [label]="label"
    [placeholder]="placeholder"
    identifier="name"
    [displayRow]="displayCustomer"
    [displayRowEm]="displayCustomerEm"
    [searchCondition]="searchCondition"
    [serverFilterFonction]="filter"
    [(selected)]="selectedCustomers"
    (selectedChange)="onSelectionChange()"
  />
</smv-panel>
