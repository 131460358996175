import { utils as XlsxUtils, writeFile } from 'xlsx';
import { DateUtils } from './date.utils';
import { KeysOfType } from './general.utils';

export interface XlsxSheet {
  name: string;
  headerRow: string[];
  dataRows: unknown[][];
}

export const sortHeader = <T>(header: T, sortOrder: readonly KeysOfType<T, string>[]): string[] => {
  const labels: string[] = [];
  for (const key of sortOrder) {
    labels.push(header[key] as string);
  }
  return labels;
};

export const splitToCells = <T>(data: T[], sortOrder: readonly KeysOfType<T, unknown>[]): unknown[][] => {
  return data.map((obj) => sortOrder.map((column) => obj[column]));
};

export const exportToXlsx = (fileName: string, sheets: XlsxSheet[]) => {
  const workbook = XlsxUtils.book_new();

  sheets.forEach((sheet) => {
    const sheetContent = [sheet.headerRow, ...sheet.dataRows];
    const workbookData = XlsxUtils.aoa_to_sheet(sheetContent);
    XlsxUtils.book_append_sheet(workbook, workbookData, sheet.name);
  });

  writeFile(workbook, `${fileName}_${DateUtils.dateToString(new Date())}.xlsx`);
};
