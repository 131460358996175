import { NgClass, NgStyle } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import Chart from 'chart.js/auto';

@Component({
  selector: 'smv-chart',
  standalone: true,
  imports: [NgStyle, NgClass],
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements AfterViewInit, OnDestroy {
  @Input() elementChart?: ChartConfiguration;
  @Input() backgroundImage?: string;
  @Input() pie = false;
  @ViewChild('chartElement') chartElement?: ElementRef;
  @Input() pdf = false;

  public chart?: Chart;

  ngAfterViewInit() {
    this.initChart();
  }

  ngOnDestroy() {
    this.chart?.destroy();
  }

  initChart() {
    if (this.chartElement) {
      const ctx = this.chartElement.nativeElement.getContext('2d');
      if (this.chart) {
        this.chart.destroy();
      }
      if (this.elementChart) {
        this.chart = new Chart(ctx, this.elementChart);

        this.chart.update();
      }
    }
  }
}
