import { Component, Input } from '@angular/core';
import { CultivationGroup } from '@widgets/vigie-virose/model/cultivation.model';
import { Filter } from '@widgets/vigie-virose/model/filter.model';
import { Insect } from '@widgets/vigie-virose/model/insect.model';
import { VirusAnalyzes } from '@widgets/vigie-virose/model/virus-analyzes.model';
import { VigieViroseService } from '@widgets/vigie-virose/services/vigie-virose.service';

export interface Indicators {
  insects: Insect[];
  viruses: VirusAnalyzes[];
}

@Component({
  selector: 'smv-vigie-virose-filter-observations',
  templateUrl: './vigie-virose-filter-observations.component.html',
  styleUrls: ['./vigie-virose-filter-observations.component.scss'],
})
export class VigieViroseFilterObservationsComponent {
  public titleCulture = $localize`Culture`;
  public titleIndicators = $localize`Indicateurs`;

  @Input() cultures: CultivationGroup[] = [];

  constructor(private vigieViroseService: VigieViroseService) {}

  get culturesSelected() {
    return this.cultures.find(
      (culture) => culture.selected || culture.cultivations.filter((c) => c.selected).length > 0
    );
  }

  get indicators() {
    const indicators: Indicators = {
      insects: [],
      viruses: [],
    };
    if (this.culturesSelected) {
      this.culturesSelected.cultivations
        .filter((c) => c.selected)
        .forEach((culture) => {
          const insects = culture.insects.filter((insect) => !indicators.insects.find((i) => i.code == insect.code));
          indicators.insects.push(...insects);
          insects
            .filter(
              (insect) =>
                insect.virusAnalyzes.length > 0 &&
                insect.virusAnalyzes.filter((virus) => virus.cultivationCode.includes(culture.code))
            )
            .forEach((i) => {
              const viruses = i.virusAnalyzes.filter(
                (virus) =>
                  virus.cultivationCode.includes(culture.code) && !indicators.viruses.find((v) => v.code == virus.code)
              );
              indicators.viruses.push(...viruses);
            });
        });
    }
    return indicators;
  }

  onCultureSelectionChanged() {
    this.cultures.forEach((culture) => {
      if (!culture.selected) {
        culture.cultivations.forEach((cultivation) =>
          cultivation.insects.forEach((insect) => {
            insect.selected = false;
            insect.virusAnalyzes.forEach((virus) => (virus.selected = false));
          })
        );
      }
    });
    this.onSelectionChanged(this.indicators);
  }

  onSelectionChanged(filteredIndicators: Indicators) {
    const insectSelected = filteredIndicators.insects.find((insect) => insect.selected);
    const virusSelected = filteredIndicators.viruses.find((virus) => virus.selected);

    this.vigieViroseService.filterState.setParticularValue('dataFilter', {
      cultures: this.culturesSelected,
      insect: insectSelected,
      virus: virusSelected,
    } as Filter);
  }
}
