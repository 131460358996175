import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';

export interface ConfirmDialogConfig {
  title: string;
  content: string | string[];
  confirmButtonText?: string;
  isDanger?: boolean;
}

@Component({
  selector: 'smv-confirm-dialog',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule, MatDividerModule],
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  public confirmBtnText: string;
  public confirmBtnColor: string;
  public contentRows: string[];

  constructor(@Inject(MAT_DIALOG_DATA) public config: ConfirmDialogConfig) {
    this.confirmBtnText = config.confirmButtonText ?? $localize`:Button|Confirm dialog:Confirmer`;
    this.confirmBtnColor = config.isDanger ? 'warn' : 'primary';
    this.contentRows = Array.isArray(config.content) ? config.content : [config.content];
  }
}
