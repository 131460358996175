import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { matFormFieldDefaults } from '@core/configuration/material-defaults.config';
import { ApiMessageInterceptor } from '@core/interceptors/api-message.interceptor';
import { AuthInterceptor } from '@core/interceptors/auth-interceptor';
import { ClientAppModule } from '@feature/client-app/client-app.module';
import { AnonymousFooterComponent } from '@layout/anonymous-footer/anonymous-footer.component';

import { HeaderComponent } from '@layout/header/header.component';
import { SidemenuComponent } from '@layout/sidemenu/sidemenu.component';

import { BackendResponseInterceptor } from '@core/interceptors/backend-response-interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AnonymousFooterComponent,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HeaderComponent,
    HttpClientModule,
    MatSidenavModule,
    MatSnackBarModule,
    SidemenuComponent,
    ClientAppModule,
    MatDialogModule,
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: matFormFieldDefaults },
    { provide: HTTP_INTERCEPTORS, useClass: ApiMessageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BackendResponseInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
