import { Injectable } from '@angular/core';
import { DrawInteractionHelper } from '@core/helpers/draw-interaction.helper';
import { ContextItem } from '@core/model/context-item.model';
import { MapUtils } from '@core/utils/map.utils';
import { Map as OlMap } from 'ol';
import { StatisticConfig, TrapFilter } from './statistic.model';

@Injectable({ providedIn: 'root' })
export class StatisticService {
  public mapInstance?: OlMap;

  public readonly statisticState = new ContextItem<StatisticConfig>({ display: false });

  drawParcelArea(isFreehand: boolean): DrawInteractionHelper | undefined {
    if (!this.mapInstance) {
      return;
    }

    const drawInteraction = new DrawInteractionHelper(this.mapInstance, {
      freehand: isFreehand,
      removeInteractionAfterDraw: false,
      onDrawEnd: (feature) => {
        const geom = feature.getGeometry();
        if (geom) {
          const wktRepresentation = MapUtils.getExtentCoordinatesWkt(geom.getExtent());
          this.statisticState.setValue({
            display: true,
            filter: new TrapFilter({
              emprise: wktRepresentation,
            }),
          });
        }
      },
    });
    drawInteraction.addToMap();
    return drawInteraction;
  }
}
