import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

import { DragDropItemComponent } from '@components/drag-drop-item/drag-drop-item.component';
import { FormFieldErrorsComponent } from '@components/form-field-errors/form-field-errors.component';
import { ColorPickerFieldComponent } from '@components/form/color-picker-field/color-picker-field.component';
import { FormFieldWrapperComponent } from '@components/form/form-field-wrapper/form-field-wrapper.component';
import { HelpOverlayComponent } from '@components/help-overlay/help-overlay.component';
import { WmsLegendComponent } from '../legend/wms-legend/wms-legend.component';
import { DataLayerEditionPanelComponent } from './data-layer-edition-panel/data-layer-edition-panel.component';
import { ExternalLayerComponent } from './data-layer-edition-panel/external-layer/external-layer.component';
import { LayerGroupEditorComponent } from './data-layer-edition-panel/layer-group-editor/layer-group-editor.component';
import { AttributeComputeValuesComponent } from './data-layer-edition-panel/layer-properties/attribute-edition/attribute-compute-values/attribute-compute-values.component';
import { AttributeDefaultValuesComponent } from './data-layer-edition-panel/layer-properties/attribute-edition/attribute-default-values/attribute-default-values.component';
import { LayerAttributeEditionComponent } from './data-layer-edition-panel/layer-properties/attribute-edition/attribute-edition.component';
import { FeatureStyleFormComponent } from './data-layer-edition-panel/layer-properties/feature-style-form/feature-style-form.component';
import { LayerAttributeTabComponent } from './data-layer-edition-panel/layer-properties/layer-attribute-tab/layer-attribute-tab.component';
import { LayerBaseStyleTabComponent } from './data-layer-edition-panel/layer-properties/layer-base-style-tab/layer-base-style-tab.component';
import { LayerHeatMapTabComponent } from './data-layer-edition-panel/layer-properties/layer-heatmap-tab/layer-heatmap-tab.component';
import { LayerInfoTabComponent } from './data-layer-edition-panel/layer-properties/layer-info-tab/layer-info-tab.component';
import { LayerLegendTabComponent } from './data-layer-edition-panel/layer-properties/layer-legend-tab/layer-legend-tab.component';
import { LayerPropertiesComponent } from './data-layer-edition-panel/layer-properties/layer-properties.component';
import { LayerTooltipTabComponent } from './data-layer-edition-panel/layer-properties/layer-tooltip-tab/layer-tooltip-tab.component';
import { LayerValidationTabComponent } from './data-layer-edition-panel/layer-properties/layer-validation-tab/layer-validation-tab.component';
import { StyleRuleEditComponent } from './data-layer-edition-panel/layer-properties/style-rule-form/style-rule-edit/style-rule-edit.component';
import { StyleRuleFormComponent } from './data-layer-edition-panel/layer-properties/style-rule-form/style-rule-form.component';
import { LayerSortComponent } from './data-layer-edition-panel/layer-sort/layer-sort.component';
import { DataLayersComponent } from './data-layers/data-layers.component';

import { QuillModule } from 'ngx-quill';

@NgModule({
  declarations: [
    DataLayerEditionPanelComponent,
    LayerSortComponent,
    DataLayersComponent,
    LayerGroupEditorComponent,
    LayerPropertiesComponent,
    LayerInfoTabComponent,
    LayerTooltipTabComponent,
    LayerHeatMapTabComponent,
    ExternalLayerComponent,
    LayerAttributeTabComponent,
    LayerValidationTabComponent,
    LayerLegendTabComponent,
    LayerBaseStyleTabComponent,
    FeatureStyleFormComponent,
    StyleRuleFormComponent,
    StyleRuleEditComponent,
    LayerAttributeEditionComponent,
    AttributeDefaultValuesComponent,
    AttributeComputeValuesComponent,
  ],
  imports: [
    CommonModule,
    ColorPickerFieldComponent,
    DragDropModule,
    DragDropItemComponent,
    FormFieldErrorsComponent,
    FormFieldWrapperComponent,
    HelpOverlayComponent,
    WmsLegendComponent,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatListModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatDividerModule,
    QuillModule.forRoot(),
  ],
  exports: [DataLayerEditionPanelComponent, DataLayersComponent, LayerPropertiesComponent],
})
export class DataLayersModule {}
