import { Component } from '@angular/core';
import { IcareService } from '../../icare.service';
import { IcareFilter } from '../../model/icare.model';

@Component({
  selector: 'smv-icare-filter-simulations',
  templateUrl: './icare-filter-simulations.component.html',
  styleUrls: ['./icare-filter-simulations.component.scss'],
})
export class IcareFilterSimulationsComponent {
  public titleGeneration = $localize`:Icare:Génération de vol`;

  constructor(private icareService: IcareService) {
    const filter: IcareFilter = {};
    this.icareService.filterState.setParticularValue('dataFilter', filter);
  }
}
