import { Component, Input, OnChanges } from '@angular/core';
import { InsectStates } from '@widgets/vigie-virose/model/insect.model';
import { TrapStatementSimple } from '@widgets/vigie-virose/model/trap.model';
import { ChartConfiguration, ChartDataset } from 'chart.js';

@Component({
  selector: 'smv-vigie-virose-trap-veriliferous-state',
  templateUrl: './vigie-virose-trap-veriliferous-state.component.html',
  styleUrls: ['./vigie-virose-trap-veriliferous-state.component.scss'],
})
export class VigieViroseTrapVeriliferousStateComponent implements OnChanges {
  @Input() expanded = true;
  @Input() traps?: TrapStatementSimple[];
  @Input() multiple = false;

  public title = '';
  public config?: ChartConfiguration;

  private virus = '';
  private insect = '';
  private legends = {
    y: '',
    yInsect: '',
  };
  private datasets: ChartDataset[] = [];

  private states = new Map<InsectStates, string>()
    .set(InsectStates.SAIN, $localize`Pièges sans {{insect_name}}`)
    .set(InsectStates.INSECTS, $localize`Pièges avec {{insect_name}}(s)`)
    .set(InsectStates.INSECTS_WITH_VIRUS, $localize`Pièges avec {{insect_name}}(s) et virus ({{virus_list}}) `);

  ngOnChanges() {
    if (this.traps) {
      this.insect = this.traps[0].diseaseState.insect_name;
      this.virus = this.traps[0].diseaseState.virus_list;

      this.initTitleAndLegends();

      this.setDatasetHealthyBar(this.traps);
      this.setDatasetInsectBar(this.traps);
      this.setDatasetSickBar(this.traps);
      this.setDatasetInsectCountLine(this.traps);

      this.config = {
        type: 'bar',
        options: this.initOptions(),
        data: {
          labels: this.traps.map((trap) => trap.mondayDate),
          datasets: this.datasets,
        },
      };
    }
  }

  initTitleAndLegends() {
    const insect = this.insect.toLowerCase();
    if (this.multiple) {
      this.title = this.insect + 's';
      this.legends = {
        y: $localize`Nbre de pièges par état virulifère`,
        yInsect: $localize`Nbre moyen de ${insect}s par relevé`,
      };
    } else {
      this.title = $localize`Etat Virulifère ` + '(' + this.insect + ')';
      this.legends = {
        y: $localize`Nbre de pièges`,
        yInsect: $localize`Nbre de ${insect}s`,
      };
    }
  }

  initOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom' as const,
          labels: {
            usePointStyle: true,
          },
        },
        title: {
          display: false,
          text: this.insect + 's',
        },
        tooltips: {
          backgroundColor: '#FFFFF',
          mode: 'index',
        },
      },
      scales: this.initScalesOptions(),
    };
  }

  initScalesOptions() {
    return {
      x: {
        display: true,
        stacked: true,
        title: {
          display: true,
        },
      },
      yInsects: {
        display: true,
        stacked: false,
        type: 'linear' as const,
        position: 'right' as const,
        title: {
          display: true,
          text: this.legends.yInsect,
        },
        grid: {
          color: '#000000',
        },
      },
      y: {
        display: true,
        stacked: true,
        type: 'linear' as const,
        position: 'left' as const,
        title: {
          display: true,
          text: this.legends.y,
        },
      },
    };
  }

  setDatasetHealthyBar(traps: TrapStatementSimple[]) {
    const dataHealthy = traps.map((trap) => trap.diseaseState.virStates.healthy);

    const datasetHealthy: ChartDataset = {
      label: this.states.get(InsectStates.SAIN)?.replace('{{insect_name}}', this.insect.toLowerCase()),
      data: dataHealthy,
      borderColor: ' #00b050',
      backgroundColor: 'rgba(0, 176, 80, 0.9)',
      fill: true,
      stack: 'Stack',
      order: 1,
      yAxisID: 'y',
      pointStyle: 'rectRounded',
    };
    this.datasets.push(datasetHealthy);
  }

  setDatasetInsectBar(traps: TrapStatementSimple[]) {
    const dataInsect = traps.map((trap) => trap.diseaseState.virStates.insect);

    const datasetInsect: ChartDataset = {
      label: this.states.get(InsectStates.INSECTS)?.replace('{{insect_name}}', this.insect.toLowerCase()),
      data: dataInsect,
      borderColor: '#ffc000',
      backgroundColor: 'rgba(255, 192, 0, 0.9)',
      fill: true,
      stack: 'Stack',
      order: 2,
      yAxisID: 'y',
      pointStyle: 'rectRounded',
    };
    this.datasets.push(datasetInsect);
  }

  setDatasetSickBar(traps: TrapStatementSimple[]) {
    const dataSick = traps.map((trap) => trap.diseaseState.virStates.sick);

    const datasetSick: ChartDataset = {
      label: this.states
        .get(InsectStates.INSECTS_WITH_VIRUS)
        ?.replace('{{insect_name}}', this.insect.toLowerCase())
        .replace('{{virus_list}}', this.virus),
      data: dataSick,
      borderColor: '#c00000',
      backgroundColor: 'rgba(192, 0, 0, 0.9)',
      fill: true,
      stack: 'Stack',
      order: 3,
      yAxisID: 'y',
      pointStyle: 'rectRounded',
    };
    this.datasets.push(datasetSick);
  }

  setDatasetInsectCountLine(traps: TrapStatementSimple[]) {
    const data = traps.map((trap) => trap.diseaseState.nbInsect);

    const dataset: ChartDataset = {
      label: this.insect + 's',
      type: 'line',
      data: data,
      borderColor: '#555555',
      backgroundColor: 'rgba(0, 0, 0, 0)',
      cubicInterpolationMode: 'monotone',
      borderWidth: 1,
      pointStyle: 'circle',
      pointRadius: 5,
      pointBorderColor: 'rgb(0, 0, 0)',
      order: 0,
      yAxisID: 'yInsects',
    };
    this.datasets.push(dataset);
  }
}
