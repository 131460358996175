<div class="toggle" (click)="toggleSortMode()">
  <div [class]="{ active: isLayerSort }" i18n="Layer sorting panel|Sort toggle">Couches</div>
  <div [class]="{ active: !isLayerSort }" i18n="Layer sorting panel|Sort toggle">Groupes de couches</div>
</div>

<div class="help-container">
  <button mat-button color="primary" (click)="helpVisible = !helpVisible">
    <mat-icon>help</mat-icon>
    <ng-container i18n>Aide</ng-container>
  </button>
  <ng-container *ngIf="helpVisible">
    <p
      *ngIf="isLayerSort; else groupSortHelp"
      class="helper-text"
      i18n="Layer sorting panel|Help message regarding the positioning of data layers"
    >
      Faire glisser la couche dans l'une des zones en pointillés pour changer sa position ou la changer de groupe.
    </p>
    <ng-template #groupSortHelp>
      <p class="helper-text" i18n="Layer sorting panel|Help message regarding the positioning of data layers">
        Faire glisser le groupe dans l'une des zones en pointillés pour changer sa position.
      </p>
    </ng-template>
    <p class="helper-text" i18n="Layer sorting panel|Help message regarding the positioning of data layers">
      Attention, la position des groupes et des couches détermine l'ordre dans lequel sont superposées les couches de
      données sur la carte. Pour ramener une couche au premier plan, déplacez-la ou déplacez le groupe auquel elle
      appartient au-dessus des autres groupes.
    </p>
  </ng-container>
</div>

<div *ngIf="isLayerSort; else groupSort" class="sort-layers-panel" cdkDropListGroup>
  <div *ngFor="let group of flatLayerGroups">
    <div class="group-name">
      <div *ngFor="let name of group.path">
        <mat-icon>arrow_right</mat-icon>
        {{ name }}
      </div>
    </div>
    <div
      class="drop-list"
      cdkDropList
      [cdkDropListData]="group.group.layers"
      (cdkDropListDropped)="layerReordered($event)"
    >
      <div *ngFor="let layer of group.group.layers" class="layer" cdkDrag>
        <mat-icon>drag_handle</mat-icon>
        {{ layer.config.shortName }}
      </div>
    </div>
  </div>
</div>

<div class="smv-sticky-bottom-actions">
  <button mat-stroked-button i18n="Button" (click)="closePanel.emit()">Annuler</button>
  <button mat-flat-button color="primary" i18n="Button" (click)="applyChanges()">Terminer</button>
</div>

<ng-template #groupSort>
  <smv-drag-drop-item [item]="rootItem"></smv-drag-drop-item>
</ng-template>
