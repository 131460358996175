import { Component } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { GeoserverVariablesService } from '@feature/client-app/widgets/widgets-filter-cql.service';

interface EditionType {
  label: string;
  control: FormControl<boolean | null>;
}

@Component({
  selector: 'smv-validation-filter-type',
  templateUrl: './validation-filter-type.component.html',
  styleUrls: ['./validation-filter-type.component.scss'],
})
export class ValidationFilterTypeComponent {
  private addControl = new FormControl<boolean>(true);
  private modifiedGeometryControl = new FormControl<boolean>(true);
  private modifiedAttributesControl = new FormControl<boolean>(true);
  private deleteControl = new FormControl<boolean>(true);

  private controls: FormArray;

  public title = $localize`Filtre type d'édition`;

  public types: EditionType[] = [
    {
      label: $localize`:Edition type:Ajout`,
      control: this.addControl,
    },
    {
      label: $localize`:Edition type:Modification de géométrie`,
      control: this.modifiedGeometryControl,
    },
    {
      label: $localize`:Edition type:Modification d'attributs`,
      control: this.modifiedAttributesControl,
    },
    {
      label: $localize`:Edition type:Suppression`,
      control: this.deleteControl,
    },
  ];

  constructor(private geoserverVariables: GeoserverVariablesService) {
    this.controls = new FormArray([
      this.addControl,
      this.modifiedGeometryControl,
      this.modifiedAttributesControl,
      this.deleteControl,
    ]);

    this.controls.valueChanges.subscribe(() => this.computeCqlFilter());
    this.computeCqlFilter();
  }

  private computeCqlFilter() {
    const filter = [];
    if (this.addControl.value) {
      filter.push('to_create = true');
    }
    if (this.modifiedGeometryControl.value) {
      filter.push('to_modify_geometry = true');
    }
    if (this.modifiedAttributesControl.value) {
      filter.push('to_modify_attributes = true');
    }
    if (this.deleteControl.value) {
      filter.push('to_delete = true');
    }

    const geoserverVariable = filter.length ? '(' + filter.join(' OR ') + ')' : 'true = false';
    this.geoserverVariables.editionValidationState.setParticularValue('editionType', geoserverVariable);
  }
}
