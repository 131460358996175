import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GeometryType } from '@core/model/application-api/layer.model';
import {
  ALIGNMENT_OPTIONS,
  FILL_PATTERN_OPTIONS,
  STROKE_PATTERN_OPTIONS,
  SYMBOL_OPTIONS,
} from '../layer-base-style-tab/style-form-config.model';
import { BaseStyleForm } from '../layer-base-style-tab/style-form.model';

@Component({
  selector: 'smv-feature-style-form',
  templateUrl: './feature-style-form.component.html',
  styleUrls: ['./feature-style-form.component.scss'],
})
export class FeatureStyleFormComponent {
  @Input() formGroup!: FormGroup<BaseStyleForm>;
  @Input() layerGeometry?: GeometryType;

  public readonly GeometryType = GeometryType;
  public readonly fillPatterns = FILL_PATTERN_OPTIONS;
  public readonly strokePatterns = STROKE_PATTERN_OPTIONS;
  public readonly symbols = SYMBOL_OPTIONS;
  public readonly textAlignments = ALIGNMENT_OPTIONS;
  public readonly styleTextHelp = $localize`:Layer base style:Texte fixe ou nom d'attribut sous la forme {attr_name}`;
}
