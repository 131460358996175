import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { LoadingComponent } from '@components/loading/loading.component';
import { PanelComponent } from '@widgets/components/panel/panel.component';
import { SamplesAdminComponent } from '../components/samples-admin/samples-admin.component';
import { UploadFileComponent } from '../components/upload-file/upload-file.component';
import { VigieViroseAdminComponent } from './vigie-virose-admin.component';

@NgModule({
  declarations: [VigieViroseAdminComponent],
  imports: [
    CommonModule,
    LoadingComponent,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    PanelComponent,
    SamplesAdminComponent,
    UploadFileComponent,
  ],
  exports: [VigieViroseAdminComponent],
})
export class VigieViroseAdminModule {}
