import { Component, OnInit } from '@angular/core';
import { IcareService } from '../../icare.service';
import { IcareFilter } from '../../model/icare.model';

@Component({
  selector: 'smv-icare-filter-observations',
  templateUrl: './icare-filter-observations.component.html',
  styleUrls: ['./icare-filter-observations.component.scss'],
})
export class IcareFilterObservationsComponent implements OnInit {
  constructor(private icareService: IcareService) {}

  ngOnInit() {
    const filter: IcareFilter = {};
    this.icareService.filterState.setParticularValue('dataFilter', filter);
  }
}
