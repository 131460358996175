<ng-container *ngIf="editable; else readonlyLayers">
  <div *ngFor="let layerGroup of layerGroups">
    <div class="layer-group">
      <ng-template *ngTemplateOutlet="groupToggle; context: { $implicit: layerGroup }"></ng-template>
      <mat-icon
        [mat-menu-trigger-for]="groupMenu"
        [matMenuTriggerData]="{ group: layerGroup }"
        *ngIf="!layerGroup.config.isUngrouped"
      >
        more_vert
      </mat-icon>
    </div>

    <div *ngFor="let layer of layerGroup.layers" class="layer" [class]="{ hidden: !layerGroup.expanded }">
      <div>{{ layer.config.shortName }}</div>
      <mat-icon [mat-menu-trigger-for]="layerMenu" [matMenuTriggerData]="{ layer: layer, group: layerGroup }">
        more_vert
      </mat-icon>
    </div>
    <div *ngIf="!layerGroup.layers.length && layerGroup.expanded" class="no-layer-text" i18n>
      Aucune couche associée à ce groupe
    </div>

    <smv-data-layers
      [hidden]="!layerGroup.expanded"
      [layerGroups]="layerGroup.subgroups"
      [subgroupLevel]="subgroupLevel + 1"
      [editable]="true"
      [parent]="layerGroup"
      (renameLayerGroup)="renameLayerGroup.emit($event)"
      (layerOrGroupRemoved)="layerOrGroupRemoved.emit()"
      (editLayerProperties)="editLayerProperties.emit($event)"
    />
  </div>
</ng-container>

<ng-template #readonlyLayers>
  <div *ngFor="let layerGroup of filteredGroups">
    <div class="layer-group">
      <ng-template *ngTemplateOutlet="groupToggle; context: { $implicit: layerGroup }"></ng-template>
      <div class="visibility-marker" (click)="layerGroup.toggleGroupVisibility()">
        <ng-container *ngIf="layerGroup.config.visible; then groupVisibilityToggleOn; else groupVisibilityToggleOff" />
      </div>
    </div>

    <div *ngFor="let layer of layerGroup.layers" class="layer" [class]="{ hidden: !layerGroup.expanded }">
      <mat-checkbox
        [checked]="layer.displayParameters.isVisible"
        [disabled]="!layerGroup.config.visible"
        (click)="toggleLayerVisibility(layer)"
      >
        {{ layer.config.shortName }}
      </mat-checkbox>
      <div class="layer-actions">
        <ng-container *ngIf="canUserToggleEditionMode && layer.config.editable">
          <mat-icon
            *ngIf="!layer.isEditing"
            (click)="updateEditionMode(layer)"
            matTooltip="Basculer en mode édition"
            i18n-matTooltip
          >
            edit
          </mat-icon>
          <mat-icon
            *ngIf="layer.isEditing"
            (click)="updateEditionMode(layer)"
            matTooltip="Désactiver le mode édition"
            i18n-matTooltip
          >
            edit_off
          </mat-icon>
        </ng-container>
        <mat-icon [mat-menu-trigger-for]="readonlyLayerMenu" [matMenuTriggerData]="{ layer: layer }">
          more_vert
        </mat-icon>
      </div>
    </div>

    <smv-data-layers
      [hidden]="!layerGroup.expanded"
      [layerGroups]="layerGroup.subgroups"
      [subgroupLevel]="subgroupLevel + 1"
      (viewLayerProperties)="viewLayerProperties.emit($event)"
      (toggleEditionMode)="toggleEditionMode.emit($event)"
    />
  </div>
</ng-template>

<mat-menu #groupMenu>
  <ng-template matMenuContent let-group="group">
    <button mat-menu-item (click)="renameLayerGroup.emit(group)" i18n>Renommer le groupe</button>
    <button mat-menu-item (click)="deleteLayerGroup(group)" i18n>Supprimer le groupe</button>
  </ng-template>
</mat-menu>

<mat-menu #layerMenu>
  <ng-template matMenuContent let-layer="layer" let-group="group">
    <button mat-menu-item i18n (click)="editLayerProperties.emit(layer)">Modifier les propriétés</button>
    <button mat-menu-item (click)="deleteLayer(layer, group)" i18n>Supprimer la couche</button>
  </ng-template>
</mat-menu>

<mat-menu #readonlyLayerMenu>
  <ng-template matMenuContent let-layer="layer">
    <button mat-menu-item i18n (click)="viewLayerProperties.emit(layer)">Propriétés de la couche</button>
    <button mat-menu-item i18n *ngIf="layer.config.bboxEpsg4326" (click)="zoomOnLayer(layer)">
      Zoom sur la couche
    </button>
    <div class="opacity-slider">
      <div class="slider-label" i18n>Opacité</div>
      <mat-slider min="0" max="1" step="0.01" discrete [displayWith]="opacitySliderLabel">
        <input
          matSliderThumb
          [value]="layer.displayParameters.opacityValue"
          (valueChange)="layer.updateOpacity($event)"
        />
      </mat-slider>
    </div>
    <div *ngIf="layer.config.subtype === 'HeatMap'" class="opacity-slider" (click)="$event.stopPropagation()">
      <div class="slider-label unselectable" i18n>Rayon</div>
      <mat-slider min="0" max="150" step="1" discrete>
        <input matSliderThumb [value]="layer.displayParameters.radius" (valueChange)="layer.updateRadius($event)" />
      </mat-slider>
    </div>
    <div *ngIf="layer.config.subtype === 'HeatMap'" class="opacity-slider" (click)="$event.stopPropagation()">
      <div class="slider-label unselectable" i18n>Flou</div>
      <mat-slider min="0" max="150" step="1" discrete>
        <input matSliderThumb [value]="layer.displayParameters.blur" (valueChange)="layer.updateBlur($event)" />
      </mat-slider>
    </div>
  </ng-template>
</mat-menu>

<!-- Collapsible layer group toggle (collapsed icon + name)-->
<ng-template #groupToggle let-group>
  <div class="group-toggle" [class]="{ disabled: !group.config.visible }" (click)="group.expanded = !group.expanded">
    <ng-container>
      <div *ngIf="subgroupLevel" [style]="{ width: subgroupSpace }"></div>
    </ng-container>
    <mat-icon *ngIf="!group.expanded">arrow_drop_down</mat-icon>
    <mat-icon *ngIf="group.expanded">arrow_drop_up</mat-icon>
    <span>{{ group.config.category }}</span>
  </div>
</ng-template>

<!-- End of line visibility toggle -->
<ng-template #groupVisibilityToggleOn>
  <mat-icon matTooltip="Masquer le groupe" i18n-matTooltip>visibility_on</mat-icon>
</ng-template>
<ng-template #groupVisibilityToggleOff>
  <mat-icon class="visibility-off" matTooltip="Afficher le groupe" i18n-matTooltip>visibility_off</mat-icon>
</ng-template>
