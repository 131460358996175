import { getSldGraphicTag } from '@core/utils/layer-style.utils';
import { XmlTag } from '@core/utils/xml.utils';
import { FeatureLike } from 'ol/Feature';
import Style from 'ol/style/Style';
import { GeometryType } from './application-api/layer.model';
import { FillStyle, FillStyleModel } from './styles/fill-style.model';
import { StrokeStyle, StrokeStyleModel } from './styles/stroke-style.model';
import { SymbolStyle, SymbolStyleModel } from './styles/symbol-style.model';
import { TextStyle, TextStyleModel } from './styles/text-style.model';

export interface CustomStyle {
  baseStyle?: CustomStyle;
  pointGeometry: boolean;
  symbolStyle: SymbolStyleModel;
  fillStyle: FillStyleModel;
  strokeStyle: StrokeStyleModel;
  textStyle: TextStyleModel;
}

export class CustomLayerStyle {
  static defaultStyle: CustomLayerStyle;
  private baseStyle: CustomStyle;

  pointGeometry = false;
  symbolStyle: SymbolStyle;
  fillStyle: FillStyle;
  strokeStyle: StrokeStyle;
  textStyle: TextStyle;

  private cachedOlStyle?: Style;

  constructor(style?: CustomStyle) {
    if (!CustomLayerStyle.defaultStyle) {
      CustomLayerStyle.generateDefaultStyle();
    }
    if (style) {
      Object.assign(this, style);
    }

    this.symbolStyle = new SymbolStyle(style?.symbolStyle);
    this.symbolStyle.setBaseStyle(CustomLayerStyle.defaultStyle.symbolStyle);

    this.fillStyle = new FillStyle(style?.fillStyle);
    this.fillStyle.setBaseStyle(CustomLayerStyle.defaultStyle.fillStyle);

    this.strokeStyle = new StrokeStyle(style?.strokeStyle);
    this.strokeStyle.setBaseStyle(CustomLayerStyle.defaultStyle.strokeStyle);

    this.textStyle = new TextStyle(style?.textStyle);
    this.textStyle.setBaseStyle(CustomLayerStyle.defaultStyle.textStyle);

    this.baseStyle =
      style?.baseStyle ??
      (CustomLayerStyle.defaultStyle.toModel ? CustomLayerStyle.defaultStyle.toModel() : this.toModel());
  }

  public static generateDefaultStyle() {
    CustomLayerStyle.defaultStyle = <CustomLayerStyle>{};
    CustomLayerStyle.defaultStyle = new CustomLayerStyle();
    CustomLayerStyle.defaultStyle.setDefaultValues();
  }

  private setDefaultValues() {
    this.symbolStyle = new SymbolStyle();
    this.symbolStyle.setDefaultValues();

    this.fillStyle = new FillStyle();
    this.fillStyle.setDefaultValues();

    this.strokeStyle = new StrokeStyle();
    this.strokeStyle.setDefaultValues();

    this.textStyle = new TextStyle();
    this.textStyle.setDefaultValues();

    this.pointGeometry = false;
  }

  public getBaseStyle(): CustomStyle {
    return this.baseStyle;
  }

  public setBaseStyle(baseStyle: CustomLayerStyle) {
    this.baseStyle = baseStyle.toModel();
    this.symbolStyle.setBaseStyle(baseStyle.symbolStyle);
    this.fillStyle.setBaseStyle(baseStyle.fillStyle);
    this.strokeStyle.setBaseStyle(baseStyle.strokeStyle);
    this.textStyle.setBaseStyle(baseStyle.textStyle);
  }

  setDefaultStyle(baseStyle: CustomLayerStyle) {
    CustomLayerStyle.defaultStyle = baseStyle;
  }

  applyStyle(style: CustomStyle) {
    this.symbolStyle = new SymbolStyle(style.symbolStyle);
    this.fillStyle = new FillStyle(style.fillStyle);
    this.strokeStyle = new StrokeStyle(style.strokeStyle);
    this.textStyle = new TextStyle(style.textStyle);
  }

  applyBaseStyle() {
    this.applyStyle(this.baseStyle);
  }

  applyCategorieColor(color: string) {
    this.fillStyle.fillColor = color;
    this.strokeStyle.strokeColor = color;
    this.symbolStyle.symbolFillColor = color;
    this.symbolStyle.symbolStrokeColor = color;
    this.fillStyle.fillSymbol.symbolFillColor = color;
    this.fillStyle.fillSymbol.symbolStrokeColor = color;
    this.strokeStyle.symbol.symbolStrokeColor = color;
    this.strokeStyle.symbol.symbolFillColor = color;
  }

  getOlStyle(feature?: FeatureLike): Style {
    const useCache = !new RegExp(/{(.*?)}/).test(this.textStyle.getText());
    if (this.cachedOlStyle) {
      if (!useCache) {
        this.cachedOlStyle.setText(this.textStyle.createTextStyle(feature));
      }
      return this.cachedOlStyle;
    }
    const style = new Style();
    style.setFill(this.fillStyle.getOlStyle());
    style.setStroke(this.strokeStyle.getOlStyle());
    const symbol = this.symbolStyle.getOlStyle();
    if (symbol) {
      style.setImage(symbol);
    }
    if (this.textStyle.getText() && this.textStyle.getText().length > 0) {
      style.setText(this.textStyle.createTextStyle(feature));
    }
    this.cachedOlStyle = style;
    return style;
  }

  toSld(geometryType: GeometryType): XmlTag {
    let tag: XmlTag;
    if (geometryType === GeometryType.POLYGON) {
      const fillContent = this.fillStyle.toSld();
      const strokeContent = this.strokeStyle.toSld();
      tag = {
        name: 'PolygonSymbolizer',
        content: [...fillContent, ...strokeContent],
      };
    } else if (geometryType === GeometryType.POINT) {
      tag = this.pointToSld();
    } else if (geometryType === GeometryType.LINE) {
      tag = {
        name: 'LineSymbolizer',
        content: '',
      };
    } else {
      throw Error('Unrecognized geometry type: ' + geometryType);
    }

    return tag;
  }

  private pointToSld(): XmlTag {
    const graphicTag = getSldGraphicTag(this.symbolStyle, this.symbolStyle.symbol);

    return {
      name: 'PointSymbolizer',
      content: [graphicTag],
    };
  }

  clearCache() {
    this.cachedOlStyle = undefined;
  }

  toModel(): CustomStyle {
    return {
      pointGeometry: this.pointGeometry,
      symbolStyle: this.symbolStyle.toModel(),
      fillStyle: this.fillStyle.toModel(),
      strokeStyle: this.strokeStyle.toModel(),
      textStyle: this.textStyle.toModel(),
    };
  }

  toModelWithBaseStyle(): CustomStyle {
    return {
      baseStyle: this.baseStyle,
      pointGeometry: this.pointGeometry,
      symbolStyle: this.symbolStyle.toModel(),
      fillStyle: this.fillStyle.toModel(),
      strokeStyle: this.strokeStyle.toModel(),
      textStyle: this.textStyle.toModel(),
    };
  }
}
