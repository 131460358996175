<!-- Before item drop zone -->
<div
  *ngIf="parent"
  class="parent-drop-zone"
  [class]="{ visible: isDraggingItem, never: !firstChild }"
  cdkDropList
  [id]="item.rootContainerIdBefore"
  [cdkDropListData]="{ parent: parent, item: item }"
  [cdkDropListConnectedTo]="connectedDropLists"
  (cdkDropListDropped)="droppedAround($event, 'before')"
></div>

<div
  class="current-item-wrapper"
  cdkDropList
  [cdkDropListData]="{ parent: parent, item: item }"
  [cdkDropListConnectedTo]="connectedDropLists"
>
  <!-- Item handle and preview-->
  <div
    *ngIf="parent"
    cdkDrag
    [id]="item.uniqueId"
    [cdkDragData]="item"
    (cdkDragStarted)="toggleDragging(true)"
    (cdkDragReleased)="toggleDragging(false)"
  >
    <div cdkDragHandle class="item-name">
      <mat-icon *ngIf="parent">drag_handle</mat-icon>
      <span>{{ item.name }}</span>
      <mat-icon class="reduce-group" *ngIf="item.children.length" (click)="item.expanded = !item.expanded">
        <ng-container *ngIf="!item.expanded">arrow_drop_down</ng-container>
        <ng-container *ngIf="item.expanded">arrow_drop_up</ng-container>
      </mat-icon>
    </div>
    <div *cdkDragPreview class="drag-preview">
      <mat-icon>drag_handle</mat-icon>
      {{ item.name }}
    </div>
    <!-- FIXME: n'apparaît pas -->
    <!-- <div class="drag-placeholder" *cdkDragPlaceholder>{{ item.name }} (annuler le déplacement)</div> -->
  </div>

  <!-- Child drop zone -->
  <div
    cdkDropList
    class="child-drop-zone"
    [class]="{ dragging: isDraggingItem, hidden: !item.expanded }"
    [id]="item.uniqueId"
    [cdkDropListConnectedTo]="connectedDropLists"
    [cdkDropListData]="{ parent: parent, item: item }"
    (cdkDropListDropped)="droppedInside($event)"
  >
    <smv-drag-drop-item
      [class]="{ root: !parent }"
      *ngFor="let subitem of item.children; let isFirst = first; let isLast = last"
      [item]="subitem"
      [parent]="item"
      [connectedDropLists]="connectedDropLists"
      [firstChild]="isFirst"
      [lastChild]="isLast"
      [dragging]="isDraggingItem"
      (draggingChange)="toggleDragging($event)"
    ></smv-drag-drop-item>
  </div>
</div>

<!-- After item drop zone -->
<div
  *ngIf="parent"
  class="parent-drop-zone"
  cdkDropList
  [class]="{ visible: isDraggingItem }"
  [id]="item.rootContainerIdAfter"
  [cdkDropListData]="{ parent: parent, item: item }"
  [cdkDropListConnectedTo]="connectedDropLists"
  (cdkDropListDropped)="droppedAround($event, 'after')"
></div>
