import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CultivationGroup } from '@widgets/vigie-virose/model/cultivation.model';

@Component({
  selector: 'smv-vigie-virose-culture',
  templateUrl: './vigie-virose-culture.component.html',
  styleUrls: ['./vigie-virose-culture.component.scss'],
})
export class VigieViroseCultureComponent {
  @Input() public cultures: CultivationGroup[] = [];

  @Output() selectionChanged = new EventEmitter();

  updateAllComplete(nameCultivationGroup: string) {
    this.cultures.forEach((culture) => {
      if (culture.name != nameCultivationGroup) {
        culture.selected = false;
        culture.cultivations.forEach((c) => (c.selected = false));
      } else {
        culture.selected = culture.cultivations != null && culture.cultivations.every((c) => c.selected);
      }
    });
  }

  someComplete(cultivationGroup: CultivationGroup): boolean {
    if (cultivationGroup.cultivations == null) {
      return false;
    }
    return cultivationGroup.cultivations.filter((c) => c.selected).length > 0 && !cultivationGroup.selected;
  }

  setAll(nameCultivationGroup: string, selected: boolean) {
    this.cultures.forEach((culture) => {
      if (culture.name != nameCultivationGroup) {
        culture.selected = false;
        culture.cultivations.forEach((c) => (c.selected = false));
      } else {
        culture.cultivations.forEach((c) => (c.selected = selected));
      }
    });
    this.selectionChanged.emit();
  }
}
