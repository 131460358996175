import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MapService } from '@core/services/map.service';
import { GeneralUtils } from '@core/utils/general.utils';
import { Feature, Point } from 'geojson';
import { Overlay } from 'ol';
import { fromLonLat } from 'ol/proj';
import { Subscription, filter, tap } from 'rxjs';
import { SearchAddressService } from './search-address.service';

@Component({
  selector: 'smv-search-address',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './search-address.component.html',
  styleUrls: ['./search-address.component.scss'],
})
export class SearchAddressComponent implements OnInit, OnDestroy {
  public filteredAddresses: Feature[] = [];
  public addressSearch: FormControl<string | Feature | null> = new FormControl<string | null>('');

  private markerElement = document.createElement('div');
  private marker = new Overlay({
    positioning: 'center-center',
    element: this.markerElement,
    stopEvent: false,
  });

  private subscriptions = new Subscription();

  constructor(private searchApi: SearchAddressService, private mapService: MapService) {
    this.markerElement.className = 'ol-marker';
  }

  ngOnInit() {
    this.subscriptions.add(
      this.addressSearch.valueChanges
        .pipe(
          filter(GeneralUtils.isNotNull),
          filter(GeneralUtils.isString),
          tap(() => (this.filteredAddresses = [])),
          filter((search) => search.length > 2)
        )
        .subscribe((search) => {
          this.searchApi.searchAddress(search).subscribe((addresses) => {
            this.filteredAddresses = addresses;
          });
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  displayFn(address?: Feature) {
    return address?.properties?.['label'];
  }

  clear() {
    this.addressSearch.setValue(null);
    this.filteredAddresses = [];
    this.mapService.handleOverlay(this.marker);
  }

  onSelect(address: Feature) {
    const lnglat = (address.geometry as Point).coordinates;
    const coord = fromLonLat(lnglat);
    this.marker.setPosition(coord);

    const type = address.properties?.['type'];

    let zoomlevel;
    switch (type) {
      case 'housenumber':
        zoomlevel = 19;
        break;
      case 'street':
      case 'locality':
        zoomlevel = 16;
        break;
      default:
        zoomlevel = 14;
    }

    this.mapService.zoomTo(coord, zoomlevel);
    this.mapService.handleOverlay(this.marker, true);
  }

  onKeyUp() {
    if (this.filteredAddresses.length) {
      this.onSelect(this.filteredAddresses[0]);
      this.addressSearch.setValue(this.filteredAddresses[0]);
    }
  }
}
