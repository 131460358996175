import { Component, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DropdownOption } from '@components/form/form-field-wrapper/form-field-wrapper.component';
import { GeoserverVariablesService } from '@widgets/widgets-filter-cql.service';

type EditionDuplicates = 'ALL' | 'ONLY_DUPLICATES' | 'NOT_DUPLICATES';

@Component({
  selector: 'smv-validation-filter-duplicate',
  templateUrl: './validation-filter-duplicate.component.html',
  styleUrls: ['./validation-filter-duplicate.component.scss'],
})
export class ValidationFilterDuplicateComponent implements OnDestroy {
  @Input() duplicates: number[] = [];

  public title = $localize`Filtre multiples éditions`;

  public displayDuplicates = new FormControl<EditionDuplicates>('ALL');
  public options: DropdownOption<EditionDuplicates>[] = [
    {
      label: $localize`:Edition duplicate:Toutes les entités`,
      value: 'ALL',
    },
    {
      label: $localize`:Edition duplicate:Modification unique`,
      value: 'NOT_DUPLICATES',
    },
    {
      label: $localize`:Edition duplicate:Modifications multiples`,
      value: 'ONLY_DUPLICATES',
    },
  ];

  constructor(private geoserverVariables: GeoserverVariablesService) {
    this.displayDuplicates.valueChanges.subscribe(() => this.computeCqlFilter());
    this.computeCqlFilter();
  }

  ngOnDestroy(): void {
    this.geoserverVariables.editionValidationState.setParticularValue('editionDuplicates', '');
  }

  private computeCqlFilter() {
    let geoserverVariable = ' in (' + this.duplicates.join(', ') + ') ';
    switch (this.displayDuplicates.value) {
      case 'ONLY_DUPLICATES':
        geoserverVariable = ' linked_entity' + geoserverVariable;
        break;
      case 'NOT_DUPLICATES':
        geoserverVariable = ' linked_entity not' + geoserverVariable;
        break;
      default:
        geoserverVariable = '';
    }
    this.geoserverVariables.editionValidationState.setParticularValue('editionDuplicates', geoserverVariable);
  }
}
