import { RpgStyle } from '@core/model/widgets/qualih2o.model';
import { Indexable } from '@core/utils/general.utils';
import { DataLayerConfig } from '@feature/client-app/data-layer-config.model';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

export class CqlLayerHelper {
  public readonly layerConfig$ = new ReplaySubject<DataLayerConfig | undefined>(1);

  public get tileLayer(): TileLayer<TileWMS> | undefined {
    return this.layer;
  }

  protected layer?: TileLayer<TileWMS>;

  private appliedCqlFilters: Indexable<string> = {};

  setLayer(layer?: DataLayerConfig): void {
    if (layer?.olLayer instanceof TileLayer) {
      this.layer = layer.olLayer;
    }
    this.layerConfig$.next(layer);
  }

  addFilter(filterKey: string, cqlFilter?: string): void {
    if (cqlFilter) {
      this.appliedCqlFilters[filterKey] = cqlFilter;
    } else {
      delete this.appliedCqlFilters[filterKey];
    }

    if (this.layer) {
      const filters = Object.values(this.appliedCqlFilters);
      this.layer.getSource()?.updateParams({ CQL_FILTER: filters.length ? filters.join(' AND ') : undefined });
    }
  }

  removeFilter(filterKey: string): void {
    this.addFilter(filterKey, undefined);
  }
}

const RPG_STYLE_NAME = new Map<RpgStyle, string>([
  ['NONE', 'rpg_vierge'],
  ['CROP', 'cultures'],
  ['VULNERABILITY', 'risque'],
]);

export class RpgLayerHelper extends CqlLayerHelper {
  public readonly layerStyle$ = new BehaviorSubject<RpgStyle>('NONE');
  public activeAac$ = new ReplaySubject<string | null>(1);

  override setLayer(layer?: DataLayerConfig): void {
    super.setLayer(layer);
    this.changeStyle(this.layerStyle$.getValue());
  }

  changeStyle(style: RpgStyle): void {
    if (this.layer) {
      this.layer.getSource()?.updateParams({ STYLES: RPG_STYLE_NAME.get(style) });
    }
    this.layerStyle$.next(style);
  }

  getStyleName(style?: RpgStyle): string | undefined {
    return style ? RPG_STYLE_NAME.get(style) : this.getActiveStyleName();
  }

  getActiveStyleName(): string | undefined {
    return RPG_STYLE_NAME.get(this.layerStyle$.getValue());
  }

  filterOnAac(aacName: string): void {
    const filter = `INTERSECTS(geom, querySingle('aac', 'geom', 'nom = ''${aacName.replace("'", "''''")}'''))`;
    this.activeAac$.next(aacName);
    this.addFilter('aac', filter);
  }

  removeAacFilter(): void {
    this.addFilter('aac', undefined);
    this.activeAac$.next(null);
  }
}
