import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

const modules = [NgIf, MatInputModule, MatButtonModule, MatIconModule, MatFormFieldModule, FormsModule];
@Component({
  selector: 'smv-search-field',
  standalone: true,
  imports: modules,
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
})
export class SearchFieldComponent {
  @Input() placeholder = '';

  @Output() search = new EventEmitter<string | null>();

  public searchValue?: string = undefined;

  private lastEmitted?: string = undefined;

  onSearch() {
    if (this.canSearch()) {
      this.lastEmitted = this.searchValue;
      this.search.emit(this.searchValue);
    }
  }

  clear() {
    this.searchValue = undefined;
    this.onSearch();
  }

  canSearch() {
    return this.searchValue !== this.lastEmitted;
  }
}
