import { DropdownOption } from '@components/form/form-field-wrapper/form-field-wrapper.component';

export type ButtonType = 'REJECT' | 'VALID';

interface ButtonsLabel {
  valid: ButtonLabel;
  reject: ButtonLabel;
}

interface ButtonLabel {
  grouped: string;
  notGrouped: string;
}

export const ValidationOptionsGrouped: DropdownOption<boolean>[] = [
  {
    label: $localize`:Edition Validation:Validation groupée`,
    value: true,
  },
  {
    label: $localize`:Edition Validation:Validation unique`,
    value: false,
  },
];

export const ValidationOptionsNotify: DropdownOption<boolean>[] = [
  {
    label: $localize`:Edition Validation:Valider puis notifier`,
    value: true,
  },
  {
    label: $localize`:Edition Validation:Ne pas notifier`,
    value: false,
  },
];

export const ValidationLabelOptions: ButtonsLabel = {
  valid: {
    grouped: $localize`:Button:Valider toutes les entités filtrées`,
    notGrouped: $localize`:Button:Valider l'entité sélectionnée`,
  },
  reject: {
    grouped: $localize`:Button:Rejeter toutes les entités filtrées`,
    notGrouped: $localize`:Button:Rejeter l'entité sélectionnée`,
  },
};

export const EditionAttributes = [
  'user_modif',
  'date_modif',
  'user_creat',
  'date_creat',
  'linked_entity',
  'to_create',
  'to_modify_geometry',
  'to_modify_attributes',
  'to_delete',
  'is_modified',
];
