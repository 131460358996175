<smv-panel title="Contraintes" i18n-title="Revolt|Filter">
  <div *ngFor="let constraint of constraints">
    <mat-checkbox color="accent" [(ngModel)]="constraint.selected" (change)="computeFilter()">
      {{ constraint.code }}
    </mat-checkbox>
  </div>
  <mat-checkbox color="accent" [(ngModel)]="noConstraint" (change)="computeFilter()" i18n="Revolt|Filter">
    Sans contrainte
  </mat-checkbox>
</smv-panel>
