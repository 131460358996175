import { Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { AttributeType } from '@core/model/application-api/layer.model';
import { Indexable } from '@core/utils/general.utils';
import { finalize, from, map } from 'rxjs';
import { AttributeForm } from '../../layer-attribute-tab/layer-attribute-tab.component';

type jsonConfiguration = Indexable<Indexable<string | string[] | boolean>>;

@Component({
  selector: 'smv-attribute-compute-values',
  templateUrl: './attribute-compute-values.component.html',
  styleUrls: ['./attribute-compute-values.component.scss'],
})
export class AttributeComputeValuesComponent {
  @Input() attributes!: FormArray<FormGroup<AttributeForm>>;
  @Input() attribute!: FormGroup<AttributeForm>;

  public isWorking = false;
  public file: File | null = null;
  public filename = new FormControl<string | undefined>(undefined);
  public nullDefaultControl = new FormControl<boolean>(true);

  public importedValues?: number;
  public constraintedAttr: string[] = [];

  public expectedFormat = {
    attributeDefaultValue1: {
      otherAttributeName: false,
      anotherAttributeName: 'value',
    },
    attributeDefaultValue2: {
      otherAttributeName: true,
      anotherAttributeName: ['value1', 'value2'],
    },
  };

  readConfigJson() {
    if (this.file) {
      this.isWorking = true;
      this.importedValues = undefined;
      this.constraintedAttr = [];
      from(this.file.text())
        .pipe(
          map((text) => JSON.parse(text)),
          finalize(() => (this.isWorking = false))
        )
        .subscribe((json: jsonConfiguration) => {
          const jsonKeys = Object.keys(json);
          if (this.attribute.controls.type.value !== AttributeType.BOOLEAN) {
            this.attribute.controls.defaultValues.setValue({ default: jsonKeys });
          }

          this.importedValues = jsonKeys.length;
          if (jsonKeys.length) {
            const constraintKeys = Object.keys(json[jsonKeys[0]]);
            this.computeConstraints(json, jsonKeys, constraintKeys);
          }
        });
    }
  }

  private computeConstraints(json: jsonConfiguration, attrValues: string[], constraintKeys: string[]) {
    constraintKeys.forEach((constraintKey) => {
      const attrForm = this.attributes.controls.find((form) => form.controls.name.value == constraintKey);

      if (attrForm) {
        if (attrForm.controls.type.value !== AttributeType.BOOLEAN) {
          const defaultValues: Indexable<string[]> = {};

          attrValues.forEach((attrValue) => {
            const value = json[attrValue][constraintKey];
            if (Array.isArray(value)) {
              defaultValues[attrValue] = value;
            } else if (typeof value === 'string') {
              defaultValues[attrValue] = [value];
            } else if (!value) {
              defaultValues[attrValue] = [];
            }
          });

          if (Object.keys(defaultValues).length) {
            if (this.nullDefaultControl.value) {
              defaultValues['null'] = [];
            }
            this.constraintedAttr.push(constraintKey);
            attrForm.controls.dependsOn.setValue(this.attribute.controls.name.value);
            attrForm.controls.defaultValues.setValue(defaultValues);
          }
        }
      }
    });
  }

  onFileChange(event: Event): void {
    const filesSelected = (event.target as HTMLInputElement).files;
    if (filesSelected && filesSelected.length > 0) {
      this.file = filesSelected.item(0);
      this.filename.setValue(this.file?.name);
    } else {
      this.filename.reset();
    }
  }
}
