import { Component, Input, OnInit } from '@angular/core';
import { CultivationGroup } from '@widgets/vigie-virose/model/cultivation.model';
import { Filter } from '@widgets/vigie-virose/model/filter.model';
import { VigieViroseService } from '@widgets/vigie-virose/services/vigie-virose.service';

@Component({
  selector: 'smv-vigie-virose-filter-simulation',
  templateUrl: './vigie-virose-filter-simulation.component.html',
  styleUrls: ['./vigie-virose-filter-simulation.component.scss'],
})
export class VigieViroseFilterSimulationComponent implements OnInit {
  @Input() cultures: CultivationGroup[] = [];

  constructor(private vigieViroseService: VigieViroseService) {}

  ngOnInit() {
    if (this.cultures) {
      const cultureSelected = this.cultures.find((c) => c.name == 'Céréales');
      const insectSelected = cultureSelected?.cultivations
        .find((cultivation) => cultivation.code == 'BLE')
        ?.insects.find((i) => i.code == 'puceron');

      this.vigieViroseService.filterState.setParticularValue('dataFilter', {
        cultures: cultureSelected,
        insect: insectSelected,
        virus: undefined,
      } as Filter);
    }
  }
}
