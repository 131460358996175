import { LayerDynamicStyle, LayerDynamicStyleModel } from './layer-dynamic-style';

export class LayerDynamicStyleCategories extends LayerDynamicStyle {
  constructor(style?: LayerDynamicStyleModel) {
    super(style);
  }

  override applyStyle(style: LayerDynamicStyleModel) {
    super.applyStyle(style);
  }

  applyBaseStyle() {
    this.applyStyle(this.baseStyle);
  }

  clone(): LayerDynamicStyleCategories {
    return new LayerDynamicStyleCategories(this.toModel());
  }

  toModel(): LayerDynamicStyleModel {
    return this.toModelBase();
  }

  toModelWithBaseStyle(): LayerDynamicStyleModel {
    return this.toModelBaseWithBaseStyle();
  }
}
