import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FormFieldWrapperComponent } from '@components/form/form-field-wrapper/form-field-wrapper.component';
import { ReadOnlyToggleDirective } from '@components/form/readonly-toggle.directive';
import { PanelComponent } from '@widgets/components/panel/panel.component';
import { EditionValidationComponent } from './edition-validation.component';
import { ValidationFeatureComponent } from './validation-feature/validation-feature.component';
import { RangeDateSliderPipe } from './validation-filter-data/range-date-slider.pipe';
import { ValidationFilterDataComponent } from './validation-filter-data/validation-filter-data.component';
import { ValidationFilterDuplicateComponent } from './validation-filter-duplicate/validation-filter-duplicate.component';
import { ValidationFilterTypeComponent } from './validation-filter-type/validation-filter-type.component';
import { ValidationFilterWarningComponent } from './validation-filter-warning/validation-filter-warning.component';

@NgModule({
  declarations: [
    EditionValidationComponent,
    ValidationFilterWarningComponent,
    ValidationFilterDuplicateComponent,
    ValidationFilterTypeComponent,
    ValidationFilterDataComponent,
    ValidationFeatureComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatDividerModule,
    ReactiveFormsModule,
    PanelComponent,
    FormFieldWrapperComponent,
    ReadOnlyToggleDirective,
    RangeDateSliderPipe,
  ],
  exports: [EditionValidationComponent],
})
export class EditionValidationModule {}
