<div class="smv-dialog">
  <h1 mat-dialog-title>{{ config.title }}</h1>
  <mat-divider></mat-divider>
  <div mat-dialog-content>
    <div class="dialog-content">
      <p *ngFor="let row of contentRows" [innerHTML]="row"></p>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false" i18n>Annuler</button>
    <button mat-raised-button [color]="confirmBtnColor" [mat-dialog-close]="true">
      {{ confirmBtnText }}
    </button>
  </div>
</div>
