<form [formGroup]="form" class="smv-form parcel-form">
  <smv-form-field-wrapper formControlName="parcelName">
    <span mat-label i18n="Label|Parcel name">Nom de la parcelle</span>
  </smv-form-field-wrapper>
  <smv-form-field-wrapper
    formControlName="farmer"
    controlType="autocomplete"
    [selectOptions]="farmers"
    selectIdentifier="id"
    requireSelection
    hasAction
    hasHint
  >
    <span mat-label i18n="Label|Farmer">Agriculteur</span>
    <span *ngIf="form.controls.farmer.value" i18n="Hint|Autocomplete">
      La dernière option sélectionnée est conservée
    </span>
    <button mat-icon-button (click)="form.controls.farmer.setValue(undefined)">
      <mat-icon>close</mat-icon>
    </button>
  </smv-form-field-wrapper>
  <smv-form-field-wrapper
    formControlName="order"
    controlType="select"
    [selectOptions]="orders"
    selectIdentifier="idObs"
    required
    hasAction
  >
    <span mat-label i18n="Label|Order">Commande</span>
    <button mat-icon-button (click)="form.controls.order.setValue(undefined)">
      <mat-icon>close</mat-icon>
    </button>
  </smv-form-field-wrapper>
  <smv-form-field-wrapper formControlName="idealScanDate" type="date" hasHint>
    <span mat-label i18n="Label|IdealScanDate">Date de scan idéale</span>
    <span mat-hint i18n="InterraScan Order|IdealScanDate hint"> La date de scan idéale est liée à la commande</span>
  </smv-form-field-wrapper>
  <smv-form-field-wrapper
    formControlName="currentCrop"
    controlType="autocomplete"
    [selectOptions]="crops"
    selectIdentifier="id"
    requireSelection
    hasAction
  >
    <span mat-label i18n="Label|Current crop">Culture en place</span>
    <button mat-icon-button (click)="form.controls.currentCrop.setValue(undefined)">
      <mat-icon>close</mat-icon>
    </button>
  </smv-form-field-wrapper>
  <smv-form-field-wrapper formControlName="harvestDate" type="date">
    <span mat-label i18n="Label|Harvest date">Date de récolte</span>
  </smv-form-field-wrapper>
  <smv-form-field-wrapper
    formControlName="nextCrop"
    controlType="autocomplete"
    [selectOptions]="crops"
    selectIdentifier="id"
    requireSelection
    hasAction
  >
    <span mat-label i18n="Label|Next crop">Culture suivante</span>
    <button mat-icon-button (click)="form.controls.nextCrop.setValue(undefined)">
      <mat-icon>close</mat-icon>
    </button>
  </smv-form-field-wrapper>
  <smv-form-field-wrapper formControlName="sowingDate" type="date">
    <span mat-label i18n="Label|Sowing date">Date de semis</span>
  </smv-form-field-wrapper>

  <smv-form-field-wrapper
    formControlName="package"
    controlType="select"
    [selectOptions]="packages"
    selectIdentifier="id"
    required
  >
    <span mat-label i18n="Label|InterraScan package">Package</span>
  </smv-form-field-wrapper>
  <smv-form-field-wrapper formControlName="physicalAccess" controlType="textarea">
    <span mat-label i18n="Label|Parcel physical access">Commentaire accès physique</span>
  </smv-form-field-wrapper>
</form>
