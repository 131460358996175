import { Directive, Input } from '@angular/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Directive({
  selector: '[smvReadOnlyToggle]',
  standalone: true,
})
export class ReadOnlyToggleDirective {
  @Input() set smvReadOnlyToggle(value: boolean) {
    if (value) {
      this.slide.toggleChange.subscribe(() => this.slide.writeValue(!this.slide.checked));
    }
  }
  constructor(private slide: MatSlideToggle) {}
}
