<smv-panel [title]="title" *ngIf="selectedFeature">
  <ng-container *ngFor="let attribute of generalInfos">
    <smv-form-field-wrapper [formControl]="attribute.control" [type]="attribute.type" readonly showEmptyWhenDisabled>
      <span mat-label>{{ attribute.label }}</span>
    </smv-form-field-wrapper>
  </ng-container>
  <smv-form-field-wrapper
    *ngIf="comment.value"
    [control]="comment"
    controlType="textarea"
    readonly
    showEmptyWhenDisabled
  >
    <span mat-label i18n="Edition Validation">Commentaire</span>
  </smv-form-field-wrapper>
  <smv-panel [title]="titleAttributes" [expanded]="false" *ngIf="areAttrModif && parser">
    <mat-slide-toggle class="unselectable" [formControl]="onlyModifAttr" i18n="Edition Validation">
      Voir uniquement les attributs modifiés
    </mat-slide-toggle>
    <form [formGroup]="parser.form" class="smv-form valid-attr-form">
      <ng-container
        *ngFor="let attribute of onlyModifAttr.value ? modifAttributes : attributes"
        [ngSwitch]="attribute.type"
      >
        <smv-form-field-wrapper
          *ngSwitchDefault
          [formControlName]="attribute.name"
          [type]="getInputType(attribute.type)"
          readonly
          showEmptyWhenDisabled
          [hasHint]="!!originalFeatureProperties"
        >
          <span mat-label>{{ attribute.label ?? attribute.name }}</span>
          <span mat-hint *ngIf="originalFeatureProperties">
            <span i18n="Edition Validation|Initial Value">Valeur initiale : </span>
            {{ originalFeatureProperties[attribute.name] }}
          </span>
        </smv-form-field-wrapper>
        <mat-slide-toggle
          *ngSwitchCase="AttributeType.BOOLEAN"
          [formControlName]="attribute.name"
          [smvReadOnlyToggle]="true"
        >
          {{ attribute.label ?? attribute.name }}
        </mat-slide-toggle>
      </ng-container>
    </form>
  </smv-panel>
</smv-panel>
