import { qualiH2OLegalNotices } from '@assets/pdf/legal-notices';
import { ElementPosition, PdfElement } from '@core/helpers/pdf/pdf-builder-types';
import { PdfBuilder } from '@core/helpers/pdf/pdf-builder.helper';
import { getRatio } from '@core/helpers/pdf/pdf-builder.utils';
import { Coordinate, format } from 'ol/coordinate';

export interface LegendData {
  url: string;
  name: string;
}

const HEADER_HEIGHT = 25;
const LEGEND_TITLE_HEIGHT = 10;

// Footer
const LEGAL_NOTICES_HEIGHT = 20;
const LEGAL_NOTICES_MARGIN = 5;
const FOOTER_YELLOW_BAND_HEIGHT = 3;
const FOOTER_BLUE_BAND_HEIGHT = 24;
const FOOTER_HEIGHT = FOOTER_YELLOW_BAND_HEIGHT + FOOTER_BLUE_BAND_HEIGHT;
const LAST_PAGE_FOOTER_HEIGHT = FOOTER_HEIGHT + LEGAL_NOTICES_HEIGHT + LEGAL_NOTICES_MARGIN;

export class QualiH2oPdfHelper {
  private builder = new PdfBuilder();
  private readonly assets = 'assets/widgets/qualih2o/pdf/';

  async buildPdf(
    olCanvas: HTMLCanvasElement,
    legendUrls: LegendData[],
    mapCenter?: Coordinate,
    withAreaStats = false
  ): Promise<void> {
    await this.buildMapPage(olCanvas, mapCenter);
    await this.buildLegendPage(legendUrls, !withAreaStats);

    if (withAreaStats) {
      await this.addReportPage($localize`Calculs de surfaces sur la zone par culture`, '#areaCropStats');
      await this.addReportPage(
        $localize`Calculs de surfaces sur la zone par vulnérabilité`,
        '#areaVulnerabilityStats',
        true
      );
    }

    this.builder.pdf.save('QualiH2O-export');
  }

  private async buildMapPage(olCanvas: HTMLCanvasElement, mapCenter?: Coordinate): Promise<void> {
    // Header
    this.addPageHeader();
    const northArrowSize = 15;
    await this.builder.addExternalImage(this.assets + 'north_arrow.png', {
      x: this.builder.getXFromRight(northArrowSize, true),
      y: this.builder.config.heightMargin - 5,
      maxHeight: northArrowSize,
      maxWidth: northArrowSize,
    });
    this.builder.addText($localize`Rapport du ` + new Date().toLocaleDateString(), { x: 130, y: 25 });

    // Map screenshot
    const availableContent = this.getAvailableContentSize();
    const ratio = getRatio(olCanvas, availableContent.width, availableContent.height);
    const mapHeight = olCanvas.height / ratio;
    this.builder.pdf.addImage(
      olCanvas.toDataURL('image/jpeg,1.0'),
      'JPEG',
      availableContent.start.x,
      availableContent.start.y + 10,
      olCanvas.width / ratio,
      mapHeight
    );

    // Scale and coordinates
    const scaleTopPosition = this.builder.getYFromTop(HEADER_HEIGHT + mapHeight + 6, true);
    const scale = await this.builder.addImageFromHtml('.ol-scale-line', {
      x: this.builder.config.widthMargin,
      y: scaleTopPosition,
      ratio,
    });
    if (mapCenter) {
      this.builder.addText(
        $localize`Coordonnées GPS : ` + format(mapCenter, '({x}, {y}) (WGS 84 / Pseudo-Mercator)', 3),
        {
          x: this.builder.getXFromLeft(scale.width + 5, true),
          y: scaleTopPosition,
          fontSize: 11,
        }
      );
    }

    await this.addFooter();
  }

  private async buildLegendPage(legendData: LegendData[], isLastPage: boolean): Promise<void> {
    this.builder.addPage();
    await this.addPageHeader();

    const availableContent = this.getAvailableContentSize(isLastPage);
    const maxWidth = availableContent.width / 2;
    const maxHeight = availableContent.height - LEGEND_TITLE_HEIGHT;

    let legendStartX = availableContent.start.x;
    let legendStartY = availableContent.start.y;
    for (const legendDatum of legendData) {
      const loadedImage = await this.builder.loadExternalImage(legendDatum.url, { width: maxWidth, height: maxHeight });

      if (legendStartY + loadedImage.height + LEGEND_TITLE_HEIGHT > availableContent.end.y) {
        legendStartY = availableContent.start.y;
        legendStartX += maxWidth;
      }

      if (legendStartX + loadedImage.width > availableContent.end.x) {
        // Page full, close this page and start a new one
        await this.addFooter();
        this.builder.addPage();
        await this.addPageHeader();

        legendStartX = availableContent.start.x;
        legendStartY = availableContent.start.y;
      }

      this.builder.addText(legendDatum.name, {
        x: legendStartX,
        y: legendStartY + LEGEND_TITLE_HEIGHT - 2,
      });
      this.builder.pdf.addImage(
        loadedImage.data,
        legendStartX,
        legendStartY + LEGEND_TITLE_HEIGHT,
        loadedImage.width,
        loadedImage.height
      );

      legendStartY += loadedImage.height + LEGEND_TITLE_HEIGHT;
    }

    await this.addFooter(isLastPage);
  }

  private async addPageHeader(): Promise<void> {
    this.builder.addText($localize`Export - Quali'H2O Territoire`, {
      x: 110,
      y: this.builder.config.heightMargin,
      fontSize: 17,
      fontStyle: 'bold',
    });

    await this.builder.addExternalImage(this.assets + 'qualih2o-logo.png', {
      x: this.builder.config.widthMargin,
      y: this.builder.config.heightMargin - 5,
      maxWidth: 60,
      maxHeight: 15,
    });
  }

  private async addFooter(withLegalNotices = false): Promise<void> {
    const bottomMargin = withLegalNotices ? LEGAL_NOTICES_HEIGHT + LEGAL_NOTICES_MARGIN : 0;

    const footerWidth = this.builder.config.pageWidth;
    const yPos = this.builder.getYFromBottom(FOOTER_BLUE_BAND_HEIGHT + bottomMargin);

    this.builder.pdf.setDrawColor(0);

    // Blue band
    this.builder.pdf.setFillColor('#000066');
    this.builder.pdf.rect(0, yPos, footerWidth, FOOTER_BLUE_BAND_HEIGHT, 'F');

    // Yellow band
    this.builder.pdf.setFillColor('#FFB500');
    this.builder.pdf.rect(0, yPos - FOOTER_YELLOW_BAND_HEIGHT, footerWidth, FOOTER_YELLOW_BAND_HEIGHT, 'F');

    // Logo
    const logoWidth = 72;
    const blueBandGap = 2;
    await this.builder.addExternalImage(this.assets + 'syngenta-logo.png', {
      x: this.builder.getXFromRight(logoWidth, true),
      y: yPos + blueBandGap,
      maxWidth: logoWidth,
      maxHeight: FOOTER_BLUE_BAND_HEIGHT - 2 * blueBandGap,
    });

    if (withLegalNotices) {
      this.addLegalNotices();
    }
  }

  private addLegalNotices(): void {
    const yPos = this.builder.getYFromBottom(LEGAL_NOTICES_HEIGHT);
    const leftMargin = 2;
    const lineMaxWidth = this.builder.config.pageWidth - leftMargin * 2;
    this.builder.pdf.setTextColor(100, 100, 100);
    this.builder.pdf.setFontSize(6);
    this.builder.addWrappedText(qualiH2OLegalNotices.main, { x: leftMargin, y: yPos }, lineMaxWidth);
    this.builder.addWrappedText(
      qualiH2OLegalNotices.emphasis,
      { x: leftMargin, y: yPos + 12, fontStyle: 'bold' },
      lineMaxWidth
    );

    this.builder.resetPdfState();
  }

  private async addReportPage(name: string, selector: string, isLastPage = false): Promise<void> {
    this.builder.addPage();
    await this.addPageHeader();

    const pageSubtitleYPos = this.builder.getYFromTop(HEADER_HEIGHT + 10, true);

    this.builder.addText(name, {
      x: this.builder.config.widthMargin,
      y: pageSubtitleYPos,
    });

    const availableContent = this.getAvailableContentSize(isLastPage);

    await this.builder.addImageFromHtml(selector, {
      x: this.builder.config.widthMargin,
      y: pageSubtitleYPos + 5,
      size: { availableHeight: availableContent.height, availableWidth: availableContent.width },
    });

    await this.addFooter(isLastPage);
  }

  private getAvailableContentSize(isLastPage = false): PdfElement & { start: ElementPosition; end: ElementPosition } {
    const { pageHeight, pageWidth, widthMargin } = this.builder.config;
    const footerHeight = isLastPage ? LAST_PAGE_FOOTER_HEIGHT : FOOTER_HEIGHT;
    return {
      height: pageHeight - footerHeight - HEADER_HEIGHT,
      width: pageWidth - widthMargin * 2,
      start: { x: widthMargin, y: HEADER_HEIGHT },
      end: { x: pageWidth - widthMargin, y: pageHeight - footerHeight },
    };
  }
}
