import { DropdownOption } from '@components/form/form-field-wrapper/form-field-wrapper.component';
import { Indexable } from '@core/utils/general.utils';
import { LayerDynamicStyleModel } from '../layer-dynamic-style';
import { CustomStyle } from '../layer-style.model';
import { WidgetType } from './widget.model';

export interface LayerGroup {
  categorie: string;
  visible: boolean;
  fromWidget?: WidgetType; // Managed by a widget
  childs: (LayerGroup | Layer)[];
}

export interface Layer {
  activeStyle?: string; // Active WMS style
  attributions?: string; // Layer attributions
  authenticationInfos?: {
    login: string;
    password: string;
  }; // Geoserver credentials
  baseLayer?: boolean;
  boundingBoxEPSG4326?: [number, number, number, number];
  code?: string; // Layer code
  description?: string;
  dynamicStyle?: LayerDynamicStyleModel;
  editable?: boolean; // Are users allowed to edit geometries
  editionType?: LayerEditType;
  filter?: string; // CQL filter (WMS/WMS requests or VectorTile frontend filter)
  format?: string; // Image format (WMTS sources)
  fromWidget?: WidgetType; // Managed by a widget
  geometryType?: GeometryType;
  heat_zoom?: number; // HeatMap layers
  heat_blur?: number; // HeatMap layers
  heat_radius?: number; // HeatMap layers
  heat_weight?: string; // HeatMap layers
  ignoreUrlInCapabiltiesResponse?: boolean;
  isDrivenByplugin?: boolean;
  isLegendDisplayed?: boolean; // Legend visible for this layer
  jsonData?: string; // GeoJSON features
  layername: string; // Layer name/ID for OGC requests
  layerProperties?: LayerProperty[];
  legendImageUrl?: string; // Used for WMS layer styles
  matrixSet?: string; // Matrix set (WMTS sources)
  maxFeatures?: number; // Max requested features (WFS requests)
  maxScaleDenominator?: number;
  minScaleDenominator?: number;
  nom_court?: string; // Name displayed in the layer panel
  opacity?: number; // OL layer opacity
  outputFormat?: string; // For WFS requests
  overlay?: FeatureOverlay; // Feature tooltip on hover
  projection?: string; // Layer projection
  style?: CustomStyle; // Layer feature style
  styles?: LayerStyle[]; // Geoserver styles?
  subType?: LayerSubtype;
  targetNamespace?: string; // Geoserver nameSpace of the layer
  targetPrefix?: string; // Geoserver prefix of the layer
  type?: LayerType; // OL layer type
  url?: string; // For WMTS/WFS sources
  useCustomSld?: boolean;
  useEditionStyle?: boolean;
  validationConfiguration?: LayerEditionValidationConfiguration;
  visible?: boolean; // Displayed in OL map by default
  zIndex?: number; // OL layer zIndex
}

export interface LayerStyle {
  /** Name passed as a request parameter */
  style: string;

  /** Path to the legend */
  legendUrl: string;

  /** User-friendly name */
  name: string;
}

export interface FeatureOverlay {
  active: boolean;
  content?: string;
}

export interface LayerProperty {
  name: string;
  label?: string;
  type: AttributeType;
  editable: boolean;
  display: boolean;
  nillable: boolean;
  configuration?: LayerPropertyConfiguration;
}

export interface LayerPropertyConfiguration {
  defaultValues: Indexable<string[]>;
  defaultBoolean: boolean;
  min: number | null;
  max: number | null;
  dateMin: Date | null;
  dateMax: Date | null;
  pattern: string | null;
  excludePattern: string | null;
  excludeValue?: string | null;
  dependsOn?: string;
  dependsOnSourceValueChecked?: string | null;
  dataSource?: string | null;
  dataSourceDisplay?: string | null;
  dataSourceChecked?: boolean;
  asyncFirstChecked?: boolean;
  values?: DropdownOption<string | number>[];
  displayInitialValue?: boolean;
}

export interface LayerEditionValidationConfiguration {
  grouped: boolean;
  warnings: boolean;
  distanceWarning?: number;
  notify: ValidationToNotify;
  notifyForced?: boolean;
  defaultValidationFeedback?: string;
  defaultRejectionFeedback?: string;
  comments: boolean;
}

export enum AttributeType {
  BOOLEAN = 'xsd:boolean',
  STRING = 'xsd:string',
  DATE = 'xsd:date',
  DATE_TIME = 'xsd:date-time',
  NUMBER = 'xsd:number',
  DOUBLE = 'xsd:double',
  INT = 'xsd:int',
}

export enum LayerType {
  WMS = 'WMS',
  WMTS = 'WMTS',
  WFS = 'WFS',
  VECTOR_TILE = 'VectorTile',
  VECTOR = 'Vector',
  IGN = 'IGN',
  GOOGLE_MAP = 'GoogleMap',
  OSM = 'OSM',
}
export enum LayerSubtype {
  HEATMAP = 'HeatMap',
  VECTOR = 'Vector',
}
export enum GeometryType {
  COLLECTION = 'collection',
  POLYGON = 'polygone',
  POINT = 'point',
  LINE = 'ligne',
}

export enum LayerEditType {
  ALL = 'all',
  MODIFY = 'modify',
  ATTRIBUTES = 'attribute',
  GEOMETRIES = 'geom',
}

export type ValidationToNotify = 'NONE' | 'VV_TRAP' | 'CNC_EDITION' | 'IS_PARCEL';
