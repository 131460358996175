<smv-aac-feature-actions *ngIf="isAACFeature" [feature]="selectedFeature" />

<form *ngIf="selectedFeature && parser; else noSelectedFeature" [formGroup]="parser.form" class="smv-form">
  <ng-container *ngFor="let attribute of parser.attributes" [ngSwitch]="attribute.type">
    <ng-container *ngSwitchDefault>
      <smv-form-field-wrapper
        *ngIf="isDefaultDisplay(attribute); else selectOption"
        [formControlName]="attribute.name"
        [type]="getInputType(attribute.type)"
        [readonly]="!attribute.editable"
        [required]="!attribute.nillable"
        [hasAction]="!parser.form.disabled && attribute.editable"
        [hasHint]="isEditable() && attribute.configuration?.displayInitialValue"
        showEmptyWhenDisabled
      >
        <span mat-label>{{ attribute.label ?? attribute.name }}</span>
        <button mat-icon-button (click)="parser.form.controls[attribute.name].setValue(null)">
          <mat-icon>close</mat-icon>
        </button>
        <span mat-hint>
          <span i18n="Edition|Initial Value">Valeur initiale : </span>
          {{ parser.savedForm.get(attribute.name)?.value }}
        </span>
      </smv-form-field-wrapper>
      <ng-template #selectOption>
        <smv-form-field-wrapper
          [formControlName]="attribute.name"
          [type]="getInputType(attribute.type)"
          [controlType]="getSelectOptions(attribute).length >= 10 ? 'autocomplete' : 'select'"
          [selectOptions]="getSelectOptions(attribute)"
          [readonly]="!attribute.editable"
          [required]="isRequired(attribute)"
          [hasAction]="!parser.form.disabled && attribute.editable && getSelectOptions(attribute).length >= 15"
          [optionsInit]="attribute.configuration?.asyncFirstChecked === false || isSelectionChange"
          [hasHint]="isEditable() && attribute.configuration?.displayInitialValue"
          showEmptyWhenDisabled
        >
          <span mat-label>{{ attribute.label ?? attribute.name }}</span>
          <button mat-icon-button (click)="parser.form.controls[attribute.name].setValue(null)">
            <mat-icon>close</mat-icon>
          </button>
          <span mat-hint>
            <span i18n="Edition|Initial Value">Valeur initiale : </span>
            {{ parser.savedForm.get(attribute.name)?.value }}
          </span>
        </smv-form-field-wrapper>
      </ng-template>
    </ng-container>

    <mat-slide-toggle
      *ngSwitchCase="AttributeType.BOOLEAN"
      [formControlName]="attribute.name"
      [smvReadOnlyToggle]="!attribute.editable"
    >
      {{ attribute.label ?? attribute.name }}
    </mat-slide-toggle>
  </ng-container>

  <div class="smv-sticky-bottom-actions edit-button" *ngIf="isEditable()">
    <button
      *ngIf="canUpdateGeom"
      mat-stroked-button
      (click)="updateGeom(parser.coordAttributes.long?.value!, parser.coordAttributes.lat?.value!)"
      matTooltip="Repositionne la carte selon les attributs spécifiés comme latitude et longitude. Le point apparaîtra quand vous enregistrerez les modifications"
      matTooltipPosition="left"
      i18n-matTooltip="Edition|Update geometry"
      i18n="Button"
    >
      Repositionner la géométrie
    </button>
    <button mat-stroked-button (click)="resetModifications()" i18n="Button">Réinitialiser les attributs</button>
    <button
      mat-stroked-button
      (click)="confirmModifications()"
      [disabled]="parser.form.invalid"
      [ngClass]="{ btnSpinner: isSaving, loaded: isSaving }"
      i18n="Button"
    >
      Enregistrer les modifications
    </button>
  </div>
</form>

<ng-template #noSelectedFeature>
  <div i18n>Veuillez sélectionner un objet</div>
</ng-template>
