<div class="smv-dialog">
  <h1 mat-dialog-title class="unselectable">
    <span i18n="Attribute Editor">Paramétrage de l'attribut</span> {{ attribute.controls.name.value }}
  </h1>
  <mat-divider></mat-divider>
  <div mat-dialog-content cdkScrollable>
    <mat-tab-group>
      <mat-tab label="Valeur par défaut" labelClass="unselectable" i18n-label="Attribute Editor|Tab name">
        <smv-attribute-default-values *ngIf="!isBooleanAttribute" [config]="config" [attribute]="attribute" />
        <mat-slide-toggle
          *ngIf="isBooleanAttribute"
          labelPosition="before"
          [formControl]="attribute.controls.defaultBoolean"
          class="toggle unselectable"
          i18n="Attribute editor|Boolean default value"
        >
          Définir la valeur par défaut à :
        </mat-slide-toggle>
      </mat-tab>
      <mat-tab
        *ngIf="!isBooleanAttribute"
        label="Contraintes"
        labelClass="unselectable"
        i18n-label="Attribute Editor|Tab name"
      >
        <div *ngIf="!isDateAttribute">
          <smv-form-field-wrapper [control]="attribute.controls.min" type="number">
            <span mat-label *ngIf="!isNumberAttribute" i18n="Attribute Editor|Constraint">Longueur minimale</span>
            <span mat-label *ngIf="isNumberAttribute" i18n="Attribute Editor|Constraint">Valeur minimale</span>
          </smv-form-field-wrapper>

          <smv-form-field-wrapper [control]="attribute.controls.max" type="number">
            <span mat-label *ngIf="!isNumberAttribute" i18n="Attribute Editor|Constraint">Longueur maximale</span>
            <span mat-label *ngIf="isNumberAttribute" i18n="Attribute Editor|Constraint">Valeur maximale</span>
          </smv-form-field-wrapper>

          <smv-form-field-wrapper [control]="attribute.controls.pattern" hasHelp>
            <span mat-label i18n="Attribute Editor|Constraint">Structure de la valeur</span>
            <span i18n="Attribute Editor|Constraint help" help>
              Utilisé seulement si aucune valeur par défaut n'est définie
            </span>
          </smv-form-field-wrapper>
          <smv-form-field-wrapper [control]="attribute.controls.excludePattern" hasHelp>
            <span mat-label i18n="Attribute Editor|Constraint">Structure à exclure</span>
            <span i18n="Attribute Editor|Constraint help" help>
              Par exemple : [0-9] pour interdire l'usage des chiffres
            </span>
          </smv-form-field-wrapper>
          <smv-form-field-wrapper [control]="attribute.controls.excludeValue">
            <span mat-label i18n="Attribute Editor|Constraint">Valeur à exclure</span>
          </smv-form-field-wrapper>
        </div>
        <div *ngIf="isDateAttribute">
          <smv-form-field-wrapper [control]="attribute.controls.dateMin" type="date">
            <span mat-label i18n="Attribute Editor|Constraint">Date minimale</span>
          </smv-form-field-wrapper>
          <smv-form-field-wrapper [control]="attribute.controls.dateMax" type="date">
            <span mat-label i18n="Attribute Editor|Constraint">Date maximale</span>
          </smv-form-field-wrapper>
        </div>

        <smv-form-field-wrapper [control]="attribute.controls.dataSource" hasHelp [helpCloseAfter]="10000">
          <span mat-label i18n="Attibute Editor|Data Source">Source des données</span>
          <span i18n="Attribute Editor|Data Source help" help>
            Lien et attributs de valeur et d'affichage<br />
            Formatage : httpURL::Chemin::NomVariableControle::NomVariableAffichage<br />
            Note: utiliser "[]" pour itérer sur un tableau<br />
            exemple: $URL::List.[]::code::name
          </span>
        </smv-form-field-wrapper>

        <smv-form-field-wrapper [control]="attribute.controls.dataSourceDisplay" hasHelp>
          <span mat-label i18n="Attibute Editor|Data visualisation">Source des données pour visualisation</span>
          <span i18n="Attribute Editor|Data visualisation help" help> Lien pour récupérer la valeur d'affichage </span>
        </smv-form-field-wrapper>

        <mat-slide-toggle
          labelPosition="before"
          [formControl]="attribute.controls.nillable"
          class="toggle unselectable"
          i18n="Attribute Editor|Constraint"
        >
          Permettre une valeur nulle :
        </mat-slide-toggle>
      </mat-tab>
      <mat-tab label="Configuration des dépendances" labelClass="unselectable" i18n-label="Attribute Editor|Tab name">
        <smv-attribute-compute-values [attributes]="attributes" [attribute]="attribute" />
      </mat-tab>
    </mat-tab-group>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" mat-dialog-close i18n="Button">Fermer</button>
  </div>
</div>
