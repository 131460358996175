import { Feature, Map } from 'ol';
import { Geometry, Polygon } from 'ol/geom';
import { Draw } from 'ol/interaction';
import { DrawEvent } from 'ol/interaction/Draw';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Style } from 'ol/style';

export interface DrawConfiguration {
  /** Clear the VectorSource on the `drawstart` event (default: true) */
  clearSourceOnDrawStart?: boolean;
  /** Freehand drawing (default: true) */
  freehand?: boolean;
  /** Remove the interaction from the map on the `drawend` event (default: true) */
  removeInteractionAfterDraw?: boolean;
  onDrawEnd: (drawnFeature: Feature<Geometry>) => void;
}

export interface LayerConfiguration {
  /** VectorLayer properties */
  properties?: object;
  zIndex?: number;
  style?: Style;
}

const defaultDrawConfig: Partial<DrawConfiguration> = {
  clearSourceOnDrawStart: true,
  freehand: true,
  removeInteractionAfterDraw: true,
};

export class DrawInteractionHelper {
  public isActive = false;

  private interaction: Draw;
  private source: VectorSource<Polygon>;
  private layer: VectorLayer<VectorSource>;
  private map: Map;

  constructor(map: Map, interactionConfig: DrawConfiguration, layerConfig: LayerConfiguration = {}) {
    const drawConfiguration = Object.assign({}, defaultDrawConfig, interactionConfig);
    this.map = map;

    this.source = new VectorSource<Polygon>({ wrapX: false });
    this.layer = new VectorLayer({
      source: this.source,
      ...layerConfig,
    });
    this.interaction = new Draw({
      source: this.source,
      type: 'Polygon',
      freehand: drawConfiguration.freehand,
    });

    this.interaction.on('drawstart', () => {
      if (drawConfiguration.clearSourceOnDrawStart) {
        this.source.clear();
      }
    });

    this.interaction.on('drawend', (event: DrawEvent) => {
      drawConfiguration.onDrawEnd(event.feature);
      if (drawConfiguration.removeInteractionAfterDraw) {
        this.removeFromMap();
      }
    });
  }

  addToMap(): void {
    this.map.addLayer(this.layer);
    this.map.addInteraction(this.interaction);
    this.isActive = true;
  }

  removeFromMap(): void {
    this.map.removeLayer(this.layer);
    this.map.removeInteraction(this.interaction);
    this.isActive = false;
  }
}
