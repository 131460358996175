import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '@core/utils/date.utils';
import { Periodicity } from './timeline.model';

@Pipe({ name: 'timelineDate', standalone: true })
export class TimelineDatePipe implements PipeTransform {
  transform(value?: string | Date, periodicity?: Periodicity): string {
    if (!value) {
      return '';
    }

    let dateString = null;
    const weekStart = this.toDateString(DateUtils.getFirstDayOfWeek(value));
    const weekEnd = this.toDateString(DateUtils.getLastDayOfWeek(value));
    switch (periodicity) {
      case Periodicity.WEEKS:
        dateString = `${weekStart} - ${weekEnd}`;
        break;
      case Periodicity.MONTHS:
        dateString = this.toDateString(value).substring(3);
        break;
      case Periodicity.YEARS:
        dateString = this.toDateString(value).substring(6);
        break;
      default:
        dateString = this.toDateString(value);
    }

    return dateString;
  }

  private toDateString(date: Date | string): string {
    // TODO: récupérer l'info depuis i18n
    return new Date(date).toLocaleString('fr-FR').split(' ')[0].split(',')[0];
  }
}
