import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';

export const AdminGuard = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (!authService.isAdminInSync()) {
    router.navigate(['']);
    return false;
  }

  return true;
};
