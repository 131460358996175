import { Component } from '@angular/core';
import { GeoserverVariablesService } from '@widgets/widgets-filter-cql.service';
import { isEmpty } from 'lodash-es';
import { Constraint } from '../../model/constraint.model';
import { RevoltService } from '../../revolt.service';

@Component({
  selector: 'smv-revolt-constraint-filter',
  templateUrl: './revolt-constraint-filter.component.html',
  styleUrls: ['./revolt-constraint-filter.component.scss'],
})
export class RevoltConstraintFilterComponent {
  public constraints: Constraint[] = [];
  public noConstraint = true;

  private readonly filterAttr = 'constraint_code';

  constructor(
    private readonly geoserverVariables: GeoserverVariablesService,
    private readonly revoltService: RevoltService
  ) {
    this.revoltService.getConstraintCodes().subscribe((constraints) => {
      constraints.forEach((constraint) => (constraint.selected = true));
      this.constraints = constraints;
      this.computeFilter();
    });
  }

  private getSelectedJoinValues(): string {
    const values = this.constraints
      .filter((filter) => filter.selected)
      .map((filter) => `${this.filterAttr} LIKE '%25${filter.code}%25'`);
    if (this.noConstraint) {
      values.unshift(`${this.filterAttr} IS NULL`);
    }
    if (isEmpty(values)) {
      return 'true = false';
    }
    return values.join(` or `);
  }

  public computeFilter() {
    const filter = `(${this.getSelectedJoinValues()})`;
    this.geoserverVariables.revoltFilterState.setParticularValue('constraint', filter);
  }
}
