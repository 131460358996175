import { isNil } from 'lodash-es';
import { Feature, Map } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { Extent, getCenter } from 'ol/extent';
import WKT from 'ol/format/WKT';
import { Geometry, Polygon } from 'ol/geom';
import { Projection, toLonLat } from 'ol/proj';

export class MapUtils {
  private static DOTS_PER_INCH = 90.714;
  private static INCHES_PER_METER = 39.37008;

  public static PROJECTION_MAP?: Projection;
  public static PROJECTION_DATABASE_CODE = 'EPSG:2154';
  public static PROJECTION_CODE_OPENLAYERS = 'EPSG:4326';

  static getResolutionFromScale(scale: number, projection: Projection): number {
    return scale / this.computeDPI(projection);
  }

  static getScaleFromResolution(resolution: number, projection: Projection, rounded?: boolean): number {
    const scale = resolution * this.computeDPI(projection);
    return rounded ? Math.round(scale) : scale;
  }

  static saveDefaultMapProjection(map: Map) {
    MapUtils.PROJECTION_MAP = this.getMapProjection(map);
  }

  static getMapProjection(map: Map): Projection {
    return map.getView().getProjection();
  }

  static getMapResolution(map: Map): number | undefined {
    return map.getView().getResolution();
  }

  static getMapScale(map: Map, rounded = false): number {
    const resolution = this.getMapResolution(map);
    if (!resolution) {
      throw new Error('Map resolution is undefined');
    }

    const projection = this.getMapProjection(map);
    return this.getScaleFromResolution(resolution, projection, rounded);
  }

  static getExtentCoordinatesWkt(extent: Extent) {
    const format = new WKT();
    const geometry = new Polygon([MapUtils.getCoordinatesFromExtent(extent)]).transform(
      MapUtils.PROJECTION_MAP,
      MapUtils.PROJECTION_DATABASE_CODE
    );
    return format.writeGeometry(geometry);
  }

  static getGeometryOfWkt(wkt: string): Geometry {
    return new WKT().readGeometry(wkt);
  }

  static getCoordinatesFromExtent(extent: Extent): Coordinate[] {
    const [minx, miny, maxx, maxy] = extent;

    const array = [];
    array.push([minx, miny]);
    array.push([maxx, miny]);
    array.push([maxx, maxy]);
    array.push([minx, maxy]);
    array.push([minx, miny]);

    return array;
  }

  /**
   *
   * @param feature
   * @returns array [$longitude, $latitude] or null
   */
  static convertFeatureToLonLat(feature: Feature) {
    const geom = feature.getGeometry();
    if (geom != undefined) {
      return toLonLat(getCenter(geom.getExtent()));
    }
    return null;
  }

  static getFeatureId(feature: Feature<Geometry>) {
    return Number(String(feature.getId()).split('.')[1]);
  }

  private static computeDPI(projection: Projection): number {
    const metersPerUnit = projection.getMetersPerUnit();
    if (isNil(metersPerUnit)) {
      throw new Error('No unit defined for projection ' + projection.getCode());
    }
    return metersPerUnit * this.INCHES_PER_METER * this.DOTS_PER_INCH;
  }
}
