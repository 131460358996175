import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';

import { ChartComponent } from '@widgets/components/chart/chart.component';
import { CustomerFilterComponent } from '@widgets/components/customer-filter/customer-filter.component';
import { FilterTypeDataComponent } from '@widgets/components/filter-type-data/filter-type-data.component';
import { PanelComponent } from '@widgets/components/panel/panel.component';
import { SheetStatisticComponent } from '@widgets/components/sheet-statistic/sheet-statistic.component';
import { StatisticComponent } from '@widgets/components/statistic/statistic.component';
import { UploadFileComponent } from '../components/upload-file/upload-file.component';
import { IcareFilterObservationsComponent } from './icare-filter/icare-filter-observations/icare-filter-observations.component';
import { IcareFilterGenerationsComponent } from './icare-filter/icare-filter-simulations/icare-filter-generation/icare-filter-generation.component';
import { IcareFilterSimulationsComponent } from './icare-filter/icare-filter-simulations/icare-filter-simulations.component';
import { IcareFilterComponent } from './icare-filter/icare-filter.component';
import { IcareSheetComponent } from './icare-sheet/icare-sheet.component';
import { IcareTrapStatisticsComponent } from './icare-sheet/icare-trap-statistics/icare-trap-statistics.component';
import { IcareComponent } from './icare.component';
import { DateSelectComponent } from './date-select/date-select.component';

@NgModule({
  declarations: [
    IcareComponent,
    IcareFilterComponent,
    IcareFilterObservationsComponent,
    IcareFilterSimulationsComponent,
    IcareFilterGenerationsComponent,
    IcareSheetComponent,
    IcareTrapStatisticsComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatCheckboxModule,
    MatExpansionModule,
    FormsModule,
    PanelComponent,
    FilterTypeDataComponent,
    CustomerFilterComponent,
    StatisticComponent,
    SheetStatisticComponent,
    ChartComponent,
    UploadFileComponent,
    DateSelectComponent,
  ],
  exports: [IcareComponent],
})
export class IcareModule {}
