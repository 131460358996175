import { KeysOfType } from '@core/utils/general.utils';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';

export class ContextItem<T> {
  private readonly subject: BehaviorSubject<T>;
  private readonly initialValue: T;
  private backupValue: T;

  constructor(initialValue: T) {
    this.subject = new BehaviorSubject<T>(initialValue);
    this.initialValue = initialValue;
    this.backupValue = initialValue;
  }

  setValue(newValue: T) {
    this.subject.next(newValue);
  }

  setParticularValue<V extends T[KeysOfType<T, V>]>(field: KeysOfType<T, V>, newValue: V) {
    const value = this.getValue();
    value[field] = newValue;
    this.subject.next(value);
  }

  getValue(): T {
    return cloneDeep(this.subject.value);
  }

  getParticularValue<V extends T[KeysOfType<T, V>]>(field: KeysOfType<T, V>) {
    return this.getValue()[field];
  }

  getBackup(): T {
    return this.backupValue;
  }

  getStream(): Observable<T> {
    return this.subject.asObservable();
  }

  reset(): void {
    this.setValue(this.initialValue);
  }

  saveToBackup(): void {
    this.backupValue = this.getValue();
  }

  resetToBackup(): void {
    this.setValue(this.backupValue);
  }
}
