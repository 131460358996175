import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'smv-anonymous-footer',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './anonymous-footer.component.html',
  styleUrls: ['./anonymous-footer.component.scss'],
})
export class AnonymousFooterComponent {
  public copyrightYear = new Date().getFullYear();
  public smvVersion = environment.version;
}
