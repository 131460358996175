<smv-panel [title]="titleCulture">
  <smv-vigie-virose-culture
    [cultures]="cultures"
    (selectionChanged)="onCultureSelectionChanged()"
    class="vv-obs-cultures"
  />
</smv-panel>
<smv-panel [title]="titleIndicators">
  <smv-vigie-virose-indicators
    [indicators]="indicators"
    (selectionChanged)="onSelectionChanged($event)"
    *ngIf="indicators.insects.length > 0 || indicators.viruses.length > 0"
    class="vv-obs-indicators"
  />
</smv-panel>
