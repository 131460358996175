import { LayerType } from '@core/model/application-api/layer.model';

export interface Server {
  name: string;
  type: LayerType;
  ignoreUrlInCapabiltiesResponse?: boolean;
  url: string;
}

export const servers: {
  [index: string]: Server[];
} = {
  smv: [
    {
      name: 'MAPVIEW™ (WMS)',
      type: LayerType.WMS,
      url: '/services-webapp-syngentamap/proxy-carte/{appId}?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetCapabilities',
    },
    {
      name: 'MAPVIEW™ (WFS)',
      type: LayerType.WFS,
      url: '/services-webapp-syngentamap/proxy-carte/{appId}?SERVICE=WFS&VERSION=1.3.0&REQUEST=GetCapabilities',
    },
    {
      name: 'MAPVIEW™ (WMTS)',
      type: LayerType.WMTS,
      ignoreUrlInCapabiltiesResponse: true,
      url: '/services-webapp-syngentamap/proxy-carte/{appId}/gwc/wmts?service=WMTS&version=1.1.1&request=GetCapabilities',
    },
  ],
  syngenta: [
    {
      name: 'MAPVIEW™ PROD (WMTS)',
      type: LayerType.WMTS,
      ignoreUrlInCapabiltiesResponse: true,
      url: 'https://mapview.syngenta.fr/geoserver/gwc/service/wmts?REQUEST=GetCapabilities',
    },
    {
      name: "Quali'Cible PROD (WMTS)",
      type: LayerType.WMTS,
      ignoreUrlInCapabiltiesResponse: true,
      url: 'https://quali-cible.syngenta.fr/geoserver/gwc/service/wmts?REQUEST=GetCapabilities',
    },
    {
      name: 'MAPVIEW™ PROD (WMS)',
      type: LayerType.WMS,
      url: 'https://mapview.syngenta.fr/geoserver/ows?service=wms&version=1.3.0&request=GetCapabilities',
    },
    {
      name: "Quali'Cible PROD (WMS)",
      type: LayerType.WMS,
      url: 'https://quali-cible.syngenta.fr/geoserver/ows?service=wms&version=1.3.0&request=GetCapabilities',
    },
    {
      name: 'MAPVIEW™ PROD (WFS)',
      type: LayerType.WFS,
      url: 'https://mapview.syngenta.fr/geoserver/ows?service=wfs&version=2.0.0&request=GetCapabilities',
    },
    {
      name: "Quali'Cible PROD (WFS)",
      type: LayerType.WFS,
      url: 'https://quali-cible.syngenta.fr/geoserver/ows?service=wfs&version=2.0.0&request=GetCapabilities',
    },
    {
      name: 'MAPVIEW™ PREPROD (WMTS)',
      type: LayerType.WMTS,
      ignoreUrlInCapabiltiesResponse: true,
      url: 'https://mapview-recette.syngenta.magellium.fr/geoserver/gwc/service/wmts?REQUEST=GetCapabilities',
    },
    {
      name: 'MAPVIEW™ PREPROD (WMS)',
      type: LayerType.WMS,
      url: 'https://mapview-recette.syngenta.magellium.fr/geoserver/ows?service=wms&version=1.3.0&request=GetCapabilities',
    },
    {
      name: 'MAPVIEW™ PREPROD (WFS)',
      type: LayerType.WFS,
      url: 'https://mapview-recette.syngenta.magellium.fr/geoserver/ows?service=wfs&version=2.0.0&request=GetCapabilities',
    },
  ],
  magellium: [
    {
      name: 'mapserver TEST (WMS)',
      type: LayerType.WMS,
      url: 'http://mapserveur.application.developpement-durable.gouv.fr/map/mapserv?map=/opt/data/carto/cartelie/prod/DDT_09/ppr_inter2.www.map&request=getCapabilities&service=wms&version=1.3.0',
    },
    {
      name: 'mapserver (WMS)',
      type: LayerType.WMS,
      url: 'http://mapserveur.application.developpement-durable.gouv.fr/map/mapserv?map=/opt/data/carto/cartelie/prod/DRIEA_IF/IAL.www.map&request=getCapabilities&service=wms&version=1.3.0',
    },
    {
      name: 'magosm (WFS 1.1.0)',
      type: LayerType.WFS,
      url: 'https://magosm.magellium.com/geoserver/ows?service=wfs&version=1.1.0&request=GetCapabilities',
    },
  ],
  magsom: [
    {
      name: 'magosm (WMS)',
      type: LayerType.WMS,
      url: 'https://magosm.magellium.com/geoserver/ows?service=wms&version=1.3.0&request=GetCapabilities',
    },
    {
      name: 'magosm (WFS)',
      type: LayerType.WFS,
      url: 'https://magosm.magellium.com/geoserver/ows?service=wfs&version=2.0.0&request=GetCapabilities',
    },
    {
      name: 'magosm (WMTS)',
      type: LayerType.WMTS,
      url: 'https://magosm.magellium.com/geoserver/gwc/service/wmts?REQUEST=GetCapabilities',
    },
    {
      name: 'ign - Corine Land Cover (WMS)',
      type: LayerType.WMS,
      url: 'https://wxs.ign.fr/corinelandcover/geoportail/r/wms?request=getCapabilities&service=WMS',
    },
    {
      name: 'ign - Parcellaire (WMTS)',
      type: LayerType.WMTS,
      url: 'https://wxs.ign.fr/beta/geoportail/wmts?service=WMTS&request=GetCapabilities',
    },
  ],
  gam: [
    {
      name: 'geoserver OpenData (WMTS)',
      type: LayerType.WMTS,
      ignoreUrlInCapabiltiesResponse: true,
      url: 'https://geoflux.lametro.fr/geoserver-opendata/gwc/service/wmts?REQUEST=GetCapabilities',
    },
    {
      name: 'geoserver OpenData (WMS)',
      type: LayerType.WMS,
      url: 'https://geoflux.lametro.fr/geoserver-opendata/ows?service=wms&version=1.3.0&request=GetCapabilities',
    },
    {
      name: 'geoserver OpenData (WFS)',
      type: LayerType.WFS,
      url: 'https://geoflux.lametro.fr/geoserver-opendata/ows?service=wfs&version=2.0.0&request=GetCapabilities',
    },
    {
      name: 'geoflux (WMTS)',
      type: LayerType.WMTS,
      ignoreUrlInCapabiltiesResponse: true,
      url: 'https://geoflux.lametro.fr/geoserver/gwc/service/wmts?REQUEST=GetCapabilities',
    },
    {
      name: 'geoflux (WMS)',
      type: LayerType.WMS,
      url: 'https://geoflux.lametro.fr/geoserver/ows?service=wms&version=1.3.0&request=GetCapabilities',
    },
    {
      name: 'geoflux (WFS)',
      type: LayerType.WFS,
      url: 'https://geoflux.lametro.fr/geoserver/ows?service=wfs&version=2.0.0&request=GetCapabilities',
    },
  ],
};
