import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LayerType } from '@core/model/application-api/layer.model';
import { ParsedCapabilities, ParsedLayer } from '@core/model/capabilities.model';
import { Indexable } from '@core/utils/general.utils';
import { WFSCapabilitiesParser, WfsLayerSchema } from '@core/utils/parsers/wfs-capabilities-parser';
import { WMSCapabilitiesParser, WmsLayerSchema } from '@core/utils/parsers/wms-capabilities-parser';
import { WMTSCapabilitiesParser, WmtsLayerSchema } from '@core/utils/parsers/wmts-capabilities-parser';
import { Observable, catchError, map, of, tap, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CapabilitiesParserService {
  private layerCache: Indexable<ParsedLayer[]> = {};
  private capabilitiesCache: Indexable<{ raw: string; parsed?: ParsedCapabilities }> = {};
  private wmtsParser = new WMTSCapabilitiesParser();
  private wmsParser = new WMSCapabilitiesParser();
  private wfsParser = new WFSCapabilitiesParser();

  constructor(private http: HttpClient) {}

  parseWmtsCapabilities(url: string): Observable<ParsedLayer[]> {
    return this.parseCapabilitiesLayers(url, LayerType.WMTS, (capabilities) =>
      capabilities.Contents.Layer.map((layer: WmtsLayerSchema): ParsedLayer => this.wmtsParser.parseLayer(layer))
    );
  }

  parseWmsCapabilities(url: string): Observable<ParsedLayer[]> {
    return this.parseCapabilitiesLayers(url, LayerType.WMS, (capabilities) =>
      capabilities.Capability.Layer.Layer.map((layer: WmsLayerSchema): ParsedLayer => this.wmsParser.parseLayer(layer))
    );
  }

  parseWfsCapabilities(url: string, preferredProjection: string): Observable<ParsedLayer[]> {
    return this.parseCapabilitiesLayers(url, LayerType.WFS, (capabilities) =>
      capabilities.WFS_Capabilities.FeatureTypeList.FeatureType.map(
        (layer: WfsLayerSchema): ParsedLayer => this.wfsParser.parseLayer(layer, preferredProjection)
      )
    );
  }

  getCapabilities(url: string, type: LayerType): Observable<ParsedCapabilities> {
    const cachedCapabilities = this.capabilitiesCache[url];
    if (cachedCapabilities?.parsed) {
      return of(cachedCapabilities.parsed);
    }

    const headers = new HttpHeaders().append('Accept', 'text/plain');
    const rawCapabilities$ = cachedCapabilities?.raw
      ? of(cachedCapabilities?.raw)
      : this.http.get(url, { responseType: 'text', headers: headers });

    return rawCapabilities$.pipe(
      map((response) => {
        this.capabilitiesCache[url] = { raw: response, parsed: undefined };
        let parser: WMTSCapabilitiesParser | WMSCapabilitiesParser | WFSCapabilitiesParser;
        switch (type) {
          case LayerType.WMTS:
            parser = this.wmtsParser;
            break;
          case LayerType.WMS:
            parser = this.wmsParser;
            break;
          case LayerType.WFS:
            parser = new WFSCapabilitiesParser();
            break;
          default:
            throw new Error('Unmanaged layer type: ' + type);
        }
        const parsedContent = parser.read(response);
        this.capabilitiesCache[url].parsed = parsedContent;
        return parsedContent;
      })
    );
  }

  private parseCapabilitiesLayers(
    url: string,
    type: LayerType,
    parseFct: (capabilities: ParsedCapabilities) => ParsedLayer[]
  ): Observable<ParsedLayer[]> {
    return (
      this.layersFromCache(url) ??
      this.getCapabilities(url, type).pipe(
        map((parsedCapabilities) => parseFct(parsedCapabilities)),
        tap((layers) => (this.layerCache[url] = layers)),
        catchError((error) => this.handleCacheParsingError(url, error))
      )
    );
  }

  private layersFromCache(url: string): Observable<ParsedLayer[]> | undefined {
    const layers = this.layerCache[url];
    return layers ? of(layers) : undefined;
  }

  /**
   * Preserves the raw data but removes the parsed content.
   *
   * @param url Cache entry
   * @param error Raised error
   * @returns throwError returning the `error` parameter
   */
  private handleCacheParsingError(url: string, error: unknown): Observable<never> {
    if (this.capabilitiesCache[url]) {
      this.capabilitiesCache[url].parsed = undefined;
    }
    return throwError(() => error);
  }
}
