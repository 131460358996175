<mat-form-field>
  <mat-label i18n>Source des données</mat-label>
  <mat-select [value]="selectedSource" (selectionChange)="updateSource($event.value)">
    <mat-option [value]="LayerSource.EXTERNAL_WMTS" i18n>Flux WMTS</mat-option>
    <mat-option [value]="LayerSource.EXTERNAL_WMS" i18n>Flux WMS</mat-option>
    <mat-option [value]="LayerSource.EXTERNAL_WFS" i18n>Flux WFS</mat-option>
    <mat-option [value]="LayerSource.GEOJSON" i18n>Fichier GeoJSON</mat-option>
  </mat-select>
</mat-form-field>

<form *ngIf="selectedSource === LayerSource.GEOJSON; else streamEditor" [formGroup]="geojsonForm">
  <mat-form-field>
    <mat-label i18n>Nom de la couche</mat-label>
    <input matInput formControlName="layerName" />
    <mat-error>
      <smv-form-field-errors [field]="geojsonForm.controls.layerName" />
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="selectedSource === LayerSource.GEOJSON; else streamEditor">
    <mat-label i18n>Lien vers le fichier GeoJSON</mat-label>
    <input matInput formControlName="url" />
    <mat-error>
      <smv-form-field-errors [field]="geojsonForm.controls.url" />
    </mat-error>
  </mat-form-field>
</form>

<div class="smv-sticky-bottom-actions">
  <button mat-stroked-button i18n="Button|Layer manager action" (click)="closePanel.emit()">Annuler</button>
  <button
    mat-flat-button
    color="primary"
    i18n="Button|Layer manager action"
    [disabled]="activeForm.invalid"
    [ngClass]="{ btnSpinner: creating }"
    (click)="createLayer()"
  >
    Ajouter la couche
  </button>
</div>

<ng-template #streamEditor>
  <mat-slide-toggle [checked]="useSmvServer" (change)="toggleSmvServer($event.checked)" i18n>
    Utiliser les flux de données de MAPVIEW™
  </mat-slide-toggle>

  <form [formGroup]="streamForm">
    <div class="server-url-field">
      <mat-form-field *ngIf="!useSmvServer">
        <mat-label i18n>URL du serveur</mat-label>
        <input matInput formControlName="url" />
        <mat-error>
          <smv-form-field-errors [field]="streamForm.controls.url" />
        </mat-error>
        <mat-hint i18n>
          Veuillez indiquer l'URL complète vers les <strong>« Capabilities »</strong> du service à utiliser
        </mat-hint>
      </mat-form-field>
      <mat-form-field *ngIf="useSmvServer">
        <mat-label i18n>URL du serveur</mat-label>
        <mat-select formControlName="url">
          <mat-select-trigger>
            {{ streamForm.controls['url'].value }}
          </mat-select-trigger>
          <mat-option *ngFor="let server of servers" [value]="server.url">
            {{ server.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button
        mat-mini-fab
        color="primary"
        matTooltip="Rechercher les couches disponibles pour ce serveur"
        i18n-matTooltip
        (click)="searchAvailableLayers()"
        [ngClass]="{ btnSpinner: loadingLayers }"
        [disabled]="streamForm.controls.url.invalid"
      >
        <mat-icon>manage_search</mat-icon>
      </button>
    </div>

    <ng-container *ngIf="detectedLayers.length">
      <div>
        <mat-form-field>
          <mat-label i18n>Couche à ajouter</mat-label>
          <input
            matInput
            [matAutocomplete]="layerAutocomplete"
            formControlName="layer"
            placeholder="Rechercher une couche"
            i18n-placeholder
          />
          <mat-autocomplete #layerAutocomplete="matAutocomplete">
            <mat-option *ngFor="let layer of filteredLayers" [value]="layer.title" (click)="selectLayer(layer)">
              <div class="layer-option">
                <span class="layer-title">
                  {{ layer.title }}
                  <span>({{ layer.serverIdentifier }})</span>
                </span>
                <span class="layer-detail">{{ layer.detail }}</span>
              </div>
            </mat-option>
          </mat-autocomplete>
          <mat-error>
            <smv-form-field-errors [field]="streamForm.controls.layer" [messages]="customErrors" />
          </mat-error>
        </mat-form-field>
      </div>

      <mat-form-field *ngIf="layerStyles.length">
        <mat-label i18n>Style de la couche</mat-label>
        <mat-select formControlName="style">
          <mat-option *ngFor="let style of layerStyles" [value]="style.name">{{ style.title }}</mat-option>
        </mat-select>
        <mat-error>
          <smv-form-field-errors [field]="streamForm.controls.style" />
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Nom de la couche dans SMV</mat-label>
        <input matInput formControlName="layerName" />
        <mat-error>
          <smv-form-field-errors [field]="streamForm.controls.layerName" />
        </mat-error>
      </mat-form-field>

      <mat-slide-toggle *ngIf="selectedSource === LayerSource.EXTERNAL_WFS" [formControl]="isHeatMap" i18n>
        Définir comme une carte de chaleur
      </mat-slide-toggle>
    </ng-container>
  </form>
</ng-template>
