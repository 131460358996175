<div class="dms-zoom-container">
  <mat-radio-group [(ngModel)]="format" class="flex-col unselectable">
    <mat-radio-button value="DD">
      <strong i18n="Coordinate Zoom|Decimal degrees coordinates">Degrés Décimaux</strong>
    </mat-radio-button>
    <mat-radio-button value="DMS">
      <strong i18n="Coordinate Zoom|Degrees, minutes, seconds coordinates">Degrés Minutes Secondes</strong>
    </mat-radio-button>
  </mat-radio-group>

  <div [ngSwitch]="format">
    <div *ngSwitchCase="'DD'">
      <form [formGroup]="formDD" class="smv-form dd-zoom-form">
        <smv-form-field-wrapper type="number" formControlName="latitude" subscriptSizing="dynamic" hasHint>
          <span mat-label i18n="Label|Latitude">Latitude</span>
          <span mat-hint i18n="Hint|Latitude" [ngClass]="{ warning: ddLatInvalid }">-90° <= latitude <= 90°</span>
        </smv-form-field-wrapper>
        <smv-form-field-wrapper type="number" formControlName="longitude" subscriptSizing="dynamic" hasHint>
          <span mat-label i18n="Label|Longitude">Longitude</span>
          <span mat-hint i18n="Hint|Longitude" [ngClass]="{ warning: ddLongInvalid }">-180° <= longitude <= 180°</span>
        </smv-form-field-wrapper>
      </form>
    </div>
    <div *ngSwitchCase="'DMS'">
      <form [formGroup]="formDMS" class="smv-form dms-zoom-form">
        <smv-form-field-wrapper
          [placeholder]="latitudePlaceholder"
          formControlName="latitude"
          subscriptSizing="dynamic"
          hasHint
        >
          <span mat-label i18n="Label|Latitude">Latitude</span>
          <span mat-hint i18n="Hint|Latitude" [ngClass]="{ warning: dmsLatInvalid }">-90° <= latitude <= 90°</span>
        </smv-form-field-wrapper>
        <smv-form-field-wrapper
          [placeholder]="longitudePlaceholder"
          formControlName="longitude"
          subscriptSizing="dynamic"
          hasHint
        >
          <span mat-label i18n="Label|Longitude">Longitude</span>
          <span mat-hint i18n="Hint|Longitude" [ngClass]="{ warning: dmsLongInvalid }">-180° <= longitude <= 180°</span>
        </smv-form-field-wrapper>
      </form>
    </div>
  </div>

  <div class="flex-col gap">
    <button
      mat-raised-button
      [disabled]="checkDisabled()"
      (click)="zoomToLocation()"
      i18n="Coordinate Zoom|Zoom to coordinates"
    >
      Aller aux coordonnées
    </button>
    <button mat-raised-button *ngIf="markerVisible" (click)="hideMarker()" i18n="Coordinate Zoom|Hide marker">
      Enlever le marqueur
    </button>
  </div>
</div>
