import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotifyCustomerComponent, NotifyCustomerConfig } from './notify-customer/notify-customer.component';

@Component({
  selector: 'smv-samples-admin',
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  templateUrl: './samples-admin.component.html',
  styleUrls: ['./samples-admin.component.scss'],
})
export class SamplesAdminComponent implements OnChanges {
  @Input() appId!: number;
  @Input() availableDates: string[] = [];
  @Input() isLoading = false;

  @Output() customersToNotify = new EventEmitter<NotifyCustomerConfig>();
  @Output() useSendDate = new EventEmitter<boolean>();

  private dialog?: MatDialogRef<NotifyCustomerComponent>;

  constructor(private dialogService: MatDialog) {}

  ngOnChanges(): void {
    this.dialog?.componentInstance?.updateDates(this.availableDates);
  }

  selectCustomersToNotify() {
    this.dialog = this.dialogService.open(NotifyCustomerComponent, { data: this.availableDates });
    this.dialog.componentInstance.useSendDate.subscribe((value) => this.useSendDate.emit(value));
    this.dialog.afterClosed().subscribe((notifyConfig?: NotifyCustomerConfig) => {
      if (notifyConfig?.customers?.length) {
        this.customersToNotify.emit(notifyConfig);
      }
    });
  }
}
