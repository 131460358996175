import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';

import { CustomerFilterComponent } from '@widgets/components/customer-filter/customer-filter.component';
import { PanelComponent } from '@widgets/components/panel/panel.component';
import { ZoomPositionComponent } from '../components/zoom-position/zoom-position.component';
import { InterraScanComponent } from './interra-scan.component';

@NgModule({
  declarations: [InterraScanComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatCheckboxModule,
    FormsModule,
    PanelComponent,
    CustomerFilterComponent,
    ZoomPositionComponent,
  ],
  exports: [InterraScanComponent],
})
export class InterraScanModule {}
