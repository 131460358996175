<div [hidden]="panelHide">
  <mat-card class="side-panel">
    <mat-card-title>
      <div>
        <span i18n>VigieVirose Administration</span>
        <button mat-icon-button (click)="displayed.emit()">
          <mat-icon>keyboard_double_arrow_right</mat-icon>
        </button>
      </div>
    </mat-card-title>

    <mat-card-content>
      <div class="buttons-recap">
        <button mat-raised-button *ngIf="canEditTrap" color="primary" (click)="openTrapStats()">
          <span i18n="VigieVirose|Creation Trap Summary">Récapitulatif des pièges</span>
        </button>
        <button mat-raised-button color="primary" (click)="openDatumStats()">
          <span i18n="VigieVirose|Creation Datum Summary">Récapitulatif des relevés</span>
        </button>
      </div>

      <smv-panel title="Administration relevés" *ngIf="canImportDatum">
        <smv-upload-file [url]="'/vigieVirose/datum/' + this.applicationId" accept=".csv,.xlsx" />

        <smv-samples-admin
          [appId]="applicationId"
          [availableDates]="availableDates"
          [isLoading]="loading"
          (customersToNotify)="notifyCustomers($event)"
          (useSendDate)="updateUseSendDate($event)"
        />
      </smv-panel>
    </mat-card-content>
  </mat-card>
</div>
