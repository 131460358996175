import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GeometryType, LayerEditType } from '@core/model/application-api/layer.model';
import { EditionService, EditionToolType } from '@core/services/edition.service';
import { NotificationService } from '@core/services/notification.service';
import { SelectionService } from '@core/services/selection.service';
import { OlGmapModule } from '@syngenta/ol-gmap';
import { Feature } from 'ol';
import VectorSource from 'ol/source/Vector';
import { StyleLike } from 'ol/style/Style';
import { Subscription } from 'rxjs';
import { DataLayerConfig } from '../data-layer-config.model';

@Component({
  selector: 'smv-geometry-edition',
  standalone: true,
  imports: [CommonModule, OlGmapModule, MatTooltipModule],
  templateUrl: './geometry-edition.component.html',
  styleUrls: ['./geometry-edition.component.scss'],
})
export class GeometryEditionComponent implements OnChanges, OnDestroy {
  @Input() layer?: DataLayerConfig;
  @Input() canOnlyModify = true;

  public GeometryType = GeometryType;
  public EditionToolType = EditionToolType;
  public activeTool?: EditionToolType;
  public geometry?: GeometryType;
  public onlyModify = true;

  public selectedFeature?: Feature;
  public copiedFeature?: Feature;
  private featureStyle?: StyleLike;

  private subscriptions = new Subscription();

  constructor(
    private editionService: EditionService,
    private selectionService: SelectionService,
    private notificationService: NotificationService
  ) {
    this.subscriptions.add(this.editionService.activeTool.getStream().subscribe((tool) => (this.activeTool = tool)));
    this.subscriptions.add(
      this.selectionService.selectedFeatures.getStream().subscribe((selections) => {
        if (selections?.[0].layer === this.layer) {
          this.selectedFeature = selections?.[0].features[0];
        }
      })
    );
  }

  ngOnChanges() {
    if (this.layer) {
      this.editionService.configureEdition(this.layer);
      this.geometry = this.computeGeometryType();
      this.onlyModify = this.computeEditionType();
      this.copiedFeature = undefined;
      this.featureStyle = (this.layer?.olLayer?.getSource() as VectorSource).getFeatures()[0]?.getStyle();
    }
  }

  ngOnDestroy() {
    this.editionService.resetEdition();
    this.subscriptions.unsubscribe();
  }

  private computeGeometryType() {
    const type = this.layer?.config.geometryType;
    if (type) {
      return type;
    } else {
      const geomType = this.editionService.getFeatureType()?.replaceAll('Multi', '');

      switch (geomType) {
        case 'Polygon':
          return GeometryType.POLYGON;
        case 'LineString':
          return GeometryType.LINE;
        case 'Point':
          return GeometryType.POINT;
        case 'GeometryCollection':
          return GeometryType.COLLECTION;
        default:
          break;
      }
    }
    return undefined;
  }

  private computeEditionType() {
    return this.canOnlyModify || this.layer?.config.editType === LayerEditType.MODIFY;
  }

  toggle(tool: EditionToolType) {
    const active = this.activeTool;
    this.editionService.stopEdition();
    if (tool != active) {
      this.editionService.activeEdition(tool);
    }
  }

  copyFeature() {
    if (this.selectedFeature) {
      this.copiedFeature = this.selectedFeature.clone();
      this.copiedFeature.setStyle(this.featureStyle);
      this.notificationService.success($localize`:Geometry Edition|Copy:L'entité a bien été copiée.`);
    }
  }

  pasteFeature() {
    if (this.copiedFeature && this.layer) {
      const feature = this.copiedFeature.clone();
      this.editionService.updateFeature(feature, feature, this.layer, 'insert');
      (this.layer?.olLayer?.getSource() as VectorSource).addFeature(feature);
      this.selectionService.addVectorFeatureToSelection(feature, this.layer);
    }
  }
}
