import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { ParameterId, WidgetType } from '@core/model/application-api/widget.model';
import { AuthService } from '@core/services/auth.service';
import { DateUtils } from '@core/utils/date.utils';
import { WidgetService } from '@widgets/widgets.service';
import { Periodicity } from '../../timeline/timeline.model';
import { Cultivation, CultivationGroup } from '../model/cultivation.model';
import { CultivationService } from '../services/cultivation.service';
import { VigieViroseService } from '../services/vigie-virose.service';

export enum VigieViroseDisplayFilter {
  FILTER_OBSERVATION = 'FILTER_OBSERVATION',
  FILTER_SIMULATION = 'FILTER_SIMULATION',
}

@Component({
  selector: 'smv-vigie-virose-filter',
  templateUrl: './vigie-virose-filter.component.html',
  styleUrls: ['./vigie-virose-filter.component.scss'],
})
export class VigieViroseFilterComponent {
  public authorizedSimulation = false;
  public cultures: CultivationGroup[] = [];

  public filter = this.vigieViroseService.filterState.getValue().displayFilter;

  constructor(
    private authService: AuthService,
    private cultivationService: CultivationService,
    private vigieViroseService: VigieViroseService,
    private widgetService: WidgetService
  ) {
    this.authorizedSimulation = this.authService.isAuthorizedSimulationVVInSync();
    this.cultivationService.getAll().subscribe((listCultivations: Cultivation[]) => {
      if (listCultivations) {
        for (const cultivation of listCultivations) {
          const cultivationGroup = this.cultures.find((culture) => culture.name == cultivation.familly);
          if (cultivationGroup) {
            cultivationGroup.cultivations.push(cultivation);
          } else {
            const newCultivationGroup = {
              name: cultivation.familly,
              cultivations: [cultivation],
            };
            this.cultures.push(newCultivationGroup);
          }
        }
      }
    });
  }

  onFilterChange() {
    this.vigieViroseService.filterState.setParticularValue('displayFilter', this.filter);
  }

  setTimelineForSimulation() {
    const currentValue = this.widgetService.getWidgetContextItem(WidgetType.TIMELINE).getValue();
    const parameters = currentValue?.parameters;

    const periodicity = parameters?.find((parameter) => parameter.id === ParameterId.PERIODICITY);
    const startDate = parameters?.find((parameter) => parameter.id === ParameterId.START_DATE);
    const endDate = parameters?.find((parameter) => parameter.id === ParameterId.END_DATE);
    if (periodicity) {
      periodicity.value = Periodicity.DAYS;
    }
    if (startDate) {
      startDate.value = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    }
    if (endDate) {
      endDate.value = formatDate(DateUtils.addDays(new Date(), 7), 'yyyy-MM-dd', 'en');
    }
    this.widgetService.getWidgetContextItem(WidgetType.TIMELINE).setValue(currentValue);
  }
}
