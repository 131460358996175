import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent, ConfirmDialogConfig } from './confirm-dialog/confirm-dialog.component';
import { IframeDialogComponent, IframeDialogConfig } from './iframe-dialog/iframe-dialog.component';
import { InfoDialogComponent, InfoDialogConfig } from './info-dialog/info-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private defaultQuestion = [
    $localize`:Confirm dialog:<strong>Attention, cette action est irréversible.</strong>`,
    $localize`:Confirm dialog:Souhaitez-vous continuer ?`,
  ];

  constructor(private dialog: MatDialog) {}

  openConfirm(config: ConfirmDialogConfig): Observable<boolean> {
    return this.dialog.open(ConfirmDialogComponent, { data: config }).afterClosed();
  }

  openInfo(config: InfoDialogConfig): Observable<boolean> {
    return this.dialog.open(InfoDialogComponent, { data: config }).afterClosed();
  }

  openIframe(config: IframeDialogConfig): Observable<boolean> {
    return this.dialog.open(IframeDialogComponent, { data: config }).afterClosed();
  }

  openUserDeleteConfirm(accounts?: string[]): Observable<boolean> {
    let confirmMessage = $localize`Vous vous apprêtez à supprimer votre compte.`;
    if (accounts) {
      if (accounts.length === 1) {
        const email = accounts[0];
        confirmMessage = $localize`Vous vous apprêtez à supprimer le compte <strong>${email}</strong>.`;
      } else {
        const emails = accounts.join(', ');
        confirmMessage = $localize`Vous vous apprêtez à supprimer les comptes <strong>${emails}</strong>.`;
      }
    }
    const config: ConfirmDialogConfig = {
      isDanger: true,
      title: $localize`Suppression de compte`,
      content: [confirmMessage, ...this.defaultQuestion],
    };
    return this.openConfirm(config);
  }

  openFarmerDeleteConfirm(email: string): Observable<boolean> {
    const confirmMessage = $localize`Vous vous apprêtez à supprimer l'agriculteur <strong>${email}</strong>.`;
    const config: ConfirmDialogConfig = {
      isDanger: true,
      title: $localize`Suppression d'agriculteur`,
      content: [confirmMessage, ...this.defaultQuestion],
    };
    return this.openConfirm(config);
  }

  openParcelDeleteConfirm(parcelIds: string[]): Observable<boolean> {
    let confirmMessage = $localize`Vous vous apprêtez à supprimer l'attribution de la parcelle <strong>${parcelIds[0]}</strong>.`;
    if (parcelIds.length > 1) {
      const parcels = parcelIds.join(', ');
      confirmMessage = $localize`Vous vous apprêtez à supprimer l'attribution des parcelles <strong>${parcels}</strong>.`;
    }
    const config: ConfirmDialogConfig = {
      isDanger: true,
      title: $localize`Suppression de l'attribution de parcelle`,
      content: [confirmMessage, ...this.defaultQuestion],
    };
    return this.openConfirm(config);
  }

  openApplicationDeleteConfirm(applications: string[]): Observable<boolean> {
    let confirmMessage;
    if (applications.length === 1) {
      const name = applications[0];
      confirmMessage = $localize`Vous vous apprêtez à supprimer l'application <strong>${name}</strong>.`;
    } else {
      const names = applications.join(', ');
      confirmMessage = $localize`Vous vous apprêtez à supprimer les applications <strong>${names}</strong>.`;
    }
    const config: ConfirmDialogConfig = {
      isDanger: true,
      title: $localize`Suppression d'application`,
      content: [confirmMessage, ...this.defaultQuestion],
    };
    return this.openConfirm(config);
  }

  openRightDeleteConfirm(rights: string[]): Observable<boolean> {
    let confirmMessage;
    if (rights.length === 1) {
      const email = rights[0];
      confirmMessage = $localize`Vous vous apprêtez à supprimer le droit d'accès du compte <strong>${email}</strong>.`;
    } else {
      const emails = rights.join(', ');
      confirmMessage = $localize`Vous vous apprêtez à supprimer les droits d'accès des comptes <strong>${emails}</strong>.`;
    }
    const config: ConfirmDialogConfig = {
      isDanger: true,
      title: $localize`Suppression de droit d'accès`,
      content: [confirmMessage, ...this.defaultQuestion],
    };
    return this.openConfirm(config);
  }

  openCustomerDeleteConfirm(customers: string[]) {
    let confirmMessage;
    if (customers.length === 1) {
      const name = customers[0];
      confirmMessage = $localize`Vous vous apprêtez à supprimer le client <strong>${name}</strong>.`;
    } else {
      const names = customers.join(', ');
      confirmMessage = $localize`Vous vous apprêtez à supprimer les clients <strong>${names}</strong>.`;
    }
    const config: ConfirmDialogConfig = {
      isDanger: true,
      title: $localize`Suppression d'un client`,
      content: [confirmMessage, ...this.defaultQuestion],
    };
    return this.openConfirm(config);
  }

  openGroupDeleteConfirm(groups: string[]) {
    let confirmMessage;
    if (groups.length === 1) {
      const name = groups[0];
      confirmMessage = $localize`Vous vous apprêtez à supprimer le groupe <strong>${name}</strong>.`;
    } else {
      const names = groups.join(', ');
      confirmMessage = $localize`Vous vous apprêtez à supprimer les groupes <strong>${names}</strong>.`;
    }
    const config: ConfirmDialogConfig = {
      isDanger: true,
      title: $localize`Suppression d'un groupe`,
      content: [confirmMessage, ...this.defaultQuestion],
    };
    return this.openConfirm(config);
  }
}
