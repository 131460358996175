import { Component, Input, OnChanges } from '@angular/core';
import { CellType, Column, DefaultType, GeneralActions } from '@components/table/table.model';
import { TrapStatement } from '@widgets/vigie-virose/model/trap.model';

@Component({
  selector: 'smv-vigie-virose-trap-history',
  templateUrl: './vigie-virose-trap-history.component.html',
  styleUrls: ['./vigie-virose-trap-history.component.scss'],
})
export class VigieViroseTrapHistoryComponent implements OnChanges {
  public title = $localize`Historique`;

  @Input() statements?: TrapStatement[];

  public actionsConfig: GeneralActions<TrapStatement> = {
    tableOnly: true,
    stickyDisabled: true,
    checkboxDisabled: true,
  };
  public columns: Column<TrapStatement, DefaultType<number>>[] = [];

  ngOnChanges() {
    if (this.statements) {
      this.initColumns();
    }
  }

  initColumns() {
    this.columns = [
      {
        field: 'mondayDate',
        label: $localize`:Table header:Semaine`,
        type: CellType.String,
        cellData: 'mondayDate',
      },
      {
        field: 'samplingDate',
        label: $localize`:Table header:Date de relevé`,
        type: CellType.String,
        cellData: 'samplingDate',
      },
      {
        field: 'etat',
        label: $localize`:Table header:Etat`,
        type: CellType.String,
        cellData: 'etat',
      },
      {
        field: 'puceron',
        label: $localize`:Table header:Puceron`,
        type: CellType.String,
        cellData: 'puceron',
      },
      {
        field: 'cicadelle',
        label: $localize`:Table header:Cicadelle`,
        type: CellType.String,
        cellData: 'cicadelle',
      },
      {
        field: 'tenthrede',
        label: $localize`:Table header:Tenthrède`,
        type: CellType.String,
        cellData: 'tenthrede',
        hide: this.isCereal(),
      },
      {
        field: 'altise',
        label: $localize`:Table header:Altise`,
        type: CellType.String,
        cellData: 'altise',
        hide: this.isCereal(),
      },
      {
        field: 'bwyvHuman',
        label: $localize`:Table header:bwyv`,
        type: CellType.String,
        cellData: 'bwyvHuman',
        hide: this.isCereal(),
      },
      {
        field: 'camvHuman',
        label: $localize`:Table header:camv`,
        type: CellType.String,
        cellData: 'camvHuman',
        hide: this.isCereal(),
      },
      {
        field: 'tumvHuman',
        label: $localize`:Table header:tumv`,
        type: CellType.String,
        cellData: 'tumvHuman',
        hide: this.isCereal(),
      },
      {
        field: 'wdvHuman',
        label: $localize`:Table header:wdv`,
        type: CellType.String,
        cellData: 'wdvHuman',
        hide: this.isColza(),
      },
      {
        field: 'pavHuman',
        label: $localize`:Table header:pav`,
        type: CellType.String,
        cellData: 'pavHuman',
        hide: this.isColza(),
      },
      {
        field: 'mavHuman',
        label: $localize`:Table header:mav`,
        type: CellType.String,
        cellData: 'mavHuman',
        hide: this.isColza(),
      },
      {
        field: 'rpvHuman',
        label: $localize`:Table header:rpv`,
        type: CellType.String,
        cellData: 'rpvHuman',
        hide: this.isColza(),
      },
    ];
  }

  get data() {
    if (this.statements) {
      return this.statements;
    }
    return [];
  }

  isCereal(): boolean {
    if (this.statements) {
      return ['BLE', 'ORG'].includes(this.statements[0].trap.cultivationCode);
    }
    return false;
  }

  isColza(): boolean {
    if (this.statements) {
      return ['COL'].includes(this.statements[0].trap.cultivationCode);
    }
    return false;
  }
}
