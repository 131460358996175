<smv-panel title="Insectes" i18n-title *ngIf="indicators && indicators.insects.length > 0">
  <mat-checkbox
    *ngFor="let insect of indicators.insects"
    [(ngModel)]="insect.selected"
    (change)="onInsectSelectionChange(insect.code)"
  >
    <img class="indicator-img" [src]="'assets/widgets/vigieVirose/' + insect.code + 'RVB.png'" />
    {{ insect.name }}
  </mat-checkbox>
</smv-panel>
<smv-panel title="Virus" i18n-title *ngIf="indicators && indicators.viruses.length > 0">
  <mat-checkbox
    *ngFor="let virus of indicators.viruses"
    [(ngModel)]="virus.selected"
    [disabled]="virusState(virus.code)"
    (change)="onSelectionChanged()"
  >
    <img class="indicator-img" src="assets/widgets/vigieVirose/maladie.png" />
    {{ virus.name }}
  </mat-checkbox>
</smv-panel>
