<ng-container *ngIf="layerConfig.dynamicStyle?.categories && !categorie">
  <div class="legend">
    <div *ngFor="let rule of layerConfig.dynamicStyle?.categories" class="legend-line">
      <smv-vector-legend [layerConfig]="layerConfig" [categorie]="rule" />
      <span>{{ getLabel(rule) }}</span>
    </div>
  </div>
</ng-container>
<div *ngIf="!layerConfig.dynamicStyle || categorie">
  <canvas #chartLegend></canvas>
</div>
