import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';

import { PanelComponent } from '@widgets/components/panel/panel.component';
import { RevoltConstraintFilterComponent } from './revolt-filter/revolt-constraint-filter/revolt-constraint-filter.component';
import { RevoltFilterComponent } from './revolt-filter/revolt-filter.component';
import { RevoltRecommendationFilterComponent } from './revolt-filter/revolt-recommendation-filter/revolt-recommendation-filter.component';
import { RevoltSoilTypeFilterComponent } from './revolt-filter/revolt-soil-type-filter/revolt-soil-type-filter.component';
import { RevoltComponent } from './revolt.component';

@NgModule({
  declarations: [
    RevoltComponent,
    RevoltFilterComponent,
    RevoltConstraintFilterComponent,
    RevoltRecommendationFilterComponent,
    RevoltSoilTypeFilterComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatRadioModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    PanelComponent,
  ],
  exports: [RevoltComponent],
})
export class RevoltModule {}
