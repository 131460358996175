import { Component } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { IcareService } from '../icare.service';

@Component({
  selector: 'smv-icare-filter',
  templateUrl: './icare-filter.component.html',
  styleUrls: ['./icare-filter.component.scss'],
})
export class IcareFilterComponent {
  public filter = this.icareService.filterState.getValue().displayFilter;
  public authorizedSimulation = true;

  constructor(private icareService: IcareService, private authService: AuthService) {
    this.authorizedSimulation = this.authService.isAuthorizedSimulationIcareInSync();
    if (!this.authorizedSimulation) {
      this.icareService.filterState.setParticularValue('displayFilter', 'FILTER_OBSERVATION');
    }
  }

  onFilterChange() {
    this.icareService.filterState.setParticularValue('displayFilter', this.filter);
  }
}
