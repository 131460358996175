import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Loader } from '@components/loading/loading.component';
import { XlsxSheet, exportToXlsx, sortHeader, splitToCells } from '@core/utils/xlsx.utils';
import { FeatureCollection } from 'geojson';
import { Observable, finalize } from 'rxjs';

interface Town {
  nom_com: string;
  nom_dept: string;
  insee_com: string;
}

@Component({
  selector: 'smv-town-list',
  templateUrl: './town-list.component.html',
  styleUrls: ['./town-list.component.scss'],
})
export class TownListComponent implements OnInit, AfterViewInit {
  public loader: Loader = { condition: true, text: $localize`Recherche des communes de l'AAC, veuillez patienter` };
  public towns: MatTableDataSource<Town> = new MatTableDataSource<Town>([]);
  public columns = ['nom_com', 'insee_com', 'nom_dept'] as const;
  public columnLabels = {
    nom_com: $localize`Commune`,
    nom_dept: $localize`Département`,
    insee_com: $localize`Code INSEE`,
  };

  @ViewChild(MatSort) sort?: MatSort;

  constructor(@Inject(MAT_DIALOG_DATA) private aacRequest$: Observable<FeatureCollection>) {}

  ngOnInit(): void {
    this.aacRequest$.pipe(finalize(() => (this.loader.condition = false))).subscribe((featureCollection) => {
      this.towns.data = featureCollection.features.map((feature) => {
        const properties = feature.properties ?? {};
        return {
          nom_com: properties['nom_com'],
          nom_dept: properties['nom_dept'],
          insee_com: properties['insee_com'],
        };
      });
    });
  }

  ngAfterViewInit(): void {
    if (this.sort) {
      this.towns.sort = this.sort;
    }
  }

  exportTowns(): void {
    const sheet: XlsxSheet = {
      name: $localize`Communes`,
      headerRow: sortHeader(this.columnLabels, this.columns),
      dataRows: splitToCells(this.towns.data, this.columns),
    };
    exportToXlsx($localize`export_communes`, [sheet]);
  }
}
