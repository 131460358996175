import { FillPattern, StrokePattern, SymbolPattern } from '@core/model/styles/style-config.model';
import { SymbolStyle } from '@core/model/styles/symbol-style.model';
import { XmlTag } from './xml.utils';

const colorToHex = (color: string) => (+color | (1 << 8)).toString(16).slice(1);

export const rgbToHex = (rgba: string) => {
  const alreadyHex = rgba?.startsWith('#') && rgba?.length == 7;
  if (alreadyHex) {
    return rgba;
  }
  const rgbParts = rgba?.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i);
  let hexCode = '#';
  if (rgbParts) {
    hexCode += colorToHex(rgbParts[1]) + colorToHex(rgbParts[2]) + colorToHex(rgbParts[3]);
  }
  return hexCode;
};

export const rgbaToOpacity = (rgba: string) => {
  const rgbaParts = rgba.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i);
  let opacity = '1';
  if (rgbaParts && rgbaParts.length > 3) {
    const matchedOpacity = (rgbaParts[4] ?? '').trim();
    if (matchedOpacity.length) {
      opacity = matchedOpacity;
    }
  }
  return opacity;
};

export const getStrokeGraphicSld = (symbol: SymbolStyle): XmlTag => {
  return {
    name: 'Stroke',
    content: [
      {
        name: 'CssParameter',
        attributes: { name: 'stroke' },
        content: rgbToHex(symbol.getSymbolStrokeColor()),
      },
      {
        name: 'CssParameter',
        attributes: { name: 'stroke-width' },
        content: symbol.getSymbolStrokeWidth(),
      },
    ],
  };
};

export const getFillGraphicSld = (color: string): XmlTag => {
  return {
    name: 'Fill',
    content: [
      {
        name: 'CssParameter',
        attributes: { name: 'fill' },
        content: rgbToHex(color),
      },
      {
        name: 'CssParameter',
        attributes: { name: 'fill-opacity' },
        content: rgbaToOpacity(color),
      },
    ],
  };
};

export const getSldGraphicTag = (
  symbol: SymbolStyle,
  fillPattern: FillPattern | StrokePattern | SymbolPattern | string,
  wkn?: string
): XmlTag => {
  if (!wkn) {
    wkn = fillPattern;
  }
  let retour: XmlTag;
  if (fillPattern == 'svg') {
    const url = symbol.getSvgSymbolUrl();
    retour = {
      name: 'Graphic',
      content: [
        {
          name: 'ExternalGraphic',
          content: [
            {
              name: 'OnlineResource',
              selfClosing: true,
              attributes: {
                'xlink:type': 'simple',
                'xlink:href': url,
              },
            },
            {
              name: 'Format',
              content: 'image/svg',
            },
          ],
        },
        {
          name: 'Size',
          content: symbol.getSize(),
        },
      ],
    };
  } else {
    retour = {
      name: 'Graphic',
      content: [
        {
          name: 'Mark',
          content: [
            {
              name: 'WellKnownName',
              content: wkn,
            },
            getStrokeGraphicSld(symbol),
            getFillGraphicSld(symbol.getSymbolFillColor()),
          ],
        },
        {
          name: 'Size',
          content: symbol.getSize(),
        },
      ],
    };
  }
  return retour;
};

export const getSldGraphic = (
  symbol: SymbolStyle,
  pattern: FillPattern | StrokePattern | SymbolPattern,
  type = 'fill'
): XmlTag => {
  let graphicType = 'GraphicFill';
  if (type == 'stroke') {
    graphicType = 'GraphicStroke';
  }
  let wkn = '';
  if (pattern == 'cross' || pattern == 'circle' || pattern == 'square' || pattern == 'triangle' || pattern == 'star') {
    wkn = pattern;
  } else if (
    pattern == 'slash' ||
    pattern == 'backslash' ||
    pattern == 'plus' ||
    pattern == 'horline' ||
    pattern == 'vertline' ||
    pattern == 'dot'
  ) {
    wkn = 'shape://' + pattern;
  }

  return {
    name: graphicType,
    content: [getSldGraphicTag(symbol, pattern, wkn)],
  };
};
