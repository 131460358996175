import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { ApplicationApiService } from '@core/services/api/application-api.service';
import { AuthService } from '@core/services/auth.service';
import { environment } from '@env/environment';

@Component({
  selector: 'smv-sidemenu',
  standalone: true,
  imports: [CommonModule, MatDividerModule, MatIconModule, MatListModule, RouterModule],
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})
export class SidemenuComponent {
  public copyrightYear = new Date().getFullYear();
  public smvVersion = environment.version;
  public menuOptions = [
    { path: '/', label: $localize`:Sidenav:Accueil` },
    { path: '/applications', label: $localize`:Sidenav:Mes applications` },
    { path: '/administration/applications', label: $localize`:Sidenav:Gestion des applications`, adminOnly: true },
    { path: '/administration/utilisateurs', label: $localize`:Sidenav:Gestion des utilisateurs`, adminOnly: true },
    { path: '/administration/clients', label: $localize`:Sidenav:Gestion des clients`, adminOnly: true },
    { path: '/administration/groupesClient', label: $localize`:Sidenav:Gestion des groupes client`, adminOnly: true },
    {
      path: '/administration/synchronisation',
      label: $localize`:Sidenav:Gestion de la synchronisation`,
      adminOnly: true,
    },
  ];
  public bottomMenuOptions = [
    {
      path: '/politique-confidentialite',
      label: $localize`:Links|Link to privacy policy:Politique de confidentialité`,
    },
  ];
  public isAdmin$ = this.authService.isAdmin();

  @Output() closeSideNav = new EventEmitter<boolean>();

  constructor(public authService: AuthService, private applicationService: ApplicationApiService) {
    this.applicationService.currentApplication.getStream().subscribe((app) => {
      if (this.bottomMenuOptions.length === 2) {
        this.bottomMenuOptions.splice(0, 1);
      }
      if (app !== undefined) {
        this.bottomMenuOptions.unshift({
          path: '/mentions-legales/' + app.id,
          label: $localize`:Links|Link to CGU:CGU`,
        });
      }
    });
  }

  sendCloseSideNav() {
    this.closeSideNav.emit(true);
  }
}
