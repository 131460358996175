import { Component, Input, OnChanges } from '@angular/core';
import { DateUtils } from '@core/utils/date.utils';
import { KeysOfType } from '@core/utils/general.utils';
import {
  Chart,
  ChartConfiguration,
  ChartData,
  ChartDataset,
  ChartEvent,
  ChartTypeRegistry,
  LegendElement,
  LegendItem,
  TooltipItem,
} from 'chart.js';
import { isNumber } from 'lodash-es';
import { TrapStatistic } from '../../model/trap.model';

@Component({
  selector: 'smv-icare-trap-statistics',
  templateUrl: './icare-trap-statistics.component.html',
  styleUrls: ['./icare-trap-statistics.component.scss'],
})
export class IcareTrapStatisticsComponent implements OnChanges {
  @Input() expanded = true;
  @Input() traps?: TrapStatistic[];
  @Input() multiple = false;

  public title = '';
  public config?: ChartConfiguration;

  private insect = 'Eudémis';
  private dashStartFlight = [15, 10];
  private dashPeakFlight = [];
  private dashEndFlight = [5];
  private legends = {
    ySimulations: '',
    ySamples: '',
  };
  private labels = {
    simulations: '',
    samples: '',
  };
  private datasets: ChartDataset[] = [];

  ngOnChanges() {
    if (this.traps?.length) {
      this.initTitleAndLegends();

      this.setDatasetAdultBar(this.traps);
      this.setDatasetFlightLine(this.traps, 'flightRealizationG1', '#0c034a', 1);
      this.setDatasetFlightLine(this.traps, 'flightRealizationG2', '#ef896b', 2);
      this.setDatasetFlightLine(this.traps, 'flightRealizationG3', '#477ecc', 3);

      this.setDatasetThresholdBar(5, this.traps.length, this.dashStartFlight);
      this.setDatasetThresholdBar(50, this.traps.length, this.dashPeakFlight);
      this.setDatasetThresholdBar(95, this.traps.length, this.dashEndFlight);

      this.config = {
        type: 'line',
        options: this.initOptions(),
        data: {
          labels: this.traps.map((trap) => DateUtils.getDateForGraphic(trap.date)),
          datasets: this.datasets,
        },
      };
    }
  }

  private initTitleAndLegends() {
    if (this.multiple) {
      this.title = $localize`:Chart Title:Evolution modélisée des populations de tordeuse Eudémis`;
      this.legends = {
        ySimulations: $localize`:Chart Legend:Pourcentage moyen de réalisation des vols`,
        ySamples: $localize`:Chart Legend:Nombre moyen de captures`,
      };
      this.labels = {
        simulations: $localize`:Chart Label:% adultes en vol`,
        samples: $localize`:Chart Label:Nombre adultes capturés`,
      };
    } else {
      this.title = $localize`:Chart Title:Evolution modélisée des populations de tordeuse Eudémis`;
      this.legends = {
        ySimulations: $localize`:Chart Legend:Pourcentage de réalisation des vols`,
        ySamples: $localize`:Chart Legend:Nombre de captures`,
      };
      this.labels = {
        simulations: $localize`:Chart Label:% adultes en vol`,
        samples: $localize`:Chart Label:Nombre adultes capturés`,
      };
    }
  }

  private initOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: this.initLegendOptions(),
        title: {
          display: false,
          text: this.insect + 's',
        },
        tooltip: this.initTooltipOptions(),
      },
      scales: this.initScalesOptions(),
    };
  }

  private initLegendOptions() {
    const defaultLegendClickHandler = Chart.defaults.plugins.legend.onClick;
    const newLegendClickHandler = (
      e: ChartEvent,
      legendItem: LegendItem,
      legend: LegendElement<keyof ChartTypeRegistry>
    ) => {
      const datasetIndex = legendItem.datasetIndex;

      if (isNumber(datasetIndex)) {
        const dataset = legend.chart.data.datasets[datasetIndex];
        if ((dataset.type === 'bar' || dataset.type === 'line') && dataset.xAxisID !== 'xThreshold') {
          defaultLegendClickHandler.call(legend, e, legendItem, legend);
        } else if (dataset.type === 'line' && dataset.xAxisID === 'xThreshold') {
          const ci = legend.chart;
          const thresholdMetas = ci.data.datasets
            .filter((data) => data.type === 'line' && data.xAxisID === 'xThreshold')
            .map((thresholdDataset) =>
              ci.data.datasets.findIndex((thresholdData) => thresholdData === thresholdDataset)
            )
            .filter((index) => index > -1)
            .map((index) => ci.getDatasetMeta(index));
          const hidden = !thresholdMetas[0].hidden;
          thresholdMetas.forEach((meta) => {
            meta.hidden = hidden;
          });
          ci.update();
        }
      }
    };

    return {
      onClick: newLegendClickHandler,
      position: 'bottom' as const,
      labels: {
        usePointStyle: true,
        filter: (elt: LegendItem, data: ChartData<keyof ChartTypeRegistry>) => {
          const datasetIndex = elt.datasetIndex;

          if (datasetIndex) {
            const dataset = data.datasets[datasetIndex];
            if (dataset.type === 'line' && dataset.xAxisID === 'xThreshold' && dataset.data[0] !== 5) {
              return false;
            }
          }
          return true;
        },
      },
    };
  }

  private initTooltipOptions() {
    return {
      backgroundColor: 'rgba(0,0,0,0.8)',
      mode: 'index' as const,
      filter: (elt: TooltipItem<keyof ChartTypeRegistry>) => {
        if (elt.dataset.xAxisID == 'xThreshold') {
          return false;
        }
        return true;
      },
    };
  }

  private initScalesOptions() {
    return {
      x: {
        display: true,
        stacked: true,
        title: {
          display: true,
        },
      },
      xThreshold: {
        display: false,
        title: {
          display: false,
        },
      },
      ySamples: {
        display: true,
        stacked: false,
        type: 'linear' as const,
        position: 'right' as const,
        title: {
          display: true,
          text: this.legends.ySamples,
        },
        grid: {
          color: '#000000',
        },
      },
      ySimulations: {
        display: true,
        stacked: false,
        type: 'linear' as const,
        position: 'left' as const,
        min: 0,
        max: 100,
        title: {
          display: true,
          text: this.legends.ySimulations,
        },
      },
    };
  }

  private setDatasetAdultBar(traps: TrapStatistic[]) {
    const adultCount = traps.map((trap) => trap.adultNumber);

    const datasetAdult: ChartDataset = {
      type: 'bar',
      label: this.labels.samples,
      data: adultCount,
      borderColor: '#f9c224',
      backgroundColor: '#f9c224',
      stack: 'Stack',
      order: 10,
      yAxisID: 'ySamples',
      pointStyle: 'rectRounded',
    };
    this.datasets.push(datasetAdult);
  }

  private setDatasetFlightLine(
    traps: TrapStatistic[],
    indicator: KeysOfType<TrapStatistic, number>,
    color: string,
    generation: number
  ) {
    const data = traps.map((trap) => trap[indicator]);

    const dataset: ChartDataset = {
      label: this.labels.simulations + ' G' + generation,
      type: 'line',
      data: data,
      borderColor: color,
      backgroundColor: color,
      cubicInterpolationMode: 'monotone',
      spanGaps: true,
      borderWidth: 1,
      pointStyle: 'circle',
      pointRadius: 5,
      pointBorderColor: 'rgb(0, 0, 0)',
      order: generation,
      yAxisID: 'ySimulations',
    };
    this.datasets.push(dataset);
  }

  private setDatasetThresholdBar(value: number, length: number, dash: number[]) {
    const data = Array(length).fill(value);

    const dataset: ChartDataset = {
      label: $localize`:Chart Label:Seuils des vols`,
      type: 'line',
      data: data,
      borderDash: dash,
      borderWidth: 5,
      borderColor: '#74ac4a',
      backgroundColor: '#74ac4a',
      cubicInterpolationMode: 'monotone',
      pointStyle: 'line',
      pointRadius: 0,
      pointBorderColor: '#74ac4a',
      fill: false,
      order: 100,
      xAxisID: 'xThreshold',
      yAxisID: 'ySimulations',
    };
    this.datasets.push(dataset);
  }
}
