import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Indexable } from '@core/utils/general.utils';
import { isNil } from 'lodash-es';

export class CoercionComponent {
  public coerced: Indexable<boolean> = {};

  /**
   * Coerce all the specified fields to booleans.
   *
   * @param fields Name of the fields to coerce
   */
  protected coerceAll(fields: string[]): void {
    fields.forEach((fieldName) => this.coerceField(fieldName));
  }

  /**
   * Coerce a field to a boolean. If the value is not provided, the
   * method will try to coerce the field from its name.
   *
   * @param field Name of the field to coerce
   * @param value (optional) Value to coerce
   */
  protected coerceField(field: string, value?: BooleanInput): void {
    const fieldValue = isNil(value) ? Reflect.get(this, field) : value;
    this.coerced[field] = coerceBooleanProperty(fieldValue);
  }
}
