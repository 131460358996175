import { cloneDeep } from 'lodash-es';
import { IcareFilter, IcareFilterConfig } from './model/icare.model';

interface IcareConfig {
  [index: string]: boolean;
}

export class IcareLayerConfig {
  private readonly allLayers: IcareConfig = {
    data_eudemis: false,
    lobesia_simulation: false,
  };

  private readonly sample = {
    EUDEMIS: 'data_eudemis',
  };

  private readonly simulation = {
    LOBESIA: 'lobesia_simulation',
  };

  private selected: (keyof IcareConfig)[] = [];

  public retrieveLayerCodeConfig(config: IcareFilterConfig) {
    this.selected = [];
    this.computeSelectedLayersCode(config);

    const layersConfig = cloneDeep(this.allLayers);
    this.selected.forEach((layerCode) => (layersConfig[layerCode] = true));

    return layersConfig;
  }

  private computeSelectedLayersCode(config: IcareFilterConfig) {
    const filter = config.dataFilter;
    if (config.displayFilter == 'FILTER_SIMULATION') {
      this.getFromSimulations(filter);
    } else if (config.displayFilter == 'FILTER_OBSERVATION') {
      this.getFromSamples(filter);
    }
  }

  private getFromSamples(filter: IcareFilter) {
    if (filter) {
      this.selected.push(this.sample.EUDEMIS);
    }
  }

  private getFromSimulations(filter: IcareFilter) {
    if (filter) {
      this.selected.push(this.simulation.LOBESIA);
    }
  }
}

export class IcareAllTrapsConfig {
  private readonly allTraps = 'v_trap_flat';

  public getLayerCode() {
    return this.allTraps;
  }
}
