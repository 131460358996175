import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormFieldErrorsComponent } from '@components/form-field-errors/form-field-errors.component';
import { HelpOverlayComponent } from '@components/help-overlay/help-overlay.component';
import { UploadFileService } from '@core/services/upload-file-service';
import { PanelComponent } from '../panel/panel.component';

import { LoadingComponent } from '@components/loading/loading.component';

const modules = [
  CommonModule,
  FormFieldErrorsComponent,
  FormsModule,
  HelpOverlayComponent,
  LoadingComponent,
  MatButtonModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatSlideToggleModule,
  MatToolbarModule,
  MatTooltipModule,
  PanelComponent,
  ReactiveFormsModule,
];

@Component({
  selector: 'smv-upload-file',
  standalone: true,
  imports: modules,
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent {
  constructor(private uploadFileService: UploadFileService) {}

  @Input() url!: string;
  @Input() accept = '.csv';
  @Input() title = $localize`Import relevés`;
  @Input() label = $localize`:Label|xlsx:Importer des relevés`;
  @Input() successMessage = $localize`:Datum import|Success post:Les relevés ont été importés.`;

  public file: File | null = null;
  public filename?: string;

  public loading = false;

  onFileChange(event: Event): string | undefined {
    const filesSelected = (event.target as HTMLInputElement).files;
    if (filesSelected && filesSelected.length > 0) {
      this.file = filesSelected.item(0);
      return this.file?.name;
    }
    return;
  }

  launchImport() {
    if (this.file) {
      this.loading = true;
      this.uploadFileService.uploadFile(this.file, this.url, this.successMessage, this.deactivateLoader);
    }
  }

  deactivateLoader = () => {
    this.loading = false;
  };
}
