<form *ngIf="rules">
  <mat-table
    cdkDropList
    #list="cdkDropList"
    [cdkDropListData]="rules.controls"
    [cdkDropListDisabled]="!isSortingRules"
    (cdkDropListDropped)="onListDrop($event)"
    [dataSource]="rules.controls"
  >
    <ng-container matColumnDef="label">
      <mat-header-cell *matHeaderCellDef>
        <span i18n="Layer rule form|Table header">Label</span>
        <smv-help-overlay i18n="Layer style form|Table header help">
          Texte qui sera utilisé au niveau de l'affichage de la légende
        </smv-help-overlay>
      </mat-header-cell>
      <mat-cell *matCellDef="let rule">
        <smv-form-field-wrapper
          [control]="rule.controls.label"
          [readonly]="readonly"
          subscriptSizing="dynamic"
          hideLabel
        />
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="minScale">
      <mat-header-cell *matHeaderCellDef i18n="Layer rule form|Table header">Echelle min</mat-header-cell>
      <mat-cell *matCellDef="let rule">
        <smv-form-field-wrapper
          [control]="rule.controls.minScale"
          [readonly]="readonly"
          type="number"
          subscriptSizing="dynamic"
          hideLabel
        />
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="maxScale">
      <mat-header-cell *matHeaderCellDef i18n="Layer rule form|Table header">Echelle max</mat-header-cell>
      <mat-cell *matCellDef="let rule">
        <smv-form-field-wrapper
          [control]="rule.controls.maxScale"
          [readonly]="readonly"
          type="number"
          subscriptSizing="dynamic"
          hideLabel
        />
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="cqlValue">
      <mat-header-cell *matHeaderCellDef>
        <span i18n="Layer rule form|Table header">Règle de filtrage</span>
        <smv-help-overlay>
          <p i18n="Layer style form|Table header help">Filtre CQL pour séléctionner les données</p>
          <a
            target="_blank"
            href="https://docs.geoserver.org/stable/en/user/tutorials/cql/cql_tutorial.html"
            i18n="Layer style form|Table header help"
          >
            voir la doc
          </a>
        </smv-help-overlay>
      </mat-header-cell>
      <mat-cell *matCellDef="let rule">
        <smv-form-field-wrapper
          [control]="rule.controls.cqlValue"
          [readonly]="readonly"
          controlType="textarea"
          subscriptSizing="dynamic"
          hideLabel
        />
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="pointFill">
      <mat-header-cell *matHeaderCellDef i18n="Layer rule form|Table header">Fond du symbole</mat-header-cell>
      <mat-cell *matCellDef="let rule" [formGroup]="rule.controls.style.controls.point">
        <smv-color-picker-field formControlName="symbolFillColor" hideField />
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="pointStroke">
      <mat-header-cell *matHeaderCellDef i18n="Layer rule form|Table header">Bordure du symbole</mat-header-cell>
      <mat-cell *matCellDef="let rule" [formGroup]="rule.controls.style.controls.point">
        <smv-color-picker-field formControlName="symbolStrokeColor" hideField />
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="polygonFill">
      <mat-header-cell *matHeaderCellDef i18n="Layer rule form|Table header">Fond</mat-header-cell>
      <mat-cell *matCellDef="let rule" [formGroup]="rule.controls.style.controls.polygon">
        <smv-color-picker-field formControlName="fillColor" hideField />
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="polygonStroke">
      <mat-header-cell *matHeaderCellDef i18n="Layer rule form|Table header">Bordure</mat-header-cell>
      <mat-cell *matCellDef="let rule" [formGroup]="rule.controls.style.controls.polygon">
        <smv-color-picker-field formControlName="strokeColor" hideField />
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let rule; let index = index">
        <button
          mat-icon-button
          type="button"
          (click)="editRuleStyle(rule)"
          matTooltip="Editer le style de la règle"
          i18n-matTooltip="Layer rule form"
        >
          <mat-icon>border_color</mat-icon>
        </button>
        <button
          mat-icon-button
          color="warn"
          type="button"
          (click)="removeRule(index)"
          matTooltip="Supprimer la règle"
          i18n-matTooltip="Layer rule form"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns" />
    <mat-row *matRowDef="let row; columns: columns" cdkDrag />
  </mat-table>
</form>
