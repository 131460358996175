<smv-filter-type-data
  [(filter)]="filter"
  [authorizedSimulation]="authorizedSimulation"
  [setTimelineForSimulation]="setTimelineForSimulation"
  (filterChange)="onFilterChange()"
>
  <smv-vigie-virose-filter-observations
    [cultures]="cultures"
    class="vv-obs"
    *ngIf="filter === 'FILTER_OBSERVATION'"
    samples
  />
  <smv-vigie-virose-filter-simulation [cultures]="cultures" *ngIf="filter === 'FILTER_SIMULATION'" simulations />
</smv-filter-type-data>
