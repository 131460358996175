<div *ngFor="let cultureGroup of cultures">
  <mat-checkbox
    color="primary"
    [(ngModel)]="cultureGroup.selected"
    [indeterminate]="someComplete(cultureGroup)"
    (change)="setAll(cultureGroup.name, $event.checked)"
  >
    {{ cultureGroup.name }}
  </mat-checkbox>
  <ul *ngIf="cultureGroup.cultivations.length > 1">
    <li *ngFor="let cultivation of cultureGroup.cultivations">
      <mat-checkbox
        [(ngModel)]="cultivation.selected"
        color="accent"
        (ngModelChange)="updateAllComplete(cultureGroup.name)"
      >
        {{ cultivation.name }}
      </mat-checkbox>
    </li>
  </ul>
</div>
