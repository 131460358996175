<div class="smv-panel unselectable">
  <div class="header" (click)="expanded = !expanded">
    <h3 class="title">{{ title }}</h3>
    <mat-icon *ngIf="!expanded">arrow_drop_down</mat-icon>
    <mat-icon *ngIf="expanded">arrow_drop_up</mat-icon>
  </div>
  <div *ngIf="expanded" class="content">
    <ng-content></ng-content>
  </div>
</div>
