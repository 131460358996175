import { VirusAnalyzes } from './virus-analyzes.model';

export interface Insect {
  code: string;
  description: string;
  familly: string;
  name: string;
  virusAnalyzes: VirusAnalyzes[];
  selected?: boolean;
}

export enum InsectEnum {
  PUCERON = 'puceron',
  CICADELLE = 'cicadelle',
  TENTHREDE = 'tenthrede',
  ALTISE = 'altise',
}

export enum InsectStates {
  SAIN,
  INSECTS,
  INSECTS_WITH_VIRUS,
}
