<smv-sheet-statistic [widgetLabel]="title" [loading]="loading" [isDataLoaded]="isDataLoaded" [titleMulti]="titleMulti">
  <smv-icare-trap-statistics *ngIf="trapMulti.length" [multiple]="true" [traps]="trapMulti" statisticsMulti />
  <mat-accordion *ngIf="traps.length > 0" statisticsTrap>
    <mat-expansion-panel *ngFor="let trap of traps" [expanded]="traps.length === 1">
      <mat-expansion-panel-header>
        <mat-panel-title class="title">
          <span *ngIf="trap.trap_name; else codeLabel">{{ trap.trap_name }}</span>
          <ng-template #codeLabel>{{ trap.trap_code }}</ng-template>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <smv-icare-trap-statistics [traps]="trapStatements[trap.trap_code]" />
    </mat-expansion-panel>
  </mat-accordion>
</smv-sheet-statistic>
