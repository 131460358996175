import { MapUtils } from '@core/utils/map.utils';
import { XmlTag } from '@core/utils/xml.utils';
import Feature, { FeatureLike } from 'ol/Feature';
import { Style } from 'ol/style';
import { GeometryType } from './application-api/layer.model';
import { Palette } from './styles/style-config.model';
import { StyleCqlFilter } from './styles/style-filter.model';
import { StyleRule, StyleRuleModel } from './styles/style-rule.model';

export interface LayerDynamicStyleModel {
  baseStyle?: LayerDynamicStyleModel;
  attribute?: string;
  palette?: Palette;
  categories?: StyleRuleModel[];
  minAttributeValue?: number;
  maxAttributeValue?: number;
  useFirstRangeIfLesser?: boolean;
  useLastRangeIfGreater?: boolean;
}

export abstract class LayerDynamicStyle {
  protected baseStyle: LayerDynamicStyleModel;
  abstract toModel(): LayerDynamicStyleModel;
  abstract toModelWithBaseStyle(): LayerDynamicStyleModel;

  abstract clone(): LayerDynamicStyle;

  abstract applyBaseStyle(): void;

  public attribute?: string;

  public palette: Palette = 'no-colors';

  public categories: StyleRule[] = [];

  constructor(style?: LayerDynamicStyleModel) {
    this.applyStyle(style);
    this.baseStyle = this.toModel();
  }

  protected getFeatureAttributeValue(feature: Feature) {
    return feature && this.attribute ? feature.get(this.attribute) : null;
  }

  public applyStyle(style?: LayerDynamicStyleModel) {
    this.attribute = style?.attribute;
    this.palette = style?.palette ?? 'no-colors';
    style?.categories?.forEach((category) => {
      this.categories.push(new StyleRule(category));
    });
  }

  public getBaseStyle() {
    return this.baseStyle;
  }

  public setBaseStyle(baseStyle: LayerDynamicStyle) {
    this.baseStyle = baseStyle.toModel();
  }

  toSld(geometryType?: GeometryType): XmlTag[] {
    const sld: XmlTag[] = [];
    this.categories.forEach((category) => {
      const sldRule = this.toSldRule(category, geometryType);
      if (sldRule) {
        sld.push(sldRule);
      }
    });

    return sld;
  }

  toModelBase() {
    return {
      attribute: this.attribute,
      palette: this.palette,
      categories: this.categories.map((x) => {
        return x.toModel();
      }),
    };
  }

  toModelBaseWithBaseStyle() {
    return {
      baseStyle: this.baseStyle,
      attribute: this.attribute,
      palette: this.palette,
      categories: this.categories.map((x) => {
        return x.toModel();
      }),
    };
  }

  toSldRule(styleRule: StyleRule, geometryType?: GeometryType): XmlTag | undefined {
    const label = styleRule.label ? styleRule.label : '';
    if (!styleRule.filter) {
      styleRule.filter = new StyleCqlFilter();
      styleRule.filter.computeFilter(styleRule.cqlValue);
    }
    const filterTag = styleRule.filter.filterSld;

    if (geometryType) {
      const rule: XmlTag = {
        name: 'Rule',
        content: [
          {
            name: 'Name',
            content: label,
          },
          {
            name: 'Title',
            content: label,
          },
          {
            name: 'Abstract',
            content: label,
          },
        ],
      };
      if (filterTag) {
        (rule.content as XmlTag[]).push(filterTag);
      }
      if (styleRule.minScaleDenom) {
        (rule.content as XmlTag[]).push({
          name: 'MinScaleDenominator',
          content: styleRule.minScaleDenom,
        });
      }
      if (styleRule.maxScaleDenom) {
        (rule.content as XmlTag[]).push({
          name: 'MaxScaleDenominator',
          content: styleRule.maxScaleDenom,
        });
      }
      (rule.content as XmlTag[]).push(styleRule.style.toSld(geometryType));
      return rule;
    }
    return;
  }

  getOlStyle(feature: FeatureLike, resolution: number): Style | undefined {
    if (MapUtils.PROJECTION_MAP) {
      const scaleDenominator = MapUtils.getScaleFromResolution(resolution, MapUtils.PROJECTION_MAP, true);

      const targetCategory = this.categories.find((category) => {
        if (scaleDenominator && category.minScaleDenom && scaleDenominator < category.minScaleDenom) {
          return false;
        }
        if (scaleDenominator && category.maxScaleDenom && scaleDenominator > category.maxScaleDenom) {
          return false;
        }

        if (
          (category.filter == undefined || category.filter.evaluate == undefined) &&
          category.cqlValue &&
          category.cqlValue.length > 0
        ) {
          const filter = new StyleCqlFilter();
          filter.computeFilter(category.cqlValue);
          category.filter = filter;
        }

        //pas de filtre
        if (category.filter == undefined) {
          return true;
        }
        return category.filter && category.filter.evaluate(feature);
      });

      if (targetCategory) {
        return targetCategory.style.getOlStyle(feature);
      }
    }
    return;
  }
}
