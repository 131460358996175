import { LayerConfig, LayerGroupConfig } from '@core/model/layer-config.model';
import BaseEvent from 'ol/events/Event';
import LayerGroup from 'ol/layer/Group';
import Heatmap from 'ol/layer/Heatmap';
import Layer from 'ol/layer/Layer';

interface VisibilityChilds {
  [index: string]: boolean;
}

export class DataLayerGroupConfig {
  config: LayerGroupConfig;
  expanded: boolean;
  olLayerGroup?: LayerGroup;
  layers: DataLayerConfig[] = [];
  subgroups: DataLayerGroupConfig[] = [];

  visibilityChildState: VisibilityChilds = {};

  get generatedId(): string {
    return this.config.generatedId;
  }

  constructor(config: LayerGroupConfig) {
    this.config = config;
    this.expanded = config.visible;
    this.layers = config.layers.map((layer) => new DataLayerConfig(layer));
    this.subgroups = config.subgroups.map((subgroup) => new DataLayerGroupConfig(subgroup));
  }

  rebuildIndexes(startIndex: number): number {
    let zIndex = startIndex;
    this.layers.forEach((layer) => {
      layer.updateZIndex(zIndex);
      zIndex--;
    });
    this.subgroups.forEach((group) => {
      zIndex = group.rebuildIndexes(zIndex);
    });
    return zIndex;
  }

  toggleGroupVisibility(): void {
    this.config.visible = !this.config.visible;
    this.expanded = this.config.visible;
    this.olLayerGroup?.setVisible(this.config.visible);
  }

  setGroupVisibility(visibility: boolean): void {
    this.config.visible = visibility;
    this.expanded = this.config.visible;
    this.olLayerGroup?.setVisible(this.config.visible);
  }

  computeVisibility(event: BaseEvent) {
    const child = event.target as Layer | LayerGroup;
    const visibility = child.getVisible();
    const index = (child.getProperties() as { generatedId: string }).generatedId;
    this.visibilityChildState[index] = visibility;
    if (this.isChildVisible()) {
      this.setGroupVisibility(true);
    }
  }

  isChildVisible() {
    for (const child of Object.keys(this.visibilityChildState)) {
      if (this.visibilityChildState[child]) {
        return true;
      }
    }
    return false;
  }

  removeLayer(layer: DataLayerConfig): void {
    const layerIndex = this.layers.findIndex((l) => l.generatedId === layer.generatedId);
    if (layerIndex !== -1) {
      this.layers.splice(layerIndex, 1);
    }
  }

  getZIndex(): number {
    let zIndex: number | undefined;
    if (this.layers.length) {
      zIndex = this.layers[0].config.zIndex;
    } else if (this.subgroups.length) {
      zIndex = this.subgroups[0].getZIndex();
    }
    return zIndex ?? 0;
  }

  updateDisplayParameters(): void {
    this.config.layers = this.layers.map((layer) => {
      layer.updateDisplayParameters();
      return layer.config;
    });
    this.config.subgroups = this.subgroups.map((subgroup) => subgroup.config);
    this.subgroups.forEach((subgroup) => subgroup.updateDisplayParameters());
  }

  updateConfigLayers(): void {
    this.config.layers = this.layers.map((layer) => layer.config);
    this.config.subgroups = this.subgroups.map((subgroup) => subgroup.config);
    this.subgroups.forEach((subgroup) => subgroup.updateConfigLayers());
  }

  /**
   * Creates a clone of the object. OpenLayers references are preserved.
   *
   * @returns Clone with preserved OL references
   */
  clone(): DataLayerGroupConfig {
    const clone = new DataLayerGroupConfig(this.config);
    clone.layers = this.layers.map((layer) => layer.clone());
    clone.olLayerGroup = this.olLayerGroup;
    return clone;
  }
}

export class DataLayerConfig {
  config: LayerConfig;
  olLayer?: Layer;
  isSatelliteLayer: boolean;
  isEditing: boolean;

  displayParameters: {
    isVisible: boolean;
    opacityValue: number;
    radius?: number;
    blur?: number;
  };

  get generatedId(): string {
    return this.config.generatedId;
  }

  constructor(config: LayerConfig) {
    this.config = config;
    this.isSatelliteLayer = this.config.type === 'GoogleMap';
    this.isEditing = false;
    this.displayParameters = {
      isVisible: this.config.visible,
      opacityValue: this.config.opacity,
      radius: this.config.heatmap.heatRadius,
      blur: this.config.heatmap.heatBlur,
    };
  }

  updateOpacity(value: number): void {
    this.displayParameters.opacityValue = value;
    this.olLayer?.setOpacity(value);
  }

  updateRadius(value: number): void {
    if (this.olLayer instanceof Heatmap) {
      this.displayParameters.radius = value;
      this.olLayer?.setRadius(value);
    }
  }

  updateBlur(value: number): void {
    if (this.olLayer instanceof Heatmap) {
      this.displayParameters.blur = value;
      this.olLayer?.setBlur(value);
    }
  }

  toggleLayerVisibility(): void {
    this.displayParameters.isVisible = !this.displayParameters.isVisible;
    this.olLayer?.setVisible(this.displayParameters.isVisible);
  }

  setLayerVisibility(visibility: boolean): void {
    this.displayParameters.isVisible = visibility;
    this.olLayer?.setVisible(this.displayParameters.isVisible);
  }

  updateDisplayParameters(): void {
    this.config.visible = this.displayParameters.isVisible;
    this.config.opacity = this.displayParameters.opacityValue;
    this.config.heatmap.heatRadius = this.displayParameters.radius;
    this.config.heatmap.heatBlur = this.displayParameters.blur;
  }

  updateZIndex(index: number): void {
    this.config.zIndex = index;
    this.olLayer?.setZIndex(index);
  }

  isDisplayed(scale: number): boolean {
    const visibleAtMinScale = (this.config.minScaleDenominator ?? 0) < scale;
    const visibleAtMaxScale = this.config.maxScaleDenominator ? this.config.maxScaleDenominator > scale : true;
    return this.displayParameters.isVisible && visibleAtMinScale && visibleAtMaxScale;
  }

  /**
   * Creates a clone of the object. OpenLayers references are preserved.
   *
   * @returns Clone with preserved OL references
   */
  clone(): DataLayerConfig {
    const clone = new DataLayerConfig(this.config);
    clone.olLayer = this.olLayer;
    return clone;
  }
}
