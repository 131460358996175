import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import {
  DropdownOption,
  FormFieldWrapperComponent,
} from '@components/form/form-field-wrapper/form-field-wrapper.component';
import { Customer } from '@core/model/customer.model';
import { CustomerService } from '@core/services/customer.service';
import { Farmer } from '@feature/client-app/widgets/interra-scan/model/farmer.model';
import { isNil } from 'lodash-es';

@Component({
  selector: 'smv-interra-scan-farmer-form',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormFieldWrapperComponent,
  ],
  templateUrl: './farmer-form.component.html',
  styleUrls: ['./farmer-form.component.scss'],
})
export class InterraScanFarmerFormComponent {
  private currentFarmer?: Farmer;

  public title = $localize`:InterraScan|Form title:Création d'un agriculteur`;
  public customers: DropdownOption<Customer>[] = [];

  public form = this.formBuilder.group({
    firstName: [undefined as string | undefined, Validators.required],
    lastName: [undefined as string | undefined, Validators.required],
    farmName: [undefined as string | undefined, Validators.required],
    email: ['', [Validators.required, Validators.email]],
    phone: [undefined as string | undefined, Validators.required],
    customer: [undefined as Customer | undefined, Validators.required],
    siret: [undefined as string | undefined],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public farmer: Farmer,
    private formBuilder: NonNullableFormBuilder,
    private customerService: CustomerService
  ) {
    this.currentFarmer = farmer;
    if (!isNil(this.currentFarmer)) {
      this.form.patchValue(this.currentFarmer);
      this.title = $localize`:InterraScan|Form title:Modification d'un agriculteur`;
    }
    this.customerService.getMyCustomers(false).subscribe((customers) => {
      this.customers = customers.map((customer) => {
        return {
          label: `${customer.name} (${customer.code})`,
          value: customer,
        };
      });
      if (!isNil(this.currentFarmer)) {
        this.form.patchValue(this.currentFarmer);
      }
    });
  }
}
