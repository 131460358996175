<div class="smv-dialog">
  <h1 mat-dialog-title i18n>Liste des communes</h1>
  <mat-divider />

  <div mat-dialog-content>
    <mat-table [dataSource]="towns" matSort matSortActive="nom_com" matSortDirection="asc">
      <ng-container *ngFor="let column of columns" [matColumnDef]="column">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnLabels[column] }}</mat-header-cell>
        <mat-cell *matCellDef="let town">{{ town[column] }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columns" />
      <mat-row *matRowDef="let row; columns: columns" />
    </mat-table>

    <smv-loading [loaders]="[loader]" [overlay]="false" [spinnerDiameter]="50" />
  </div>

  <div mat-dialog-actions>
    <button *ngIf="!loader.condition" mat-flat-button color="primary" (click)="exportTowns()" i18n="Button">
      Exporter
    </button>
    <button mat-flat-button mat-dialog-close i18n="Button">Fermer</button>
  </div>
</div>
