import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { CustomLayerStyle } from '@core/model/layer-style.model';
import { AuthService } from '@core/services/auth.service';
import { ServerStatusService } from '@core/services/server-status.service';
import { TemplateUtils } from '@core/utils/template.utils';

@Component({
  selector: 'smv-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('drawer') drawer?: MatDrawer;

  isLoggedIn = false;
  isNoHeaderFooter = false;
  isBackendUp = true;

  constructor(private authService: AuthService, private serverStatusService: ServerStatusService) {}

  ngOnInit(): void {
    this.authService.isLoggedIn().subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;
      if (!this.isLoggedIn) {
        this.drawer?.close();
      }
    });
    TemplateUtils.noHeaderFooter.getStream().subscribe((value) => (this.isNoHeaderFooter = value));
    this.serverStatusService.serverStatus.getStream().subscribe((value) => (this.isBackendUp = value != 'DOWN'));
    CustomLayerStyle.generateDefaultStyle();
  }
}
