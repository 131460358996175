import { Component, Input } from '@angular/core';
import { GeoServerTrap } from '@widgets/vigie-virose/model/trap.model';

@Component({
  selector: 'smv-vigie-virose-trap-description',
  templateUrl: './vigie-virose-trap-description.component.html',
  styleUrls: ['./vigie-virose-trap-description.component.scss'],
})
export class VigieViroseTrapDescriptionComponent {
  @Input() trap?: GeoServerTrap;

  getCultivation() {
    switch (this.trap?.trap_cultivation_code) {
      case 'ORG':
        return $localize`:Cultivation:Orge`;
      case 'BLE':
        return $localize`:Cultivation:Blé`;
      case 'COL':
        return $localize`:Cultivation:Colza`;
      default:
        return '';
    }
  }
}
