<smv-sheet-statistic
  widgetLabel="VigieVirose"
  [loading]="loading"
  [isDataLoaded]="isDataLoaded"
  [titleMulti]="titleMulti"
>
  <smv-vigie-virose-trap-veriliferous-state
    *ngFor="let trap of trapsCereals[MULTI]"
    [multiple]="true"
    [traps]="trap"
    statisticsMulti
  />
  <mat-accordion *ngIf="trapList.length > 0" statisticsTrap>
    <mat-expansion-panel *ngFor="let trap of trapList" [expanded]="trapList.length === 1">
      <mat-expansion-panel-header>
        <mat-panel-title class="title"><span i18n>Piège</span> {{ trap.trap_code }}</mat-panel-title>
      </mat-expansion-panel-header>
      <smv-vigie-virose-trap-description [trap]="trap"></smv-vigie-virose-trap-description>
      <smv-vigie-virose-trap-history [statements]="traps[trap.trap_code]"></smv-vigie-virose-trap-history>
      <smv-vigie-virose-trap-veriliferous-state
        *ngFor="let trap of trapsCereals[trap.trap_code]; first as isFirst"
        [traps]="trap"
        [expanded]="isFirst"
      />
      <smv-vigie-virose-trap-veriliferous-state
        *ngFor="let trap of trapsColza[trap.trap_code]; first as isFirst"
        [traps]="trap"
        [expanded]="isFirst"
      />
    </mat-expansion-panel>
  </mat-accordion>
</smv-sheet-statistic>
