import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validate that the date is after a certain value.
 *
 * @returns Validation errors with "dateAfter" key
 */
export function dateAfterValidator(minDate: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    return control.value.valueOf() <= minDate.valueOf() ? { dateAfter: minDate } : null;
  };
}

/**
 * Validate that the date is before a certain value.
 *
 * @returns Validation errors with "dateBefore" key
 */
export function dateBeforeValidator(maxDate: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    return control.value.valueOf() >= maxDate.valueOf() ? { dateBefore: maxDate } : null;
  };
}
