<smv-header *ngIf="!isNoHeaderFooter" [isLoggedIn]="isLoggedIn" (toggleMenu)="drawer.toggle()"></smv-header>
<mat-drawer-container hasBackdrop>
  <mat-drawer mode="over" #drawer>
    <smv-sidemenu (closeSideNav)="drawer.close()"></smv-sidemenu>
  </mat-drawer>
  <mat-drawer-content>
    <div class="main-content">
      <router-outlet *ngIf="this.isBackendUp; else maintenance"></router-outlet>
      <ng-template #maintenance>
        <h1 i18n="Main Content|Services in maintenance">
          Les services MapView sont actuellement en maintenance, veuillez patienter.
        </h1>
      </ng-template>
    </div>
    <smv-anonymous-footer *ngIf="!isLoggedIn && !isNoHeaderFooter"></smv-anonymous-footer>
  </mat-drawer-content>
</mat-drawer-container>
