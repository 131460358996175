import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Validate color value.
 *
 * @returns Validation errors with "notAColor" key
 */
export const colorPickerValidator = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value) {
    return null;
  }

  const isHex = /^#[a-fA-F0-9]{6}$/.test(control.value);
  const isRgba =
    /^rgba\(([01]?\d?\d|2[0-4]\d|25[0-5]),\s?([01]?\d?\d|2[0-4]\d|25[0-5]),\s?([01]?\d?\d|2[0-4]\d|25[0-5]),\s?(0\.\d{0,5}|1[.0]?)\)$/.test(
      control.value
    );
  const isRgb =
    /^rgb\(([01]?\d?\d|2[0-4]\d|25[0-5]),([01]?\d?\d|2[0-4]\d|25[0-5]),([01]?\d?\d|2[0-4]\d|25[0-5])\)$/.test(
      control.value
    );

  const colorValid = isHex || isRgba || isRgb;
  return colorValid ? null : { notAColor: true };
};
