<mat-form-field subscriptSizing="dynamic">
  <mat-label i18n="Attributes Editor" class="unselectable">Importer une configuration</mat-label>
  <input matInput [formControl]="filename" readonly (click)="UploadFileInput.click()" />
  <button
    mat-icon-button
    matSuffix
    color="primary"
    matTooltip="Importer"
    (click)="UploadFileInput.click()"
    i18n-matTooltip
  >
    <mat-icon>cloud_upload</mat-icon>
  </button>
  <smv-help-overlay matSuffix [closeAfter]="10000">
    <span i18n="Attribute Editor|Configuration help">Format attendu :</span>
    <pre>{{ expectedFormat | json }}</pre>
    <span i18n="Attribute Editor|Configuration help">
      Valeur possible pour les autres attributs :
      <ul>
        <li>Booléen : spécifie si la valeur peut être remplie</li>
        <li>Chaîne de caractères : spécifie l'unique valeur possible</li>
        <li>Tableau de chaîne de caractères : spécifie les valeurs possibles</li>
      </ul>
    </span>
  </smv-help-overlay>
  <input hidden type="file" #UploadFileInput (change)="onFileChange($event)" accept=".json" />
</mat-form-field>
<mat-slide-toggle
  labelPosition="before"
  [formControl]="nullDefaultControl"
  class="unselectable"
  i18n="Attribute Editor|Constraint"
>
  Imposer une valeur nulle quand cet attribut a une valeur nulle :
</mat-slide-toggle>
<button
  mat-stroked-button
  color="primary"
  [disabled]="file === null"
  (click)="readConfigJson()"
  [ngClass]="{ btnSpinner: isWorking, loaded: isWorking, unselectable: true }"
  i18n
>
  Démarrer la récupération de la configuration
</button>

<p *ngIf="attribute.controls.dependsOn.value" i18n="Attribute Editor" class="unselectable">
  Est dépendant de l'attribut {{ attribute.controls.dependsOn.value }}
</p>

<p *ngIf="importedValues !== undefined" i18n="Attribute Editor" class="unselectable">
  {{ importedValues }} valeurs récupérées
</p>
<mat-list *ngIf="constraintedAttr.length" cdkScrollable>
  <div mat-subheader class="unselectable" i18n="Attribute Editor">Attributs contraints par cet attribut :</div>
  <mat-list-item *ngFor="let attr of constraintedAttr" class="unselectable">
    <mat-icon matListItemIcon>arrow_right</mat-icon>
    <span>{{ attr }}</span>
  </mat-list-item>
</mat-list>
