import { Component } from '@angular/core';

@Component({
  selector: 'smv-opti-semis-filter',
  templateUrl: './opti-semis-filter.component.html',
  styleUrls: ['./opti-semis-filter.component.scss'],
})
export class OptiSemisFilterComponent {
  public title = $localize`:OptiSemis:Filtre`;
}
