<div class="actions">
  <button mat-stroked-button (click)="loadAttrValues()" [ngClass]="{ btnSpinner: isLoading, loaded: isLoading }">
    <mat-icon>refresh</mat-icon>
    <ng-container i18n="Attribute Editor">Charger les valeurs de l'attribut</ng-container>
  </button>
  <button mat-stroked-button (click)="resetAttrValues()">
    <ng-container i18n="Attribute Editor">Réinitialiser les valeurs de l'attribut</ng-container>
  </button>
</div>
<mat-slide-toggle
  labelPosition="before"
  [formControl]="attribute.controls.displayInitialValue"
  class="toggle unselectable"
  i18n="Edit feature|Display initial value"
>
  Afficher la valeur initiale en édition :
</mat-slide-toggle>
<smv-form-field-wrapper
  [formControl]="newAttr"
  subscriptSizing="dynamic"
  hasAction
  hasHelp
  (keyup.enter)="onAddAttribute(newAttr.value)"
  [helpCloseAfter]="10000"
>
  <span mat-label i18n="Attribute Editor" class="unselectable">Ajouter une possible valeur par défaut</span>
  <button mat-icon-button *ngIf="newAttr.value" (click)="onAddAttribute(newAttr.value)">
    <mat-icon>add</mat-icon>
  </button>
  <span i18n="Attribute Editor|Default values" help>
    <p>Plusieurs variables sont disponibles :</p>
    <ul>
      <li>{{ UUID }} : génère un identifiant unique de type uuid</li>
      <li>{{ ID }} : génère un identifiant de 5 &agrave; 12 chiffres aléatoires</li>
      <li>{{ LONGITUDE }}: génère la longitude du point sélectioné</li>
      <li>{{ LATITUDE }}: génère la latitude du point sélectioné</li>
      <li>{{ DEPT_CODE }}: génère le numéro du département de la commune</li>
      <li>{{ NOM_COMMUNE }}: génère le nom de la commune</li>
      <li>{{ ZIP_CODE }}: génère le code postal de la commune</li>
    </ul>
  </span>
</smv-form-field-wrapper>
<h3 class="help-title unselectable">
  <span i18n="Attribute Editor">Liste des valeurs</span>
  <span> ({{ nbOfValues }})</span>
  <smv-help-overlay [closeAfter]="10000" i18n="Attribute Editor|Default Values Help">
    <p>Si la liste est vide, l'utilisateur pourra rentrer n'importe quelle valeur.</p>
    <p>Si la liste contient un seul élément, la valeur sera automatiquement saisie.</p>
    <p>Si la liste contient plusieurs éléments, l'utilisateur devra choisir entre ces valeurs.</p>
  </smv-help-overlay>
</h3>
<mat-list class="list" cdkScrollable>
  <div *ngFor="let key of keys" cdkDropList (cdkDropListDropped)="drop($event, key)">
    <div mat-subheader *ngIf="key !== 'default'">{{ key }}</div>
    <mat-list-item *ngFor="let attr of attribute.controls.defaultValues.value[key]; let indexValues = index" cdkDrag>
      <mat-icon matListItemIcon>arrow_right</mat-icon>
      <div class="item">
        <span>{{ attr }}</span>
        <button
          mat-icon-button
          color="warn"
          type="button"
          (click)="removeAttribute(key, indexValues)"
          matTooltip="Supprimer la valeur"
          i18n-matTooltip="Attribute Editor"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-list-item>
  </div>
</mat-list>
