<div [hidden]="panelHide">
  <mat-card class="side-panel revolt-panel">
    <mat-card-title>
      <div>
        <span i18n="Revolt|Title widget">Rêvolt</span>
        <button mat-icon-button (click)="displayed.emit()">
          <mat-icon>keyboard_double_arrow_right</mat-icon>
        </button>
      </div>
    </mat-card-title>

    <mat-card-content>
      <smv-revolt-filter />
    </mat-card-content>
  </mat-card>
</div>
