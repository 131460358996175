import { getFillGraphicSld, getSldGraphic } from '@core/utils/layer-style.utils';
import { XmlTag } from '@core/utils/xml.utils';
import { Fill } from 'ol/style';
import { FillPattern } from './style-config.model';
import { SymbolStyle, SymbolStyleModel } from './symbol-style.model';

export interface FillStyleModel {
  fillSymbol?: SymbolStyleModel | null;
  fillColor?: string | null;
  fillPattern?: FillPattern | null;
  fillPatternSize?: number | null;
  fillPatternMargin?: number | null;
}

interface BaseFillStyle {
  fillSymbol: SymbolStyleModel;
  fillColor: string;
  fillPattern: FillPattern;
  fillPatternSize: number;
  fillPatternMargin: number;
}

export class FillStyle {
  private baseStyle: BaseFillStyle;

  public fillSymbol: SymbolStyle;
  public fillColor = 'rgba(51, 153, 204, 0.5)';
  public fillPattern: FillPattern = 'dot';
  public fillPatternSize = 0;
  public fillPatternMargin = 0;

  constructor(data?: FillStyleModel | null) {
    if (data) {
      Object.assign(this, data);
      this.fillSymbol = new SymbolStyle(data.fillSymbol);
    } else {
      this.fillSymbol = new SymbolStyle();
    }
    this.baseStyle = this;
  }

  public setBaseStyle(baseStyle: FillStyle) {
    this.baseStyle = baseStyle;
    if (baseStyle != undefined) {
      this.fillSymbol.setBaseStyle(baseStyle.fillSymbol);
    }
  }

  hasFillPattern() {
    const fillPattern = this.getFillPattern();
    return fillPattern && fillPattern.length > 0 && fillPattern != 'simple';
  }

  setDefaultValues() {
    this.fillSymbol = new SymbolStyle();
    this.fillSymbol.setDefaultValues();
    this.fillColor = 'rgba(51, 153, 204, 0.5)';

    this.fillPattern = 'dot';
    this.fillPatternMargin = 0;
  }

  toModel(): FillStyleModel {
    return {
      fillSymbol: this.fillSymbol.toModel(),
      fillColor: this.fillColor,
      fillPattern: this.fillPattern,
      fillPatternMargin: this.fillPatternMargin,
      fillPatternSize: this.fillPatternSize,
    };
  }

  getFillPatternMargin(): number {
    return this.fillPatternMargin != undefined ? this.fillPatternMargin : this.baseStyle.fillPatternMargin;
  }

  getFillColor(): string {
    return this.fillColor ? this.fillColor : this.baseStyle.fillColor;
  }

  toSld(): XmlTag[] {
    const fillContent: XmlTag[] = [];
    if (this.hasFillPattern()) {
      fillContent.push({
        name: 'Fill',
        content: [getSldGraphic(this.fillSymbol, this.getFillPattern())],
      });
      fillContent.push({
        name: 'VendorOption',
        attributes: { name: 'graphic-margin' },
        content:
          this.getFillPatternMargin() +
          ` ` +
          this.getFillPatternMargin() +
          ` ` +
          this.getFillPatternMargin() +
          ` ` +
          this.getFillPatternMargin(),
      });
    } else {
      fillContent.push(getFillGraphicSld(this.getFillColor()));
    }
    return fillContent;
  }

  getFillPattern(): FillPattern {
    return this.fillPattern ?? this.baseStyle.fillPattern;
  }

  getRenderFunction() {
    const fillPattern = this.getFillPattern();
    const img = this.fillSymbol.getImage();

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      const fillColorFct = (() => {
        if (['slash', 'backslash'].indexOf(fillPattern) >= 0) {
          canvas.width = 32;
          canvas.height = 16;
        } else if (['horline', 'vertline'].indexOf(fillPattern) >= 0) {
          canvas.width = 10;
          canvas.height = 10;
        } else if (['svg'].indexOf(fillPattern) >= 0) {
          canvas.width = this.fillSymbol.getSize() + this.getFillPatternMargin() * 2;
          canvas.height = this.fillSymbol.getSize() + this.getFillPatternMargin() * 2;
        } else {
          canvas.width = this.fillSymbol.getSize() + this.getFillPatternMargin() * 2;
          canvas.height = this.fillSymbol.getSize() + this.getFillPatternMargin() * 2;
        }

        const strokeColor = this.fillSymbol.getSymbolStrokeColor();
        context.strokeStyle = strokeColor;

        const fillColor = this.fillSymbol.getSymbolFillColor();
        context.fillStyle = fillColor;
        context.lineWidth = this.fillSymbol.getSymbolStrokeWidth();
        if (fillPattern == 'slash') {
          const x0 = 36;
          const x1 = -4;
          const y0 = -2;
          const y1 = 18;
          const offset = 32;
          context.beginPath();
          context.moveTo(x0, y0);
          context.lineTo(x1, y1);
          context.moveTo(x0 - offset, y0);
          context.lineTo(x1 - offset, y1);
          context.moveTo(x0 + offset, y0);
          context.lineTo(x1 + offset, y1);
          context.stroke();
        } else if (fillPattern == 'backslash') {
          const x0 = 36;
          const x1 = -4;
          const y0 = -2;
          const y1 = 18;
          const offset = 32;
          context.scale(-1, 1);
          context.beginPath();
          context.moveTo(x0, y0);
          context.lineTo(x1, y1);
          context.moveTo(x0 - offset, y0);
          context.lineTo(x1 - offset, y1);
          context.moveTo(x0 + offset, y0);
          context.lineTo(x1 + offset, y1);
          context.stroke();
        } else if (fillPattern == 'cross') {
          context.scale(this.fillSymbol.getSize() / 10, this.fillSymbol.getSize() / 10);
          context.beginPath();
          context.moveTo(5, 0);
          context.lineTo(5, 10);
          context.moveTo(0, 5);
          context.lineTo(10, 5);
          context.stroke();
        } else if (fillPattern == 'plus') {
          context.translate(Number(this.getFillPatternMargin()), Number(this.getFillPatternMargin()));
          context.scale(this.fillSymbol.getSize() / 10, this.fillSymbol.getSize() / 10);
          context.beginPath();
          context.moveTo(5, 0);
          context.lineTo(5, 10);
          context.moveTo(0, 5);
          context.lineTo(10, 5);
          context.stroke();
        } else if (fillPattern == 'square') {
          context.scale(this.fillSymbol.getSize() / 10, this.fillSymbol.getSize() / 10);
          context.beginPath();
          context.moveTo(1, 1);
          context.lineTo(9, 1);
          context.lineTo(9, 9);
          context.lineTo(1, 9);
          context.lineTo(1, 1);
          if (strokeColor.length > 0) {
            context.stroke();
          }
          if (fillColor.length > 0) {
            context.fill();
          }
          context.setTransform(1, 0, 0, 1, 0, 0);
        } else if (fillPattern == 'dot') {
          context.scale(this.fillSymbol.getSize() / 10, this.fillSymbol.getSize() / 10);
          context.beginPath();
          context.arc(5, 5, 1, 0, 2 * Math.PI);
          if (strokeColor.length > 0) {
            context.stroke();
          }
          if (fillColor.length > 0) {
            context.fill();
          }
          context.setTransform(1, 0, 0, 1, 0, 0);
        } else if (fillPattern == 'circle') {
          context.scale(this.fillSymbol.getSize() / 10, this.fillSymbol.getSize() / 10);
          context.beginPath();
          context.arc(5, 5, 4, 0, 2 * Math.PI);
          if (strokeColor.length > 0) {
            context.stroke();
          }
          if (fillColor.length > 0) {
            context.fill();
          }
          context.setTransform(1, 0, 0, 1, 0, 0);
        } else if (fillPattern == 'horline') {
          context.beginPath();
          context.moveTo(0, 5);
          context.lineTo(10, 5);
          context.stroke();
        } else if (fillPattern == 'vertline') {
          context.beginPath();
          context.moveTo(5, 0);
          context.lineTo(5, 10);
          context.stroke();
        } else if (fillPattern == 'svg') {
          if (img instanceof HTMLImageElement) {
            context.drawImage(
              img,
              this.getFillPatternMargin(),
              this.getFillPatternMargin(),
              this.fillSymbol.getSize(),
              this.fillSymbol.getSize()
            );
          }
        }

        return context.createPattern(canvas, 'repeat');
      })();
      return fillColorFct;
    }
    return;
  }

  getOlStyle(): Fill {
    const fillColor = this.hasFillPattern() ? this.getRenderFunction() : this.getFillColor();
    return new Fill({
      color: fillColor,
    });
  }
}
