import { Component } from '@angular/core';
import { GeoserverVariablesService } from '@widgets/widgets-filter-cql.service';
import { isEmpty } from 'lodash-es';
import { Constraint } from '../../model/constraint.model';
import { OptiSemisService } from '../../opti-semis.service';

@Component({
  selector: 'smv-opti-semis-constraint-filter',
  templateUrl: './opti-semis-constraint-filter.component.html',
  styleUrls: ['./opti-semis-constraint-filter.component.scss'],
})
export class OptiSemisConstraintFilterComponent {
  public constraints: Constraint[] = [];
  public noConstraint = true;

  private filterAttr = 'constraint_code';

  constructor(private geoserverVariables: GeoserverVariablesService, private optiSemisService: OptiSemisService) {
    this.optiSemisService.getConstraintCodes().subscribe((constraints) => {
      constraints.forEach((constraint) => (constraint.selected = true));
      this.constraints = constraints;
      this.computeFilter();
    });
  }

  private getSelectedJoinValues(): string {
    const values = this.constraints
      .filter((filter) => filter.selected)
      .map((filter) => `${this.filterAttr} LIKE '%25${filter.code}%25'`);
    if (this.noConstraint) {
      values.unshift(`${this.filterAttr} IS NULL`);
    }
    if (isEmpty(values)) {
      return 'true = false';
    }
    return values.join(` or `);
  }

  public computeFilter() {
    const filter = `(${this.getSelectedJoinValues()})`;
    this.geoserverVariables.optiSemisFilterState.setParticularValue('constraint', filter);
  }
}
