import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { GeometryType } from '@core/model/application-api/layer.model';
import { RuleForm } from '../layer-base-style-tab/style-form.model';
import { StyleRuleEditComponent } from './style-rule-edit/style-rule-edit.component';

@Component({
  selector: 'smv-style-rule-form',
  templateUrl: './style-rule-form.component.html',
  styleUrls: ['./style-rule-form.component.scss'],
})
export class StyleRuleFormComponent implements OnChanges {
  @Input() rules?: FormArray<FormGroup<RuleForm>>;
  @Input() layerGeometry?: GeometryType;
  @Input() readonly = false;
  @Input() isSortingRules = false;

  @Output() rulesChange = new EventEmitter<FormArray<FormGroup<RuleForm>>>();

  @ViewChild(MatTable) table?: MatTable<FormGroup<RuleForm>>;

  public columns: string[] = [];
  private savedColumns: string[] = [];

  constructor(private dialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isSortingRules']) {
      if (changes['isSortingRules'].currentValue) {
        this.savedColumns = Array.from(this.columns);
        this.columns = ['label', 'cqlValue'];
      } else {
        this.columns = Array.from(this.savedColumns);
      }
    }
    if (changes['layerGeometry']) {
      this.updateColumns();
    }
    this.rules?.valueChanges.subscribe(() => {
      this.rulesChange.emit(this.rules);
      this.table?.renderRows();
    });
  }

  private updateColumns(): void {
    this.columns = ['label', 'minScale', 'maxScale', 'cqlValue'];
    switch (this.layerGeometry) {
      case GeometryType.POINT:
        this.columns.push('pointStroke', 'pointFill');
        break;
      case GeometryType.LINE:
        this.columns.push('polygonStroke');
        break;
      case GeometryType.POLYGON:
        this.columns.push('polygonStroke', 'polygonFill');
        break;
      default:
        this.columns.push('polygonStroke', 'polygonFill', 'pointStroke', 'pointFill');
    }
    this.columns.push('actions');
  }

  editRuleStyle(rule: FormGroup<RuleForm>) {
    this.dialog.open(StyleRuleEditComponent, {
      data: { ruleStyle: rule.get('style'), geometry: this.layerGeometry },
      minWidth: '50vw',
    });
  }

  removeRule(index: number): void {
    this.rules?.removeAt(index);
    this.table?.renderRows();
  }

  onListDrop(event: CdkDragDrop<FormGroup<RuleForm>[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    this.table?.renderRows();
  }
}
