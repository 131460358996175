import { LayerDynamicStyleModel } from '@core/model/layer-dynamic-style';
import { CustomStyle } from '@core/model/layer-style.model';

export const editionCustomStyle: CustomStyle = {
  pointGeometry: false,
  symbolStyle: {
    symbol: 'circle',
    symbolSize: 8,
    symbolStrokeColor: '#000000',
    symbolFillColor: 'rgba(51, 153, 204, 1)',
    symbolStrokeWidth: 0.5,
  },
  fillStyle: {
    fillSymbol: {
      symbol: 'circle',
      symbolSize: 16,
      symbolStrokeColor: '#000000',
      symbolFillColor: 'rgba(51, 153, 204, 0.5)',
      symbolStrokeWidth: 1,
    },
    fillColor: 'rgba(51, 153, 204, 0.5)',
    fillPattern: 'dot',
    fillPatternMargin: 0,
    fillPatternSize: 0,
  },
  strokeStyle: {
    symbol: {
      symbol: 'circle',
      symbolSize: 16,
      symbolStrokeColor: '#000000',
      symbolFillColor: 'rgba(51, 153, 204, 0.5)',
      symbolStrokeWidth: 1,
    },
    strokeColor: '#3399CC',
    strokeWidth: 1,
    strokePattern: 'simple',
    patternMargin: 0,
  },
  textStyle: {
    text: '',
    textMargin: 0,
    offsetX: 0,
    offsetY: 0,
    fontSize: 11,
    textFillColor: '#333333',
    alignment: 'center',
    textStrokeColor: 'rgba(200, 200, 200, 0.6)',
    textBackGroundColor: '',
    textRotation: 0,
  },
};

export const editionDynamicStyle: LayerDynamicStyleModel = {
  attribute: 'to_delete',
  palette: 'no-colors',
  categories: [
    {
      cqlValue: "to_create='true'",
      label: 'Création',
      style: {
        pointGeometry: false,
        symbolStyle: {
          symbol: 'circle',
          symbolSize: 8,
          symbolStrokeColor: '#000000',
          symbolFillColor: 'rgba(69,233,157,0.8)',
          symbolStrokeWidth: 0.5,
        },
        fillStyle: {
          fillSymbol: {
            symbol: 'circle',
            symbolSize: 8,
            symbolStrokeColor: '#000000',
            symbolFillColor: 'rgba(69,233,157,0.8)',
            symbolStrokeWidth: 1,
          },
          fillColor: 'rgba(69,233,157,0.8)',
          fillPattern: 'simple',
          fillPatternMargin: 0,
          fillPatternSize: 0,
        },
        strokeStyle: {
          symbol: {
            symbol: 'circle',
            symbolSize: 16,
            symbolStrokeColor: '#000000',
            symbolFillColor: 'rgba(69,233,157,1)',
            symbolStrokeWidth: 1,
          },
          strokeColor: 'rgba(69,233,157,1)',
          strokeWidth: 1,
          strokePattern: 'simple',
          patternMargin: 0,
        },
        textStyle: {
          text: '',
          textMargin: 0,
          offsetX: 0,
          offsetY: 0,
          fontSize: 11,
          textFillColor: '#333333',
          alignment: 'center',
          textStrokeColor: 'rgba(200, 200, 200, 0.6)',
          textBackGroundColor: '',
          textRotation: 0,
        },
      },
    },
    {
      cqlValue: "to_modify_geometry='true'",
      label: 'Modification de la géométrie',
      style: {
        pointGeometry: false,
        symbolStyle: {
          symbol: 'circle',
          symbolSize: 8,
          symbolStrokeColor: '#000000',
          symbolFillColor: 'rgba(233,242,80,0.57)',
          symbolStrokeWidth: 0.5,
        },
        fillStyle: {
          fillSymbol: {
            symbol: 'circle',
            symbolSize: 16,
            symbolStrokeColor: '#000000',
            symbolFillColor: 'rgba(233,242,80,0.57)',
            symbolStrokeWidth: 1,
          },
          fillColor: 'rgba(233,242,80,0.57)',
          fillPattern: 'simple',
          fillPatternMargin: 0,
          fillPatternSize: 0,
        },
        strokeStyle: {
          symbol: {
            symbol: 'circle',
            symbolSize: 16,
            symbolStrokeColor: '#000000',
            symbolFillColor: 'rgba(233,242,80,1)',
            symbolStrokeWidth: 1,
          },
          strokeColor: 'rgba(233,242,80,1)',
          strokeWidth: 1,
          strokePattern: 'simple',
          patternMargin: 0,
        },
        textStyle: {
          text: '',
          textMargin: 0,
          offsetX: 0,
          offsetY: 0,
          fontSize: 11,
          textFillColor: '#333333',
          alignment: 'center',
          textStrokeColor: 'rgba(200, 200, 200, 0.6)',
          textBackGroundColor: '',
          textRotation: 0,
        },
      },
    },
    {
      cqlValue: "to_modify_attributes='true' ",
      label: 'Modification des attributs',
      style: {
        pointGeometry: false,
        symbolStyle: {
          symbol: 'circle',
          symbolSize: 8,
          symbolStrokeColor: '#000000',
          symbolFillColor: 'rgba(243,186,88,0.75)',
          symbolStrokeWidth: 0.5,
        },
        fillStyle: {
          fillSymbol: {
            symbol: 'circle',
            symbolSize: 16,
            symbolStrokeColor: '#000000',
            symbolFillColor: 'rgba(243,186,88,0.75)',
            symbolStrokeWidth: 1,
          },
          fillColor: 'rgba(243,186,88,0.75)',
          fillPattern: 'simple',
          fillPatternMargin: 0,
          fillPatternSize: 0,
        },
        strokeStyle: {
          symbol: {
            symbol: 'circle',
            symbolSize: 16,
            symbolStrokeColor: '#000000',
            symbolFillColor: 'rgba(243,186,88,1)',
            symbolStrokeWidth: 1,
          },
          strokeColor: 'rgba(243,186,88,1)',
          strokeWidth: 1,
          strokePattern: 'simple',
          patternMargin: 0,
        },
        textStyle: {
          text: '',
          textMargin: 0,
          offsetX: 0,
          offsetY: 0,
          fontSize: 11,
          textFillColor: '#333333',
          alignment: 'center',
          textStrokeColor: 'rgba(200, 200, 200, 0.6)',
          textBackGroundColor: '',
          textRotation: 0,
        },
      },
    },
    {
      cqlValue: "to_delete='true'",
      label: 'Suppression',
      style: {
        pointGeometry: false,
        symbolStyle: {
          symbol: 'circle',
          symbolSize: 8,
          symbolStrokeColor: '#000000',
          symbolFillColor: 'rgba(246,21,21,0.5)',
          symbolStrokeWidth: 0.5,
        },
        fillStyle: {
          fillSymbol: {
            symbol: 'circle',
            symbolSize: 16,
            symbolStrokeColor: '#000000',
            symbolFillColor: 'rgba(246,21,21,0.5)',
            symbolStrokeWidth: 1,
          },
          fillColor: 'rgba(246,21,21,0.5)',
          fillPattern: 'simple',
          fillPatternMargin: 0,
          fillPatternSize: 0,
        },
        strokeStyle: {
          symbol: {
            symbol: 'circle',
            symbolSize: 16,
            symbolStrokeColor: '#000000',
            symbolFillColor: 'rgba(246,21,21,1)',
            symbolStrokeWidth: 1,
          },
          strokeColor: 'rgba(246,21,21,1)',
          strokeWidth: 1,
          strokePattern: 'simple',
          patternMargin: 0,
        },
        textStyle: {
          text: '',
          textMargin: 0,
          offsetX: 0,
          offsetY: 0,
          fontSize: 11,
          textFillColor: '#333333',
          alignment: 'center',
          textStrokeColor: 'rgba(200, 200, 200, 0.6)',
          textBackGroundColor: '',
          textRotation: 0,
        },
      },
    },
    {
      cqlValue: '',
      label: 'Base',
      style: {
        pointGeometry: false,
        symbolStyle: {
          symbol: 'circle',
          symbolSize: 8,
          symbolStrokeColor: '#000000',
          symbolFillColor: 'rgba(51, 153, 204, 0.5)',
          symbolStrokeWidth: 0.5,
        },
        fillStyle: {
          fillSymbol: {
            symbol: 'circle',
            symbolSize: 16,
            symbolStrokeColor: '#000000',
            symbolFillColor: 'rgba(51, 153, 204, 0.5)',
            symbolStrokeWidth: 1,
          },
          fillColor: 'rgba(51, 153, 204, 0.5)',
          fillPattern: 'simple',
          fillPatternMargin: 0,
          fillPatternSize: 0,
        },
        strokeStyle: {
          symbol: {
            symbol: 'circle',
            symbolSize: 16,
            symbolStrokeColor: '#000000',
            symbolFillColor: 'rgba(51, 153, 204, 1)',
            symbolStrokeWidth: 1,
          },
          strokeColor: 'rgba(51, 153, 204, 1)',
          strokeWidth: 1,
          strokePattern: 'simple',
          patternMargin: 0,
        },
        textStyle: {
          text: '',
          textMargin: 0,
          offsetX: 0,
          offsetY: 0,
          fontSize: 11,
          textFillColor: '#333333',
          alignment: 'center',
          textStrokeColor: 'rgba(200, 200, 200, 0.6)',
          textBackGroundColor: '',
          textRotation: 0,
        },
      },
    },
  ],
};
