import { ParsedCapabilities, ParsedLayer } from '@core/model/capabilities.model';
import WMTSCapabilities from 'ol/format/WMTSCapabilities';

// WMTS Schema example:
// https://schemas.opengis.net/wmts/1.0/examples/wmtsGetCapabilities_response.xml
export interface WmtsLayerSchema {
  Title: string;
  Identifier: string;
  Abstract?: string;
  WGS84BoundingBox: [number, number, number, number];
}

export class WMTSCapabilitiesParser {
  private parser = new WMTSCapabilities();

  read(xml: string): ParsedCapabilities {
    return this.parser.read(xml);
  }

  parseLayer(layer: WmtsLayerSchema): ParsedLayer {
    return {
      bbox: layer.WGS84BoundingBox,
      detail: layer.Abstract,
      serverIdentifier: layer.Identifier,
      title: layer.Title,
    };
  }
}
