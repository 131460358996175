export class DragDropItem<T> {
  children: DragDropItem<T>[] = [];
  uniqueId = 'drag-item-' + Math.floor(Math.random() * 10000);
  rootContainerIdBefore = 'parent-before-' + this.uniqueId;
  rootContainerIdAfter = 'parent-after-' + this.uniqueId;
  name: string;
  isRoot = false;
  expanded = true;
  data?: T;

  constructor(name: string, data?: T) {
    this.name = name;
    this.data = data;
  }

  static root<T>(): DragDropItem<T> {
    const item = new DragDropItem<T>('');
    item.isRoot = true;
    return item;
  }

  getData(): T {
    if (this.data === undefined) {
      throw new Error('Missing data for item ' + this.name);
    }
    return this.data;
  }

  getContainers(): string[] {
    return this.isRoot ? [this.uniqueId] : [this.uniqueId, this.rootContainerIdBefore, this.rootContainerIdAfter];
  }

  getChildrenContainers(): string[] {
    const allContainers: string[] = [];
    this.children.forEach((child) => {
      allContainers.push(...child.getContainers());
      allContainers.push(...child.getChildrenContainers());
    });
    return allContainers;
  }

  allowDrop(containerId: string): boolean {
    return !this.getContainers().includes(containerId) && !this.getChildrenContainers().includes(containerId);
  }

  childIndex(childItem: DragDropItem<T>): number {
    return this.children.findIndex((child) => child.uniqueId === childItem.uniqueId);
  }

  hasChild(childItem: DragDropItem<T>): boolean {
    return this.children.some((child) => child.uniqueId === childItem.uniqueId);
  }

  removeChild(childItem: DragDropItem<T>): void {
    this.children = this.children.filter((child) => child.uniqueId !== childItem.uniqueId);
  }
}
