import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { DropdownOption } from '@components/form/form-field-wrapper/form-field-wrapper.component';
import { TableComponent } from '@components/table/table.component';
import { CellType, Column, DefaultType, GeneralActions } from '@components/table/table.model';
import { Application } from '@core/model/application.model';
import { Customer } from '@core/model/customer.model';
import { ApplicationApiService } from '@core/services/api/application-api.service';
import { CustomerService } from '@core/services/customer.service';
import { DateUtils } from '@core/utils/date.utils';
import { Periodicity } from '@feature/client-app/widgets/timeline/timeline.model';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Loader, LoadingComponent } from '@components/loading/loading.component';
import { finalize } from 'rxjs';

export interface NotifyCustomerConfig {
  customers: Customer[];
  pastWeekCount: number;
}

@Component({
  selector: 'smv-notify-customer',
  standalone: true,
  imports: [
    CommonModule,
    LoadingComponent,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSlideToggleModule,
    TableComponent,
  ],
  templateUrl: './notify-customer.component.html',
  styleUrls: ['./notify-customer.component.scss'],
})
export class NotifyCustomerComponent {
  public dateOptions: DropdownOption<number>[] = [];
  public pastWeekCount = 0;

  public useSendDateVar = false;
  @Output() useSendDate = new EventEmitter<boolean>();

  public dateSelected?: number;

  public actionsConfig: GeneralActions<Customer> = {
    tableOnly: true,
    checkboxAllSelected: true,
  };
  public columns: Column<Customer, DefaultType<number>>[] = [
    {
      field: 'code',
      label: $localize`:Table header:Code`,
      type: CellType.String,
      cellData: 'code',
    },
    { field: 'name', label: $localize`:Table header:Nom`, type: CellType.String, cellData: 'name' },
  ];
  public customers: Customer[] = [];
  public selectedCustomers: Customer[] = [];

  private application?: Application;

  public loader: Loader = {
    text: $localize`:Notify customer|Getting loader:Chargement des données, veuillez patienter`,
    condition: false,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) dates: string[],
    private dialogRef: MatDialogRef<NotifyCustomerComponent>,
    private customerService: CustomerService,
    private applicationService: ApplicationApiService
  ) {
    this.application = this.applicationService.currentApplication.getValue();
    this.updateDates(dates);
  }

  updateDates(dates: string[]) {
    if (this.application) {
      const firstWeek = this.buildSelectWeek(
        this.application,
        dates.map((date) => new Date(date))
      );
      this.dateSelected = firstWeek;
      this.changeDataWeek(firstWeek);
    }
  }

  changeUseSendDate(useSendDate: boolean) {
    this.useSendDateVar = useSendDate;
    this.useSendDate.emit(useSendDate);
  }

  changeDataWeek(pastWeekCount: number) {
    this.dateSelected = pastWeekCount;
    this.pastWeekCount = pastWeekCount;
    if (this.application) {
      this.loader.condition = true;
      this.customerService
        .getCustomersWithSamples(this.application.id, this.pastWeekCount, this.useSendDateVar)
        .pipe(finalize(() => (this.loader.condition = false)))
        .subscribe({
          next: (customers) => (this.customers = customers),
        });
    }
  }

  sendData() {
    const config: NotifyCustomerConfig = {
      customers: this.selectedCustomers,
      pastWeekCount: this.pastWeekCount,
    };
    this.dialogRef.close(config);
  }

  private buildSelectWeek(app: Application, availableDates: Date[]) {
    const year = new Date().getFullYear();
    let splitDate = app.functionnalityConfig.campaignStart.split('/');
    const startDate = new Date(`${splitDate[1]}/${splitDate[0]}/${year}`);
    splitDate = app.functionnalityConfig.campaignEnd.split('/');
    let endDate = new Date(`${splitDate[1]}/${splitDate[0]}/${year}`);

    if (endDate.valueOf() < startDate.valueOf()) {
      endDate.setFullYear(year + 1);
    }
    if (endDate.valueOf() > new Date().valueOf()) {
      endDate = new Date();
    }

    const dates = DateUtils.getDates(DateUtils.getFirstDayOfWeek(startDate), endDate, Periodicity.WEEKS);
    dates.reverse();

    const comparableDates = availableDates
      .filter((availableDate) => availableDate.valueOf() >= startDate.valueOf())
      .map((availableDate) => availableDate.toDateString());
    this.dateOptions = [];
    for (const dateIndex in dates) {
      if (comparableDates.includes(dates[dateIndex].toDateString())) {
        this.dateOptions.push({
          label: `Semaine ${DateUtils.getWeekNumber(dates[dateIndex])} (${DateUtils.getDateForGraphic(
            dates[dateIndex]
          )})`,
          value: Number(dateIndex),
        });
      }
    }

    return this.dateOptions.length ? this.dateOptions[0].value : 0;
  }
}
