import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(private snackbar: MatSnackBar) {}

  success(message: string, closeAfter?: number | 'never', multiline?: boolean): void {
    this.openSnackbar('success', message, closeAfter, multiline);
  }

  error(message: string, closeAfter?: number | 'never', multiline?: boolean): void {
    this.openSnackbar('error', message, closeAfter, multiline);
  }

  unknownServerError(closeAfter?: number | 'never'): void {
    this.openSnackbar(
      'error',
      $localize`:Error message|Unknown technical error:Une erreur technique est survenue. Veuillez réessayer ultérieurement ou prendre contact avec l'administrateur.`,
      closeAfter
    );
  }

  private openSnackbar(
    type: string,
    message: string,
    closeAfter?: number | 'never',
    multiline?: boolean,
    action = 'X'
  ) {
    const config: MatSnackBarConfig = { panelClass: ['notification-bar-' + type] };
    if (closeAfter !== 'never') {
      config.duration = closeAfter ?? 3000;
    }
    if (multiline && Array.isArray(config.panelClass)) {
      config.panelClass.push('notification-bar-multiline');
    }
    this.snackbar.open(message, action, config);
  }
}
