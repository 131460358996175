import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationApiService } from '@core/services/api/application-api.service';
import { CommonService } from '@core/services/common.service';
import { NotificationService } from '@core/services/notification.service';
import { finalize } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UploadFileService extends CommonService {
  constructor(
    protected override http: HttpClient,
    private applicationService: ApplicationApiService,
    private notificationService: NotificationService
  ) {
    super(http);
  }

  uploadFile(file: File, url: string, succesMessage: string, callback?: () => void): void {
    const formData: FormData = new FormData();
    formData.append('file', file);
    this.http
      .post(this.baseUrl + url, formData, { responseType: 'text' })
      .pipe(finalize(() => (callback ? callback() : null)))
      .subscribe({
        next: (data) => {
          if (data.length > 0) {
            succesMessage += '\nMessage du serveur:\n' + data;
          }
          this.notificationService.success(succesMessage, data.length > 0 ? 'never' : undefined, data.length > 0);
        },
        error: (data) => {
          if (data?.error?.length && data.headers.get('SyngentaErrorType') === 'syngenta-raw-error-message') {
            return this.notificationService.error(data.error, 'never', true);
          }
          this.notificationService.unknownServerError();
        },
      });
  }
}
