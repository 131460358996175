import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Server, servers } from '@assets/servers/servers';
import { LayerSubtype, LayerType } from '@core/model/application-api/layer.model';
import { ParsedLayer, ParsedStyle } from '@core/model/capabilities.model';
import {
  LayerConfig,
  VectorLayerConfig,
  WfsLayerConfig,
  WmsLayerConfig,
  WmtsLayerConfig,
} from '@core/model/layer-config.model';
import { User } from '@core/model/user.model';
import { ApplicationApiService } from '@core/services/api/application-api.service';
import { AuthService } from '@core/services/auth.service';
import { CapabilitiesParserService } from '@core/services/capabilities-parser.service';
import { LayerService } from '@core/services/layer.service';
import { NotificationService } from '@core/services/notification.service';
import { Indexable } from '@core/utils/general.utils';
import { Observable, finalize } from 'rxjs';

enum LayerSource {
  EXTERNAL_WMTS,
  EXTERNAL_WMS,
  EXTERNAL_WFS,
  GEOJSON,
}

interface GeojsonForm {
  layerName: FormControl<string | null>;
  url: FormControl<string | null>;
}

interface StreamForm {
  layerName: FormControl<string | null>;
  url: FormControl<string | null>;
  layer: FormControl<string | null>;
  style: FormControl<string | null>;
}

@Component({
  selector: 'smv-external-layer',
  templateUrl: './external-layer.component.html',
  styleUrls: ['./external-layer.component.scss'],
})
export class ExternalLayerComponent implements OnInit {
  @Input() preferredProjection!: string;
  @Input() creating = false;

  @Output() creatingChange = new EventEmitter<boolean>();
  @Output() closePanel = new EventEmitter<void>();
  @Output() saveLayer = new EventEmitter<LayerConfig>();

  private currentUser?: User;

  public streamForm = new FormGroup<StreamForm>({
    layerName: new FormControl(null, Validators.required),
    url: new FormControl<string | null>(null, Validators.required),
    layer: new FormControl<string | null>(null, [Validators.required, this.layerSelectionValidator.bind(this)]),
    style: new FormControl<string | null>(null),
  });

  public geojsonForm = new FormGroup<GeojsonForm>({
    layerName: new FormControl(null, Validators.required),
    url: new FormControl<string | null>(null, Validators.required),
  });

  public readonly LayerSource = LayerSource;
  public selectedSource = LayerSource.EXTERNAL_WMTS;
  public activeForm: FormGroup = this.streamForm;
  public detectedLayers: ParsedLayer[] = [];
  public filteredLayers: ParsedLayer[] = [];
  public layerStyles: ParsedStyle[] = [];
  public customErrors: Indexable<string> = {
    unmatchedLayer: $localize`La couche sélectionnée n'est pas disponible sur le serveur`,
  };
  public loadingLayers = false;
  public useSmvServer = false;
  public servers: Server[] = [];
  public isHeatMap = new FormControl<boolean>(false);

  constructor(
    private capabilitiesParser: CapabilitiesParserService,
    private authService: AuthService,
    private layerService: LayerService,
    private notification: NotificationService,
    private applicationService: ApplicationApiService
  ) {
    this.authService.whoAmI().subscribe((user: User) => {
      if (user) {
        this.currentUser = user;
      }
    });
  }

  ngOnInit(): void {
    this.streamForm.controls.layer.valueChanges.subscribe(
      (layerInputValue) =>
        (this.filteredLayers = layerInputValue?.length ? this.filterLayers(layerInputValue) : this.detectedLayers)
    );
  }

  updateSource(source: LayerSource): void {
    this.geojsonForm.patchValue({ layerName: null, url: null });
    this.streamForm.patchValue({ layerName: null, layer: null, url: null, style: null });
    this.activeForm = source === LayerSource.GEOJSON ? this.geojsonForm : this.streamForm;
    this.selectedSource = source;
    this.useSmvServer = false;
  }

  toggleSmvServer(active: boolean): void {
    this.useSmvServer = active;
    if (active) {
      this.servers = servers['smv'].filter(this.filterServers.bind(this));
      if (this.currentUser?.groupName) {
        const groupName = this.currentUser?.groupName.toLowerCase();
        if (Object.keys(servers).includes(groupName)) {
          this.servers.unshift(...servers[groupName].filter(this.filterServers.bind(this)));
        }
      }
      this.servers.forEach(
        (server) =>
          (server.url = server.url.replace(
            '{appId}',
            this.applicationService.currentApplication.getValue()?.id.toString() ?? 'undefined'
          ))
      );
      this.streamForm.controls.url.setValue(this.servers[this.servers.length - 1].url);
    } else {
      this.streamForm.controls.url.setValue(null);
    }
  }

  private filterServers(server: Server): boolean {
    switch (this.selectedSource) {
      case LayerSource.EXTERNAL_WFS:
        return server.type === LayerType.WFS;
      case LayerSource.EXTERNAL_WMS:
        return server.type === LayerType.WMS;
      case LayerSource.EXTERNAL_WMTS:
        return server.type === LayerType.WMTS;
      default:
        return false;
    }
  }

  createLayer(): void {
    this.creating = true;
    if (this.selectedSource === LayerSource.GEOJSON) {
      const { layerName, url } = this.geojsonForm.value;
      if (layerName && url) {
        const newLayer = new VectorLayerConfig();
        newLayer.shortName = layerName;
        newLayer.type = LayerType.VECTOR;
        newLayer.url = url;
        this.saveLayer.emit(newLayer);
      }
    } else {
      const { layerName, layer } = this.streamForm.value;
      // Cannot be accessed from the streamForm.value when the field is disabled (SMV server)
      const url = this.streamForm.controls.url.getRawValue();

      const parsedLayer = this.detectedLayers.find((l) => l.title.toLowerCase() === layer?.toLowerCase());
      if (layerName && url && parsedLayer) {
        const newLayer = LayerConfig.fromConfig(
          new Map()
            .set('shortName', layerName)
            .set('url', url)
            .set('geoserverLayerName', parsedLayer?.serverIdentifier)
            .set('bboxEpsg4326', parsedLayer.bbox)
        );
        switch (this.selectedSource) {
          case LayerSource.EXTERNAL_WMTS:
            this.createWmtsConfig(newLayer);
            break;
          case LayerSource.EXTERNAL_WMS:
            this.createWmsConfig(newLayer, parsedLayer);
            break;
          case LayerSource.EXTERNAL_WFS:
            this.createWfsConfig(newLayer, parsedLayer);
            break;
        }
      }
    }
  }

  searchAvailableLayers(): void {
    const url = this.streamForm.controls.url.value;
    if (url) {
      this.detectedLayers = [];
      this.loadingLayers = true;
      this.streamForm.controls.layer.setValue(null);
      let capabilitiesRequest$: Observable<ParsedLayer[]> | undefined = undefined;
      switch (this.selectedSource) {
        case LayerSource.EXTERNAL_WMTS:
          capabilitiesRequest$ = this.capabilitiesParser.parseWmtsCapabilities(url);
          break;
        case LayerSource.EXTERNAL_WMS:
          capabilitiesRequest$ = this.capabilitiesParser.parseWmsCapabilities(url);
          break;
        case LayerSource.EXTERNAL_WFS:
          capabilitiesRequest$ = this.capabilitiesParser.parseWfsCapabilities(url, this.preferredProjection);
          break;
      }

      if (capabilitiesRequest$) {
        capabilitiesRequest$.pipe(finalize(() => (this.loadingLayers = false))).subscribe({
          next: (layers) => {
            this.detectedLayers = layers.sort((l1, l2) => l1.title.toLowerCase().localeCompare(l2.title.toLowerCase()));
            this.filteredLayers = this.detectedLayers;
          },
          error: (e) => {
            {
              console.error(e);
              this.notification.error(
                $localize`Une erreur est survenue. Veuillez vérifier que vous avez sélectionné le bon type de flux et que le service est disponible.`
              );
            }
          },
        });
      }
    }
  }

  selectLayer(layer: ParsedLayer): void {
    this.streamForm.controls.layerName.setValue(layer.title);
    this.layerStyles = layer.styles ?? [];
    if (this.layerStyles.length) {
      this.streamForm.controls.style.setValue(this.layerStyles[0].name);
    } else {
      this.streamForm.controls.style.setValue(null);
    }
  }

  private filterLayers(name: string): ParsedLayer[] {
    return this.detectedLayers.filter((layer) => layer.title.toLowerCase().includes(name));
  }

  private layerSelectionValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const matchedLayer = this.detectedLayers.find((layer) => layer.title.toLowerCase() === control.value.toLowerCase());
    return matchedLayer ? null : { unmatchedLayer: true };
  }

  private createWmtsConfig(baseConfig: LayerConfig): void {
    const newLayer = WmtsLayerConfig.fromLayer(baseConfig);
    newLayer.type = LayerType.WMTS;
    const chosenServer = this.servers.find((server) => server.url === newLayer.url);
    newLayer.ignoreUrlInCapabilities = chosenServer?.ignoreUrlInCapabiltiesResponse ?? false;
    this.saveLayer.emit(newLayer);
  }

  private createWmsConfig(baseConfig: LayerConfig, parsedLayer: ParsedLayer): void {
    const newLayer = WmsLayerConfig.fromLayer(baseConfig);
    newLayer.type = LayerType.WMS;
    newLayer.attributions = parsedLayer.attributions;
    newLayer.maxScaleDenominator = parsedLayer.maxScaleDenominator;
    newLayer.minScaleDenominator = parsedLayer.minScaleDenominator;
    if (parsedLayer.styles?.length) {
      newLayer.wmsStyles = parsedLayer.styles.map((style) => ({
        style: style.name,
        legendUrl: style.legendUrl,
        name: style.title,
      }));
      newLayer.activeStyle = this.streamForm.controls.style.getRawValue() ?? '';
      newLayer.legendUrl = newLayer.getActiveStyle()?.legendUrl;
    }
    this.saveLayer.emit(newLayer);
  }

  private createWfsConfig(baseConfig: LayerConfig, parsedLayer: ParsedLayer): void {
    const newLayer = WfsLayerConfig.fromLayer(baseConfig);
    newLayer.type = LayerType.WFS;
    if (this.isHeatMap.value) {
      newLayer.subtype = LayerSubtype.HEATMAP;
      newLayer.heatmap = {
        heatBlur: 15,
        heatRadius: 8,
      };
    }
    newLayer.projection = parsedLayer.projection;
    if (parsedLayer.outputFormat) {
      newLayer.outputFormat = parsedLayer.outputFormat;
    }
    this.layerService
      .getGeoserverConfig(newLayer)
      .pipe(finalize(() => this.saveLayer.emit(newLayer)))
      .subscribe((featuresType) => {
        newLayer.geoserverNameSpace = featuresType.targetNamespace;
        newLayer.geoserverPrefix = featuresType.targetPrefix;
      });
  }
}
