import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService, ListWrapper } from '@core/services/common.service';
import { Constraint } from './model/constraint.model';
import { Crop } from './model/crop.model';
import { Recommendation } from './model/recommendation.model';

@Injectable({ providedIn: 'root' })
export class OptiSemisService extends CommonService {
  protected override readonly baseUrl: string = this.baseUrl + '/optiSemis';

  constructor(protected override http: HttpClient) {
    super(http);
  }

  getCrops() {
    return this.pipeExtractList(this.http.get<ListWrapper<Crop>>(`${this.baseUrl}/crops`));
  }

  getRecommendations() {
    return this.pipeExtractList(this.http.get<ListWrapper<Recommendation>>(`${this.baseUrl}/recommendations`));
  }

  getConstraintCodes() {
    return this.pipeExtractList(this.http.get<ListWrapper<Constraint>>(`${this.baseUrl}/constraintCodes`));
  }
}
