<div class="smv-dialog">
  <h1 mat-dialog-title>{{ config.title }}</h1>
  <mat-divider></mat-divider>
  <div mat-dialog-content>
    <div class="dialog-content">
      <iframe [src]="url" allowfullscreen frameborder="0"></iframe>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true" i18n>Ok</button>
  </div>
</div>
