import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { FormFieldWrapperComponent } from '@components/form/form-field-wrapper/form-field-wrapper.component';

interface DialogData {
  userEmail: string;
  default?: string;
}

@Component({
  selector: 'smv-notify-feedback-dialog',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule, MatDividerModule, FormFieldWrapperComponent],
  templateUrl: './notify-feedback-dialog.component.html',
  styleUrls: ['./notify-feedback-dialog.component.scss'],
})
export class NotifyFeedbackDialogComponent {
  public userEmail: string;
  public feedback = new FormControl<string>('', Validators.required);

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.userEmail = data.userEmail;
    this.feedback.patchValue(data.default ?? '');
  }
}
