import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationApiService } from '@core/services/api/application-api.service';
import { CommonService } from '@core/services/common.service';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EditionCommentService extends CommonService {
  protected override readonly baseUrl: string = this.baseUrl + '/edition/comment';

  constructor(protected override http: HttpClient, private applicationService: ApplicationApiService) {
    super(http);
  }

  public getCommentOfProposal(
    proposalId: number,
    dataLayerCode: string,
    email: string
  ): Observable<string | undefined> {
    const appId = this.applicationService.currentApplication.getValue()?.id;
    if (appId) {
      const params = {
        appId,
        proposalId,
        dataLayerCode,
        email,
      };
      return this.http.get(this.baseUrl, { params, responseType: 'text' });
    }
    return of(undefined);
  }

  public deleteComments(proposalIds: number[], dataLayerCode: string): Observable<number | undefined> {
    const appId = this.applicationService.currentApplication.getValue()?.id;
    if (appId) {
      const params = {
        appId,
        proposalIds,
        dataLayerCode,
      };
      return this.http.delete<number>(this.baseUrl, { params });
    }
    return of(undefined);
  }
}
