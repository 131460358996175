<smv-panel title="Type de sol" i18n-title="Revolt|Soil Type Filter">
  <mat-radio-group [formControl]="selectedSoilType">
    <mat-radio-button
      (click)="$event.stopPropagation()"
      *ngFor="let soilType of soilTypes"
      [value]="soilType.waterAvailability"
    >
      {{ soilType.label }}
    </mat-radio-button>
  </mat-radio-group>
</smv-panel>
