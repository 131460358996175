import { CommonModule } from '@angular/common';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { FormFieldWrapperComponent } from '@components/form/form-field-wrapper/form-field-wrapper.component';
import { SpecificationConfig } from '@core/model/application.model';
import { AuthService } from '@core/services/auth.service';
import { NotificationService } from '@core/services/notification.service';
import { VigieViroseService } from '@widgets/vigie-virose/services/vigie-virose.service';
import { GeoserverVariablesService } from '../widgets/widgets-filter-cql.service';

interface SpecifierInfo {
  email: FormControl<string | undefined>;
  entity: FormControl<string | undefined>;
}

interface DialogData {
  config: SpecificationConfig;
  appId: number;
}

@Component({
  selector: 'smv-specifier-information',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    ReactiveFormsModule,
    FormFieldWrapperComponent,
  ],
  templateUrl: './specifier-information.component.html',
  styleUrls: ['./specifier-information.component.scss'],
})
export class SpecifierInformationComponent {
  public config: SpecificationConfig;
  public specifierForm = new FormGroup<SpecifierInfo>({
    email: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.email, Validators.required],
    }),
    entity: new FormControl<string | undefined>(undefined, { nonNullable: true, validators: Validators.required }),
  });

  private appId: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: DialogData,
    private dialogRef: MatDialogRef<SpecifierInformationComponent>,
    private geoserverVariables: GeoserverVariablesService,
    private vvService: VigieViroseService,
    private authService: AuthService,
    private notificationService: NotificationService
  ) {
    this.config = data.config;
    this.appId = data.appId;
    this.geoserverVariables.specifierFilterState.setValue('true = false');
    this.specifierForm.patchValue({ email: this.authService.getSpecifierUserEmailInSync() });
  }

  public handleConfirm() {
    const email = this.specifierForm.value.email;
    const entity = this.specifierForm.value.entity;
    if (email && entity) {
      this.authService.setSpecifierUser(email, this.appId, this.config.restrictToKnown).subscribe((user) => {
        if (!user && this.config.restrictToKnown) {
          this.notificationService.error(
            $localize`:Specifier Information:L'email que vous avez renseigné ne correspond à aucun spécificateur pour cette application. Veuillez contacter un administrateur.`
          );
        } else {
          this.geoserverVariables.editionValidationState.setParticularValue('editors', [user?.email ?? email]);
          this.vvService.checkCanSpecifyTrap(entity, user?.email ?? email, this.config.restrictToKnown).subscribe({
            next: (response: boolean) => {
              if (response) {
                this.geoserverVariables.specifierFilterState.setValue(
                  this.config.attributeName + " = '" + entity + "'"
                );
                this.dialogRef.close(true);
              } else {
                this.notificationService.error(
                  $localize`:Specifier Information:Vous n'avez pas les droits pour accéder au piège renseigné. Veuillez contacter un administrateur.`
                );
              }
            },
            error: (error: HttpErrorResponse) => {
              if (error.status === HttpStatusCode.BadRequest) {
                this.notificationService.error(
                  $localize`:Specifier Information:Le code piège ${entity} n'existe pas. Veuillez vérifier vos informations ou contacter un administrateur.`
                );
              } else {
                this.notificationService.error(
                  $localize`:Specifier Information:Vous n'avez pas les droits pour accéder au piège renseigné. Veuillez contacter un administrateur.`
                );
              }
            },
          });
        }
      });
    }
  }
}
