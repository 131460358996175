import { DateUtils } from '@core/utils/date.utils';

export enum Periodicity {
  DAYS = 'Jours',
  WEEKS = 'Semaines',
  MONTHS = 'Mois',
  YEARS = 'Annees',
}

export enum PlayState {
  INITIAL = 'INITIAL',
  PLAYING = 'PLAYING',
  PAUSING = 'PAUSING',
}

export class Timeline {
  private date: Date;
  private periodicite: Periodicity;

  constructor(selectedDate: Date, selectedPeriodicite: Periodicity) {
    this.date = selectedDate;
    this.periodicite = selectedPeriodicite;
  }

  get value(): string {
    return DateUtils.convertDateInUTC(this.date).toISOString();
  }

  get startValue() {
    switch (this.periodicite) {
      case Periodicity.DAYS:
        return this.value;
      case Periodicity.WEEKS:
        return DateUtils.getUTCFirstDayOfWeek(this.date).toISOString();
      case Periodicity.MONTHS:
        return DateUtils.getUTCFirstDayOfMonth(this.date).toISOString();
      case Periodicity.YEARS:
        return DateUtils.getUTCFirstDayOfYear(this.date).toISOString();
      default:
        return undefined;
    }
  }

  get endValue() {
    switch (this.periodicite) {
      case Periodicity.DAYS:
        return this.value;
      case Periodicity.WEEKS:
        return DateUtils.getUTCLastDayOfWeek(this.date).toISOString();
      case Periodicity.MONTHS:
        return DateUtils.getUTCLastDayOfMonth(this.date).toISOString();
      case Periodicity.YEARS:
        return DateUtils.getUTCLastDayOfYear(this.date).toISOString();
      default:
        return undefined;
    }
  }
}
