import { CustomLayerStyle, CustomStyle } from '../layer-style.model';
import { StyleCqlFilter } from './style-filter.model';

export interface StyleRuleModel {
  cqlValue?: string;
  minScaleDenom?: number;
  maxScaleDenom?: number;
  label?: string;
  style: CustomStyle;
}

export class StyleRule {
  public cqlValue?: string;
  public minScaleDenom?: number;
  public maxScaleDenom?: number;
  public label?: string;
  public filter?: StyleCqlFilter;
  public style: CustomLayerStyle;

  constructor(rule?: StyleRuleModel) {
    if (rule) {
      Object.assign(this, rule);
    }
    if (rule?.cqlValue) {
      this.filter = new StyleCqlFilter();
      this.filter.computeFilter(rule.cqlValue);
    }
    this.style = new CustomLayerStyle(rule?.style);
  }

  clone() {
    return new StyleRule(this.toModel());
  }

  toModel(): StyleRuleModel {
    return {
      cqlValue: this.cqlValue,
      minScaleDenom: this.minScaleDenom,
      maxScaleDenom: this.maxScaleDenom,
      label: this.label,
      style: this.style.toModel(),
    };
  }
}
