import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validate that the value does not include a substring.
 *
 * @returns Validation errors with "excludePattern" key
 */
export function excludePatternValidator(pattern: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    return String(control.value).match(new RegExp(pattern, 'm'))?.length ? { excludePattern: pattern } : null;
  };
}

/**
 * Validate that the value does not include a substring.
 *
 * @returns Validation errors with "excludePattern" key
 */
export function excludeValueValidator(value: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    return String(control.value) === value ? { excludeValue: value } : null;
  };
}
