<div *ngIf="this.url" class="upload-file-fields">
  <mat-form-field subscriptSizing="dynamic">
    <mat-label>{{ label }}</mat-label>
    <input name="filename" matInput [(ngModel)]="filename" readonly (click)="UploadFileInput.click()" />
    <button
      mat-icon-button
      matSuffix
      color="primary"
      matTooltip="importer"
      (click)="UploadFileInput.click()"
      i18n-matTooltip
    >
      <mat-icon>cloud_upload</mat-icon>
    </button>
    <input hidden type="file" #UploadFileInput (change)="filename = onFileChange($event)" [accept]="accept" />
  </mat-form-field>
  <button
    mat-raised-button
    color="primary"
    [ngClass]="{ btnSpinner: loading }"
    [disabled]="!this.file"
    (click)="launchImport()"
    i18n
  >
    Lancer l'importation
  </button>
</div>
