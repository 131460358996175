import { editionCustomStyle, editionDynamicStyle } from '@assets/styles/edition-style';
import {
  AttributeType,
  GeometryType,
  Layer,
  LayerEditType,
  LayerGroup,
  LayerType,
} from '@core/model/application-api/layer.model';
import { WidgetModel, WidgetType } from '@core/model/application-api/widget.model';
import { AttributesConfig, buildLayerAttributes } from '@core/utils/app-config-utils';
import { environment } from '@env/environment';

export const QualiH2oWidget: WidgetModel = {
  widgetId: WidgetType.QUALIH2O,
  visible: true,
  configuration: { isVisible: true },
  parameters: [],
};

export const RPG_GEOSERVER_LAYER = 'syngenta:rpg';
export const AAC_GEOSERVER_LAYER = 'syngenta:aac';

const baseUrl = '/services-webapp-syngentamap/proxy-carte/{appId}';
const wmtsBaseUrl = '/services-webapp-syngentamap/proxy-carte/{appId}/gwc/wmts';

const wmsCapabilitiesUrl = baseUrl + '?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetCapabilities';
const wfsCapabilitiesUrl = baseUrl + '?SERVICE=WFS&VERSION=2.0.0&REQUEST=GetCapabilities';
const wmtsCapabilitiesUrl = wmtsBaseUrl + '?service=WMTS&version=1.1.1&request=GetCapabilities';
const legendUrlServerPrefix =
  environment.apiUrl +
  '/proxy-carte/{appId}?service=WMS&version=1.3.0&request=GetLegendGraphic&format=image%2Fpng&width=20&height=20';

export const kmlExportBaseUrl = baseUrl + '?SERVICE=WMS&VERSION=1.3.0';

export const QualiH2oLayerGroup: (Layer | LayerGroup)[] = [
  {
    fromWidget: WidgetType.QUALIH2O,
    layername: RPG_GEOSERVER_LAYER,
    boundingBoxEPSG4326: [-5.226042547032825, 41.28749085215904, 10.753188548411197, 51.09961803651709],
    description: 'Parcelles issues des données Registre Parcellaire Graphique (RPG)',
    isLegendDisplayed: true,
    layerProperties: buildLayerAttributes(
      new Map<string, AttributesConfig>([
        ['id_parcel', { type: AttributeType.STRING, editable: false }],
        ['vulnerabilite', { type: AttributeType.STRING, editable: false }],
        ['code_groupe', { type: AttributeType.STRING, editable: false }],
        ['groupe_culture', { type: AttributeType.STRING, editable: false }],
        ['nom_culture', { type: AttributeType.STRING, editable: false }],
        ['commune', { type: AttributeType.STRING, editable: false }],
        ['surf_parc', { type: AttributeType.NUMBER, editable: false }],
        ['surf_buffer_5', { type: AttributeType.NUMBER, editable: false }],
        ['surf_buffer_20', { type: AttributeType.NUMBER, editable: false }],
        ['surf_buffer_50', { type: AttributeType.NUMBER, editable: false }],
        ['surf_buffer_100', { type: AttributeType.NUMBER, editable: false }],
      ])
    ),
    maxScaleDenominator: 200000,
    opacity: 1,
    nom_court: 'Parcelles',
    type: LayerType.WMS,
    visible: true,
    zIndex: 19,
    activeStyle: 'rpg_vierge',
    styles: [
      {
        style: 'cultures',
        legendUrl: legendUrlServerPrefix + '&layer=syngenta%3Arpg',
        name: 'cultures',
      },
      {
        style: 'risque',
        legendUrl: legendUrlServerPrefix + '&layer=syngenta%3Arpg',
        name: 'risque',
      },
      {
        style: 'rpg_vierge',
        legendUrl: legendUrlServerPrefix + '&layer=syngenta%3Arpg',
        name: 'rpg_vierge',
      },
    ],
    url: wmsCapabilitiesUrl,
    ignoreUrlInCapabiltiesResponse: true,
  },
  {
    fromWidget: WidgetType.QUALIH2O,
    baseLayer: true,
    layername: 'syngenta:departements',
    boundingBoxEPSG4326: [-5.214783667830763, 41.28003552055111, 9.633560056356272, 51.13341608026269],
    isLegendDisplayed: false,
    opacity: 1,
    nom_court: 'Départements',
    type: LayerType.WMTS,
    visible: false,
    zIndex: 18,
    activeStyle: 'syngenta:departements',
    styles: [],
    url: wmtsCapabilitiesUrl,
    ignoreUrlInCapabiltiesResponse: true,
  },
  {
    fromWidget: WidgetType.QUALIH2O,
    baseLayer: true,
    maxScaleDenominator: 200000,
    layername: 'syngenta:raster_vulnerabilite',
    boundingBoxEPSG4326: [-4.788014453355911, 42.44387965466498, 8.096183653906724, 50.901446759389614],
    isLegendDisplayed: false,
    opacity: 1,
    nom_court: 'Indicateur de vulnérabilité par pixel (25m x 25m)',
    type: LayerType.WMTS,
    visible: false,
    zIndex: 17,
    activeStyle: 'raster',
    styles: [],
    url: wmtsCapabilitiesUrl,
    ignoreUrlInCapabiltiesResponse: true,
  },
  {
    fromWidget: WidgetType.QUALIH2O,
    categorie: 'Hydrographie - Edition',
    visible: false,
    childs: [
      {
        fromWidget: WidgetType.QUALIH2O,
        layername: 'syngenta:point_captage',
        targetNamespace: 'http://syngenta',
        targetPrefix: 'syngenta',
        boundingBoxEPSG4326: [-5.165620514252545, 42.2008216257378, 8.350550895276953, 50.91382149651051],
        isLegendDisplayed: true,
        layerProperties: buildLayerAttributes(
          new Map<string, AttributesConfig>([
            ['Num', { type: AttributeType.INT }],
            ['nom_ouvrage', { type: AttributeType.STRING }],
            ['nom_point_prelevement', { type: AttributeType.STRING }],
            ['codeinsee', { type: AttributeType.INT }],
            ['commune', { type: AttributeType.STRING }],
            ['dpt', { type: AttributeType.INT }],
            ['esu_eso', { type: AttributeType.STRING }],
            ['typecapatg', { type: AttributeType.STRING }],
            ['code_aac', { type: AttributeType.STRING }],
            ['code_bss', { type: AttributeType.STRING }],
            ['type_point_prelevement', { type: AttributeType.STRING }],
            ['problematique', { type: AttributeType.STRING }],
            ['Longitude', { type: AttributeType.NUMBER }],
            ['Latitude', { type: AttributeType.NUMBER }],
            ['date_modif', { type: AttributeType.DATE_TIME, editable: false }],
            ['user_modif', { type: AttributeType.STRING, editable: false }],
            ['date_creat', { type: AttributeType.DATE_TIME, editable: false }],
            ['user_creat', { type: AttributeType.STRING, editable: false }],
            ['is_modified', { type: AttributeType.BOOLEAN, editable: false, display: false }],
            ['linked_entity', { type: AttributeType.STRING, editable: false, display: false }],
            ['to_create', { type: AttributeType.BOOLEAN, editable: false, display: false }],
            ['to_modify_geometry', { type: AttributeType.BOOLEAN, editable: false, display: false }],
            ['to_modify_attributes', { type: AttributeType.BOOLEAN, editable: false, display: false }],
            ['to_delete', { type: AttributeType.BOOLEAN, editable: false, display: false }],
          ])
        ),
        geometryType: GeometryType.POINT,
        editable: true,
        editionType: LayerEditType.ALL,
        filter: '{EDITION_VALIDATION}',
        useCustomSld: true,
        useEditionStyle: true,
        style: editionCustomStyle,
        dynamicStyle: editionDynamicStyle,
        maxFeatures: 3000,
        opacity: 1,
        nom_court: 'Point de captage',
        type: LayerType.WFS,
        visible: false,
        zIndex: 16,
        url: wfsCapabilitiesUrl,
      },
      {
        fromWidget: WidgetType.QUALIH2O,
        layername: 'syngenta:perimetre_protection_immediate',
        targetNamespace: 'http://syngenta',
        targetPrefix: 'syngenta',
        boundingBoxEPSG4326: [-2.5619439203711636, 42.98826431386263, 7.853462127803628, 50.91306367806844],
        isLegendDisplayed: true,
        layerProperties: buildLayerAttributes(
          new Map<string, AttributesConfig>([
            ['id', { type: AttributeType.NUMBER, editable: false }],
            ['surface', { type: AttributeType.NUMBER }],
            ['info_comp', { type: AttributeType.STRING }],
            ['nom_ass', { type: AttributeType.STRING }],
            ['zorder', { type: AttributeType.NUMBER }],
            ['code', { type: AttributeType.STRING }],
            ['dpt', { type: AttributeType.STRING }],
            ['code_1', { type: AttributeType.STRING }],
            ['zone_sensible', { type: AttributeType.BOOLEAN }],
            ['date_modif', { type: AttributeType.DATE_TIME, editable: false }],
            ['user_modif', { type: AttributeType.STRING, editable: false }],
            ['date_creat', { type: AttributeType.DATE_TIME, editable: false }],
            ['user_creat', { type: AttributeType.STRING, editable: false }],
            ['is_modified', { type: AttributeType.BOOLEAN, editable: false, display: false }],
            ['linked_entity', { type: AttributeType.STRING, editable: false, display: false }],
            ['to_create', { type: AttributeType.BOOLEAN, editable: false, display: false }],
            ['to_modify_geometry', { type: AttributeType.BOOLEAN, editable: false, display: false }],
            ['to_modify_attributes', { type: AttributeType.BOOLEAN, editable: false, display: false }],
            ['to_delete', { type: AttributeType.BOOLEAN, editable: false, display: false }],
          ])
        ),
        geometryType: GeometryType.POLYGON,
        editable: true,
        editionType: LayerEditType.ALL,
        filter: '{EDITION_VALIDATION}',
        useCustomSld: true,
        useEditionStyle: true,
        style: editionCustomStyle,
        dynamicStyle: editionDynamicStyle,
        maxFeatures: 3000,
        opacity: 1,
        nom_court: 'Périmètre de Protection Immédiate (PPI)',
        type: LayerType.WFS,
        visible: false,
        zIndex: 15,
        url: wfsCapabilitiesUrl,
      },
      {
        fromWidget: WidgetType.QUALIH2O,
        layername: 'syngenta:perimetre_protection_rapprochee',
        targetNamespace: 'http://syngenta',
        targetPrefix: 'syngenta',
        boundingBoxEPSG4326: [-2.5707621926570696, 42.9188045152667, 8.34139237934973, 50.9417242195427],
        isLegendDisplayed: true,
        layerProperties: buildLayerAttributes(
          new Map<string, AttributesConfig>([
            ['id', { type: AttributeType.NUMBER, editable: false }],
            ['code', { type: AttributeType.STRING }],
            ['surface', { type: AttributeType.NUMBER }],
            ['info_comp', { type: AttributeType.STRING }],
            ['nom_ass', { type: AttributeType.STRING }],
            ['zorder', { type: AttributeType.NUMBER }],
            ['code', { type: AttributeType.STRING }],
            ['dpt', { type: AttributeType.STRING }],
            ['code_1', { type: AttributeType.STRING }],
            ['date_modif', { type: AttributeType.DATE_TIME, editable: false }],
            ['user_modif', { type: AttributeType.STRING, editable: false }],
            ['date_creat', { type: AttributeType.DATE_TIME, editable: false }],
            ['user_creat', { type: AttributeType.STRING, editable: false }],
            ['is_modified', { type: AttributeType.BOOLEAN, editable: false, display: false }],
            ['linked_entity', { type: AttributeType.STRING, editable: false, display: false }],
            ['to_create', { type: AttributeType.BOOLEAN, editable: false, display: false }],
            ['to_modify_geometry', { type: AttributeType.BOOLEAN, editable: false, display: false }],
            ['to_modify_attributes', { type: AttributeType.BOOLEAN, editable: false, display: false }],
            ['to_delete', { type: AttributeType.BOOLEAN, editable: false, display: false }],
          ])
        ),
        geometryType: GeometryType.POLYGON,
        editable: true,
        editionType: LayerEditType.ALL,
        filter: '{EDITION_VALIDATION}',
        useCustomSld: true,
        useEditionStyle: true,
        style: editionCustomStyle,
        dynamicStyle: editionDynamicStyle,
        maxFeatures: 3000,
        opacity: 1,
        nom_court: 'Périmètre de Protection Rapprochée (PPR)',
        type: LayerType.WFS,
        visible: false,
        zIndex: 14,
        url: wfsCapabilitiesUrl,
      },
      {
        fromWidget: WidgetType.QUALIH2O,
        layername: 'syngenta:perimetre_protection_eloignee',
        targetNamespace: 'http://syngenta',
        targetPrefix: 'syngenta',
        boundingBoxEPSG4326: [-1.7940349919030332, 42.92700340615404, 8.329760295551141, 50.94096765986135],
        isLegendDisplayed: true,
        layerProperties: buildLayerAttributes(
          new Map<string, AttributesConfig>([
            ['id', { type: AttributeType.NUMBER, editable: false }],
            ['code', { type: AttributeType.STRING }],
            ['surface', { type: AttributeType.NUMBER }],
            ['info_comp', { type: AttributeType.STRING }],
            ['nom_ass', { type: AttributeType.STRING }],
            ['zorder', { type: AttributeType.NUMBER }],
            ['code', { type: AttributeType.STRING }],
            ['dpt', { type: AttributeType.STRING }],
            ['code_1', { type: AttributeType.STRING }],
            ['zone_sensible', { type: AttributeType.BOOLEAN }],
            ['date_modif', { type: AttributeType.DATE_TIME, editable: false }],
            ['user_modif', { type: AttributeType.STRING, editable: false }],
            ['date_creat', { type: AttributeType.DATE_TIME, editable: false }],
            ['user_creat', { type: AttributeType.STRING, editable: false }],
            ['is_modified', { type: AttributeType.BOOLEAN, editable: false, display: false }],
            ['linked_entity', { type: AttributeType.STRING, editable: false, display: false }],
            ['to_create', { type: AttributeType.BOOLEAN, editable: false, display: false }],
            ['to_modify_geometry', { type: AttributeType.BOOLEAN, editable: false, display: false }],
            ['to_modify_attributes', { type: AttributeType.BOOLEAN, editable: false, display: false }],
            ['to_delete', { type: AttributeType.BOOLEAN, editable: false, display: false }],
          ])
        ),
        geometryType: GeometryType.POLYGON,
        editable: true,
        editionType: LayerEditType.ALL,
        filter: '{EDITION_VALIDATION}',
        useCustomSld: true,
        useEditionStyle: true,
        style: editionCustomStyle,
        dynamicStyle: editionDynamicStyle,
        maxFeatures: 3000,
        opacity: 1,
        nom_court: 'Périmètre de Protection Éloignée (PPE)',
        type: LayerType.WFS,
        visible: false,
        zIndex: 13,
        url: wfsCapabilitiesUrl,
      },
      {
        fromWidget: WidgetType.QUALIH2O,
        layername: 'syngenta:zap',
        targetNamespace: 'http://syngenta',
        targetPrefix: 'syngenta',
        boundingBoxEPSG4326: [-12.50705984568685, 40.39635817046064, 12.002857384957792, 52.2084644072666],
        isLegendDisplayed: true,
        layerProperties: buildLayerAttributes(
          new Map<string, AttributesConfig>([
            ['id', { type: AttributeType.NUMBER, editable: false }],
            ['code', { type: AttributeType.STRING }],
            ['surface', { type: AttributeType.NUMBER }],
            ['info_comp', { type: AttributeType.STRING }],
            ['nom_ass', { type: AttributeType.STRING }],
            ['zorder', { type: AttributeType.NUMBER }],
            ['code', { type: AttributeType.STRING }],
            ['dpt', { type: AttributeType.STRING }],
            ['code_1', { type: AttributeType.STRING }],
            ['zone_sensible', { type: AttributeType.BOOLEAN }],
            ['date_modif', { type: AttributeType.DATE_TIME, editable: false }],
            ['user_modif', { type: AttributeType.STRING, editable: false }],
            ['date_creat', { type: AttributeType.DATE_TIME, editable: false }],
            ['user_creat', { type: AttributeType.STRING, editable: false }],
            ['is_modified', { type: AttributeType.BOOLEAN, editable: false, display: false }],
            ['linked_entity', { type: AttributeType.STRING, editable: false, display: false }],
            ['to_create', { type: AttributeType.BOOLEAN, editable: false, display: false }],
            ['to_modify_geometry', { type: AttributeType.BOOLEAN, editable: false, display: false }],
            ['to_modify_attributes', { type: AttributeType.BOOLEAN, editable: false, display: false }],
            ['to_delete', { type: AttributeType.BOOLEAN, editable: false, display: false }],
          ])
        ),
        geometryType: GeometryType.POLYGON,
        editable: true,
        editionType: LayerEditType.ALL,
        filter: '{EDITION_VALIDATION}',
        useCustomSld: true,
        useEditionStyle: true,
        style: editionCustomStyle,
        dynamicStyle: editionDynamicStyle,
        maxFeatures: 3000,
        opacity: 1,
        nom_court: "Zone d'Action Prioritaire (ZAP) ou Zone de Protection (ZP)",
        type: LayerType.WFS,
        visible: false,
        zIndex: 12,
        url: wfsCapabilitiesUrl,
      },
      {
        fromWidget: WidgetType.QUALIH2O,
        layername: AAC_GEOSERVER_LAYER,
        targetNamespace: 'http://syngenta',
        targetPrefix: 'syngenta',
        boundingBoxEPSG4326: [-5.176224814539788, 42.152751174515096, 8.357546063021571, 50.94362826528606],
        isLegendDisplayed: true,
        layerProperties: buildLayerAttributes(
          new Map<string, AttributesConfig>([
            ['code', { type: AttributeType.STRING }],
            ['nom', { type: AttributeType.STRING }],
            ['dpt', { type: AttributeType.STRING }],
            ['surface', { type: AttributeType.NUMBER }],
            ['zorder', { type: AttributeType.NUMBER }],
            ['type_aire', { type: AttributeType.STRING }],
            ['version', { type: AttributeType.NUMBER }],
            ['id', { type: AttributeType.NUMBER, editable: false }],
            ['type_capt', { type: AttributeType.STRING }],
            ['caract', { type: AttributeType.STRING }],
            ['zone_sensible', { type: AttributeType.BOOLEAN }],
            ['code_1', { type: AttributeType.STRING }],
            ['date_modif', { type: AttributeType.DATE_TIME, editable: false }],
            ['user_modif', { type: AttributeType.STRING, editable: false }],
            ['date_creat', { type: AttributeType.DATE_TIME, editable: false }],
            ['user_creat', { type: AttributeType.STRING, editable: false }],
            ['is_modified', { type: AttributeType.BOOLEAN, editable: false, display: false }],
            ['linked_entity', { type: AttributeType.STRING, editable: false, display: false }],
            ['to_create', { type: AttributeType.BOOLEAN, editable: false, display: false }],
            ['to_modify_geometry', { type: AttributeType.BOOLEAN, editable: false, display: false }],
            ['to_modify_attributes', { type: AttributeType.BOOLEAN, editable: false, display: false }],
            ['to_delete', { type: AttributeType.BOOLEAN, editable: false, display: false }],
          ])
        ),
        geometryType: GeometryType.POLYGON,
        editable: true,
        editionType: LayerEditType.ALL,
        filter: '{EDITION_VALIDATION}',
        useCustomSld: true,
        useEditionStyle: true,
        style: editionCustomStyle,
        dynamicStyle: editionDynamicStyle,
        maxFeatures: 3000,
        opacity: 1,
        nom_court: "Aire d'Alimentation de Captage (AAC)",
        type: LayerType.WFS,
        visible: false,
        zIndex: 10,
        url: wfsCapabilitiesUrl,
      },
    ],
  },
  {
    fromWidget: WidgetType.QUALIH2O,
    categorie: 'Hydrographie',
    visible: true,
    childs: [
      {
        fromWidget: WidgetType.QUALIH2O,
        layername: 'syngenta:point_captage',
        boundingBoxEPSG4326: [-5.165620514252545, 42.2008216257378, 8.350550895276953, 50.91382149651051],
        isLegendDisplayed: true,
        layerProperties: buildLayerAttributes(
          new Map<string, AttributesConfig>([
            ['Num', { type: AttributeType.INT }],
            ['nom_ouvrage', { type: AttributeType.STRING }],
            ['nom_point_prelevement', { type: AttributeType.STRING }],
            ['codeinsee', { type: AttributeType.INT }],
            ['commune', { type: AttributeType.STRING }],
            ['dpt', { type: AttributeType.INT }],
            ['esu_eso', { type: AttributeType.STRING }],
            ['typecapatg', { type: AttributeType.STRING }],
            ['code_aac', { type: AttributeType.STRING }],
            ['code_bss', { type: AttributeType.STRING }],
            ['type_point_prelevement', { type: AttributeType.STRING }],
            ['problematique', { type: AttributeType.STRING }],
            ['Longitude', { type: AttributeType.NUMBER }],
            ['Latitude', { type: AttributeType.NUMBER }],
            ['date_modif', { type: AttributeType.DATE_TIME, editable: false }],
            ['user_modif', { type: AttributeType.STRING, editable: false }],
            ['date_creat', { type: AttributeType.DATE_TIME, editable: false }],
            ['user_creat', { type: AttributeType.STRING, editable: false }],
          ])
        ),
        opacity: 1,
        nom_court: 'Point de captage',
        type: LayerType.WMS,
        visible: false,
        zIndex: 16,
        activeStyle: 'syngenta:point_captage',
        styles: [
          {
            style: 'syngenta:point_captage',
            legendUrl: legendUrlServerPrefix + '&layer=syngenta%3Apoint_captage',
            name: 'syngenta:point_captage',
          },
        ],
        url: wmsCapabilitiesUrl,
      },
      {
        fromWidget: WidgetType.QUALIH2O,
        layername: 'syngenta:perimetre_protection_immediate',
        boundingBoxEPSG4326: [-2.5619439203711636, 42.98826431386263, 7.853462127803628, 50.91306367806844],
        isLegendDisplayed: true,
        layerProperties: buildLayerAttributes(
          new Map<string, AttributesConfig>([
            ['id', { type: AttributeType.NUMBER, editable: false }],
            ['surface', { type: AttributeType.NUMBER }],
            ['info_comp', { type: AttributeType.STRING }],
            ['nom_ass', { type: AttributeType.STRING }],
            ['zorder', { type: AttributeType.NUMBER }],
            ['code', { type: AttributeType.STRING }],
            ['dpt', { type: AttributeType.STRING }],
            ['code_1', { type: AttributeType.STRING }],
            ['zone_sensible', { type: AttributeType.BOOLEAN }],
            ['date_modif', { type: AttributeType.DATE_TIME, editable: false }],
            ['user_modif', { type: AttributeType.STRING, editable: false }],
            ['date_creat', { type: AttributeType.DATE_TIME, editable: false }],
            ['user_creat', { type: AttributeType.STRING, editable: false }],
          ])
        ),
        opacity: 1,
        nom_court: 'Périmètre de Protection Immédiate (PPI)',
        type: LayerType.WMS,
        visible: false,
        zIndex: 15,
        activeStyle: 'syngenta:PPI',
        styles: [
          {
            style: 'syngenta:PPI',
            legendUrl: legendUrlServerPrefix + '&layer=syngenta%3Aperimetre_protection_immediate',
            name: 'syngenta:PPI',
          },
        ],
        url: wmsCapabilitiesUrl,
      },
      {
        fromWidget: WidgetType.QUALIH2O,
        layername: 'syngenta:perimetre_protection_rapprochee',
        boundingBoxEPSG4326: [-2.5707621926570696, 42.9188045152667, 8.34139237934973, 50.9417242195427],
        isLegendDisplayed: true,
        layerProperties: buildLayerAttributes(
          new Map<string, AttributesConfig>([
            ['id', { type: AttributeType.NUMBER, editable: false }],
            ['code', { type: AttributeType.STRING }],
            ['surface', { type: AttributeType.NUMBER }],
            ['info_comp', { type: AttributeType.STRING }],
            ['nom_ass', { type: AttributeType.STRING }],
            ['zorder', { type: AttributeType.NUMBER }],
            ['code', { type: AttributeType.STRING }],
            ['dpt', { type: AttributeType.STRING }],
            ['code_1', { type: AttributeType.STRING }],
            ['zone_sensible', { type: AttributeType.BOOLEAN }],
            ['date_modif', { type: AttributeType.DATE_TIME, editable: false }],
            ['user_modif', { type: AttributeType.STRING, editable: false }],
            ['date_creat', { type: AttributeType.DATE_TIME, editable: false }],
            ['user_creat', { type: AttributeType.STRING, editable: false }],
          ])
        ),
        opacity: 1,
        nom_court: 'Périmètre de Protection Rapprochée (PPR)',
        type: LayerType.WMS,
        visible: false,
        zIndex: 14,
        activeStyle: 'syngenta:PPR',
        styles: [
          {
            style: 'syngenta:PPR',
            legendUrl: legendUrlServerPrefix + '&layer=syngenta%3Aperimetre_protection_rapprochee',
            name: 'syngenta:PPR',
          },
        ],
        url: wmsCapabilitiesUrl,
      },
      {
        fromWidget: WidgetType.QUALIH2O,
        layername: 'syngenta:perimetre_protection_eloignee',
        boundingBoxEPSG4326: [-1.7940349919030332, 42.92700340615404, 8.329760295551141, 50.94096765986135],
        isLegendDisplayed: true,
        layerProperties: buildLayerAttributes(
          new Map<string, AttributesConfig>([
            ['id', { type: AttributeType.NUMBER }],
            ['code', { type: AttributeType.STRING }],
            ['surface', { type: AttributeType.NUMBER }],
            ['info_comp', { type: AttributeType.STRING }],
            ['nom_ass', { type: AttributeType.STRING }],
            ['zorder', { type: AttributeType.NUMBER }],
            ['code', { type: AttributeType.STRING }],
            ['dpt', { type: AttributeType.STRING }],
            ['code_1', { type: AttributeType.STRING }],
            ['zone_sensible', { type: AttributeType.BOOLEAN }],
            ['date_modif', { type: AttributeType.DATE_TIME, editable: false }],
            ['user_modif', { type: AttributeType.STRING, editable: false }],
            ['date_creat', { type: AttributeType.DATE_TIME, editable: false }],
            ['user_creat', { type: AttributeType.STRING, editable: false }],
          ])
        ),
        opacity: 1,
        nom_court: 'Périmètre de Protection Éloignée (PPE)',
        type: LayerType.WMS,
        visible: false,
        zIndex: 13,
        activeStyle: 'syngenta:PPE',
        styles: [
          {
            style: 'syngenta:PPE',
            legendUrl: legendUrlServerPrefix + '&layer=syngenta%3Aperimetre_protection_eloignee',
            name: 'syngenta:PPE',
          },
        ],
        url: wmsCapabilitiesUrl,
      },
      {
        fromWidget: WidgetType.QUALIH2O,
        layername: 'syngenta:zap',
        boundingBoxEPSG4326: [-12.50705984568685, 40.39635817046064, 12.002857384957792, 52.2084644072666],
        isLegendDisplayed: true,
        layerProperties: buildLayerAttributes(
          new Map<string, AttributesConfig>([
            ['id', { type: AttributeType.NUMBER, editable: false }],
            ['code', { type: AttributeType.STRING }],
            ['surface', { type: AttributeType.NUMBER }],
            ['info_comp', { type: AttributeType.STRING }],
            ['nom_ass', { type: AttributeType.STRING }],
            ['zorder', { type: AttributeType.NUMBER }],
            ['code', { type: AttributeType.STRING }],
            ['dpt', { type: AttributeType.STRING }],
            ['code_1', { type: AttributeType.STRING }],
            ['zone_sensible', { type: AttributeType.BOOLEAN }],
            ['date_modif', { type: AttributeType.DATE_TIME, editable: false }],
            ['user_modif', { type: AttributeType.STRING, editable: false }],
            ['date_creat', { type: AttributeType.DATE_TIME, editable: false }],
            ['user_creat', { type: AttributeType.STRING, editable: false }],
          ])
        ),
        opacity: 1,
        nom_court: "Zone d'Action Prioritaire (ZAP) ou Zone de Protection (ZP)",
        type: LayerType.WMS,
        visible: false,
        zIndex: 12,
        activeStyle: 'ZAP',
        styles: [
          {
            style: 'ZAP',
            legendUrl: legendUrlServerPrefix + '&layer=syngenta%3Azap',
            name: 'ZAP',
          },
        ],
        url: wmsCapabilitiesUrl,
      },
      {
        fromWidget: WidgetType.QUALIH2O,
        layername: 'qualicible:sensitive_area',
        boundingBoxEPSG4326: [-5.098070837767534, 42.19895299839715, 8.286172675974306, 50.90149171067237],
        isLegendDisplayed: true,
        layerProperties: buildLayerAttributes(
          new Map<string, AttributesConfig>([
            ['id', { type: AttributeType.INT, editable: false }],
            ['type_zone', { type: AttributeType.STRING }],
            ['nom', { type: AttributeType.STRING }],
            ['dpt', { type: AttributeType.STRING }],
            ['surface', { type: AttributeType.NUMBER }],
          ])
        ),
        opacity: 1,
        nom_court: "Zones sensibles (Quali'Cible)",
        type: LayerType.WMS,
        visible: false,
        zIndex: 11,
        activeStyle: 'qualicible:zones_sensibles',
        styles: [
          {
            style: 'qualicible:zones_sensibles',
            legendUrl: legendUrlServerPrefix + '&layer=qualicible%3Asensitive_area',
            name: 'qualicible:zones_sensibles',
          },
        ],
        url: wmsCapabilitiesUrl,
      },
      {
        fromWidget: WidgetType.QUALIH2O,
        layername: AAC_GEOSERVER_LAYER,
        boundingBoxEPSG4326: [-5.176224814539788, 42.152751174515096, 8.357546063021571, 50.94362826528606],
        isLegendDisplayed: true,
        layerProperties: buildLayerAttributes(
          new Map<string, AttributesConfig>([
            ['code', { type: AttributeType.STRING }],
            ['nom', { type: AttributeType.STRING }],
            ['dpt', { type: AttributeType.STRING }],
            ['surface', { type: AttributeType.NUMBER }],
            ['zorder', { type: AttributeType.NUMBER }],
            ['type_aire', { type: AttributeType.STRING }],
            ['version', { type: AttributeType.NUMBER }],
            ['id', { type: AttributeType.NUMBER, editable: false }],
            ['type_capt', { type: AttributeType.STRING }],
            ['caract', { type: AttributeType.STRING }],
            ['zone_sensible', { type: AttributeType.BOOLEAN }],
            ['code_1', { type: AttributeType.STRING }],
            ['date_modif', { type: AttributeType.DATE_TIME, editable: false }],
            ['user_modif', { type: AttributeType.STRING, editable: false }],
            ['date_creat', { type: AttributeType.DATE_TIME, editable: false }],
            ['user_creat', { type: AttributeType.STRING, editable: false }],
          ])
        ),
        opacity: 1,
        nom_court: "Aire d'Alimentation de Captage (AAC)",
        type: LayerType.WMS,
        visible: false,
        zIndex: 10,
        activeStyle: 'syngenta:AAC',
        styles: [
          {
            style: 'syngenta:AAC',
            legendUrl: legendUrlServerPrefix + '&layer=syngenta%3Aaac',
            name: 'syngenta:AAC',
          },
        ],
        url: wmsCapabilitiesUrl,
        ignoreUrlInCapabiltiesResponse: true,
      },
      {
        fromWidget: WidgetType.QUALIH2O,
        layername: 'syngenta:hydrographie',
        maxScaleDenominator: 200000,
        boundingBoxEPSG4326: [-5.531257964268032, 42.087960675437905, 8.820990824907902, 51.187275382672354],
        isLegendDisplayed: false,
        layerProperties: [],
        opacity: 1,
        nom_court: 'Réseau hydrographique',
        type: LayerType.WMTS,
        visible: false,
        zIndex: 9,
        url: wmtsCapabilitiesUrl,
        ignoreUrlInCapabiltiesResponse: true,
      },
      {
        fromWidget: WidgetType.QUALIH2O,
        layername: 'syngenta:hydro_all_buffers_5',
        maxScaleDenominator: 200000,
        boundingBoxEPSG4326: [-5.208694653405283, 42.09416132585202, 8.402733582925132, 51.09331389759742],
        isLegendDisplayed: false,
        opacity: 1,
        nom_court: 'Hydrographie tampon 5m',
        type: LayerType.WMTS,
        visible: false,
        zIndex: 8,
        url: wmtsCapabilitiesUrl,
        ignoreUrlInCapabiltiesResponse: true,
      },
      {
        fromWidget: WidgetType.QUALIH2O,
        layername: 'syngenta:hydro_all_buffers_20',
        maxScaleDenominator: 200000,
        boundingBoxEPSG4326: [-5.175934735832456, 42.17216718009703, 8.373006800498482, 50.94583144866864],
        isLegendDisplayed: false,
        opacity: 1,
        nom_court: 'Hydrographie tampon 20m',
        type: LayerType.WMTS,
        visible: false,
        zIndex: 7,
        url: wmtsCapabilitiesUrl,
        ignoreUrlInCapabiltiesResponse: true,
      },
      {
        fromWidget: WidgetType.QUALIH2O,
        layername: 'syngenta:hydro_all_buffers_50',
        maxScaleDenominator: 200000,
        boundingBoxEPSG4326: [-5.172054109958569, 42.169184593768954, 8.35018409730523, 50.9452218025109],
        isLegendDisplayed: false,
        opacity: 1,
        nom_court: 'Hydrographie tampon 50m',
        type: LayerType.WMTS,
        visible: false,
        zIndex: 6,
        url: wmtsCapabilitiesUrl,
        ignoreUrlInCapabiltiesResponse: true,
      },
      {
        fromWidget: WidgetType.QUALIH2O,
        layername: 'syngenta:hydro_all_buffers_100',
        maxScaleDenominator: 200000,
        boundingBoxEPSG4326: [-5.172787024293351, 42.16966566295424, 8.3654315839642, 50.946491170361966],
        isLegendDisplayed: false,
        opacity: 1,
        nom_court: 'Hydrographie tampon 100m',
        type: LayerType.WMTS,
        visible: false,
        zIndex: 5,
        url: wmtsCapabilitiesUrl,
        ignoreUrlInCapabiltiesResponse: true,
      },
    ],
  },
];
