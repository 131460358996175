/* eslint-disable brace-style */
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { HelpOverlayComponent } from '@components/help-overlay/help-overlay.component';
import { LoadingComponent } from '@components/loading/loading.component';
import { PagingComponent } from '@components/paging/paging.component';
import { SearchFieldComponent } from '@components/search-field/search-field.component';
import { PagingInfo } from '@core/model/paging-info.model';
import { User } from '@core/model/user.model';
import { Indexable, KeysOfType } from '@core/utils/general.utils';
import { isNil } from 'lodash-es';
import {
  ActionType,
  CellType,
  Column,
  DefaultAction,
  DefaultType,
  GeneralActions,
  SelectionAction,
  Task,
} from './table.model';

const modules = [
  CommonModule,
  MatDividerModule,
  MatButtonModule,
  MatIconModule,
  MatCheckboxModule,
  MatSelectModule,
  SearchFieldComponent,
  FormsModule,
  MatTooltipModule,
  MatTableModule,
  MatSortModule,
  MatMenuModule,
  RouterModule,
  PagingComponent,
  LoadingComponent,
  HelpOverlayComponent,
  MatSlideToggleModule,
  MatInputModule,
  MatFormFieldModule,
];

@Component({
  selector: 'smv-table',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: modules,
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<N extends number | string, T extends DefaultType<N>, V extends DefaultType<N>>
  implements OnChanges, AfterViewInit
{
  public CellType = CellType;
  public ActionType = ActionType;
  public task: Task<T, N> = {
    selected: false,
    subtasks: [],
  };
  public allSelected = false;

  public columnsDef: Column<T, V>[] = [];
  public displayedColumns: string[] = [];
  public dataSource = new MatTableDataSource<T>();

  public inputSliderValues: Indexable<boolean> = {
    editor: false,
    admin: false,
    rightAdmin: false,
    reader: false,
    validator: false,
    specifier: false,
    superAdmin: false,
  };
  public searchInput = '';

  @Input() generalActions?: GeneralActions<T>;

  @Input() columns: Column<T, V>[] = [];
  @Input() data: T[] = [];
  @Input() identifier?: KeysOfType<T, string>;
  @Input() sortDisabled = false;
  @Input() paginatorDisabled = false;

  @Input() currentUser?: User;
  @Input() pagingInfo: PagingInfo = new PagingInfo('list');

  @Input() showUserFilter = false;

  @Output() selectedRows = new EventEmitter<T[]>();
  @Output() refresh = new EventEmitter<PagingInfo>();
  @Output() valueChange = new EventEmitter<T>();
  @Output() add = new EventEmitter();
  @Output() modify = new EventEmitter<N>();
  @Output() defaultAction = new EventEmitter<DefaultAction>();
  @Output() delete = new EventEmitter<SelectionAction<N>>();
  @Output() rightAction = new EventEmitter();
  @Output() actionSelected = new EventEmitter<SelectionAction<N>>();

  ngAfterViewInit(): void {
    if (this.generalActions?.defaultSort) {
      this.sortData(this.generalActions.defaultSort);
    }
  }

  ngOnChanges() {
    this.columnsDef = this.columns;
    this.displayedColumns = this.columns.filter((column) => !column.hide).map((c) => c.field);
    if (!this.generalActions?.checkboxDisabled) {
      this.displayedColumns.unshift('select');
    }

    if (this.data) {
      this.resetSelection();
      if (!this.generalActions?.checkboxDisabled && this.identifier) {
        for (const element of this.data) {
          this.task.subtasks.push({
            id: element.id,
            identifier: element[this.identifier] as string,
            selected: false,
            element: element,
          });
        }
        this.selectAll(this.generalActions?.checkboxAllSelected ?? false);
      }
      this.dataSource = new MatTableDataSource(this.data);
    }
  }

  onRefresh() {
    this.refresh.emit(this.pagingInfo);
  }

  search(filter: string | null) {
    this.searchInput = filter ? filter : '';
    const sliders = this.showUserFilter ? this.inputSliderValues : [];
    this.pagingInfo.filters = [{ name: 'filter', value: this.searchInput }];
    if (sliders) {
      for (const [key, value] of Object.entries(sliders)) {
        this.pagingInfo.filters.push({ name: key, value: value });
      }
    }
    this.pagingInfo.currentPage = 0;
    this.onRefresh();
  }

  sortData(sort: Sort) {
    const index = this.pagingInfo.sort.findIndex((actualSort) => (actualSort.column = sort.active));
    if (index >= 0) {
      this.pagingInfo.sort.splice(index);
    }

    this.pagingInfo.sort.push({ column: sort.active, order: sort.direction });
    this.onRefresh();
  }

  resetSelection() {
    this.allSelected = false;
    this.task.selected = false;
    this.task.subtasks = [];
  }

  selectAll(selected: boolean) {
    this.allSelected = selected;
    this.task.subtasks?.forEach((t) => {
      if (!(this.generalActions?.hideCheckboxCondition && this.generalActions.hideCheckboxCondition(t.element))) {
        t.selected = selected;
      }
    });
    this.checkSelectionUpdate();
  }

  updateAllSelected() {
    this.allSelected = this.task.subtasks.every((t) => {
      if (!(this.generalActions?.hideCheckboxCondition && this.generalActions.hideCheckboxCondition(t.element))) {
        return t.selected;
      }
      return true;
    });
    this.checkSelectionUpdate();
  }

  someSelected(): boolean {
    return !isNil(this.task.subtasks.find((t) => t.selected)) && !this.allSelected;
  }

  onChange(row: T) {
    this.valueChange.emit(row);
  }

  onAdd() {
    this.add.emit();
  }

  onModify(id: N) {
    this.modify.emit(id);
  }

  onDefaultAction(identifier: string, key: string) {
    const data: DefaultAction = {
      identifier,
      key,
    };
    this.defaultAction.emit(data);
  }

  askToDelete() {
    const identifiers = this.task.subtasks.filter((subtask) => subtask.selected).map((subtask) => subtask.identifier);
    const ids = this.task.subtasks.filter((subtask) => subtask.selected).map((subtask) => subtask.id);
    this.onDelete(identifiers, ids);
  }

  onDelete(identifiers: string[], ids: N[]) {
    const data: SelectionAction<N> = {
      identifiers: identifiers,
      ids: ids,
    };
    this.delete.emit(data);
  }

  openRightAction() {
    this.rightAction.emit();
  }

  onActionSelected() {
    const identifiers = this.task.subtasks.filter((subtask) => subtask.selected).map((subtask) => subtask.identifier);
    const ids = this.task.subtasks.filter((subtask) => subtask.selected).map((subtask) => subtask.id);
    const data: SelectionAction<N> = {
      identifiers: identifiers,
      ids: ids,
    };
    this.actionSelected.emit(data);
  }

  private checkSelectionUpdate() {
    const selectedIds = this.task.subtasks
      .filter((subtask) => {
        if (
          !(this.generalActions?.hideCheckboxCondition && this.generalActions.hideCheckboxCondition(subtask.element))
        ) {
          return subtask.selected;
        }
        return false;
      })
      .map((subtask) => subtask.id);

    const selectedData = this.data.filter((datum) => selectedIds.includes(datum.id));
    this.selectedRows.emit(selectedData);
  }
}
