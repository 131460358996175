import { OverlayModule, ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

type MessageType = 'help' | 'warning';

const modules = [NgIf, OverlayModule, MatButtonModule, MatIconModule];

@Component({
  selector: 'smv-help-overlay',
  standalone: true,
  imports: modules,
  templateUrl: './help-overlay.component.html',
  styleUrls: ['./help-overlay.component.scss'],
})
export class HelpOverlayComponent implements OnInit {
  @Input() closeAfter = 3000;
  @Input() messageType: MessageType = 'help';

  public isHelp = false;
  public isWarning = false;
  public iconColor = 'primary';
  public isOverlayOpen = false;

  public scrollStrategy: ScrollStrategy;

  private activeTimeout?: number;

  constructor(private readonly sso: ScrollStrategyOptions) {
    this.scrollStrategy = this.sso.close();
  }

  ngOnInit() {
    switch (this.messageType) {
      case 'help':
        this.isHelp = true;
        break;
      case 'warning':
        this.isWarning = true;
        this.iconColor = 'warn';
    }
  }

  toggleOverlay(): void {
    this.isOverlayOpen = !this.isOverlayOpen;

    if (this.isOverlayOpen) {
      this.activeTimeout = window.setTimeout(() => (this.isOverlayOpen = false), this.closeAfter);
    } else {
      clearTimeout(this.activeTimeout);
    }
  }

  outsideEvent() {
    this.isOverlayOpen = false;
    clearTimeout(this.activeTimeout);
  }
}
