import { QualiH2OFilter } from '@core/model/widgets/qualih2o.model';

export const cropFilters: QualiH2OFilter[] = [
  { label: $localize`Céréales à paille majeures`, filterValue: 'Céréales à paille majeures' },
  { label: $localize`Colza`, filterValue: 'Colza' },
  { label: $localize`Cultures diverses`, filterValue: 'Cultures diverses' },
  { label: $localize`Cultures industrielles`, filterValue: 'Cultures industrielles' },
  { label: $localize`Cultures pérennes`, filterValue: 'Cultures pérennes' },
  { label: $localize`Fourrages`, filterValue: 'Fourrages' },
  { label: $localize`Gel`, filterValue: 'Gel' },
  { label: $localize`Maïs`, filterValue: 'Maïs' },
  { label: $localize`Protéagineux`, filterValue: 'Protéagineux' },
  { label: $localize`Tournesol`, filterValue: 'Tournesol' },
  { label: $localize`Vigne`, filterValue: 'Vigne' },
  { label: $localize`Autres céréales`, filterValue: 'Autres céréales' },
  { label: $localize`Autres oléagineux`, filterValue: 'Autres oléagineux' },
];

export const vulnerabilityFilters: QualiH2OFilter[] = [
  { label: $localize`Très faiblement vulnérable`, filterValue: 'o' },
  { label: $localize`Faiblement vulnérable`, filterValue: 'm' },
  { label: $localize`Moyennement vulnérable`, filterValue: 'd' },
  { label: $localize`Fortement vulnérable`, filterValue: 'v' },
];

export const aacFilters: QualiH2OFilter[] = [
  { label: $localize`Prioritaire`, filterValue: 'Captage prioritaire' },
  { label: $localize`Non prioritaire`, filterValue: 'Non prioritaire' },
  { label: $localize`Dégradé`, filterValue: 'Captage dégradé' },
];
