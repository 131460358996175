<div class="smv-btn-tools-group" *ngIf="!onlyModify && (selectedFeature || copiedFeature)">
  <lib-ol-gmap-button
    *ngIf="selectedFeature"
    icon="file_copy"
    (click)="copyFeature()"
    matTooltip="Copier l'objet sélectionné"
    matTooltipPosition="right"
    i18n-matTooltip
  />
  <lib-ol-gmap-button
    *ngIf="copiedFeature"
    icon="content_paste"
    (click)="pasteFeature()"
    matTooltip="Coller"
    matTooltipPosition="right"
    i18n-matTooltip
  />
</div>
<div class="smv-btn-tools-group" *ngIf="!onlyModify && geometry">
  <lib-ol-gmap-button
    *ngIf="geometry === GeometryType.COLLECTION || geometry === GeometryType.POLYGON"
    icon="circle"
    (click)="toggle(EditionToolType.ADD_CIRCLE)"
    [class]="{ 'smv-btn-active': activeTool === EditionToolType.ADD_CIRCLE }"
    matTooltip="Ajouter un cercle"
    matTooltipPosition="right"
    i18n-matTooltip
  />
  <lib-ol-gmap-button
    *ngIf="geometry === GeometryType.COLLECTION || geometry === GeometryType.POLYGON"
    icon="pentagon"
    (click)="toggle(EditionToolType.ADD_POLYGON)"
    [class]="{ 'smv-btn-active': activeTool === EditionToolType.ADD_POLYGON }"
    matTooltip="Ajouter un polygone"
    matTooltipPosition="right"
    i18n-matTooltip
  />
  <lib-ol-gmap-button
    *ngIf="geometry === GeometryType.COLLECTION || geometry === GeometryType.LINE"
    icon="polyline"
    (click)="toggle(EditionToolType.ADD_LINE)"
    [class]="{ 'smv-btn-active': activeTool === EditionToolType.ADD_LINE }"
    matTooltip="Ajouter une ligne"
    matTooltipPosition="right"
    i18n-matTooltip
  />
  <lib-ol-gmap-button
    *ngIf="geometry === GeometryType.COLLECTION || geometry === GeometryType.POINT"
    icon="noise_control_off"
    (click)="toggle(EditionToolType.ADD_POINT)"
    [class]="{ 'smv-btn-active': activeTool === EditionToolType.ADD_POINT }"
    matTooltip="Ajouter un point"
    matTooltipPosition="right"
    i18n-matTooltip
  />
</div>
<div class="smv-btn-tools-group">
  <lib-ol-gmap-button
    *ngIf="geometry !== GeometryType.POINT"
    icon="transform"
    (click)="toggle(EditionToolType.UPDATE_SHAPE)"
    [class]="{ 'smv-btn-active': activeTool === EditionToolType.UPDATE_SHAPE }"
    matTooltip="Modifier une géométrie"
    matTooltipPosition="right"
    i18n-matTooltip
  />
  <lib-ol-gmap-button
    icon="open_with"
    (click)="toggle(EditionToolType.MOVE_SHAPE)"
    [class]="{ 'smv-btn-active': activeTool === EditionToolType.MOVE_SHAPE }"
    matTooltip="Déplacer une géométrie"
    matTooltipPosition="right"
    i18n-matTooltip
  />
  <lib-ol-gmap-button
    *ngIf="!onlyModify"
    icon="delete_forever"
    (click)="toggle(EditionToolType.DELETE)"
    [class]="{ 'smv-btn-active': activeTool === EditionToolType.DELETE }"
    matTooltip="Supprimer une géométrie"
    matTooltipPosition="right"
    i18n-matTooltip
  />
</div>
