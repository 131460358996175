import { Component, EventEmitter, Injectable, Input, Output } from '@angular/core';
import { MatPaginatorIntl, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { PagingInfo } from '@core/model/paging-info.model';
import { Subject } from 'rxjs';

@Injectable()
export class SMVPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();
  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return $localize`:Paging|Label: Aucun résultat`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize > length ? (Math.ceil(length / pageSize) - 1) * pageSize : page * pageSize;

    const endIndex = Math.min(startIndex + pageSize, length);
    return startIndex + 1 + ' - ' + endIndex + $localize`:Paging|Label: sur ` + length;
  };

  firstPageLabel = $localize`:Paging|Label:Première page`;
  itemsPerPageLabel = $localize`:Paging|Label:Nombre d'éléments par page`;
  lastPageLabel = $localize`:Paging|Label:Dernière page`;

  nextPageLabel = $localize`:Paging|Label:Page suivante`;
  previousPageLabel = $localize`:Paging|Label:Page précédente`;
}

@Component({
  selector: 'smv-paging',
  standalone: true,
  imports: [MatPaginatorModule],
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.scss'],
  providers: [{ provide: MatPaginatorIntl, useClass: SMVPaginatorIntl }],
})
export class PagingComponent {
  public pageSizeOptions: number[] = [5, 10, 25, 100];

  @Input() pagingInfo: PagingInfo = new PagingInfo();

  @Output() pageChange = new EventEmitter();
  onPageChange(event: PageEvent) {
    this.pagingInfo.setPageSize(event.pageSize);
    this.pagingInfo.currentPage = event.pageIndex;
    this.pageChange.emit(this.pagingInfo.currentPage);
  }
}
