import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { tap } from 'rxjs';

export const AuthGuard = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const state = router.routerState.snapshot;
  return authService.checkAuthenticationStatus().pipe(
    tap((isLoggedIn) => {
      if (!isLoggedIn) {
        router.navigate(['/connexion'], { queryParams: { returnUrl: state.url } });
      }
    })
  );
};
