import { Component, Inject } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AttributeType } from '@core/model/application-api/layer.model';
import { WfsLayerConfig } from '@core/model/layer-config.model';
import { AttributeForm } from '../layer-attribute-tab/layer-attribute-tab.component';

interface DialogData {
  config: WfsLayerConfig;
  attributes: FormArray<FormGroup<AttributeForm>>;
  attribute: FormGroup<AttributeForm>;
}

@Component({
  selector: 'smv-layer-attribute-edition',
  templateUrl: './attribute-edition.component.html',
  styleUrls: ['./attribute-edition.component.scss'],
})
export class LayerAttributeEditionComponent {
  public config: WfsLayerConfig;
  public attributes: FormArray<FormGroup<AttributeForm>>;
  public attribute: FormGroup<AttributeForm>;

  public isBooleanAttribute = false;
  public isDateAttribute = false;
  public isNumberAttribute = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.config = data.config;
    this.attributes = data.attributes;
    this.attribute = data.attribute;

    const type = this.attribute.controls.type.value ?? AttributeType.STRING;
    this.isBooleanAttribute = type === AttributeType.BOOLEAN;
    this.isDateAttribute = [AttributeType.DATE, AttributeType.DATE_TIME].includes(type);
    this.isNumberAttribute = [AttributeType.NUMBER, AttributeType.DOUBLE, AttributeType.INT].includes(type);
  }
}
