import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContextItem } from '@core/model/context-item.model';
import { PaginatedResult } from '@core/model/paginated-result.model';
import { PagingInfo } from '@core/model/paging-info.model';
import { Position } from '@core/model/position.model';
import { CommonService, ListWrapper } from '@core/services/common.service';
import { MapUtils } from '@core/utils/map.utils';
import { Observable, map } from 'rxjs';
import { InterraScanCrop } from './model/crop.model';
import { Farmer } from './model/farmer.model';
import { InterraScanOrder } from './model/order.model';
import { Package } from './model/package.model';
import { Parcel } from './model/parcel.model';
import { ParcelSynthesis } from './model/synthesis.model';

interface ParcelWrapper {
  InterraScanParcel: Parcel;
}

interface ParcelSynthesisWrapper {
  InterraScanSynthesis: ParcelSynthesis;
}

@Injectable({ providedIn: 'root' })
export class InterraScanService extends CommonService {
  protected override readonly baseUrl: string = this.baseUrl + '/interraScan';

  public activeDetailPopup = new ContextItem<boolean>(true);

  constructor(protected override http: HttpClient) {
    super(http);
  }

  retrieveFarmers(appId: number, pagingInfo: PagingInfo): Observable<PaginatedResult<Farmer>> {
    return this.sendListQuery<Farmer>(pagingInfo, `${this.baseUrl}/farmers/${appId}`, false);
  }

  retrieveAllFarmers(appId: number, pagingInfo?: PagingInfo): Observable<Farmer[]> {
    const url = `${this.baseUrl}/farmers/${appId}/list`;
    let params = new HttpParams();
    if (pagingInfo?.filters.length) {
      params = pagingInfo.setPageFilters(params);
    }
    return this.pipeExtractList(this.http.get<ListWrapper<Farmer>>(url, { params }));
  }

  retrieveAllOrders(farmerId: string): Observable<InterraScanOrder[]> {
    const url = `${this.baseUrl}/orders/${farmerId}/list`;
    return this.pipeExtractList(this.http.get<ListWrapper<InterraScanOrder>>(url));
  }

  retrieveAllCrops(appId: number, pagingInfo?: PagingInfo): Observable<InterraScanCrop[]> {
    const url = `${this.baseUrl}/crops/list`;
    let params = new HttpParams();
    if (pagingInfo?.filters.length) {
      params = pagingInfo.setPageFilters(params);
    }
    return this.pipeExtractList(this.http.get<ListWrapper<InterraScanCrop>>(url, { params }));
  }

  createFarmer(farmer: Farmer) {
    return this.http.post<void>(`${this.baseUrl}/farmers/create`, farmer);
  }

  modifyFarmer(farmer: Farmer) {
    return this.http.post<void>(`${this.baseUrl}/farmers/modify`, farmer);
  }

  deleteFarmer(farmerId: string) {
    return this.http.delete<void>(`${this.baseUrl}/farmers/${farmerId}`);
  }

  retrieveAllPackages(): Observable<Package[]> {
    return this.pipeExtractList(this.http.get<ListWrapper<Package>>(`${this.baseUrl}/parcels/availablePackages`));
  }

  retrieveParcels(appId: number, pagingInfo: PagingInfo): Observable<PaginatedResult<Parcel>> {
    return this.sendListQuery<Parcel>(pagingInfo, `${this.baseUrl}/parcels/page/${appId}`, false);
  }

  retrieveAllParcels(appId: number, pagingInfo?: PagingInfo): Observable<Parcel[]> {
    const url = `${this.baseUrl}/parcels/list/${appId}`;
    let params = new HttpParams();
    if (pagingInfo?.filters.length) {
      params = pagingInfo.setPageFilters(params);
    }
    return this.pipeExtractList(this.http.get<ListWrapper<Parcel>>(url, { params }));
  }

  retrieveParcelDetails(appId: number, parcelId: string): Observable<Parcel> {
    const params = { appId };
    return this.pipeExtractParcel(this.http.get<ParcelWrapper>(`${this.baseUrl}/parcels/${parcelId}`, { params }));
  }

  retrieveParcelPosition(parcelId: string): Observable<Position | null> {
    return this.pipeExtractWktGeom(
      this.http.get(`${this.baseUrl}/parcels/${parcelId}/center`, { responseType: 'text' })
    );
  }

  retrieveParcelSynthesis(appId: number): Observable<ParcelSynthesis> {
    const params = { appId };
    return this.pipeExtractParcelSynthesis(
      this.http.get<ParcelSynthesisWrapper>(`${this.baseUrl}/parcels/synthesis`, { params })
    );
  }

  createParcel(parcel: Partial<Parcel>) {
    return this.http.post<void>(`${this.baseUrl}/parcels/create`, parcel);
  }

  modifyParcel(parcel: Partial<Parcel>) {
    return this.http.post<void>(`${this.baseUrl}/parcels/modify`, parcel);
  }

  deleteParcels(parcelIds: string[]) {
    const params = { parcelIds };
    return this.http.delete<void>(`${this.baseUrl}/parcels`, { params });
  }

  private pipeExtractParcel(obs: Observable<ParcelWrapper>): Observable<Parcel> {
    return obs.pipe(map((result: ParcelWrapper) => result?.InterraScanParcel));
  }

  private pipeExtractParcelSynthesis(obs: Observable<ParcelSynthesisWrapper>): Observable<ParcelSynthesis> {
    return obs.pipe(map((result: ParcelSynthesisWrapper) => result?.InterraScanSynthesis));
  }

  private pipeExtractWktGeom(obs: Observable<string>): Observable<Position | null> {
    return obs.pipe(
      map((result: string) => {
        if (result) {
          const geom = MapUtils.getGeometryOfWkt(result).transform(
            MapUtils.PROJECTION_DATABASE_CODE,
            MapUtils.PROJECTION_MAP
          );
          return {
            geom,
          };
        }
        return null;
      })
    );
  }
}
