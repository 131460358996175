import { LayerDynamicStyle, LayerDynamicStyleModel } from './layer-dynamic-style';

export class LayerDynamicStyleGradient extends LayerDynamicStyle {
  minAttributeValue = 0;

  maxAttributeValue = 100;

  useFirstRangeIfLesser = false;

  useLastRangeIfGreater = false;

  constructor(style?: LayerDynamicStyleModel) {
    super(style as LayerDynamicStyleModel);
    this.applyGradientStyle(style);
    this.baseStyle = this.toModel();
  }

  private applyGradientStyle(style?: LayerDynamicStyleModel) {
    this.minAttributeValue = style?.minAttributeValue ?? 0;
    this.maxAttributeValue = style?.maxAttributeValue ?? 100;
    this.useFirstRangeIfLesser = style?.useFirstRangeIfLesser === true;
    this.useLastRangeIfGreater = style?.useLastRangeIfGreater === true;
  }

  override applyStyle(style?: LayerDynamicStyleModel) {
    this.applyGradientStyle(style);
    super.applyStyle(style);
  }

  applyBaseStyle() {
    this.applyStyle(this.baseStyle);
  }

  clone(): LayerDynamicStyleGradient {
    return new LayerDynamicStyleGradient(this.toModel());
  }

  toModel(): LayerDynamicStyleModel {
    const baseModel = this.toModelBase();
    return {
      attribute: baseModel.attribute,
      palette: baseModel.palette,
      categories: baseModel.categories,
      minAttributeValue: this.minAttributeValue,
      maxAttributeValue: this.maxAttributeValue,
      useFirstRangeIfLesser: this.useFirstRangeIfLesser,
      useLastRangeIfGreater: this.useLastRangeIfGreater,
    };
  }

  toModelWithBaseStyle(): LayerDynamicStyleModel {
    const baseModel = this.toModelBaseWithBaseStyle();
    return {
      baseStyle: baseModel.baseStyle,
      attribute: baseModel.attribute,
      palette: baseModel.palette,
      categories: baseModel.categories,
      minAttributeValue: this.minAttributeValue,
      maxAttributeValue: this.maxAttributeValue,
      useFirstRangeIfLesser: this.useFirstRangeIfLesser,
      useLastRangeIfGreater: this.useLastRangeIfGreater,
    };
  }
}
