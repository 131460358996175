import { HttpParams } from '@angular/common/http';
import { XmlBuilder, XmlTag } from '@core/utils/xml.utils';
import { isNil, uniqueId } from 'lodash-es';
import { FeatureLike } from 'ol/Feature';
import WMSTileSource from 'ol/source/TileWMS';
import Style from 'ol/style/Style';
import {
  FeatureOverlay,
  GeometryType,
  Layer,
  LayerEditType,
  LayerEditionValidationConfiguration,
  LayerGroup,
  LayerProperty,
  LayerStyle,
  LayerSubtype,
  LayerType,
} from './application-api/layer.model';
import { WidgetType } from './application-api/widget.model';
import { LayerDynamicStyle } from './layer-dynamic-style';
import { LayerDynamicStyleCategories } from './layer-dynamic-style-categories';
import { LayerDynamicStyleGradient } from './layer-dynamic-style-gradient';
import { CustomLayerStyle } from './layer-style.model';

function isLayerGroup(value: object): value is LayerGroup {
  return Object.hasOwn(value, 'childs');
}

export interface LayerAttributions {
  title: string;
  link?: string;
}

export class LayerGroupConfig {
  generatedId = uniqueId('group_');
  category?: string;
  visible = true;
  subgroups: LayerGroupConfig[] = [];
  layers: LayerConfig[] = [];
  isUngrouped = false; // Flag for the ungrouped layers pseudo-group

  constructor(group?: LayerGroup) {
    if (group) {
      this.category = group.categorie;
      this.visible = group.visible;
      const { subgroups, layers } = this.parseLayerGroup(group);
      this.subgroups = subgroups;
      this.layers = layers;
    }
  }

  toApiLayerGroup(): (Layer | LayerGroup)[] {
    if (this.isUngrouped) {
      return this.layers.map((layer) => layer.toApiLayer());
    } else {
      return [
        {
          categorie: this.category ?? '',
          visible: this.visible,
          childs: [
            ...this.layers.map((layer) => layer.toApiLayer()),
            ...this.subgroups.flatMap((subgroup) => subgroup.toApiLayerGroup()),
          ],
        },
      ];
    }
  }

  getSublayerCount(): number {
    return this.subgroups.reduce(
      (accumulator, currentGroup) => accumulator + currentGroup.getSublayerCount(),
      this.layers.length
    );
  }

  private parseLayerGroup(group: LayerGroup): { subgroups: LayerGroupConfig[]; layers: LayerConfig[] } {
    const subgroups: LayerGroupConfig[] = [];
    const layers: LayerConfig[] = [];
    group.childs.forEach((child) => {
      if (isLayerGroup(child)) {
        subgroups.push(new LayerGroupConfig(child));
      } else {
        switch (child.type) {
          case LayerType.WMTS:
          case LayerType.VECTOR_TILE:
          case LayerType.IGN:
            layers.push(new WmtsLayerConfig(child));
            break;
          case LayerType.WFS:
            layers.push(new WfsLayerConfig(child));
            break;
          case LayerType.WMS:
            layers.push(new WmsLayerConfig(child));
            break;
          case LayerType.VECTOR:
            layers.push(new VectorLayerConfig(child));
            break;
          default:
            layers.push(new LayerConfig(child));
        }
      }
    });
    return { subgroups, layers };
  }
}

const LayerConfigMapper = new Map<keyof Layer, keyof LayerConfig>()
  .set('attributions', 'attributions')
  .set('authenticationInfos', 'authenticationInfos')
  .set('boundingBoxEPSG4326', 'bboxEpsg4326')
  .set('code', 'code')
  .set('baseLayer', 'baseLayer')
  .set('description', 'description')
  .set('editable', 'editable')
  .set('editionType', 'editType')
  .set('fromWidget', 'fromWidget')
  .set('geometryType', 'geometryType')
  .set('isLegendDisplayed', 'isLegendDisplayed')
  .set('layerProperties', 'properties')
  .set('maxScaleDenominator', 'maxScaleDenominator')
  .set('minScaleDenominator', 'minScaleDenominator')
  .set('opacity', 'opacity')
  .set('overlay', 'featureOverlay')
  .set('nom_court', 'shortName')
  .set('subType', 'subtype')
  .set('type', 'type')
  .set('useCustomSld', 'useCustomSld')
  .set('useEditionStyle', 'useEditionStyle')
  .set('validationConfiguration', 'validationConfiguration')
  .set('visible', 'visible')
  .set('zIndex', 'zIndex');
export class LayerConfig {
  /** Generated unique identifier */
  generatedId = uniqueId('layer_');

  /** Layer attributions */
  attributions?: LayerAttributions;

  /** Geoserver credentials */
  authenticationInfos?: {
    login: string;
    password: string;
  };

  /**Couche Fond de plan? */
  baseLayer = false;

  /** Bounding box to zoom on the layer */
  bboxEpsg4326?: [number, number, number, number];

  /** ? */
  code?: string;

  /** Custom style applied to layer features */
  customStyle?: CustomLayerStyle;

  /** Layer description */
  description?: string;

  /** Custom dynamic style */
  dynamicStyle?: LayerDynamicStyle;

  /** Allow users to edit geometries and/or attributes */
  editable = false;

  /** Type of objects that can be edited by users */
  editType?: LayerEditType;

  /** Tooltip displayed on hover */
  featureOverlay: FeatureOverlay = { active: false };

  /** Layer related to a widget */
  fromWidget?: WidgetType;

  /** Type of geometries used by the layer */
  geometryType?: GeometryType;

  /** Specific configuration for heatmaps */
  heatmap: {
    heatWeightProperty?: string;
    heatZoom?: number;
    heatBlur?: number;
    heatRadius?: number;
  } = {};

  /** Whether the legend can be displayed */
  isLegendDisplayed?: boolean;

  /** Max scale at which the layer will be visible */
  maxScaleDenominator?: number;

  /** Min scale at which the layer will be visible */
  minScaleDenominator?: number;

  /** Current opacity */
  opacity = 1;

  /** Layer attributes, filled from the form */
  properties?: LayerProperty[];

  /** Layer name as seen in the layer panel */
  shortName?: string;

  /** Subtype for vector layers */
  subtype?: LayerSubtype;

  /** Type of layer (WMTS, WMS, OSM, ...) */
  type?: LayerType;

  /** Use a custom style */
  useCustomSld = false;

  /** Use the custom style for edition */
  useEditionStyle = false;

  /** Configuration of the validation of edition */
  validationConfiguration?: LayerEditionValidationConfiguration;

  /** Current visibility on the map */
  visible = true;

  /** Current zIndex */
  zIndex?: number;

  /** Original config received from the server */
  originalConfig?: Layer;

  constructor(layer?: Layer) {
    if (layer) {
      LayerConfigMapper.forEach((newKey, originalKey) => {
        if (!isNil(layer[originalKey])) {
          (this[newKey] as unknown) = layer[originalKey];
        }
      });
      this.originalConfig = layer;
      this.heatmap = {
        heatWeightProperty: layer.heat_weight,
        heatZoom: layer.heat_zoom,
        heatBlur: layer.heat_blur,
        heatRadius: layer.heat_radius,
      };
      if (layer.style) {
        this.customStyle = new CustomLayerStyle(layer.style);
        this.customStyle.setBaseStyle(this.customStyle);
      }
      if (layer.dynamicStyle) {
        if (layer.dynamicStyle.minAttributeValue == undefined) {
          this.dynamicStyle = new LayerDynamicStyleCategories(layer.dynamicStyle);
        } else {
          this.dynamicStyle = new LayerDynamicStyleGradient(layer.dynamicStyle);
        }
      }
      if (layer.layerProperties) {
        this.properties?.forEach((property) => {
          if (property.nillable == undefined) {
            property.nillable = true;
          }
        });
      }
    }
  }

  static fromConfig(config: Map<keyof LayerConfig, unknown>): LayerConfig {
    const newLayer = new LayerConfig();
    config.forEach((value, key) => {
      (newLayer[key] as unknown) = value;
    });
    return newLayer;
  }

  static fromLayer(layer: LayerConfig): LayerConfig {
    const newLayer = new LayerConfig();
    Object.assign(newLayer, layer);
    return newLayer;
  }

  getStyleFunction() {
    if (this.useCustomSld) {
      return this.getFeatureStyle.bind(this);
    } else {
      return undefined;
    }
  }

  getFeatureStyle(feature: FeatureLike, resolution: number): Style | undefined {
    if (this.dynamicStyle) {
      return this.dynamicStyle.getOlStyle(feature, resolution);
    }
    if (this.customStyle) {
      return this.customStyle.getOlStyle(feature);
    }
    return;
  }

  getAttributionTemplate(): string | undefined {
    return this.attributions
      ? `<a href="${this.attributions.link ?? ''}" target="_blank">${this.attributions.title}</a>`
      : undefined;
  }

  toApiLayer(): Layer {
    const layer: Layer = { layername: '' };
    if (this.originalConfig) {
      Object.assign(layer, this.originalConfig);
    }
    LayerConfigMapper.forEach((newKey, originalKey) => {
      (layer[originalKey] as unknown) = this[newKey];
    });
    layer.style = this.customStyle?.toModelWithBaseStyle();
    layer.dynamicStyle = this.dynamicStyle?.toModelWithBaseStyle();
    layer.heat_weight = this.heatmap.heatWeightProperty;
    layer.heat_zoom = this.heatmap.heatZoom;
    layer.heat_radius = this.heatmap.heatRadius;
    layer.heat_blur = this.heatmap.heatBlur;
    return layer;
  }
}

const WmsLayerConfigMapper = new Map<keyof Layer, keyof WmsLayerConfig>()
  .set('activeStyle', 'activeStyle')
  .set('filter', 'cqlFilter')
  .set('layername', 'geoserverLayerName')
  .set('legendImageUrl', 'legendUrl')
  .set('projection', 'projection')
  .set('styles', 'wmsStyles')
  .set('url', 'url');
export class WmsLayerConfig extends LayerConfig {
  cqlFilter?: string;
  geoserverLayerName = 'layername';
  projection?: string;

  /** Active layer style (one of wmsStyles) */
  activeStyle?: string;

  /** Style name, passed as a request param */
  wmsStyles?: LayerStyle[];

  /** Base URL of the WMS service */
  url?: string;

  /** Path to the style legend */
  legendUrl?: string;

  constructor(layer?: Layer) {
    super(layer);
    if (layer) {
      WmsLayerConfigMapper.forEach((newKey, originalKey) => {
        if (!isNil(layer[originalKey])) {
          (this[newKey] as unknown) = layer[originalKey];
        }
      });
    }
  }

  styleToSld(): string {
    if (this.useCustomSld && this.geometryType) {
      return this.baseSldStructure(this.getFeatureTypeStyleTag());
    }
    return '';
  }

  styleToSlds(): string[] {
    const rulesTag = this.getFeatureTypeStyleTag().content as XmlTag[];
    return rulesTag.map(this.getFeatureTypeStyleTag).map(this.baseSldStructure);
  }

  getActiveStyle(): LayerStyle | undefined {
    return this.wmsStyles?.find((style) => style.name === this.activeStyle);
  }

  getLegendUrl(source: WMSTileSource, scale: number): string | string[] {
    if (this.legendUrl) {
      return this.legendUrl;
    }
    const url = source.getUrls()?.[0] + '?';
    let params = new HttpParams()
      .append('SERVICE', 'wms')
      .append('REQUEST', 'GetLegendGraphic')
      .append('LAYER', this.geoserverLayerName)
      .append('FORMAT', 'image/png')
      .append('WIDTH', 40)
      .append('HEIGHT', 40)
      .append('SCALE', scale)
      .append('LEGEND_OPTIONS', 'forceLabels:on');
    if (this.useCustomSld) {
      const maxUrlLength = 2000;
      const sldBody = this.styleToSld();
      if (sldBody.length > maxUrlLength) {
        const sldBodies = this.styleToSlds();
        const urls = sldBodies.map((sld) => {
          params = params.set('SLD_BODY', sld);
          return url + params.toString();
        });
        return urls;
      }
      params = params.append('SLD_BODY', sldBody);
    } else if (this.activeStyle) {
      params = params.append('STYLE', this.getActiveStyle()?.style ?? '');
    }
    return url + params.toString();
  }

  override toApiLayer(): Layer {
    const layer = super.toApiLayer();
    WmsLayerConfigMapper.forEach((newKey, originalKey) => {
      (layer[originalKey] as unknown) = this[newKey];
    });
    return layer;
  }

  private baseSldStructure(featureTypeStyleTag: XmlTag) {
    const styleSld: XmlTag = {
      name: 'StyledLayerDescriptor',
      attributes: {
        version: '1.0.0',
        'xsi:schemaLocation': 'http://www.opengis.net/sld StyledLayerDescriptor.xsd',
        xmlns: 'http://www.opengis.net/sld',
        'xmlns:ogc': 'http://www.opengis.net/ogc',
        'xmlns:xlink': 'http://www.w3.org/1999/xlink',
        'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
      },
      content: [
        {
          name: 'NamedLayer',
          content: [
            {
              name: 'Name',
              content: this.geoserverLayerName,
            },
            {
              name: 'UserStyle',
              content: [
                {
                  name: 'Title',
                  content: 'default style',
                },
                {
                  name: 'Abstract',
                },
                featureTypeStyleTag,
              ],
            },
          ],
        },
      ],
    };
    return XmlBuilder.build(styleSld);
  }

  private getFeatureTypeStyleTag(sld?: XmlTag): XmlTag {
    if (this.geometryType) {
      let contentTag: XmlTag[];
      if (sld) {
        contentTag = [sld];
      } else if (this.dynamicStyle) {
        contentTag = [...this.dynamicStyle.toSld(this.geometryType)];
      } else if (this.customStyle) {
        contentTag = [
          {
            name: 'Rule',
            content: [this.customStyle.toSld(this.geometryType)],
          },
        ];
      } else {
        return {
          name: 'FeatureTypeStyle',
        };
      }
      return {
        name: 'FeatureTypeStyle',
        content: contentTag,
      };
    }
    return {
      name: 'FeatureTypeStyle',
    };
  }

  static override fromLayer(layer: LayerConfig): WmsLayerConfig {
    const newLayer = new WmsLayerConfig();
    Object.assign(newLayer, layer);
    return newLayer;
  }
}

const WfsLayerConfigMapper = new Map<keyof Layer, keyof WfsLayerConfig>()
  .set('authenticationInfos', 'auth')
  .set('filter', 'cqlFilter')
  .set('layername', 'geoserverLayerName')
  .set('maxFeatures', 'maxFeatures')
  .set('targetNamespace', 'geoserverNameSpace')
  .set('outputFormat', 'outputFormat')
  .set('targetPrefix', 'geoserverPrefix')
  .set('projection', 'projection')
  .set('url', 'url');
export class WfsLayerConfig extends LayerConfig {
  /** Layer Geoserver auth info */
  auth?: { login: string; password: string };

  cqlFilter?: string;
  geoserverLayerName = 'layername';
  geoserverNameSpace?: string;
  geoserverPrefix?: string;
  maxFeatures = 1000;
  outputFormat = 'application/json';
  projection?: string;

  /** Base URL of the WFS service */
  url?: string;

  constructor(layer?: Layer) {
    super(layer);
    if (layer) {
      WfsLayerConfigMapper.forEach((newKey, originalKey) => {
        if (!isNil(layer[originalKey])) {
          (this[newKey] as unknown) = layer[originalKey];
        }
      });
    }
  }

  override toApiLayer(): Layer {
    const layer = super.toApiLayer();
    WfsLayerConfigMapper.forEach((newKey, originalKey) => {
      (layer[originalKey] as unknown) = this[newKey];
    });
    return layer;
  }

  static override fromLayer(layer: LayerConfig): WfsLayerConfig {
    const newLayer = new WfsLayerConfig();
    Object.assign(newLayer, layer);
    return newLayer;
  }
}

const WmtsLayerConfigMapper = new Map<keyof Layer, keyof WmtsLayerConfig>()
  .set('activeStyle', 'activeStyle')
  .set('authenticationInfos', 'auth')
  .set('format', 'format')
  .set('layername', 'geoserverLayerName')
  .set('ignoreUrlInCapabiltiesResponse', 'ignoreUrlInCapabilities')
  .set('matrixSet', 'matrixSet')
  .set('styles', 'wmsStyles')
  .set('url', 'url');
export class WmtsLayerConfig extends LayerConfig {
  /** Layer Geoserver auth info */
  auth?: { login: string; password: string };

  /** Active layer style (one of wmsStyles) */
  activeStyle?: string;

  /** Style name, passed as a request param */
  wmsStyles?: LayerStyle[];

  format?: string;
  geoserverLayerName = 'layername';
  ignoreUrlInCapabilities = false;
  matrixSet?: string;

  /** Base URL of the WMTS service */
  url?: string;

  constructor(layer?: Layer) {
    super(layer);
    if (layer) {
      WmtsLayerConfigMapper.forEach((newKey, originalKey) => {
        if (!isNil(layer[originalKey])) {
          (this[newKey] as unknown) = layer[originalKey];
        }
      });
    }
  }

  getActiveStyle(): LayerStyle | undefined {
    return this.wmsStyles?.find((style) => style.name === this.activeStyle);
  }

  override toApiLayer(): Layer {
    const layer = super.toApiLayer();
    WmtsLayerConfigMapper.forEach((newKey, originalKey) => {
      (layer[originalKey] as unknown) = this[newKey];
    });
    return layer;
  }

  static override fromLayer(layer: LayerConfig): WmtsLayerConfig {
    const newLayer = new WmtsLayerConfig();
    Object.assign(newLayer, layer);
    return newLayer;
  }
}

export class VectorLayerConfig extends LayerConfig {
  /** Features created using SMV */
  geojsonFeatures?: string;

  /** URL to fetch the GeoJSON features */
  url?: string;

  constructor(layer?: Layer) {
    super(layer);
    if (layer) {
      this.geojsonFeatures = layer.jsonData;
      this.url = layer.url;
    }
  }

  override toApiLayer(): Layer {
    const layer = super.toApiLayer();
    layer.jsonData = this.geojsonFeatures;
    layer.url = this.url;
    return layer;
  }

  static override fromLayer(layer: LayerConfig): VectorLayerConfig {
    const newLayer = new VectorLayerConfig();
    Object.assign(newLayer, layer);
    return newLayer;
  }
}
