import { Component } from '@angular/core';

@Component({
  selector: 'smv-revolt-filter',
  templateUrl: './revolt-filter.component.html',
  styleUrls: ['./revolt-filter.component.scss'],
})
export class RevoltFilterComponent {
  public title = $localize`:Widget:Filtre`;
}
