import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContextItem } from '@core/model/context-item.model';
import { environment } from '@env/environment';
import { Subject, catchError, throttleTime, throwError } from 'rxjs';
import { CommonService } from './common.service';

type ServerStatus = 'UP' | 'DOWN';
@Injectable({ providedIn: 'root' })
export class ServerStatusService extends CommonService {
  public serverStatus = new ContextItem<ServerStatus>('UP');
  private throttleSubject = new Subject();

  constructor(protected override http: HttpClient) {
    super(http);
    this.throttleSubject
      .pipe(throttleTime(10000, undefined, { leading: true, trailing: true }))
      .subscribe(() => this.sendCheckServerStatus());
  }

  public checkServerStatus() {
    this.throttleSubject.next(null);
  }

  private sendCheckServerStatus() {
    this.http
      .get(environment.apiUrl + '/rest/user/serverStatus', { responseType: 'text' })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (Math.trunc(error.status / 100) == 5) {
            this.serverStatus.setValue('DOWN');
          }
          return throwError(() => error);
        })
      )
      .subscribe((response) => {
        if (response === 'Server up') {
          this.serverStatus.setValue('UP');
        }
      });
  }
}
