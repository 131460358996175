<div class="smv-dialog">
  <h1 mat-dialog-title i18n>Informations du technicien</h1>
  <mat-divider></mat-divider>
  <div mat-dialog-content *ngIf="config?.layerCode; else accessDenied">
    <form [formGroup]="specifierForm" class="smv-form">
      <smv-form-field-wrapper formControlName="email">
        <span mat-label i18n="Specifier Info">Email</span>
      </smv-form-field-wrapper>
      <smv-form-field-wrapper formControlName="entity">
        <span mat-label>{{ config.labelPopup }}</span>
      </smv-form-field-wrapper>
    </form>
  </div>

  <ng-template #accessDenied>
    <div mat-dialog-content>
      <p i18n="Specifier Info|Malformed config" class="unselectable">
        La configuration de l'application est incomplète et ne vous permet pas d'y accéder. Veuillez contacter un
        administrateur.
      </p>
    </div>
  </ng-template>

  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false" i18n="Button">Annuler</button>
    <button
      mat-raised-button
      color="primary"
      (click)="handleConfirm()"
      [disabled]="specifierForm.invalid"
      i18n="Button"
    >
      Confirmer
    </button>
  </div>
</div>
