import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DropdownOption } from '@components/form/form-field-wrapper/form-field-wrapper.component';
import { LayerEditionValidationConfiguration, ValidationToNotify } from '@core/model/application-api/layer.model';
import { DataLayerConfig } from '@feature/client-app/data-layer-config.model';
import { debounce, interval } from 'rxjs';

interface LayerValidationConfig {
  grouped: FormControl<boolean>;
  warnings: FormControl<boolean>;
  distanceWarning: FormControl<number | undefined>;
  notify: FormControl<ValidationToNotify>;
  notifyForced: FormControl<boolean>;
  defaultValidationFeedback: FormControl<string | undefined>;
  defaultRejectionFeedback: FormControl<string | undefined>;
  comments: FormControl<boolean>;
}

@Component({
  selector: 'smv-layer-validation-tab',
  templateUrl: './layer-validation-tab.component.html',
  styleUrls: ['./layer-validation-tab.component.scss'],
})
export class LayerValidationTabComponent implements OnInit {
  @Input() layer!: DataLayerConfig;

  @Output() validConfigUpdate = new EventEmitter<LayerEditionValidationConfiguration>();

  public validConfig = new FormGroup<LayerValidationConfig>({
    grouped: new FormControl<boolean>(false, { nonNullable: true }),
    warnings: new FormControl<boolean>(true, { nonNullable: true }),
    distanceWarning: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: Validators.min(100),
    }),
    notify: new FormControl<ValidationToNotify>('NONE', { nonNullable: true }),
    notifyForced: new FormControl<boolean>(false, { nonNullable: true }),
    defaultValidationFeedback: new FormControl<string | undefined>(undefined, { nonNullable: true }),
    defaultRejectionFeedback: new FormControl<string | undefined>(undefined, { nonNullable: true }),
    comments: new FormControl<boolean>(false, { nonNullable: true }),
  });

  public notifyOptions: DropdownOption<ValidationToNotify>[] = [
    {
      label: $localize`:Layer Validation:Aucune notification`,
      value: 'NONE',
    },
    {
      label: $localize`:Layer Validation:Notifier les pièges de VigieVirose`,
      value: 'VV_TRAP',
    },
    {
      label: $localize`:Layer Validation:Notifier l'édition CNC`,
      value: 'CNC_EDITION',
    },
    {
      label: $localize`:Layer Validation:Faire suivre la modification d'une parcelle pour InterraScan`,
      value: 'IS_PARCEL',
    },
  ];

  ngOnInit(): void {
    if (this.layer.config.validationConfiguration) {
      this.validConfig.patchValue(this.layer.config.validationConfiguration);
    }
    this.emitAttributes();

    this.validConfig.valueChanges.pipe(debounce(() => interval(500))).subscribe(() => this.emitAttributes());
  }

  private emitAttributes(): void {
    this.validConfigUpdate.emit(this.validConfig.valid ? this.validConfig.getRawValue() : undefined);
  }
}
