<mat-form-field appearance="fill">
  <mat-label i18n>Rechercher un lieu</mat-label>
  <input
    type="text"
    matInput
    placeholder="Renseigner 3 caractères minimum"
    i18n-placeholder
    [formControl]="addressSearch"
    [matAutocomplete]="auto"
    #trigger="matAutocompleteTrigger"
    (keyup.enter)="onKeyUp(); trigger.closePanel()"
  />
  <button matIconSuffix mat-icon-button (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelect($event.option.value)">
    <mat-option disabled *ngIf="!filteredAddresses.length" i18n>Pas de résultat</mat-option>
    <mat-option *ngFor="let address of filteredAddresses" [value]="address">
      <div class="address-label">
        <b>{{ address.properties?.["label"] }}</b>
      </div>
      <p class="address-context">
        <em>{{ address.properties?.["city"] }}, {{address.properties?.["context"] }}</em>
      </p>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
