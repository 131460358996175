import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'rangeDate', standalone: true })
export class RangeDateSliderPipe implements PipeTransform {
  transform(startIndex: number, dates: string[] | Date[], endIndex: number): string {
    if (startIndex === endIndex) {
      return this.toDateString(dates[startIndex]);
    }
    return this.toDateString(dates[startIndex]) + ' - ' + this.toDateString(dates[endIndex]);
  }

  private toDateString(date: Date | string): string {
    // TODO: récupérer l'info depuis i18n
    return new Date(date).toLocaleString('fr-FR').split(' ')[0].split(',')[0];
  }
}
