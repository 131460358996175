<div [hidden]="panelHide">
  <mat-card class="side-panel">
    <mat-card-title>
      <div>
        <span i18n>Service Eudémis Administration</span>
        <button mat-icon-button (click)="displayed.emit()">
          <mat-icon>keyboard_double_arrow_right</mat-icon>
        </button>
      </div>
    </mat-card-title>

    <mat-card-content>
      <smv-zoom-position [label]="labelZoom" [positionFunction]="getPosition" />
      <smv-customer-filter [appId]="applicationId" />

      <button mat-raised-button *ngIf="canImport" color="primary" (click)="openTrapStats()" class="trapview-traps">
        <span i18n="Icare Administration|TrapView Trap Summary">Récapitulatif des pièges TrapView</span>
      </button>
    </mat-card-content>
  </mat-card>
</div>
