<ng-container *ngIf="layersWithLegend.length; else NoLegend">
  <mat-accordion>
    <mat-expansion-panel *ngFor="let layer of layersWithLegend; first as isFirst" [expanded]="isFirst">
      <mat-expansion-panel-header>
        <mat-panel-title> {{ layer.config.shortName }} </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container [ngSwitch]="layer.config.type">
        <ng-container *ngSwitchCase="LayerType.WMS">
          <smv-wms-legend [layer]="layer" />
        </ng-container>
        <ng-container *ngSwitchCase="LayerType.WFS">
          <ng-container *ngTemplateOutlet="vectorLegend"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="LayerType.VECTOR">
          <ng-container *ngTemplateOutlet="vectorLegend"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="LayerType.VECTOR_TILE">
          <ng-container *ngTemplateOutlet="vectorLegend"></ng-container>
        </ng-container>
        <ng-template #vectorLegend><smv-vector-legend [layerConfig]="layer.config" /></ng-template>
        <ng-container *ngSwitchDefault i18n="Legend">Pas de légende</ng-container>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>

<ng-template #NoLegend>
  <ng-container *ngIf="layers.length; else NoLayer" i18n="Legend">
    Aucune légende n'est disponible pour les couches affichées
  </ng-container>
</ng-template>
<ng-template #NoLayer i18n="Legend">Veuillez afficher une couche</ng-template>
