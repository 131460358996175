import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  DropdownOption,
  FormFieldWrapperComponent,
} from '@components/form/form-field-wrapper/form-field-wrapper.component';
import { NotificationService } from '@core/services/notification.service';
import { InterraScanService } from '@widgets/interra-scan/interra-scan.service';
import { InterraScanCrop } from '@widgets/interra-scan/model/crop.model';
import { Farmer } from '@widgets/interra-scan/model/farmer.model';
import { InterraScanOrder } from '@widgets/interra-scan/model/order.model';
import { Package } from '@widgets/interra-scan/model/package.model';
import { Parcel } from '@widgets/interra-scan/model/parcel.model';
import { isNil } from 'lodash-es';
import { filter, forkJoin, Observable, tap } from 'rxjs';

@Component({
  selector: 'smv-interra-scan-parcel-detail-form',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, ReactiveFormsModule, FormFieldWrapperComponent],
  templateUrl: './parcel-detail-form.component.html',
  styleUrls: ['./parcel-detail-form.component.scss'],
})
export class ParcelDetailFormComponent implements OnChanges {
  @Input() appId!: number;
  @Input() parcel?: Partial<Parcel>;
  @Output() formInvalid = new EventEmitter<boolean>();
  @Output() parcelChange = new EventEmitter<Partial<Parcel>>();

  public farmers: DropdownOption<Farmer>[] = [];
  public orders: DropdownOption<InterraScanOrder>[] = [];
  public crops: DropdownOption<InterraScanCrop>[] = [];
  public packages: DropdownOption<Package>[] = [];

  private DEFAULT_ID = 'default';

  public form = this.formBuilder.group({
    parcelName: [undefined as string | undefined, Validators.required],
    package: [undefined as Package | undefined, Validators.required],
    farmer: [undefined as Farmer | undefined, Validators.required],
    order: [undefined as InterraScanOrder | undefined],
    idealScanDate: [undefined as Date | undefined],
    currentCrop: [undefined as InterraScanCrop | undefined],
    nextCrop: [undefined as InterraScanCrop | undefined],
    harvestDate: [undefined as Date | undefined],
    sowingDate: [undefined as Date | undefined],
    physicalAccess: [undefined as string | undefined],
  });

  private init = false;

  constructor(
    private formBuilder: NonNullableFormBuilder,
    private interraScanService: InterraScanService,
    private notificationService: NotificationService
  ) {
    this.form.controls.farmer.valueChanges.subscribe((farmer) => {
      this.orders = [];
      if (farmer) {
        this.createOrderObs(farmer.id).subscribe();
      }
    });

    this.form.controls.order.valueChanges.subscribe((order) => {
      if (order) {
        this.form.patchValue({ idealScanDate: order.idealScanDate });
      }
    });

    this.form.valueChanges.pipe(filter(() => this.init)).subscribe(() => {
      this.formInvalid.emit(this.form.invalid);
      if (this.form.valid) {
        const formValues = this.form.getRawValue();
        const newOrder = formValues.order;
        if (newOrder) {
          newOrder.idealScanDate = formValues.idealScanDate;
          formValues.order = newOrder;
        }

        this.parcelChange.emit({ ...this.parcel, ...formValues });
      }
    });
  }

  ngOnChanges(): void {
    if (!isNil(this.appId) && !isNil(this.parcel) && !this.init) {
      if (!isNil(this.parcel.order)) {
        this.parcel.order.idObs = this.parcel.order.id!;
      }
      if (!isNil(this.parcel.id)) {
        this.form.patchValue(this.parcel);
      }
      const farmerObs = this.interraScanService.retrieveAllFarmers(this.appId).pipe(
        tap((farmers) => {
          if (farmers.length) {
            this.farmers = farmers.map((farmer) => {
              return {
                label: `${farmer.firstName} ${farmer.lastName} (${farmer.email})`,
                value: farmer,
              };
            });
          } else {
            this.notificationService.error(
              $localize`:InterraScan|No farmer declared:Aucun agriculteur n'est déclaré pour votre structure. Veuillez en créer dans la gestion des agriculteurs.`
            );
          }
        })
      );

      const cropObs = this.interraScanService.retrieveAllCrops(this.appId).pipe(
        tap((crops) => {
          if (crops.length) {
            this.crops = crops.map((crop) => {
              return {
                label: `${crop.label}`,
                value: crop,
              };
            });
          } else {
            this.notificationService.error(
              $localize`:InterraScan|No crop declared:Aucune culture n'est déclarée pour votre structure.`
            );
          }
        })
      );
      const packageObs = this.interraScanService.retrieveAllPackages().pipe(
        tap(
          (packages) =>
            (this.packages = packages.reverse().map((packageOffer) => {
              return {
                label: packageOffer.label,
                value: packageOffer,
              };
            }))
        )
      );
      forkJoin([farmerObs, cropObs, packageObs]).subscribe({
        next: () => {
          if (!isNil(this.parcel)) {
            this.form.patchValue(this.parcel);
          }
        },
        error: () => this.notificationService.unknownServerError(),
      });
      this.init = true;
    }
  }

  createOrderObs(farmerId: string): Observable<InterraScanOrder[]> {
    const orderObs = this.interraScanService.retrieveAllOrders(farmerId).pipe(
      tap((orders) => {
        this.orders = [{ label: $localize`:New order:Nouvelle commande`, value: { idObs: this.DEFAULT_ID } }];
        if (orders.length) {
          this.orders.push(
            ...orders.map((order) => {
              order.idObs = order.id!;
              return {
                label: `${order.name} (${order.year})`,
                value: order,
              };
            })
          );
        }
        // On retrouve la commande de la parcelle, si on n'a pas changé d'agriculteur
        if (!isNil(this.parcel) && this.parcel.order && this.parcel.farmer && this.parcel.farmer.id == farmerId) {
          this.form.patchValue({ order: this.parcel.order });
        }
      })
    );
    return orderObs;
  }
}
