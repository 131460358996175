import { ParsedCapabilities, ParsedLayer } from '@core/model/capabilities.model';
import * as X2JS from 'x2js';

type X2JsStringElement = { toString: () => string };
export interface WfsLayerSchema {
  Title: string;
  Name: string;
  Abstract: string;
  WGS84BoundingBox: { LowerCorner: X2JsStringElement; UpperCorner: X2JsStringElement };
  OutputFormats: { Format: string };
  // For WFS version 1.*
  DefaultSRS: string;
  OtherSRS?: string[];
  // For WFS version 2.*
  DefaultCRS: string;
  OtherCRS?: string[];
}

export class WFSCapabilitiesParser {
  private parser = new X2JS();

  read(xml: string): ParsedCapabilities {
    return this.parser.xml2js(xml);
  }

  parseLayer(layer: WfsLayerSchema, preferredProjection: string): ParsedLayer {
    const lowerCorner = layer.WGS84BoundingBox.LowerCorner.toString().split(' ');
    const upperCorner = layer.WGS84BoundingBox.UpperCorner.toString().split(' ');
    const bbox = lowerCorner.concat(upperCorner).map((coord) => +coord);

    const preferredProjCode = preferredProjection.replace('EPSG:', '');
    const availableProjections = [layer.DefaultSRS ?? layer.DefaultCRS, ...(layer.OtherSRS ?? layer.OtherCRS ?? [])];
    const preferredProjectionFound = availableProjections.find((proj) => proj.includes(preferredProjCode));
    return {
      bbox: bbox as [number, number, number, number],
      detail: layer.Abstract,
      serverIdentifier: layer.Name,
      title: layer.Title,
      outputFormat: layer.OutputFormats?.Format,
      projection: preferredProjectionFound ?? layer.DefaultSRS,
    };
  }
}
