import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'smv-revolt',
  templateUrl: './revolt.component.html',
  styleUrls: ['./revolt.component.scss', '../../client-app-panel.scss'],
})
export class RevoltComponent {
  @Input() applicationId!: number;
  @Input() panelHide = true;
  @Output() displayed = new EventEmitter();
}
