<smv-panel [title]="title">
  <mat-radio-group [(ngModel)]="filter" (change)="onFilterChange()" class="smv-filter">
    <mat-radio-button value="FILTER_OBSERVATION" i18n>Observations</mat-radio-button>
    <ng-content select="[samples]" *ngIf="filter === 'FILTER_OBSERVATION'" />
    <mat-radio-button value="FILTER_SIMULATION" *ngIf="authorizedSimulation" i18n>
      Simulations
    </mat-radio-button>
    <ng-content select="[simulations]" *ngIf="filter === 'FILTER_SIMULATION'" />
  </mat-radio-group>
</smv-panel>
